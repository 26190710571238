import React from "react"
import { Link, hashHistory } from "react-router"
import axios from "axios"
import cookie from "react-cookie"
import { loadAnalytics } from "./withTracker"
import DocumentMeta from "react-document-meta"
import Loading from "./loading"

import config from "./config"
const APPID = config.ids.appId
const APIURL = config.path.apiUrl
import lang from "./lang"
//const nl2br = require('react-nl2br');
var HtmlToReactParser = require("html-to-react").Parser
var htmlToReactParserTo = new HtmlToReactParser()
var helper = require("./Helper.js")

class Pages extends React.Component {
  constructor(props) {
    super(props)
    this.state = { pageTitle: "", pageDesc: "", status: "Loading", windowHeightNormal: 0, windowHeight: 0 }
    /*add class */
    helper.addBodyClass()
  }
  componentWillMount() {
    var site_maintenance_verify = cookie.load("client_site_maintenance_verify") !== "" && cookie.load("client_site_maintenance_verify") !== "undefined" && cookie.load("client_site_maintenance_verify") !== undefined ? cookie.load("client_site_maintenance_verify") : ""

    var site_maintenance_popup_open = cookie.load("site_maintenance_popup_open") !== "" && cookie.load("site_maintenance_popup_open") !== "undefined" && cookie.load("site_maintenance_popup_open") !== undefined ? cookie.load("site_maintenance_popup_open") : ""

    if (site_maintenance_popup_open === "1" && (site_maintenance_verify === "" || site_maintenance_verify === "0")) {
      hashHistory.push("/")
      return
    }

    const pageSlug = this.props.params.pageSlug
    axios.get(APIURL + "cms/page?status=A&app_id=" + APPID + "&page_slug=" + pageSlug).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ pageTitle: res.data.result_set[0].cmspage_title, status: "ok" })
        this.setState({ pageDesc: res.data.result_set[0].cmspage_description })
      } else if (res.data.status === "error") {
        this.setState({ status: "error" })
      }
    })

    var windowHeight = window.innerHeight
    this.setState({ windowHeight: windowHeight - 45 + "px" })
    this.setState({ windowHeightNormal: windowHeight + "px" })
  }
  componentDidMount() {
    loadAnalytics()
  }

  render() {
    if (this.state.status === "ok") {
      return (
        <div className="container-fluid desktop-container" style={{ minHeight: this.state.windowHeightNormal }}>
          {" "}
          <DocumentMeta
            {...{
              title: this.state.pageTitle + lang.site_title_with_separator,
              description: this.state.pageTitle,
              meta: {
                name: {
                  keywords: this.state.pageTitle,
                },
              },
            }}
          />
          <div className="row">
            <div className="col-xs-12 main-title-bar">
              <Link to={"/"}>
                {" "}
                <img src="/img/left-arrow.png" className="pull-left icon-img-small" alt="left-arrow" />{" "}
              </Link>
              <h3 className="top-title main-title padding-right-20 text-uppercase">{this.state.pageTitle}</h3>
            </div>
          </div>
          <div className="row">
            <div className="space-10 bg-gray"></div>
          </div>
          <div className="white-outer center-block">
            <div className="col-xs-10 div-center">
              <div className="space-5"></div>
              <p className="text-left font-bold font-size-18">{this.state.pageTitle}</p>
              {htmlToReactParserTo.parse(this.state.pageDesc)}
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="space-30"></div>
        </div>
      )
    } else if (this.state.status === "error") {
      return (
        <div className="container-fluid desktop-container" style={{ minHeight: this.state.windowHeightNormal }}>
          <div className="row">
            <div className="col-xs-12 main-title-bar">
              <Link to={"/"}>
                {" "}
                <img src="/img/left-arrow.png" className="pull-left icon-img-small" alt="left-arrow" />{" "}
              </Link>
              <h3 className="top-title main-title padding-right-20 text-uppercase">Page content not found</h3>
            </div>
          </div>
          <div className="row">
            <div className="space-10 bg-gray"></div>
          </div>
          <div className="white-outer center-block">
            <div className="col-xs-10 div-center">
              <div className="space-5"></div>
              <p style={{ textAlign: "center" }}>Page content not found</p>
              <div className="space-5"></div>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-30"></div> <div className="space-30"></div> <div className="space-30"></div> <div className="space-30"></div>
        </div>
      )
    } else {
      return (
        <div className="container-fluid desktop-container">
          <div className="row">
            <Loading />
          </div>
        </div>
      )
    }
  }
}
export default Pages

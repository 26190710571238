/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import { Link, hashHistory } from "react-router"
import update from "immutability-helper"
import validator from "validator"
import { loadAnalytics } from "./withTracker"
import { validated } from "react-custom-validation"
import getResult from "./Api"
import axios from "axios"
import { API_v4_URL } from "./constants"
import cookie from "react-cookie"
import FacebookLogin from "react-facebook-login"
import DocumentMeta from "react-document-meta"
import Loading from "./loading"
import Alert from "./alert"

import config from "./config"
import lang from "./lang"
const APIURL = config.path.apiUrl
const APIURLV2 = config.path.apiUrlv2
const APPID = config.ids.appId
var helper = require("./Helper.js")
var dateFormat = require("dateformat")
const apps = require("./apps")
const MemberAppID = "B401FC82-8E39-4076-838A-55192F765004"
const MemberAppIDGour = "B93EC9A9-1A67-4EBA-ADB7-BC7C14FA88A4"
const MemberAppIDSCC = "6C0280A0-B655-4E0F-A43F-041C189683BA"

/* Load meta tags   sdsd */
const meta = {
  title: lang.signin_meta_title,
  description: lang.signin_meta_title,
  meta: {
    name: {
      keywords: lang.signin_meta_title,
    },
  },
}

class Signin extends React.Component {
  /* If user logged in goto  order page */

  state = {
    fields: {
      email: "",
      password: "",
      settings: "",
      invalidMemberID: "",
      accepted_coupons: 0,
      email_missmatch_error: "",
      email_missmatch_error_count: 0,
    },
    status: "Loading",
    /*add class */
    // helper.addBodyClass();
  }

  componentWillMount() {
    this.setState({ status: "ok" })

    if (cookie.load("dineinClick") === "yes") {
      cookie.save("dineinFrom", "signin")
    }

    var promise = getResult("settings", "")
    promise.then((res) => {
      this.setState({ settings: res.data.result_set })
      cookie.save("client_company_name", res.data.result_set.company_name)
      cookie.save("check_new_user", 0)
      if (typeof res.data.result_set.client_enable_express_checkout !== "undefined" && parseInt(res.data.result_set.client_enable_express_checkout) === 1) {
        hashHistory.push("/new-signin")
      }
    })
  }

  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }))
  }

  setStateLoading(sts) {
    this.setState({ status: sts })
  }

  componentDidMount() {
    var site_maintenance_verify = cookie.load("client_site_maintenance_verify") !== "" && cookie.load("client_site_maintenance_verify") !== "undefined" && cookie.load("client_site_maintenance_verify") !== undefined ? cookie.load("client_site_maintenance_verify") : ""

    var site_maintenance_popup_open = cookie.load("site_maintenance_popup_open") !== "" && cookie.load("site_maintenance_popup_open") !== "undefined" && cookie.load("site_maintenance_popup_open") !== undefined ? cookie.load("site_maintenance_popup_open") : ""

    if (site_maintenance_popup_open === "1" && (site_maintenance_verify === "" || site_maintenance_verify === "0")) {
      hashHistory.push("/")
      return
    }

    var list = document.getElementsByClassName("form-group")
    var n
    for (n = 0; n < list.length; ++n) {
      list[n].classList.add("is-empty")
    }

    var promise = getResult("settings", "")
    promise.then((res) => {
      this.setState({ settings: res.data.result_set })
      cookie.save("client_otp_verification_enable", res.data.result_set.client_otp_verification_enable)
      cookie.save("client_otp_verification_via", res.data.result_set.client_otp_verification_via)
      cookie.save("client_company_name", res.data.result_set.company_name)
      cookie.save("check_new_user", 0)
    })
    setTimeout(
      function () {
        if (this.state.settings.client_facebook_login == "1") {
          $(".row-facebook-login").removeClass("hidden")
        }
      }.bind(this),
      1000
    )

    loadAnalytics()
  }
  /* continue with delivery success */

  handleFormSubmit = () => {
    document.getElementById("loadIcon").style.display = "block"
    this.setStateLoading("Loading")
    const formPayload = this.state.fields
    var qs = require("qs")
    var postObject = {
      app_id: APPID,
      type: "web",
      login_username: formPayload.email,
      login_password: formPayload.password,
    }
    axios
      .post(APIURL + "customer/login", qs.stringify(postObject))
      .then((response) => {
        document.getElementById("loadIcon").style.display = "none"
        if (response.data.status === "ok") {
          cookie.remove("isTempUser")
          //alert(response.data.result_set.customer_phone);
          var mobileno = ""
          if (response.data.result_set.customer_phone !== "" || response.data.result_set.customer_phone !== null || typeof response.data.result_set.customer_phone !== "undefined") {
            mobileno = response.data.result_set.customer_phone
          }

          /* set User cookie values - Start */
          cookie.save("UserId", response.data.result_set.customer_id)
          cookie.save("UserFname", response.data.result_set.customer_first_name !== "" ? response.data.result_set.customer_first_name : "")
          cookie.save("UserLname", response.data.result_set.customer_last_name != "" ? response.data.result_set.customer_last_name : "")
          cookie.save("UserMobile", mobileno)
          cookie.save("UserEmail", response.data.result_set.customer_email)
          cookie.save("UserDefaultAddress", response.data.result_set.customer_address_name)
          cookie.save("UserDefaultUnitOne", response.data.result_set.customer_address_line1)
          cookie.save("UserDefaultUnitTwo", response.data.result_set.customer_address_line2)
          cookie.save("UserDefaultPostalCode", response.data.result_set.customer_postal_code)
          /* set User cookie values - End */

          /* set redirection */

          /* upadte customer info */
          var qs = require("qs")
          var postObject = {
            app_id: APPID,
            reference_id: getResult("referenceId"),
            customer_id: response.data.result_set.customer_id,
            availability_id: cookie.load("defaultAvilablityId"),
          }

          axios.post(APIURL + "cart/update_customer_info", qs.stringify(postObject)).then((res) => {
            if (res.data.status === "ok" && cookie.load("reserVationClick") !== "fromhome") {
              /* check FB login function */
              cookie.save("cartValue", "Yes")
              if (cookie.load("defaultAvilablityId") === config.ids.dineinId) {
                if (APPID === "287E5552-328E-4DBE-A3CA-E998ACCAC9BD") {
                  helper.sarveshTableSetup()
                  // this.setStateLoading('ok');
                } else {
                  this.setStateLoading("ok")
                  if (typeof cookie.load("orderOutletId") !== "undefined" && cookie.load("orderOutletId") !== "" && typeof cookie.load("orderTableNo") !== "undefined" && cookie.load("orderTableNo") !== "") {
                    hashHistory.push("/checkout")
                  } else {
                    hashHistory.push("/book-a-table")
                  }
                }
              } else {
                this.setStateLoading("ok")
                document.getElementById("loadIcon").style.display = "none"
                hashHistory.push("/delivery-date")
              }
            } else {
              if (cookie.load("reserVationClick") === "fromhome") {
                hashHistory.push("/")
                cookie.save("reserVationClick", "fromlogin")
              } else if (cookie.load("dineinClick") === "yes" && cookie.load("defaultAvilablityId") === config.ids.dineinId) {
                if (APPID === "287E5552-328E-4DBE-A3CA-E998ACCAC9BD") {
                  helper.sarveshTableSetup()
                } else {
                  this.setStateLoading("ok")
                  hashHistory.push("/book-a-table")
                }
              } else {
                //hashHistory.push("/my-account");
                hashHistory.push("/")
                cookie.save("cartValue", "No")
              }
            }
          })

          /*  if( cookie.load('cartValue') === "Yes"){  
					 if(cookie.load('defaultAvilablityId') === config.ids.dineinId){  console.log(2); 
							hashHistory.push("/book-a-table");
						   }
			  }else{
			 	hashHistory.push("/#/dashboard");
			  }   */
        } else {
          this.setStateLoading("error")
          document.getElementById("loadIcon").style.display = "none"
          //this.setState({signInError :response.data.message });
          //cookie.save("signInError",response.data.message);
          //	cookie.save("signInError",response.data.message);
          helper.showInfo(response.data.message)

          //$("#Signin-Error").modal("toggle");
          //document.getElementById("form-error").innerHTML='<div class="box_error"><ul><li>'+response.data.message+'</li></ul></div>';
        }
      })
      .catch(function (error) {
        helper.showInfo(error)
      })
  }
  render() {
    return <Form fields={this.state.fields} onChange={this.fieldChange} onValid={this.handleFormSubmit} onInvalid={() => console.log("Form invalid!")} />
  }
}
const isEmpty = (value) => (value === "" ? "This field is required." : null)

const isEmail = (email) => (validator.isEmail(email) ? null : "This is not a valid email.")

function validationConfig(props) {
  const { email, password } = props.fields

  return {
    fields: ["email", "password"],

    validations: {
      email: [
        [isEmail, email],
        //[isUnique, email]
      ],
      password: [[isEmpty, password]],
    },
  }
}

class Form extends React.Component {
  state = {
    status: "Loading",
    fbId: "",
    guestFields: {},
    check_new_user: 0,
    guestErrors: {},
    guest_checkout_enable: 0,
    client_anonymous_customer_login_enable: 0,
    guest_type: 0,
    backtoGuestform: false,
    renderLoading: "Loading",
    windowHeightNormal: 0,
    windowHeight: 0,
    email_missmatch_error: "",
    email_missmatch_error_count: 0,
  }

  constructor(props) {
    super(props)
    // alert('load');
    var promise = getResult("settingsProWithavailability", "No")
    promise.then((res) => {
      //alert('loaded');
      //this.setStateLoading('Loading');
      this.setState({
        client_site_url: res.data.result_set.client_site_url,
        fbId: res.data.result_set.ninja_pro_fb_app_id,
        renderLoading: "ok",
        guest_checkout_enable: res.data.result_set.client_guest_checkout_enable,
        client_anonymous_customer_login_enable: res.data.result_set.client_anonymous_customer_login_enable,
      })
    })
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
  }
  componentDidMount() {
    if (APPID === MemberAppID || APPID === MemberAppIDSCC || APPID === MemberAppIDGour) {
      var current = this
      setTimeout(function () {
        current.guestCheckout()
      }, 1000)
    }
  }
  componentWillMount() {
    this.setStateLoading("ok")

    var windowHeight = window.innerHeight
    this.setState({ windowHeight: windowHeight + "px" })
  }

  /* get FB ID*/

  /* guest form */
  guestCheckout() {
    $("#GuestCheckout").modal("toggle")
  }

  enableGuset() {
    $(".enable-guest").show()
    $(".disable-guest").hide()
    this.setState({ guest_type: 1 })
  }

  disableGuset() {
    $(".enable-guest").hide()
    $(".disable-guest").show()
    this.setState({ guest_type: 0 })
  }

  showGusetCheckout() {
    if (parseInt(this.state.guest_checkout_enable) === 1) {
      return (
        <div className="row row-guest-checkout">
          <div className="text-center ">
            <button type="button" onClick={this.guestCheckout.bind(this)} className="btn text-uppercase btn-guest-checkout">
              <img src="/img/icons/user-white.png" alt="guest-checkout" className="icon-img-guest-checkout" />
              {APPID === "B401FC82-8E39-4076-838A-55192F765004" || APPID === "6C0280A0-B655-4E0F-A43F-041C189683BA" || APPID === "B93EC9A9-1A67-4EBA-ADB7-BC7C14FA88A4" ? "Member Login" : "Express Checkout"}
            </button>
          </div>
        </div>
      )
    }
  }

  getAnonymousCheckout() {
    $("#loadIcon").show()
    axios.get(API_v4_URL + "anonymouscustomer/get_anonymous_customer/?app_id=" + APPID).then((response) => {
      $("#loadIcon").hide()
      if (response.data.status === "ok") {
        this.setGuestData(response, "isAnonymousUser")
      } else {
        helper.showInfo("This feature is currently unavailable please try again later")
      }
      return false
    })
  }

  showAnonymousCheckout() {
    if (parseInt(this.state.client_anonymous_customer_login_enable) === 1) {
      return (
        <div className="row row-guest-checkout">
          <div className="text-center ">
            <h4>Continue without an account</h4>
            <button type="button" onClick={this.getAnonymousCheckout.bind(this)} className="btn text-uppercase btn-guest-checkout btn-guest-checkout-btn">
              <img src="/img/icons/user-white.png" alt="guest-checkout" className="icon-img-guest-checkout" />
              {"Guest Order"}
            </button>
          </div>
        </div>
      )
    }
  }

  transformEntry(item, type) {
    switch (type) {
      case "email":
        var parts = item.split("@"),
          len = parts[0].length
        return item.replace(parts[0].slice(1, -1), "*".repeat(len - 2))
      case "phone":
        return item[0] + "*".repeat(item.length - 4) + item.slice(-3)
      default:
        throw new Error("Undefined type: " + type)
    }
  }

  handleValidation() {
    let fields = this.state.guestFields
    let errors = {}
    let formIsValid = true
    var guestMobile = fields["guest-mobile"]
    if (APPID === MemberAppID || APPID === MemberAppIDSCC || APPID === MemberAppIDGour) {
      var membershipid = fields["guest-membershipid"]
      if (!membershipid) {
        formIsValid = false
        errors["guest-membershipid"] = "Membership # field is required."
      } /* else {
	        	if(APPID===MemberAppID) {
	        		var validMember = '^[a-zA-Z]{1}[0-9]{4}$';
		        	var result = membershipid.match(validMember);
		        	if(result===null || result==="") {
		        		console.log('aaaa', result)
		        		formIsValid = false;
		           		errors["guest-membershipid"] = "Invalid Membership #";
		        	}
	        	}
	        }*/
      /*else if(this.state.invalidMemberID===true) {
	           formIsValid = false;
	           errors["guest-membershipid"] = "Invalid Membership #";
	        }*/
    }
    if (!guestMobile) {
      formIsValid = false
      errors["guest-mobile"] = "Mobile number field is required."
    }

    if (typeof guestMobile !== "undefined") {
      if (!guestMobile.match(/^[0-9]+$/)) {
        formIsValid = false
        errors["guest-mobile"] = "Invalid Mobile number"
      } else if (guestMobile.length < 8) {
        formIsValid = false
        errors["guest-mobile"] = "Mobile number must be of minimum 8 characters length."
      }
    }

    if (!fields["guest-name"]) {
      formIsValid = false
      errors["guest-name"] = "Name field is required."
    }

    if (typeof fields["guest-name"] !== "undefined") {
      if (!fields["guest-name"].match(/^[A-Za-z ]{3,20}$/)) {
        formIsValid = false
        errors["guest-name"] = "Name must contain only letters."
      }
    }

    if (!fields["guest-email"]) {
      formIsValid = false
      errors["guest-email"] = "Email field is required."
    }

    if (typeof fields["guest-email"] !== "undefined" && fields["guest-email"] != "") {
      let lastAtPos = fields["guest-email"].lastIndexOf("@")
      let lastDotPos = fields["guest-email"].lastIndexOf(".")

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["guest-email"].indexOf("@@") == -1 && lastDotPos > 2 && fields["guest-email"].length - lastDotPos > 2)) {
        formIsValid = false
        errors["guest-email"] = "Email is not valid."
      }
    }

    /*   if(!fields["guest-cemail"]){
		formIsValid = false;
		errors["guest-cemail"] = "Email field is required.";
	 }

	 if(typeof fields["guest-cemail"] !== "undefined"){
		let lastAtPos = fields["guest-cemail"].lastIndexOf('@');
		let lastDotPos = fields["guest-email"].lastIndexOf('.');

		if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["guest-cemail"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["guest-cemail"].length - lastDotPos) > 2)) {
		   formIsValid = false;
		   errors["guest-cemail"] = "Email is not valid.";
		 }
	}   
 if( (typeof fields["guest-email"] !== "undefined" && typeof fields["guest-cemail"] !== "undefined" )  && (fields["guest-email"].toLowerCase() != fields["guest-cemail"].toLowerCase()) && (fields["guest-email"].toLowerCase()  !=="" && fields["guest-cemail"].toLowerCase()  !== "") ) {

	if( (typeof fields["guest-cemail"] !== "undefined" &&  typeof fields["guest-email"] !== "undefined") && (fields["guest-email"].toLowerCase() != fields["guest-cemail"].toLowerCase()) && (fields["guest-email"].toLowerCase()  !=="" && fields["guest-cemail"].toLowerCase()  !== "") ) {
		formIsValid = false;
		errors["guest-email"] = "Your email and confirmation email  do not match.";
	 }	 */

    if (parseInt(this.state.guest_type) === 1) {
      if (!fields["guest-password"]) {
        formIsValid = false
        errors["guest-password"] = "Password field is required."
      }
      if (typeof fields["guest-password"] !== "undefined") {
        if (fields["guest-password"].length < 6) {
          formIsValid = false
          errors["guest-password"] = "Password must be of minimum 6 characters length."
        }
      }

      if (!fields["guest-retype-password"]) {
        formIsValid = false
        errors["guest-retype-password"] = "Confirm Password field is required."
      }

      if (typeof fields["guest-retype-password"] !== "undefined") {
        if (fields["guest-retype-password"].length < 6) {
          formIsValid = false
          errors["guest-retype-password"] = "Confirm Password must be of minimum 6 characters length"
        } else if (fields["guest-password"] != fields["guest-retype-password"]) {
          formIsValid = false
          errors["guest-retype-password"] = "Your password and confirmation password do not match."
        }
      }
    }

    if (!fields["guest-accepted-alcohol"] && (APPID === "2AE048E7-2D84-42BE-9BB5-22016FC4DFBC" || APPID === "44275190-B355-4486-8083-EE1C63607916" || APPID === "F34559CA-596A-4F2E-8547-17B2AEBB3326")) {
      formIsValid = false
      errors["guest-accepted-alcohol"] = "This field is required."
    }

    this.setState({ guestErrors: errors })
    return formIsValid
  }

  sendOtpToCustomer() {
    var company_otp_verification = cookie.load("client_otp_verification_enable")
    var company_otp_via = cookie.load("client_otp_verification_via")

    if (company_otp_verification === "1") {
      $("#loadIcon").show()
      var qs = require("qs")
      var postObject = {
        app_id: APPID,
        customer_first_name: this.state.guestFields["guest-name"],
        customer_last_name: "",
        customer_email: this.state.guestFields["guest-email"],
        customer_phone: this.state.guestFields["guest-mobile"],
        customer_otp_type: company_otp_via,
      }

      axios.post(API_v4_URL + "customer/send_customer_otp", qs.stringify(postObject)).then((response) => {
        if (response.data.status === "ok") {
          $("#loadIcon").hide()
          document.getElementById("otp_verification_option").style.display = "block"
          if (company_otp_via === "1") {
            helper.showInfo("OTP has been sent to your email id (" + this.transformEntry(this.state.guestFields["guest-email"], "email") + " )")
          } else {
            helper.showInfo("OTP has been sent to your mobile number (" + this.transformEntry(this.state.guestFields["guest-mobile"], "phone") + " )")
          }
          document.getElementById("loadIcon").style.display = "none"
          document.getElementById("gust_checkout_form").style.display = "none"

          //  return false;
        } else {
          this.setStateLoading("error")
          document.getElementById("otp_error_msg").innerHTML = '<span class="spn-error">' + response.data.message + "</span>"
          document.getElementById("loadIcon").style.display = "none"
          $("#loadIcon").hide()
        }
        return false
      })
    }
  }

  applyValidation() {
    if (this.state.check_new_user === 1) {
      //helper.showInfo("You already have account.");
      //return false;
    }

    if (this.handleValidation()) {
      /*if(APPID===MemberAppID) {
			if(this.state.invalidMemberID===true) {
				this.setState({guestErrors : {"guest-membershipid":'Invalid Membership #'}})
				return false;
			}
		}*/

      var guestType = parseInt(this.state.guest_type)
      $("#loadIcon").show()
      this.setState({ email_missmatch_error: "" })
      var company_otp_verification = cookie.load("client_otp_verification_enable")
      var company_otp_via = cookie.load("client_otp_verification_via")

      const skipflow = 1
      if (company_otp_verification === "1" && cookie.load("check_new_user") === "0" && skipflow === 0) {
        var qs = require("qs")
        var postObject = {
          app_id: APPID,
          customer_first_name: this.state.guestFields["guest-name"],
          customer_last_name: "",
          customer_email: this.state.guestFields["guest-email"],
          customer_phone: this.state.guestFields["guest-mobile"],
          customer_otp_type: company_otp_via,
        }

        axios.post(API_v4_URL + "customer/send_customer_otp", qs.stringify(postObject)).then((response) => {
          if (response.data.status === "ok") {
            document.getElementById("otp_verification_option").style.display = "block"
            if (company_otp_via === "1") {
              helper.showInfo("OTP has been sent to your email id (" + this.transformEntry(this.state.guestFields["guest-email"], "email") + " )")
            } else {
              helper.showInfo("OTP has been sent to your mobile number (" + this.transformEntry(this.state.guestFields["guest-mobile"], "phone") + " )")
            }
            document.getElementById("loadIcon").style.display = "none"
            document.getElementById("gust_checkout_form").style.display = "none"

            //  return false;
          } else {
            this.setStateLoading("error")
            document.getElementById("otp_error_msg").innerHTML = '<span class="spn-error">' + response.data.message + "</span>"
            document.getElementById("loadIcon").style.display = "none"
            $("#loadIcon").hide()
          }
          return false
        })
      } else {
        var accepted_coupons = 0
        if (this.state.guestFields["guest-accepted-coupons"] !== "" && typeof this.state.guestFields["guest-accepted-coupons"] !== "undefined" && typeof this.state.guestFields["guest-accepted-coupons"] !== undefined) {
          accepted_coupons = this.state.guestFields["guest-accepted-coupons"]
        }

        if (guestType === 1) {
          var qs = require("qs")
          var postObject = {
            app_id: APPID,
            type: "web",
            customer_first_name: this.state.guestFields["guest-name"],
            customer_last_name: "",
            customer_email: this.state.guestFields["guest-email"],
            customer_password: this.state.guestFields["guest-password"],
            customer_phone: this.state.guestFields["guest-mobile"],
            accepted_coupons: accepted_coupons,
            site_url: this.state.client_site_url + "#/",
          }
          axios
            .post(APIURL + "customer/registration", qs.stringify(postObject))
            .then((response) => {
              $("#loadIcon").hide()
              $(".gue-comm-error").hide()
              if (response.data.status === "ok") {
                this.setState({ guestFields: { "guest-name": "", "guest-email": "", "guest-mobile": "", "guest-password": "", "guest-retype-password": "" } })
                $(".guest-success").show()
                $(".success-msg-p").html(response.data.message)
              } else {
                if (typeof response.data.form_error !== "undefined") {
                  $(".guest-form-error").show()
                  $(".guest-form-error").html(response.data.form_error)
                  $(".guest-form-error p").addClass("error-msg")
                } else {
                  $(".guest-form-error").show()
                  $(".guest-form-error-p").html(response.data.message)
                }
              }
              return false
            })
            .catch(function (error) {
              console.log(error)
            })
        } else if (guestType === 0) {
          var qs = require("qs")
          var postObject = {
            app_id: APPID,
            type: "web",
            customer_first_name: this.state.guestFields["guest-name"],
            customer_last_name: "",
            customer_email: this.state.guestFields["guest-email"],
            customer_password: this.state.guestFields["guest-password"],
            customer_phone: this.state.guestFields["guest-mobile"],
            customer_member_id: APPID === MemberAppID || APPID === MemberAppIDSCC || APPID === MemberAppIDGour ? this.state.guestFields["guest-membershipid"] : "",
            search_type: APPID === MemberAppID || APPID === MemberAppIDSCC || APPID === MemberAppIDGour ? "MemberID" : "Mobile",
            accepted_coupons: accepted_coupons,
            site_url: this.state.client_site_url + "#/",
            is_temp_user: cookie.load("isTempUser"),
          }
          axios
            .post(APIURLV2 + "GusestAccount/create", qs.stringify(postObject))
            .then((response) => {
              $("#loadIcon").hide()
              $(".gue-comm-error").hide()
              if (response.data.status === "ok") {
                if (parseInt(response.data.gusetStatus) === 1) {
                  this.setGuestData(response)
                } else if (parseInt(response.data.gusetStatus) === 2) {
                  this.setGuestData(response)
                } else if (parseInt(response.data.gusetStatus) === 3) {
                  this.setGuestData(response, "", 1)
                }
                this.closeGuestToggle()
              } else {
                if (typeof response.data.form_error !== "undefined") {
                  $(".guest-form-error").show()
                  $(".guest-form-error").html(response.data.form_error)
                  $(".guest-form-error p").addClass("error-msg")
                } else {
                  $(".guest-form-error").show()
                  $(".guest-form-error-p").html(response.data.message)
                }
                if (typeof response.data.email_missmatch_error !== "undefined") {
                  this.setState({ email_missmatch_error: response.data.email_missmatch_error })
                  this.setState({ email_missmatch_error_count: parseInt(parseInt(this.state.email_missmatch_error_count) + 1) })
                } else {
                  this.setState({ email_missmatch_error_count: 0, email_missmatch_error: "" })
                }
              }
              return false
            })
            .catch(function (error) {
              console.log(error)
            })
        }
      }
    } else {
      console.log("Form has errors.")
    }
  }

  setGuestData(response, isAnonymousUser = "", isTempUser = "") {
    var mobileno = ""
    if (response.data.result_set.customer_phone !== "" || response.data.result_set.customer_phone !== null || typeof response.data.result_set.customer_phone !== "undefined") {
      mobileno = response.data.result_set.customer_phone
    }

    /* set User cookie values - Start */
    cookie.save("UserId", response.data.result_set.customer_id)
    cookie.save("UserFname", response.data.result_set.customer_first_name !== "" ? response.data.result_set.customer_first_name : "")
    cookie.save("UserLname", response.data.result_set.customer_last_name != "" ? response.data.result_set.customer_last_name : "")
    cookie.save("UserMobile", mobileno)
    cookie.save("userAccountType", response.data.result_set.customer_account_type)
    cookie.save("UserEmail", response.data.result_set.customer_email)
    /*cookie.save("UserDefaultAddress",response.data.result_set.customer_address_name);
			cookie.save("UserDefaultUnitOne",response.data.result_set.customer_address_line1);
			cookie.save("UserDefaultPostalCode",response.data.result_set.customer_postal_code); */
    cookie.save("UserDefaultUnitTwo", response.data.result_set.customer_address_line2)
    /* set User cookie values - End */

    if (isAnonymousUser) {
      cookie.save("isAnonymousUser", 1)
    } else {
      cookie.save("isAnonymousUser", 0)
    }

    if (isTempUser) {
      cookie.save("isTempUser", 1)
    } else {
      cookie.save("isTempUser", 0)
    }

    /* set redirection */

    /* upadte customer info */
    var qs = require("qs")
    var postObject = {
      app_id: APPID,
      reference_id: getResult("referenceId"),
      customer_id: response.data.result_set.customer_id,
      availability_id: cookie.load("defaultAvilablityId"),
    }

    axios.post(APIURL + "cart/update_customer_info", qs.stringify(postObject)).then((res) => {
      if (res.data.status === "ok" && cookie.load("reserVationClick") !== "fromhome") {
        /* check FB login function */
        cookie.save("cartValue", "Yes")
        if (cookie.load("defaultAvilablityId") === config.ids.dineinId) {
          if (APPID === "287E5552-328E-4DBE-A3CA-E998ACCAC9BD") {
            helper.sarveshTableSetup()
            // this.setStateLoading('ok');
          } else {
            this.setStateLoading("ok")
            if (typeof cookie.load("orderOutletId") !== "undefined" && cookie.load("orderOutletId") !== "" && typeof cookie.load("orderTableNo") !== "undefined" && cookie.load("orderTableNo") !== "") {
              hashHistory.push("/checkout")
            } else {
              hashHistory.push("/book-a-table")
            }
          }
        } else {
          this.setStateLoading("ok")
          document.getElementById("loadIcon").style.display = "none"
          hashHistory.push("/delivery-date")
        }
      } else {
        if (cookie.load("reserVationClick") === "fromhome") {
          hashHistory.push("/")
          cookie.save("reserVationClick", "fromlogin")
        } else if (cookie.load("dineinClick") === "yes" && cookie.load("defaultAvilablityId") === config.ids.dineinId) {
          if (APPID === "287E5552-328E-4DBE-A3CA-E998ACCAC9BD") {
            helper.sarveshTableSetup()
          } else {
            this.setStateLoading("ok")
            hashHistory.push("/book-a-table")
          }
        } else {
          if (isAnonymousUser || isTempUser) {
            hashHistory.push("/")
          } else {
            cookie.remove("isTempUser")
            hashHistory.push("/my-account")
          }
          cookie.save("cartValue", "No")
        }
      }
    })
  }

  closeGuestToggle() {
    $("#GuestCheckout").modal("toggle")
  }
  closeGuestCheckoutToggle() {
    $("#GuestCheckout").modal("toggle")
    hashHistory.push("/")
  }
  guestFormChange(field, e) {
    let guestFields = this.state.guestFields
    guestFields[field] = e.target.value
    this.setState({ guestFields, guestErrors: {} })
    if (APPID === MemberAppID || APPID === MemberAppIDSCC || APPID === MemberAppIDGour) {
      if (field === "guest-membershipid") {
        var mobileNo = e.target.value
        if (mobileNo.length >= 5) {
          //this.validateCustomer(mobileNo);
        }
      }
    } else {
      if (field === "guest-mobile") {
        var mobileNo = e.target.value
        if (mobileNo.length >= 7) {
          //this.validateCustomer(mobileNo);
        }
        document.getElementById("otp_error_msg").innerHTML = ""
      }
    }
  }
  guestFormChangeCheckBox(field, e) {
    let guestFields = this.state.guestFields
    var ID = e.target.id
    console.log(ID)
    var value = ""
    if ($("#" + ID).is(":checked")) {
      value = 1
    } else {
      value = 0
    }
    guestFields[field] = value
    this.setState({ guestFields, guestErrors: {} })
  }

  validateCustomer(mobileNo) {
    let mobileNumber = mobileNo
    let searchData = ""
    if (APPID === MemberAppID || APPID === MemberAppIDSCC || APPID === MemberAppIDGour) {
      searchData = "search_type=MemberID&member_id=" + mobileNumber
    } else {
      searchData = "search_type=Mobile&phone_no=" + mobileNumber
    }

    $("#loadIcon").show()
    axios.get(APIURL + "settings/get_customerdetail_phone/?app_id=" + APPID + "&status=A&" + searchData).then((res) => {
      $("#loadIcon").hide()
      if (res.data.status === "ok") {
        var firstName = res.data.result_set.customer_first_name
        var email = res.data.result_set.customer_email
        var mobileNumber = res.data.result_set.customer_phone
        var customer_member_id = res.data.result_set.customer_member_id
        var acceptedCoupons = res.data.result_set.accepted_coupons
        $(".is-exist").removeClass("is-empty")
        $(".is-exist").addClass("is-focused")
        var MobileNumbers = ""
        if (APPID === MemberAppID || APPID === MemberAppIDSCC || APPID === MemberAppIDGour) {
          MobileNumbers = mobileNumber
        } else {
          MobileNumbers = this.state.guestFields["guest-mobile"]
        }
        this.setState({
          backtoGuestform: false,
          guestFields: {
            "guest-name": firstName,
            "guest-email": email,
            "guest-mobile": MobileNumbers,
            "guest-membershipid": customer_member_id,
            "guest-password": this.state.guestFields["guest-password"],
            "guest-retype-password": this.state.guestFields["guest-retype-password"],
            "guest-accepted-coupons": acceptedCoupons,
          },
          invalidMemberID: false,
          accepted_coupons: acceptedCoupons,
        })
        var company_otp_verification = cookie.load("client_otp_verification_enable")
        if (company_otp_verification === "1") {
          this.setState({ check_new_user: 1 })
          this.sendOtpToCustomer()
        }

        cookie.save("check_new_user", 1)
      } else {
        // console.log(this.state.guestFields);
        if (APPID === MemberAppID || APPID === MemberAppIDSCC || APPID === MemberAppIDGour) {
          this.setState({ backtoGuestform: false, check_new_user: 0, guestFields: { "guest-name": "", "guest-email": "", "guest-mobile": this.state.guestFields["guest-mobile"], "guest-membershipid": mobileNo } })
        } else {
          this.setState({ backtoGuestform: false, check_new_user: 0, guestFields: { "guest-name": "", "guest-email": "", "guest-mobile": this.state.guestFields["guest-mobile"] } })
        }
        cookie.save("check_new_user", 0)
        /* $(".is-exist").addClass("is-empty");	
                     $(".is-exist").removeClass("is-focused");				 
                      this.setState({ guestFields : { "guest-mobile" : this.state.guestFields['guest-mobile'] , "guest-password" : this.state.guestFields['guest-password'], "guest-retype-password" : this.state.guestFields['guest-retype-password']}   });*/
      }
    })
  }

  /* guest form - that's it */

  setStateLoading(sts) {
    this.setState({ status: sts })
  }
  responseFacebook = (response) => {
    // console.log(response);
    //alert(response.birthday);
    if (response.name !== "" && response.email !== "" && response.name !== undefined && response.email !== undefined) {
      this.setStateLoading("Loading")
      document.getElementById("loadIcon").style.display = "block"
      var vSplitName = response.name.split(" ")
      var firstname = vSplitName[0]
      var lastname = vSplitName[1]
      var birthday = response.birthday
      var qs = require("qs")
      /* update gender field */
      var postGender = ""
      if (typeof response.gender !== "undefined" && response.gender === "male") {
        postGender = "M"
      } else if (typeof response.gender !== "undefined" && response.gender === "female") {
        postGender = "F"
      }
      /* update DOB */
      var dob = ""
      if (typeof birthday !== "undefined" && birthday !== "") {
        dob = dateFormat(response.birthday, "yyyy-mm-dd")
      }

      var postObject = {
        app_id: APPID,
        type: "web",
        login_firstname: response.first_name,
        login_lastname: response.last_name,
        login_username: response.email,
        customer_fb_id: response.id,
        customer_gender: postGender,
        customer_dob: dob,
      }
      axios
        .post(APIURL + "customer/fbloginapp", qs.stringify(postObject))
        .then((response) => {
          if (response.data.status === "ok") {
            cookie.remove("isTempUser")
            /* set User cookie values - Start  */
            //alert(response.data.result_set.customer_phone);
            var mobileno = ""
            if (typeof response.data.result_set.customer_phone === "undefined" || response.data.result_set.customer_phone === "null" || response.data.result_set.customer_phone === "") {
              mobileno = ""
            } else {
              mobileno = response.data.result_set.customer_phone
            }

            cookie.save("UserId", response.data.result_set.customer_id)
            cookie.save("UserEmail", response.data.result_set.customer_email)
            cookie.save("UserFname", response.data.result_set.customer_first_name !== "" ? response.data.result_set.customer_first_name : "")
            cookie.save("UserLname", response.data.result_set.customer_last_name !== "" ? response.data.result_set.customer_last_name : "")
            cookie.save("UserMobile", mobileno)

            cookie.save("UserDefaultAddress", response.data.result_set.customer_address_name)
            cookie.save("UserDefaultUnitOne", response.data.result_set.customer_address_line1)
            cookie.save("UserDefaultUnitTwo", response.data.result_set.customer_address_line2)
            cookie.save("UserDefaultPostalCode", response.data.result_set.customer_postal_code)

            /* set User cookie values - End  */

            /* set redirection */

            /* upadte customer info  */
            var qs = require("qs")
            var postObject = {
              app_id: APPID,
              reference_id: getResult("referenceId"),
              customer_id: response.data.result_set.customer_id,
              availability_id: cookie.load("defaultAvilablityId"),
            }

            axios.post(APIURL + "cart/update_customer_info", qs.stringify(postObject)).then((res) => {
              if (res.data.status === "ok" && cookie.load("reserVationClick") !== "fromhome") {
                cookie.save("cartValue", "Yes")
                if (cookie.load("defaultAvilablityId") === config.ids.dineinId) {
                  if (APPID === "287E5552-328E-4DBE-A3CA-E998ACCAC9BD") {
                    helper.sarveshTableSetup()
                  } else {
                    if (typeof cookie.load("orderOutletId") !== "undefined" && cookie.load("orderOutletId") !== "" && typeof cookie.load("orderTableNo") !== "undefined" && cookie.load("orderTableNo") !== "") {
                      hashHistory.push("/checkout")
                    } else {
                      hashHistory.push("/book-a-table")
                    }
                  }
                } else {
                  hashHistory.push("/delivery-date")
                }
                this.setStateLoading("ok")
                document.getElementById("loadIcon").style.display = "none"
              } else {
                if (cookie.load("reserVationClick") === "fromhome") {
                  hashHistory.push("/")
                  cookie.save("reserVationClick", "fromlogin")
                } else if (cookie.load("dineinClick") === "yes" && cookie.load("defaultAvilablityId") === config.ids.dineinId) {
                  if (APPID === "287E5552-328E-4DBE-A3CA-E998ACCAC9BD") {
                    helper.sarveshTableSetup()
                  } else {
                    this.setStateLoading("ok")
                    hashHistory.push("/book-a-table")
                  }
                } else {
                  hashHistory.push("/my-account")
                  cookie.save("cartValue", "No")
                }
              }
            })
          } else {
            this.setStateLoading("error")
            document.getElementById("loadIcon").style.display = "none"
            helper.showInfo(response.data.message)
            //document.getElementById("form-error").innerHTML='<div class="box_error"><ul><li>'+response.data.message+'</li></ul></div>';
          }
        })
        .catch(function (error) {
          //  console.log(error);
          helper.showInfo(error)
        })
    }
  }

  submit_otp_verification() {
    var customer_otp_val = this.state.customer_otp_val
    var accepted_coupons = 0
    if (this.state.guestFields["guest-accepted-coupons"] !== "" && typeof this.state.guestFields["guest-accepted-coupons"] !== "undefined" && typeof this.state.guestFields["guest-accepted-coupons"] !== undefined) {
      accepted_coupons = this.state.guestFields["guest-accepted-coupons"]
    }
    if (customer_otp_val != "" && customer_otp_val != undefined) {
      var qs = require("qs")
      var postObject = { app_id: APPID, customer_otp_val: customer_otp_val, customer_email_id: this.state.guestFields["guest-email"], verify_type: "register" }

      axios.post(APIURL + "customer/customer_otp_verification", qs.stringify(postObject)).then((response) => {
        if (response.data.status === "ok") {
          var guestType = parseInt(this.state.guest_type)

          if (guestType === 1) {
            var qs = require("qs")
            var postObject = {
              app_id: APPID,
              type: "web",
              customer_first_name: this.state.guestFields["guest-name"],
              customer_last_name: "",
              customer_email: this.state.guestFields["guest-email"],
              customer_password: this.state.guestFields["guest-password"],
              customer_phone: this.state.guestFields["guest-mobile"],
              accepted_coupons: accepted_coupons,
              site_url: this.state.client_site_url + "#/",
            }
            axios
              .post(APIURL + "customer/registration", qs.stringify(postObject))
              .then((response) => {
                $("#loadIcon").hide()
                $(".gue-comm-error").hide()
                if (response.data.status === "ok") {
                  this.setState({ guestFields: { "guest-name": "", "guest-email": "", "guest-mobile": "", "guest-password": "", "guest-retype-password": "" } })
                  $(".guest-success").show()
                  $(".success-msg-p").html(response.data.message)
                } else {
                  if (typeof response.data.form_error !== "undefined") {
                    $(".guest-form-error").show()
                    $(".guest-form-error").html(response.data.form_error)
                    $(".guest-form-error p").addClass("error-msg")
                  } else {
                    $(".guest-form-error").show()
                    $(".guest-form-error-p").html(response.data.message)
                  }
                }
                return false
              })
              .catch(function (error) {
                console.log(error)
              })
          } else if (guestType === 0) {
            var qs = require("qs")
            var postObject = {
              app_id: APPID,
              type: "web",
              customer_first_name: this.state.guestFields["guest-name"],
              customer_last_name: "",
              customer_email: this.state.guestFields["guest-email"],
              customer_password: this.state.guestFields["guest-password"],
              customer_phone: this.state.guestFields["guest-mobile"],
              site_url: this.state.client_site_url + "#/",
              customer_member_id: APPID === MemberAppID || APPID === MemberAppIDSCC || APPID === MemberAppIDGour ? this.state.guestFields["guest-membershipid"] : "",
              search_type: APPID === MemberAppID || APPID === MemberAppIDSCC || APPID === MemberAppIDGour ? "MemberID" : "Mobile",
              accepted_coupons: accepted_coupons,
            }
            axios
              .post(APIURL + "GusestAccount/create", qs.stringify(postObject))
              .then((response) => {
                $("#loadIcon").hide()
                $(".gue-comm-error").hide()
                if (response.data.status === "ok") {
                  if (parseInt(response.data.gusetStatus) === 1) {
                    this.setGuestData(response)
                  } else if (parseInt(response.data.gusetStatus) === 2) {
                    this.setGuestData(response)
                  }
                  this.closeGuestToggle()
                } else {
                  if (typeof response.data.form_error !== "undefined") {
                    $(".guest-form-error").show()
                    $(".guest-form-error").html(response.data.form_error)
                    $(".guest-form-error p").addClass("error-msg")
                  } else {
                    $(".guest-form-error").show()
                    $(".guest-form-error-p").html(response.data.message)
                  }
                }
                return false
              })
              .catch(function (error) {
                console.log(error)
              })
          }
        } else {
          document.getElementById("spn-otp-error").innerHTML = '<span class="spn-error">' + response.data.message + "</span>"
        }
      })
    }
  }

  handleResendotp() {
    var qs = require("qs")
    var postObject = { app_id: APPID, customer_email: this.state.guestFields["guest-email"], customer_mobile: this.state.guestFields["guest-mobile"], customer_first_name: this.state.guestFields["guest-name"], customer_last_name: "" }

    document.getElementById("loadIcon").style.display = "block"

    axios.post(API_v4_URL + "customer/resend_otp", qs.stringify(postObject)).then((response) => {
      if (response.data.status === "ok") {
        document.getElementById("loadIcon").style.display = "none"
        document.getElementById("resend_otp_button").style.display = "none"
        helper.showInfo(response.data.message)
      } else {
        document.getElementById("loadIcon").style.display = "none"
        document.getElementById("spn-otp-verified-error").innerHTML = '<span class="spn-error">' + response.data.message + "</span>"
        setTimeout(function () {
          document.getElementById("spn-otp-verified-error").innerHTML = ""
        }, 3000)
      }
    })
  }

  handleChangeTxt = (item, event) => {
    this.setState({ [item]: event.target.value })
    document.getElementById("spn-otp-error").innerHTML = ""
  }

  backto_login() {
    this.setState({ guestFields: { "guest-mobile": "", "guest-name": "", "guest-email": "", "guest-cemail": "" }, check_new_user: 0 })
    document.getElementById("gust_checkout_form").style.display = "block"
    document.getElementById("otp_verification_option").style.display = "none"
    document.getElementById("resend_otp_button").style.display = "block"
  }

  backToOtpScreen() {
    document.getElementById("gust_checkout_form").style.display = "none"
    document.getElementById("otp_verification_option").style.display = "block"
    document.getElementById("resend_otp_button").style.display = "none"
  }

  /* login button color */
  loginButtonColr() {
    return APPID === apps.workspace ? "signin-new" : "signin-new sign_btn"
  }

  render() {
    // alert(this.state.fbId);
    const { fields, onChange, onValid, onInvalid, $field, $validation } = this.props
    let errMsgEmail, errMsgPassword
    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && <span className="spn-error">{$validation.email.error.reason}</span>
    }
    if ($validation.password.error.reason !== undefined) {
      errMsgPassword = $validation.password.show && <span className="spn-error">{$validation.password.error.reason}</span>
    }
    if (this.state.renderLoading === "ok") {
      return (
        <div>
          <DocumentMeta {...meta} />
          <div id="loadIcon" style={{ display: "none", bottom: 0, left: 0, position: "fixed", right: 0, top: 0, margin: "auto", zIndex: 9999, background: "rgba(255, 255, 255, 0.8)" }}>
            {" "}
            <img style={{ bottom: 0, left: 0, position: "absolute", right: 0, top: 0, margin: "auto" }} src="/img/loader.gif" alt="Loading" />{" "}
          </div>
          <div className="background_color">
            <div className="container-fluid desktop-container ninja-theme-v2" style={{ minHeight: this.state.windowHeight }}>
              <Alert />
              <div className="row">
                <div className="col-xs-12 top-nav inner-top-nav">
                  <div className="col-xs-3 nav-head pd-l-20">
                    {" "}
                    <Link to={"/"}>
                      <img src="/img/icons/arrow-right.png" alt="left-arrow" className="icon-img-small-3" />
                    </Link>
                  </div>
                  <div className="col-xs-6 nav-head ">
                    <h1 className="main-title">LOGIN</h1>
                  </div>
                  <div className="col-xs-3 nav-head pd-l-20">
                    {" "}
                    <Link to={"/"}>
                      <img src="/img/icons/shopping-cart.png" alt="cart" className="icon-img-small-4-inner cart-icon pull-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row login-container" id="signin-form">
                <div className="col-xs-offset-1 col-xs-10 col-xs-offset-1">
                  <div className="row">
                    <div className="col-xs-12">
                      {this.showGusetCheckout()}
                      {this.showAnonymousCheckout()}
                      {APPID !== "782D2401-5C31-43E9-83CF-B3AB5A394E66" && APPID !== MemberAppID && APPID !== MemberAppIDSCC && APPID !== MemberAppIDGour && (
                        <div className="row row-facebook-login hidden">
                          <div id="form-error"></div>
                          <div className="col-xs-12">
                            <FacebookLogin
                              appId={this.state.fbId}
                              fields="name,email,picture,first_name,last_name,birthday,gender"
                              callback={this.responseFacebook}
                              scope="public_profile,email,user_birthday"
                              cssClass="btn btn-raised btn-info login-with-facebook"
                              redirectUri={config.path.baseUrl + "sign-in"}
                              icon="fa-facebook"
                              textButton="&nbsp; Login with Facebook"
                            />
                          </div>
                        </div>
                      )}
                      {APPID !== MemberAppID && APPID !== MemberAppIDSCC && APPID !== MemberAppIDGour && (
                        <div className="row  login-screen">
                          {APPID !== "782D2401-5C31-43E9-83CF-B3AB5A394E66" && <h1>Sign In</h1>}
                          <form action method>
                            <div className="row">
                              <div className=" item-add2">
                                {APPID !== "782D2401-5C31-43E9-83CF-B3AB5A394E66" && (
                                  <div>
                                    <div className="form-group label-floating is-empty card-no">
                                      <div className="input-group">
                                        <label className="control-label" htmlFor="email">
                                          Email Address
                                        </label>
                                        <input type="text" id="email" className="form-control" value={fields.email} {...$field("email", (e) => onChange("email", e.target.value))} />
                                        {errMsgEmail}
                                      </div>
                                    </div>
                                    <div className="form-group  label-floating is-empty card-no">
                                      <div className="input-group">
                                        <label className="control-label" htmlFor="password">
                                          Password
                                        </label>
                                        <input type="password" id="password" className="form-control" value={fields.password} {...$field("password", (e) => onChange("password", e.target.value))} />
                                        {errMsgPassword}
                                      </div>
                                    </div>
                                    <Link className="forget-password-link pull-left" to={"/forgot-password"}>
                                      Forgot Your Password?
                                    </Link>
                                  </div>
                                )}
                                <div className="row">
                                  <div className="col-xs-12 col-sm-8 col-sm-offset-2 text-center font-weight-400">
                                    {APPID !== "782D2401-5C31-43E9-83CF-B3AB5A394E66" && (
                                      <button
                                        type="submit"
                                        className={this.loginButtonColr()}
                                        onClick={(e) => {
                                          e.preventDefault()
                                          this.props.$submit(onValid, onInvalid)
                                        }}
                                      >
                                        {" "}
                                        GO{" "}
                                      </button>
                                    )}
                                    <Link className="signup-link" to={"/sign-up"}>
                                      Create Account
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* hide guset check out 
        <div className="row disabled-continue-as-a-guest">
          <div className="text-center">
            <button type="button" id="checkout" className="btn btn-raised btn-info login-check-out-as-guest font-gray disable-btn">CheckOut As Guest</button><br />
          </div>
          <div className="spacDateFielde-50 hide-on-click" />
          <div className="space-50 hide-on-click" />
        </div> */}
              <div className="new-user-reg">
                <form action method>
                  <div className="row">
                    <div className=" item-add2">
                      <div className="space-10" />
                      <p className="text-center">Please enter your name and email address to receive receipt </p>
                      <div className="form-group label-floating is-empty card-no">
                        <div className="input-group">
                          <label className="control-label" htmlFor="name">
                            Name
                          </label>
                          <input id="name" className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="form-group label-floating is-empty card-no">
                        <div className="input-group">
                          <label className="control-label" htmlFor="emailaddress">
                            Email Address
                          </label>
                          <input id="emailaddress" className="form-control" type="Text" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="space-50" />
                <div className="space-10" />
              </div>
              {/*  <div className="row">
          <button className="col-lg-12 continue-but desktop-container container-fluid" onClick={(e) => { e.preventDefault(); this.props.$submit(onValid, onInvalid);}}>LET'S GO!</button>
        </div> */}
            </div>
          </div>
          {/* For Guest Checkout popup */}
          <div className="modal ninja-theme-v2" id="GuestCheckout">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  {APPID === MemberAppID || APPID === MemberAppIDSCC || APPID === MemberAppIDGour ? (
                    <Link onClick={this.closeGuestCheckoutToggle.bind(this)}>
                      <button type="button" className="close">
                        <img src="/img/icons/close_popup.png" className="img-responsive" alt="close" />
                      </button>
                    </Link>
                  ) : (
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                      <img src="/img/icons/close_popup.png" className="img-responsive" alt="close" />
                    </button>
                  )}

                  <h4 className="modal-title">
                    <img src="/img/icons/user-white.png" alt="guest-checkout" className="icon-img-guest-checkout" />
                    {APPID === "B401FC82-8E39-4076-838A-55192F765004" || APPID === "6C0280A0-B655-4E0F-A43F-041C189683BA" || APPID === "B93EC9A9-1A67-4EBA-ADB7-BC7C14FA88A4" ? "Member Login" : "Guest Checkout"}
                  </h4>
                </div>

                <div className="modal-body">
                  <div className="guest-checkout-form">
                    <div className="alert-success guest-success gue-comm-error" style={{ display: "none" }}>
                      <p className="success-msg success-msg-p  "> Guest account created successfully</p>
                    </div>

                    <div className="alert-error guest-form-error gue-comm-error" style={{ display: "none" }}>
                      <p className="error-msg guest-form-error-p"></p>
                    </div>
                    <div className="gust_checkout_form" id="gust_checkout_form">
                      <form action method>
                        {(APPID === MemberAppID || APPID === MemberAppIDSCC || APPID === MemberAppIDGour) && (
                          <div>
                            <div className="form-group label-floating is-empty card-no">
                              <div className="input-group">
                                <label className="control-label">Membership #</label>
                                <input id="guest-membershipid" type="text" ref="guest-membershipid" name="guest-membershipid" onChange={this.guestFormChange.bind(this, "guest-membershipid")} value={this.state.guestFields["guest-membershipid"]} className="form-control" />
                              </div>
                            </div>
                            <span className="help-text guest-error">{this.state.guestErrors["guest-membershipid"]}</span>
                          </div>
                        )}
                        <div className="form-group label-floating is-empty card-no is-exist">
                          <div className="input-group">
                            <label className="control-label">Mobile</label>
                            <input id="guest-mobile" type="text" ref="guest-mobile" maxLength="8" name="guest-mobile" onChange={this.guestFormChange.bind(this, "guest-mobile")} value={this.state.guestFields["guest-mobile"]} className="form-control" />
                          </div>
                        </div>
                        <span className="help-text">(Used to contact you regarding your order)</span>
                        <span className="help-text guest-error">{this.state.guestErrors["guest-mobile"]}</span>
                        <div className="form-group label-floating is-empty card-no is-exist">
                          <div className="input-group">
                            <label className="control-label">Name</label>
                            <input id="guest-name" name="guest-name" onChange={this.guestFormChange.bind(this, "guest-name")} value={this.state.check_new_user === 0 ? this.state.guestFields["guest-name"] : ""} className="form-control" type="text" maxLength="20" />
                          </div>
                        </div>
                        <span className="help-text guest-error">{this.state.guestErrors["guest-name"]}</span>
                        <div id="otp_error_msg"></div>
                        <div className="form-group label-floating is-empty card-no is-exist">
                          <div className="input-group">
                            <label className="control-label">Email</label>
                            <input id="guest-email" className="form-control" type="text" name="guest-email" onChange={this.guestFormChange.bind(this, "guest-email")} value={this.state.check_new_user === 0 ? this.state.guestFields["guest-email"] : ""} />
                          </div>
                        </div>
                        <span className="help-text">(Used to send you a receipt of your order)</span>
                        <span className="help-text guest-error">{this.state.guestErrors["guest-email"]}</span>

                        {this.state.email_missmatch_error !== "" && this.state.email_missmatch_error_count > 2 && <span className="help-text guest-error">Your Email : {this.state.email_missmatch_error}</span>}
                        {/*	<div className="form-group label-floating is-empty card-no is-exist">
									<div className="input-group">
										<label className="control-label" >Confirm Email</label>
										<input id="guest-cemail" className="form-control" type="text" name="guest-email"  onChange={this.guestFormChange.bind(this, "guest-cemail")} value={ (this.state.check_new_user === 0?this.state.guestFields["guest-cemail"] : "")} />
									</div>
								</div>	
										<span className="help-text guest-error"  >{this.state.guestErrors["guest-cemail"]}</span> */}

                        <div className="form-group label-floating is-empty card-no enable-guest">
                          <div className="input-group">
                            <label className="control-label">Password</label>
                            <input id="guest-password" className="form-control" type="password" name="guest-password" onChange={this.guestFormChange.bind(this, "guest-password")} value={this.state.guestFields["guest-password"]} />
                          </div>
                        </div>
                        <span className="help-text guest-error enable-guest">{this.state.guestErrors["guest-password"]}</span>
                        <div className="form-group label-floating is-empty card-no enable-guest">
                          <div className="input-group">
                            <label className="control-label">Retype Password</label>
                            <input id="guest-retype-password" className="form-control" type="password" name="guest-retype-password" onChange={this.guestFormChange.bind(this, "guest-retype-password")} value={this.state.guestFields["guest-retype-password"]} />
                            <input id="guest-type" type="hidden" name="guest-type" value={this.state.guest_type} />
                          </div>
                        </div>
                        <span className="help-text guest-error enable-guest">{this.state.guestErrors["guest-retype-password"]}</span>

                        {(APPID === "2AE048E7-2D84-42BE-9BB5-22016FC4DFBC" || APPID === "44275190-B355-4486-8083-EE1C63607916" || APPID === "F34559CA-596A-4F2E-8547-17B2AEBB3326") && (
                          <div className="checkbox_sign_ups">
                            <input type="checkbox" id="guest-accepted-alcohol" name="guest-accepted-alcohol" onChange={this.guestFormChangeCheckBox.bind(this, "guest-accepted-alcohol")} />
                            {console.log(this.state.guestFields)}
                            <p>You agree that you are of legal age to purchase and consume alcohol in the locale in which the alcohol is delivered and acknowledge and agree to provide valid government-issued identification upon delivery</p>
                            {this.state.guestErrors["guest-accepted-alcohol"] !== "" && typeof this.state.guestErrors["guest-accepted-alcohol"] !== undefined && typeof this.state.guestErrors["guest-accepted-alcohol"] !== "undefined" && (
                              <span className="help-text guest-error" style={{ marginLeft: "0px" }}>
                                {this.state.guestErrors["guest-accepted-alcohol"]}
                              </span>
                            )}
                          </div>
                        )}
                        {APPID !== "B401FC82-8E39-4076-838A-55192F765004" && APPID !== "6C0280A0-B655-4E0F-A43F-041C189683BA" && APPID !== "B93EC9A9-1A67-4EBA-ADB7-BC7C14FA88A4" && (
                          <div>
                            <div className="checkbox_sign_ups">
                              <input type="checkbox" id="guest-terms" name="guest-terms" value="1" defaultChecked={true} disabled="disabled" onChange={this.guestFormChangeCheckBox.bind(this, "guest-terms")} />

                              <p>I agree to the Privacy Policy and Terms of Service of {cookie.load("client_company_name")}</p>
                            </div>
                            <div className="checkbox_sign_ups">
                              <input
                                type="checkbox"
                                id="guest-accepted-coupons"
                                name="guest-accepted-coupons"
                                onChange={this.guestFormChangeCheckBox.bind(this, "guest-accepted-coupons")}
                                checked={this.state.guestFields["guest-accepted-coupons"] === "1" || this.state.guestFields["guest-accepted-coupons"] === 1 ? true : false}
                                disabled={this.state.accepted_coupons === "1" ? true : false}
                              />
                              <p>I consent to receive coupons and promotions news from {cookie.load("client_company_name")}, its franchisees and affiliates, and understand and agree that my information will be for the above. </p>
                              <div id="spn-accept-error"></div>
                            </div>
                          </div>
                        )}
                      </form>

                      {/*<div className="checkbox_sign_ups">
							
							<input type="checkbox" name="terms_condition_accept" value="1" id="accepted_button" className="accepted_button" checked="checked" />							
                               <p>I consent to receive coupons and promotions news from {cookie.load('client_company_name')}, its franchisees and affiliates, and understand and agree that my information will be used as described here and in {cookie.load('client_company_name')} Privacy Policy and Terms of Service.</p>
							   <div id="spn-accept-error"></div>
                        </div>*/}

                      {/* <div className="col-xs-12 col-sm-6 set-password-block">
							
						<Link className="set-password-account anchr-tag disable-guest " onClick={this.enableGuset.bind(this)} >Set Password <br />& Create an account</Link>
						<Link className="set-password-account enable-guest anchr-tag"  onClick={this.disableGuset.bind(this)}>No I dont want to <br/>Create account</Link>
						</div> */}

                      <div className="col-xs-12 col-sm-12 create-account-block">
                        <p>If you would like update your email address, please contact the outlet.</p>
                        {this.state.backtoGuestform === true && (
                          <Link className="set-password-account anchr-tag disable-guest " onClick={this.backToOtpScreen.bind(this)}>
                            Back to OTP Verification
                          </Link>
                        )}
                        <button type="button" onClick={this.applyValidation.bind(this)} className="btn btn-raised btn-guest-account ">
                          Proceed
                        </button>
                      </div>
                    </div>

                    <div className="space-20" id="otp_verification_option" style={{ display: "none" }}>
                      <p className="text-center font-bold font-size-18">OTP Verification</p>
                      <form
                        className="customer_otp"
                        id="customer_otp"
                        onSubmit={(e) => {
                          this.submit_otp_verification(this)
                          e.preventDefault()
                        }}
                      >
                        <div id="spn-otp-verified-error"></div>
                        <div className="form-group label-floating card-no input-form-group">
                          <div className="input-group">
                            <label className="control-label">
                              Enter your OTP<span>*</span>
                            </label>
                            <input type="text" name="customer_otp_val" className="form-control" value={this.state.customer_otp_val} onChange={this.handleChangeTxt.bind(this, "customer_otp_val")} />
                            <div id="spn-otp-error"></div>
                          </div>
                        </div>

                        <div className="text-center ">
                          <button type="button" className="btn btn-raised btn-info full-width-btn-2 text-uppercase" onClick={this.submit_otp_verification.bind(this)}>
                            Submit
                          </button>
                        </div>
                      </form>

                      <a className="resent_otp_gust" style={{ cursor: "pointer" }} id="resend_otp_button" onClick={this.handleResendotp.bind(this)}>
                        <b>Resent OTP</b>
                      </a>
                      <br />
                      <a className="back_to_register_gust" onClick={this.backto_login.bind(this)}>
                        <b>Back to guest login</b>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* For Register Success */}

          {/* For Signin Error */}

          {/* For Reset Success */}

          {/* For Reset Error */}
        </div>
      )
    } else {
      return (
        <div className="container-fluid desktop-container position-rel">
          <div className="row">
            <Loading />
          </div>
        </div>
      )
    }
  }
}
Form = validated(validationConfig)(Form)

export default Signin

/* eslint-disable */
import React from "react"
import { loadAnalytics } from "./withTracker"
import { hashHistory } from "react-router"
import axios from "axios"
import Loading from "./loading"
import cookie from "react-cookie"
import DocumentMeta from "react-document-meta"

import config from "./config"
import lang from "./lang"
const APIURL = config.path.apiUrl
const APPID = config.ids.appId
var helper = require("./Helper.js")

/* Load meta tags */
const meta = {
  title: lang.activation_meta_title,
  description: lang.activation_meta_title,
  meta: {
    name: {
      keywords: lang.activation_meta_title,
    },
  },
}

class ReservationActivation extends React.Component {
  componentWillMount() {
    /* add class */
    helper.addBodyClass()
  }

  /* Account activation */
  componentDidMount() {
    loadAnalytics()
    var qs = require("qs")
    var activationType = this.props.params.type
    var activationId = this.props.params.keyval
    activationId = atob(activationId)

    /* for account activation - start */
    if (typeof activationId !== "undefined" && activationId !== "" && activationType === "accept") {
      // return false;
      var postObject = {
        app_id: APPID,
        sug_reser_id: activationId,
      }
      axios.post(APIURL + "reservation/reservation_suggest_accept", qs.stringify(postObject)).then((response) => {
        /* Success response */
        if (response.data.status === "success") {
          var errorMsg = "<h2>Thank you</h2> Order has been placed successfully."
          helper.showInfo(errorMsg)
          hashHistory.push("/")
        }

        /* Success response */
        if (response.data.status === "error") {
          if (response.data.already_assigned === "1") {
            var errorMsg = "<h2>Sorry</h2> Your Reservation Already Expired.<br> You can make new reservation."
          } else {
            var errorMsg = "<h2>Thank you</h2> Reservation has been Cancelled successfully."
          }

          helper.showInfo(errorMsg)
          hashHistory.push("/")
        }
        /* error reponse */
      })
    }
    /* for account activation - end  */

    /* for account activation - start */
    if (typeof activationId !== "undefined" && activationId !== "" && activationType === "reject") {
      // return false;
      var postObject = {
        app_id: APPID,
        sug_reser_id: activationId,
      }
      axios.post(APIURL + "reservation/reservation_suggest_reject", qs.stringify(postObject)).then((response) => {
        /* Success response */
        if (response.data.status === "success") {
          var errorMsg = "<h2>Thank you</h2> Reservation has been Cancelled successfully."
          helper.showInfo(errorMsg)
          hashHistory.push("/")
        }

        /* Success response */
        if (response.data.status === "error") {
          if (response.data.already_assigned === "1") {
            var errorMsg = "<h2>Sorry</h2> Your Reservation Already Expired.<br> You can make new reservation."
          } else {
            var errorMsg = ""
          }

          helper.showInfo(errorMsg)
          hashHistory.push("/")
        }
        /* error reponse */
      })
    }
    /* for account activation - end  */
  }

  render() {
    return (
      <div>
        <DocumentMeta {...meta} />
        {/*<Loading/> */}
        <div className="chk_popup act_loder" id="PayProcess" style={{ position: "unset" }}>
          <div className="white-outer center-block process_order">
            <div className="row ">
              <div className="col-xs-12 padd2">
                <h4>{lang.activation_meta_title}</h4>
                <div className="clock_part">
                  <img src="/img/spinner.gif" alt="" />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default ReservationActivation

import { isUndefined } from "lodash"
import config from "./config"

export default {
  /* Home page navigation items   d*/
  navigation: {
    home: "Home",
    myaccount: "My Account",
    vieworders: "View Orders",
    viewrewards: "View Rewards",
    about: "About Us",
    privacy: "Privacy Policy",
    contact: "Contact Us",
  },

  fb: config.fb,
  /* Twitter  */
  tw: config.tw,
  /* google Plus data   */
  gp: config.gp,
  /* Whatsapp data   */
  wa: config.wa,

  /* Availability and images lables */
  delivery: "Delivery",
  deliveryImage: "/img/icons/Delivery.png",
  deliveryImageWhite: "/img/icons/Delivery_white.svg",
  takeaWay: "Takeaway",
  takeaWayImage: "/img/icons/Takeaway.svg",
  takeaWayImageWhite: "/img/icons/Takeaway_white.svg",
  dineIn: "Dine In",
  dineInImage: "/img/icons/home-popup/dineIn.svg",
  dineInImageWhite: "/img/icons/dineIn_white.svg",
  dineInImageWhiteDefault: "/img/icons/home-popup/dineIn_white.svg",
  reservation: "Reservation",
  reservationImage: "/img/icons/Reservation.svg",
  reservationImageWhite: "/img/icons/Reservation_white.svg",
  /*  Products Module Languages  */
  no_products_found: "No Products found ",

  /*  Cart  Module Languages  */
  add_to_cart: "Add To Cart",
  no_cart_items: "No items found in the cart",
  availability_disabled: "Other availabilities currently disabled by administrator",
  select_all_modofier: "Please select all modifiers",
  modifier_missing: "product combination is wrong. Please check your combination",
  combo_missing: "Combo product selection wrong. Please check your products",
  cart_success: "Great Choice ! Item added to cart",

  /* checkout page */
  choose_payment_mode: "Please choose any one payment method",
  user_info_missing: "Some User basic information missing. Please update your profile and try again",
  delivery_info_missing: "Please choose a delivery date and time.",
  something_wrong: "Something went wrong. Please check all user information (or) product details",

  /* delvery date module */
  choose_delvery_date: "Choose delivery date",
  choose_delvery_date_error: "Please choose a delivery/pickup date and time.",

  /* sign up page and related modules  */
  signup_success: "Thank you for signing up. Please check your email for an activation link or do check your spam inbox in the event you do not find your email.",
  reset_key_invalid: "Invalid security key.",

  /*all page meta tags */
  site_title: config.site_title,
  site_title_with_separator: config.site_title_with_separator,
  home_meta_title: config.home_meta_title,
  home_meta_description: !isUndefined(config.home_meta_description) ? config.home_meta_description : config.home_meta_title,
  home_meta_keywords: !isUndefined(config.home_meta_keywords) ? config.home_meta_keywords : config.home_meta_title,
  prodcuts_meta_title: "Products Details",
  scan_qr_code_page: "Book a Table",
  signin_meta_title: "User Signin",
  signup_meta_title: "User signup",
  forgotpassword_meta_title: "forgot password",
  checkout_meta_title: "Checkout",
  ordersuccess_meta_title: "Order Success",
  about_meta_title: "Aboutus",
  privacy_meta_title: "Privacy Policy",
  myaccount_meta_title: "My account",
  contactus_meta_title: "Contact us",
  orders_meta_title: "Orders",
  cart_page_title: "Cart",
  cart_page_description: "",
  cart_page_keywords: "",
  addons_meta_title: "Complete Your Meal",
  activation_meta_title: "Please wait, we are validating your account",
  reset_meta_title: "Reset Password",
  outlet_closed_message: "Currently all outlets are closed. please try again later.",
}

/* eslint-disable */
/* global $, angular, React */
import React from "react"
import axios from "axios"
import { Link, hashHistory } from "react-router"
import DocumentMeta from "react-document-meta"
import cookie from "react-cookie"
import ThemeProvider from "react-toolbox/lib/ThemeProvider"
import theme from "./react-toolbox/theme.js"
import Distancebar from "./Distancebar"
import Outletclosedmessage from "./Outletclosedmessage"
import { geolocated } from "react-geolocated"
import "./react-toolbox/theme.css"
import DatePicker from "react-toolbox/lib/date_picker/DatePicker"
import TimePicker from "rc-time-picker"
import LocationSearchInput from "./LocationSearchInput"
import OutsideClickHandler from "react-outside-click-handler"
import { API_v4_URL } from "./constants"
import "./assets/css/App.css"
import { isEmpty } from "lodash"
import getResult from "./Api"
import "react-select/dist/react-select.css"

import Loading from "./loading"
import Overlay from "./Overlay"
import config from "./config"
import alerts from "./AlertsMsg"
import Alert from "./alert"
import lang from "./lang"
import { loadAnalytics } from "./withTracker"

const APIURL = config.path.apiUrl
const APIURLPRO = config.path.apiUrlPro
const APPID = config.ids.appId
const APIURLv2 = config.path.apiUrlv2
var helper = require("./Helper.js")
var HtmlToReactParser = require("html-to-react").Parser
var htmlToReactParser = new HtmlToReactParser()
var Select = require("react-select")
//import 'rc-time-picker/assets/index.css';
const apps = require("./apps")
var strp = require("strp")
var dateFormat = require("dateformat")
const datetime = new Date()
var currentDateValue = datetime.getDate()

var defaultDate = ""
var defaultresTime = ""
var min_datetime = new Date(new Date(datetime).setDate(currentDateValue - 1))
var max_datetime = new Date(new Date(datetime).setDate(currentDateValue + 15))

var monthNamesArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

var dayNamesArr = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

let timetxt = new Date()
timetxt.setHours(17)
timetxt.setMinutes(28)

/* Load meta tags */
const meta = {
  title: lang.home_meta_title,
  description: lang.home_meta_description,
  meta: {
    name: {
      keywords: lang.home_meta_keywords,
    },
  },
}

const expires = new Date()
/*expires.setMinutes(expires.getMinutes() + 1);*/
expires.setDate(expires.getDate() + 1)

var Product_LeadTime = 0
var TodayDate = new Date()
var autoFillDate = TodayDate
var getDate_advanced = TodayDate.getDate()
var min_datetime_advanced = new Date(new Date(TodayDate).setDate(getDate_advanced - 1))
var max_datetime_advanced = new Date(new Date(TodayDate).setDate(getDate_advanced))
var weekdayArr = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]

class Home extends React.Component {
  constructor(props) {
    super(props)
    //var reservationPersonMax = (APPID === 'DDAA254C-BD1E-49F0-AD1E-164A140421D3') ? 7 : 11;

    /* Declare state */
    this.state = {
      closeNormalPopup: false,
      zoneOutletSeleted: "",
      zoneDeliveryOutlet: "",
      deliveryOutlets: [],
      zoneDetails: [],
      windowHeightNormal: 0,
      windowHeight: 0,
      searchHtml: "",
      secAddrData: [],
      addressField: "",
      Outlets: [],
      category: [],
      common: [],
      banner: [],
      bannercom: [],
      defaultAvilablity: config.ids.defaultAvilablityName,
      defaultAvilablityImage: "",
      navStatus: "Loading",
      openNav: "side-l",
      rewadNav: "collapsed",
      login: "",
      searchText: "",
      settings: [],
      options: [],
      showPromoPopup: "No",
      showNormalPopup: "No",
      pageDesc: "",
      bannersource: "",
      noPagerClass: "",
      bxClass: "",
      onetimeclickstate: "No",
      reservationPersonMax: "",
      reservationChildMax: "",
      orderCount: 0,
      promotionCount: 0,
      enableAddressSearch: false,
      masterCardLogo: "",
      outletCloseddMessage: "",
      date_advanced: autoFillDate,
      selectTime_advanced: "",
      selectMins_advanced: "",
      disableDate_advanced: [],
      cutOfftime_advanced: "",
      intervalTime_advanced: "",
      min_datetime_advanced: min_datetime_advanced,
      max_datetime_advanced: max_datetime_advanced,
      timeText_advanced: "Time (HH/MM)",
      timeHtml_advanced: "",
      timeExceed_advanced: "No",
      time_slot_type_advanced: "1",
      TimeSlotHtmlVal_advanced: "",
      serverTat: "0",
      allowTimeSlot: "No",
      hollidayList: [],
      manualLoading: false,
      outlet_max_pax_limit: 0,
      client_maintenance_mode: 0,
      client_maintanace_popup_hide: false,
      client_password_maintain: "",
      client_password_maintain_error: "",
    }

    if (cookie.load("reserVationClick") !== "fromhome") {
      cookie.save("itsfromreservation", "no")
    }
    this.changeResOutlet = this.changeResOutlet.bind(this)
    this.changeResTimeset = this.changeResTimeset.bind(this)
    this.selectOutlet = this.selectOutlet.bind(this)
    this.closeNornalPopup = this.closeNornalPopup.bind(this)
    this.changeTimer = this.changeTimer.bind(this) //changeHrs
    this.changeMins = this.changeMins.bind(this) //changeMins
    /* API CALL FOR PRODUCT MENU NAVIGATION */
    var promise = getResult("settingsPro", "No")
    promise.then((res) => {
      this.setState({ settings: res.data.result_set })

      var applyOutletIdtxt = res.data.result_set.ninja_outletbasedproduct_enable === "1" ? "yes" : "no"

      cookie.save("site_maintenance_popup_open", res.data.result_set.client_maintenance_mode, { path: "/" })
      var client_maintenance_mode_check = res.data.result_set.client_maintenance_mode === "1" ? "1" : "0"
      this.setState({ client_maintenance_mode: client_maintenance_mode_check })

      cookie.save("dineinClick", "no")
      cookie.save("applyOutletId", applyOutletIdtxt)
      cookie.save("siteURL", res.data.result_set.client_site_url)

      cookie.save("fb_pixel_id", res.data.result_set.ninja_pro_fb_pixel_id !== null ? res.data.result_set.ninja_pro_fb_pixel_id : "")
      cookie.save("ga_id", res.data.result_set.ninja_pro_google_ga_id !== null ? res.data.result_set.ninja_pro_google_ga_id : "")
      cookie.save("gtm_id", res.data.result_set.ninja_pro_google_gtm_id !== null ? res.data.result_set.ninja_pro_google_gtm_id : "")
      var avlId = res.data.result_set.ninja_pro_default_availability_id
      var avlText = res.data.result_set.ninja_pro_default_availability_name === "Pickup" ? "Takeaway" : res.data.result_set.ninja_pro_default_availability_name
      var avlImage = "/img/icons/" + res.data.result_set.ninja_pro_default_availability_id + ".svg"

      if (cookie.load("dineinFrom") === "booktbl" || cookie.load("dineinFrom") === "signin") {
        cookie.remove("defaultAvilablityId")
        cookie.remove("defaultAvilablity")
        cookie.remove("defaultAvilablityImage")
      }
      cookie.save("dineinFrom", "homepage")
      cookie.save("navmenuClk", "no")
      cookie.save("bckurlTxt", "")

      /*set default avilablity cookie value */
      helper.setDefaultAvailability(avlId, avlText, avlImage)

      /* set default values */
      this.setState({ defaultAvilablity: avlText, defaultAvilablityName: avlText, defaultAvilablityImage: avlImage })

      /* load menu items */
      this.loadMenuNavigation(cookie.load("defaultAvilablityId"), cookie.load("defaultAvilablity"), cookie.load("defaultAvilablityImage"), "")

      /* Check promo popup  - start */
      var showPrmoPopup = parseInt(res.data.result_set.client_promocode_options) === 1 ? "Yes" : "No"
      var showNormalPopup = parseInt(res.data.result_set.client_promo_code_normal_popup_enable) === 1 ? "Yes" : "No"
      this.setState({ showPromoPopup: showPrmoPopup, showNormalPopup: showNormalPopup })

      /* Get normal popup content */
      if (cookie.load("NormalPopup") !== "Yes" && this.state.showNormalPopup === "Yes") {
        var stsaicResult = helper.getStaticBlock("normal-popup")
        stsaicResult.then((stTes) => {
          if (stTes.data.status === "ok") {
            this.setState({ pageDesc: stTes.data.result_set[0].staticblocks_description })
          }
        })
      }
      /*  Get master card logo - End */

      /* Get normal popup content */
      var masterResult = helper.getStaticBlock("master-card-logo-popup")
      masterResult.then((stTes) => {
        if (stTes.data.status === "ok") {
          this.setState({ masterCardLogo: stTes.data.result_set[0].staticblocks_description })
        }
      })

      /* outlet closed messages  */
      let outResult = helper.getStaticBlock("outlet-closed-message")
      outResult.then((stTes) => {
        if (stTes.data.status === "ok") {
          this.setState({ outletCloseddMessage: stTes.data.result_set[0].staticblocks_description })
        }
      })

      /* get outlets lits */
      if ((cookie.load("defaultAvilablityId") === config.ids.pickupId && cookie.load("defaultAvilablity") === lang.takeaWay && typeof cookie.load("orderOutletId") === "undefined") || typeof cookie.load("orderPostalCode") === "undefined") {
        this.getpickupOutletlist()
      }
      /* get outlets lits - End*/

      /* get secondary address lits */
      if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "" && cookie.load("defaultAvilablityId") === config.ids.deliveryId) {
        if (typeof cookie.load("orderOutletId") === "undefined" || typeof cookie.load("orderPostalCode") === "undefined") {
          this.getSecAddr()
        }
      }
      /* get secondary address lits - end */

      /* JC */
      if (parseInt(res.data.result_set.client_outlet_zone) === 1) {
        axios.get(API_v4_URL + "Getoutlets/getAllOutles/pickup_outlets?app_id=" + APPID + "&availability_id=" + config.ids.deliveryId).then((res) => {
          if (res.data.status === "ok") {
            this.setState({ deliveryOutlets: res.data.result_set })
          }
        })
      } /* JC*/

      if (parseInt(res.data.result_set.advanced_time_slot_reservation_enable) === 1) {
        cookie.save("advanced_time_slot_reservation_enable", "1")
      } else {
        cookie.save("advanced_time_slot_reservation_enable", "")
      }
    })
  }

  closeNornalPopup() {
    this.setState({ closeNormalPopup: true })
  }

  componentWillUnmount() {
    this.setState({ closeNormalPopup: false })
  }
  /* BX slider */
  __bxslider() {
    var bxslider = $(".bxslider").bxSlider({
      infiniteLoop: true,
      auto: true,
      pause: 4000,
    })
  }

  componentWillMount() {
    console.log(config.stripe.stripeCurrency)
    /* add class body tag */

    //  console.log('Will mount');
    var windowHeight = window.innerHeight
    this.setState({ windowHeight: windowHeight - 45 + "px" })
    this.setState({ windowHeightNormal: windowHeight + "px" })
    //this.__bxslider();

    /* load banner */
    axios.get(APIURL + "cms/banner?app_id=" + APPID).then((res) => {
      if (res.data.status === "ok") {
        var sliderLen = res.data.result_set
        var noPageClass = sliderLen.length === 1 ? "bx-slide-single" : ""
        var bxClass = sliderLen.length === 1 ? "" : "bxslider"
        //this.setState({ banner: res.data.result_set });
        this.setState({ bxClass: bxClass, banner: res.data.result_set, bannersource: res.data.common.image_source, noPagerClass: noPageClass })

        // console.log('loaded');
        //this.setState({ navStatus: res.data.status });
        //this.__bxslider();
      }
    })

    this.berforeopenReserVtnPopup()

    if (typeof cookie.load("onetimeClick") === "undefined") {
      this.setState({ onetimeclickstate: "Yes" })
    }
  }

  componentDidMount() {
    cookie.save("reserVationClick", "")
    if (cookie.load("open_reservation") !== "" && typeof cookie.load("open_reservation") !== undefined && cookie.load("open_reservation") !== "undefined" && cookie.load("open_reservation") !== undefined) {
      cookie.remove("open_reservation")
      var current = this
      setTimeout(function () {
        current.changeAvilablityTemp(config.ids.reservationId, lang.reservation, lang.reservationImageWhite)
      }, 2500)
    }
    //  console.log('did mount');
    // this.__bxslider();

    /* orders count */
    if (typeof cookie.load("UserId") !== "undefined" || (cookie.load("UserId") && cookie.load("UserId") !== "")) {
      var qs = require("qs")
      const inputKeys = ["promotion", "order_all"]

      var postObject = { app_id: APPID, customer_id: cookie.load("UserId"), availability_id: cookie.load("defaultAvilablityId"), act_arr: JSON.stringify(inputKeys) }
      axios
        .post(APIURL + "reports/activity_counts", qs.stringify(postObject))
        .then((response) => {
          if (response.data.status === "ok") {
            var resultData = response.data
            this.setState({ orderCount: resultData.count.order_all, promotionCount: resultData.count.promotion })
          }
        })
        .catch(function (error) {
          console.log(error)
        })
      /* oreders count */
    }

    loadAnalytics()
    setTimeout(
      function () {
        if (APPID == "76DABFCC-0A47-40C7-8588-E3C32F87B3C8") {
          var options = {
            whatsapp: this.state.settings.ninja_pro_phone_number, // WhatsApp number
            call_to_action: "Message us", // Call to action
            position: "right", // Position may be 'right' or 'left'
          }
          var proto = document.location.protocol,
            host = "getbutton.io",
            url = proto + "//static." + host
          var s = document.createElement("script")
          s.type = "text/javascript"
          s.async = true
          s.src = url + "/widget-send-button/js/init.js"
          s.onload = function () {
            WhWidgetSendButton.init(host, proto, options)
          }
          var x = document.getElementsByTagName("script")[0]
          x.parentNode.insertBefore(s, x)
        }
        if (APPID == "CD9D3E7B-C4E4-4FF0-B751-A7FE97524F53") {
          var options = {
            whatsapp: this.state.settings.ninja_pro_phone_number, // WhatsApp number
            call_to_action: "Message us", // Call to action
            position: "right", // Position may be 'right' or 'left'
          }
          var proto = document.location.protocol,
            host = "getbutton.io",
            url = proto + "//static." + host
          var s = document.createElement("script")
          s.type = "text/javascript"
          s.async = true
          s.src = url + "/widget-send-button/js/init.js"
          s.onload = function () {
            WhWidgetSendButton.init(host, proto, options)
          }
          var x = document.getElementsByTagName("script")[0]
          x.parentNode.insertBefore(s, x)
        }
      }.bind(this),
      1000
    )
  }
  closeTooltip() {
    $(".scan-tooltip").hide()
  }
  getpickupOutletlist() {
    axios.get(API_v4_URL + "Getoutlets/pickup_outlets?app_id=" + APPID).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ Status: res.data.status })
        this.setState({ Outlets: res.data.result_set })
        if (apps.workspace === APPID) {
          this.addWorkSpaceCookieValues(res.data.result_set)
        }
      } else {
        this.setState({ Status: res.data.status })
      }
    })
  }

  /* click order type */
  clickOrderTypePopup() {
    if (typeof cookie.load("onetimeClick") === "undefined" && this.state.onetimeclickstate === "Yes" && cookie.load("site_maintenance_popup_open") !== "1" && APPID != "81891E42-EB66-44EF-A90E-1E3F2AA9B722" && APPID != "AA850867-4108-4EB8-872B-024903608E9F") {
      $("#onload-screen").modal({
        backdrop: "static",
        keyboard: false,
      })
    }
  }

  /* check promo popup */
  checkPromoPopup() {
    if (cookie.load("promoPopup") !== "Yes" && this.state.showPromoPopup === "Yes") {
      $("#promo_popup").modal("show")
    }
  }

  /* check normal poup //showNormalPopup */
  checkNormalPopup() {
    if (this.state.closeNormalPopup === false && cookie.load("NormalPopup") !== "Yes" && this.state.showNormalPopup === "Yes" && cookie.load("itsfromreservation") !== "yes") {
      $("#normal_popup").modal({
        backdrop: "static",
        keyboard: false,
      })
    }
  }

  checkMaintenancePopup() {
    // $("#onload-screen").modal("hide");
    // $('.modal-backdrop').remove();

    /* Get Client Maintenance popup content */
    if (cookie.load("site_maintenance_popup_open") === "1" && this.state.client_maintenance_mode === "1") {
      $("#promo_popup-2").modal({
        backdrop: "static",
        keyboard: false,
      })
    }
  }

  triggerReservationPopup() {
    if (cookie.load("reserVationClick") === "fromlogin") {
      console.log("reservationpopup")
      document.body.classList.add("reservationPopup")
      //$('.goback_arwcls').hide();
      $("#ReservationMdl").modal({
        backdrop: "static",
        keyboard: false,
      })
    }
  }

  /* format date'es */
  parseDate(input) {
    var parts = input.match(/(\d+)/g)
    // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(parts[0], parts[1] - 1, parts[2]) // months are 0-based
  }

  getOptions(input) {
    var orderOutletIdtext = cookie.load("orderOutletId")
    var applyOutletIdtext = cookie.load("applyOutletId")
    var addquery_txt = ""

    if (applyOutletIdtext === "yes") {
      if (typeof orderOutletIdtext !== "undefined" && orderOutletIdtext !== "") {
        addquery_txt = "&outletId=" + orderOutletIdtext + "&applyOutletId=yes"
      }
    }

    var searchResult = []
    return axios.get(APIURLPRO + "/products/products_search?app_id=" + APPID + "&availability=" + cookie.load("defaultAvilablityId") + addquery_txt + "&search_value=" + input).then((response) => {
      if (response.data.status === "ok") {
        var resultData = response.data.result_set
        resultData.map((loadData) => searchResult.push({ value: loadData.product_slug, label: strp.stripslashes(helper.getAliasName(loadData.product_alias, loadData.product_name)) }))
      }

      return { options: searchResult }
    })
  }

  getAllOutlets() {
    return axios.get(APIURL + "/outlets/pickup_outlets?app_id=" + APPID + "&availability=" + config.ids.reservationId).then((response) => {
      //console.log(response.data);
      var ResOutletHtml = "",
        frstResOutletid = 0,
        ResOutletOptionTxt = "",
        ResOutletAddressAndId = "",
        ResOutletAddressTxt = "",
        AdultHtml = "",
        ChildrenHtml = "",
        outlet_max_pax_limit = 0
      if (response.data.status === "ok") {
        var resultData = response.data.result_set
        resultData.map(
          (loadData) =>
            (ResOutletHtml += "<option data-maxlimit='" + parseInt(Number(loadData.outlet_max_pax_limit)) + "' value='" + loadData.oa_outlet_id + "~" + loadData.outlet_adult_limit + "~" + loadData.outlet_child_limit + "~" + loadData.outlet_address_line1 + "' >" + loadData.outlet_name + "</option>")
        )
      } else {
        var resultData = []
      }

      if (resultData.length > 0) {
        frstResOutletid = resultData[0].oa_outlet_id
        ResOutletOptionTxt = resultData[0].outlet_name
        ResOutletAddressTxt = resultData[0].outlet_address_line1
        ResOutletAddressAndId = resultData[0].oa_outlet_id + "~" + resultData[0].outlet_address_line1
        outlet_max_pax_limit = parseInt(Number(resultData[0].outlet_max_pax_limit))
      }
      if (frstResOutletid !== 0) {
        this.getRestimeData(frstResOutletid)
      }
      this.setState({ Reservationoutlets: resultData })
      var outletDropDown = htmlToReactParser.parse(ResOutletHtml)
      this.setState({ ResOutletDropDownHtml: outletDropDown })
      this.setState({ selectResOutlet: frstResOutletid })
      this.setState({ selectResOutletAdrr: ResOutletAddressTxt })
      this.setState({ selectResOutletAdrrAndId: ResOutletAddressAndId })
      this.setState({ selectResOutletOptionTxt: ResOutletOptionTxt })
      this.setState({ resDate: defaultDate })
      this.setState({ resTimeval: defaultresTime })
      this.setState({ noofAdults: 0 })
      this.setState({ noofChildren: 0 })
      this.setState({ outlet_max_pax_limit: outlet_max_pax_limit })

      var maxLmt = APPID === "DDAA254C-BD1E-49F0-AD1E-164A140421D3" ? 7 : 11

      if (cookie.load("advanced_time_slot_reservation_enable") === "1") {
        this.getReservationtime(frstResOutletid, defaultDate)
        this.getTimesettingArrFun()
      }

      this.setState({ reservationPersonMax: resultData[0]?.outlet_adult_limit })
      this.setState({ reservationChildMax: resultData[0]?.outlet_child_limit })
      var maxAdult = this.state.reservationPersonMax
      var maxChild = this.state.reservationChildMax
      if (parseInt(maxAdult) > 0) {
        for (var a = 0; a <= maxAdult; a++) {
          var adltxt = a > 1 ? "Adults" : "Adult"
          AdultHtml += "<option value='" + a + "'  >" + a + " " + adltxt + "</option>"
        }
        for (var a = 0; a <= maxChild; a++) {
          var chldtxt = a > 1 ? "Children" : "Child"
          ChildrenHtml += "<option value='" + a + "'  >" + a + " " + chldtxt + "</option>"
        }
      } else {
        for (var a = 0; a < maxLmt; a++) {
          var adltxt = a > 1 ? "Adults" : "Adult"
          var chldtxt = a > 1 ? "Children" : "Child"
          AdultHtml += "<option value='" + a + "'  >" + a + " " + adltxt + "</option>"
          ChildrenHtml += "<option value='" + a + "'  >" + a + " " + chldtxt + "</option>"
        }
      }
      var AdultHtmlDropDown = htmlToReactParser.parse(AdultHtml)
      var ChildrenHtmlDropDown = htmlToReactParser.parse(ChildrenHtml)
      this.setState({ noofAdultHtml: AdultHtmlDropDown })
      this.setState({ noofChildrenHtml: ChildrenHtmlDropDown })
    })
  }

  getTimesettingArrFun() {
    var selectedDate = this.state.date_advanced
    var currentThis = this
    if (this.state.cutOfftime_advanced !== "") {
      selectedDate.setDate(selectedDate.getDate() + 1)

      var min_datetime = this.state.min_datetime_advanced
      min_datetime.setDate(min_datetime.getDate() + 1)

      var max_datetime = this.state.max_datetime_advanced
      max_datetime.setDate(max_datetime.getDate() + 1)
      this.setState({
        date_advanced: selectedDate,
        min_datetime_advanced: min_datetime,
        max_datetime_advanced: max_datetime,
      })
    }

    // axios.get("https://mars.ninjaos.com/apiv2/settings/availableDatesAdvanced/?app_id=F77D467A-F552-4BB3-ABBF-C491DB6661A0&availability_id=634E6FA8-8DAF-4046-A494-FFC1FCF8BD11&outletId=80&tatTime=0")

    axios.get(APIURLv2 + "settings/availableDatesAdvanced/?app_id=" + APPID + "&availability_id=" + config.ids.reservationId + "&outletId=" + this.state.selectResOutlet + "&tatTime=" + this.state.serverTat).then((tmres) => {
      $(".custom-date-label").removeClass("loader-main-cls")
      $(".custom-date-label").find(".loader-sub-div").remove()
      if (tmres.data.status === "success") {
        var result = tmres.data.timeslot_data.result_set[0]
        this.setState({ time_slot_type_advanced: result.time_slot_type })
        var mindatetime = result.minimum_date
        if (Product_LeadTime > 0) {
          if (mindatetime < Product_LeadTime) {
            mindatetime = Product_LeadTime
          } else {
            mindatetime = mindatetime
          }
        }
        mindatetime = parseInt(mindatetime) + parseInt(getDate_advanced)
        var currentDate = new Date(new Date(TodayDate).setDate(mindatetime))

        min_datetime = new Date(new Date(TodayDate).setDate(mindatetime - 1))
        var totalDays = parseInt(min_datetime.getDate()) + parseInt(1) + parseInt(Product_LeadTime) + parseInt(result.maximum_date)
        var max_datetime = new Date(new Date(TodayDate).setDate(totalDays))
        var cutOfftime = result.cut_off !== "" ? parseInt(result.cut_off) : ""

        /* Holliday Disable Dates */
        var hollidayList = tmres.data.timeslot_data.holidayresult
        var DisableDats = []
        if (hollidayList !== "") {
          hollidayList.map(function (loadData) {
            /*var chekingHoliday = parseInt(min_datetime.getFullYear())+'-'+parseInt(min_datetime.getMonth())+'-'+(currentThis.padnewone(parseInt(min_datetime.getDate())+1));
              if(chekingHoliday===chekingHoliday) {
              min_datetime = new Date(new Date(min_datetime).setDate(currentThis.padnewone(parseInt(min_datetime.getDate())+1)));
              currentDate = new Date(new Date(currentDate).setDate(currentThis.padnewone(parseInt(currentDate.getDate())+1)));
              }*/

            var checkingDate = currentDate.getFullYear() + "-" + currentThis.padnewone(parseInt(currentDate.getMonth()) + 1) + "-" + currentThis.padnewone(currentDate.getDate())
            if (loadData == checkingDate) {
              currentDate = new Date(new Date(TodayDate).setDate(parseInt(currentDate.getDate()) + 1))
            }

            DisableDats.push(currentThis.parseDate(loadData))
          })
        }

        var slot = tmres.data.timeslot_data.result_set[0].slot
        for (let k = mindatetime; k <= totalDays; k++) {
          var addDates = new Date(new Date(TodayDate).setDate(k))
          var addDay = weekdayArr[addDates.getDay()]
          if (slot[addDay] === "" || slot[addDay] === undefined) {
            var checking_Date = currentDate.getFullYear() + "-" + currentThis.padnewone(parseInt(currentDate.getMonth()) + 1) + "-" + currentDate.getDate()
            var hideDate = addDates.getFullYear() + "-" + this.padnewone(parseInt(addDates.getMonth()) + 1) + "-" + this.padnewone(addDates.getDate())
            if (hideDate == checking_Date) {
              currentDate = new Date(new Date(TodayDate).setDate(parseInt(currentDate.getDate()) + 1))
            }

            DisableDats.push(this.parseDate(hideDate))
          }
        }

        this.setState({
          min_datetime_advanced: min_datetime,
          max_datetime_advanced: max_datetime,
          date_advanced: currentDate,
          forcutoffstart: currentDate,
          cutOfftime_advanced: cutOfftime,
          timeExceed_advanced: "No",
          hollidayList: hollidayList,
          /*   allowHoliDay: "Yes", */
          disableDate_advanced: DisableDats,
        })
        var daytxt = weekdayArr[currentDate.getDay()]
        if (result.time_slot_type === "1") {
          this.setState(
            {
              intervalTime_advanced: tmres.data.timeslot_data.result_set[0].interval_time,
              timeandsecArrayset: slot,
            },
            function () {
              this.timeDropDownFunction(daytxt)
            }
          )
        } else if (result.time_slot_type === "2") {
          this.setState(
            {
              timeText_advanced: "Choose Time slot",
              timeandsecArrayset: tmres.data.timeslot_data.result_set[0].slot,
            },
            function () {
              this.loadTimeSlotDropDown(daytxt)
            }
          )
        }
      } else {
        this.setState({ intervalTime_advanced: "" })
        this.setState({ timeandsecArrayset: [] })
        this.setState({ timeHtml_advanced: "" })
        this.setState({ minsHtml: "" })
        this.setState({ timeExceed_advanced: "Yes" })
      }
    })
  }

  timeDropDownFunction(daytxt, skiptime = 0) {
    var timeExceed = "No"
    var TimeHtml = ""
    var changeTmtxt = ""
    var checkTimeArr = []
    var timethis = this
    var tatval = this.state.serverTat
    var cutOfftime = this.state.cutOfftime_advanced
    var totdayDate = new Date()
    var addHrs = parseInt(totdayDate.getMinutes()) + parseInt(this.state.serverTat)
    totdayDate.setMinutes(addHrs)
    var selectDate = this.state.date_advanced.getDate() + "-" + this.state.date_advanced.getMonth() + "-" + this.state.date_advanced.getFullYear()
    var j = 0
    var slotArr = []
    var addtionalArray = []
    var startList,
      endList = ""
    var intervalTime = this.state.intervalTime_advanced
    if (this.state.timeandsecArrayset[daytxt] !== "" && this.state.timeandsecArrayset[daytxt] !== undefined) {
      this.state.timeandsecArrayset[daytxt].map(function (item) {
        //Start Slot
        var slot_time1 = item.slot_time1.split(":")
        var startTimeVal = slot_time1.length > 0 ? slot_time1[0] : ""
        var startMinitVal = slot_time1.length > 0 ? slot_time1[1] : ""
        var strdatevalobj = new Date()
        strdatevalobj.setHours(startTimeVal)
        var stringrMinits = parseInt(startMinitVal) + parseInt(tatval)
        strdatevalobj.setMinutes(stringrMinits)
        startTimeVal = strdatevalobj.getHours()
        startMinitVal = strdatevalobj.getMinutes()

        //End Slot
        var endArr = item.slot_time2 !== "" ? item.slot_time2.split(":") : []
        var endTimeVal = endArr.length > 0 ? endArr[0] : ""
        var endMinitVal = endArr.length > 0 ? endArr[1] : ""
        var enddatevalobj = new Date()
        enddatevalobj.setHours(endTimeVal)
        var ingrendMinits = parseInt(endMinitVal) + parseInt(tatval)
        enddatevalobj.setMinutes(ingrendMinits)
        endTimeVal = enddatevalobj.getHours()
        endMinitVal = enddatevalobj.getMinutes()

        if (startTimeVal !== "" && endTimeVal !== "") {
          for (let valueTime = startTimeVal; valueTime <= endTimeVal; valueTime++) {
            var todaycond = 0
            var checkDate = totdayDate.getDate() + "-" + totdayDate.getMonth() + "-" + totdayDate.getFullYear()

            if (checkDate === selectDate) {
              if (valueTime < totdayDate.getHours()) {
                todaycond = 1
              }
            }
            if (checkTimeArr.indexOf(valueTime) >= 0 && todaycond === 0) {
              addtionalArray[timethis.padnewone(valueTime)] = strdatevalobj + "-" + enddatevalobj
            }
            if (checkTimeArr.indexOf(valueTime) === -1 && todaycond === 0) {
              if (skiptime <= valueTime) {
                if (j === 0) {
                  timethis.setState({ selectTime_advanced: valueTime })
                }

                if (startTimeVal == timethis.padnewone(valueTime)) {
                  startList = startMinitVal
                } else {
                  startList = 0
                }
                if (endTimeVal == valueTime) {
                  endList = endMinitVal
                } else {
                  endList = Math.round((60 / intervalTime - 1) * intervalTime)
                }
                if (startList > endList) {
                  endList = startList
                }
                slotArr[timethis.padnewone(valueTime)] = startList + "-" + endList
                checkTimeArr.push(valueTime)
                if (j === 0) {
                  timethis.setState({
                    selectTime_advanced: timethis.padnewone(valueTime),
                  })
                }
                var cutoffexist = 0

                if (cutOfftime !== "" && cutOfftime < valueTime && timethis.state.date_advanced.getDate() == timethis.state.forcutoffstart.getDate()) {
                  cutoffexist = 1
                }
                if (cutoffexist === 0) {
                  TimeHtml += "<option value='" + timethis.padnewone(valueTime) + "'  >" + timethis.padnewone(valueTime) + "</option>"
                  j++
                }
              }
            }
          }
        }
      })
    }
    var timeDropDown = ""
    var currentselectdate = this.state.date_advanced
    if (TimeHtml !== "") {
      timeDropDown = htmlToReactParser.parse(TimeHtml)
    } else {
      timeExceed = "Yes"

      var min_datetime = new Date(new Date(this.state.date_advanced).setDate(currentselectdate.getDate()))

      this.setState({ min_datetime_advanced: min_datetime })
      var addonday = parseInt(currentselectdate.getDate()) + 1
      var cudate = new Date(new Date(currentselectdate).setDate(addonday))

      this.setState({ date_advanced: cudate }, function () {
        this.timeDropDownFunction(weekdayArr[cudate.getDay()])
      })
    }

    this.setState(
      {
        timeHtml_advanced: timeDropDown,
        minsHtml: "",
        slotArr: slotArr,
        addtionalArray: addtionalArray,
        timeExceed_advanced: timeExceed,
      },
      function () {
        if (TimeHtml !== "") {
          this.minsDropDownFunction()
        }
      }
    )
  }

  minsDropDownFunction() {
    var slotArr = this.state.slotArr
    var intervalTime = this.state.intervalTime_advanced
    var selectTime = this.state.selectTime_advanced
    var timeExceed = "No"
    var startTimeLimit = 0
    var totdayDate = new Date()

    var addHrs = parseInt(totdayDate.getMinutes()) + parseInt(this.state.serverTat)

    totdayDate.setMinutes(addHrs)

    var selectDate = this.state.date_advanced.getDate() + "-" + this.state.date_advanced.getMonth() + "-" + this.state.date_advanced.getFullYear()

    var checkDate = totdayDate.getDate() + "-" + totdayDate.getMonth() + "-" + totdayDate.getFullYear()
    var endTimeLimit = (60 / intervalTime - 1) * intervalTime
    var startTime_Limit = startTimeLimit
    var endTime_Limit = endTimeLimit
    var slotArray = []
    while (startTime_Limit <= endTime_Limit) {
      slotArray.push(startTime_Limit)
      startTime_Limit += parseInt(intervalTime)
    }

    var k = 0
    var htmlMins = ""
    if (slotArr !== "" && slotArr[selectTime] !== "" && typeof slotArr[selectTime] !== undefined) {
      var slotArrNew = slotArr[selectTime].split("-")

      var startTimeLimitNew = parseInt(slotArrNew[0])
      var endTimeLimitNew = parseInt(slotArrNew[1])
      var minArrayList = []
      var startSelect = ""
      var startcompleteTime = 0
      if (totdayDate.getHours() == selectTime && checkDate == selectDate) {
        if (totdayDate.getMinutes() > startTimeLimitNew) {
          startcompleteTime = 1
        }
      }
      var currentEnd = 0
      if (slotArray.indexOf(startTimeLimitNew) === -1 && startcompleteTime === 0) {
        htmlMins += "<option value='" + this.padnewone(startTimeLimitNew) + "'  >" + this.padnewone(startTimeLimitNew) + "</option>"
        currentEnd = startTimeLimitNew
        startSelect = startTimeLimitNew
      }
      while (startTimeLimit <= endTimeLimit) {
        let completeTime = 0
        if (totdayDate.getHours() == selectTime && checkDate == selectDate) {
          if (totdayDate.getMinutes() > startTimeLimit) {
            completeTime = 1
          }
        }
        if (completeTime === 0) {
          if (startTimeLimitNew > startTimeLimit) {
            completeTime = 1
          }
        }
        if (completeTime === 0) {
          if (endTimeLimitNew < startTimeLimit) {
            completeTime = 1
          }
        }
        if (completeTime === 0) {
          if (k === 0) {
            this.setState({ selectMins_advanced: this.padnewone(startTimeLimit) })
          }
          minArrayList.push(startTimeLimit)
          htmlMins += "<option value='" + this.padnewone(startTimeLimit) + "'  >" + this.padnewone(startTimeLimit) + "</option>"
          currentEnd = startTimeLimit
          k++
        }
        startTimeLimit += parseInt(intervalTime)
      }

      var endcompleteTime = 0
      if (totdayDate.getHours() == selectTime && checkDate == selectDate) {
        if (totdayDate.getMinutes() > endTimeLimitNew) {
          endcompleteTime = 1
        }
      }

      if (minArrayList.indexOf(endTimeLimitNew) === -1 && startSelect !== endTimeLimitNew && endcompleteTime === 0) {
        htmlMins += "<option value='" + this.padnewone(endTimeLimitNew) + "'  >" + this.padnewone(endTimeLimitNew) + "</option>"
        currentEnd = endTimeLimitNew
      }
    }
    var loadAddtionalTime = this.loadAddtionalTime(currentEnd)
    htmlMins += loadAddtionalTime
    var minsDropDown = ""
    if (htmlMins !== "") {
      minsDropDown = htmlToReactParser.parse(htmlMins)
    } else {
      timeExceed = "Yes"
      var day_txt = weekdayArr[this.state.date_advanced.getDay()]
      var select_Time = parseInt(selectTime) + 1
      this.timeDropDownFunction(day_txt, select_Time)
    }
    this.setState({ minsHtml: minsDropDown, timeExceed_advanced: timeExceed })
  }

  getsettings() {
    var promise = helper.getProSettings("", "No") //getResult('settings','');
    promise.then((res) => {
      this.setState({ settings: res.data.result_set })

      /* Holiday  - start */
      var DisableDats = []
      if (parseInt(res.data.result_set.client_holiday_enable) === 1 && cookie.load("defaultAvilablityId") !== config.ids.dineinId) {
        const apiheaderauth = {
          headers: {
            Auth: cookie.load("Token"),
            "X-API-KEY": "8611E7D6-ECA7-4172-966C-4C10EA1E8C7N",
          },
        }
        axios.get(APIURL + "settings/get_holidaylist?app_id=" + APPID + "&availability=" + cookie.load("defaultAvilablityId") + "&outlet_id=327", apiheaderauth).then((res) => {
          if (res.data.status === "ok") {
            var resultData = res.data.result_set
            resultData.map((loadData) => DisableDats.push(this.parseDate(loadData.date)))
            this.setState({ hollidayList: resultData, allowHoliDay: "Yes", disableDate_advanced: DisableDats })
          }
        })
      }
      /* Holiday - end */
    })
  }

  loadAddtionalTime(currentEnd) {
    var html_Mins = ""
    var select_Time = this.state.selectTime_advanced
    var intervalTime = this.state.intervalTime_advanced
    var startTimeLimit = 0
    var endTimeLimit = (60 / intervalTime - 1) * intervalTime
    var addtional_Array = this.state.addtionalArray
    if (addtional_Array !== "" && addtional_Array[select_Time] !== "" && typeof addtional_Array[select_Time] !== undefined && typeof addtional_Array[select_Time] !== "undefined") {
      var totdayDate = new Date()

      var addHrs = parseInt(totdayDate.getMinutes()) + parseInt(this.state.serverTat)

      totdayDate.setMinutes(addHrs)

      var selectDate = this.state.date_advanced.getDate() + "-" + this.state.date_advanced.getMonth() + "-" + this.state.date_advanced.getFullYear()

      var checkDate = totdayDate.getDate() + "-" + totdayDate.getMonth() + "-" + totdayDate.getFullYear()

      var add_start_time = addtional_Array[select_Time].split("-")

      var startTime = new Date(add_start_time[0].trim())
      var endTime = new Date(add_start_time[1].trim())
      var endMin = endTimeLimit
      if (endTime.getHours() == select_Time) {
        endMin = endTime.getMinutes()
      }
      var completeTime = 0
      if (totdayDate.getHours() == select_Time && checkDate == selectDate) {
        if (totdayDate.getMinutes() > startTimeLimit) {
          completeTime = 1
        }
      }
      if (completeTime === 0) {
        html_Mins += "<option value='" + this.padnewone(startTime.getMinutes()) + "'  >" + this.padnewone(startTime.getMinutes()) + "</option>"
      }
      var minArrayList = []
      var endofMin = 0
      while (startTimeLimit <= endTimeLimit) {
        if (currentEnd < startTimeLimit && startTime.getMinutes() < startTimeLimit) {
          let completeTime = 0
          if (totdayDate.getHours() == select_Time && checkDate == selectDate) {
            if (totdayDate.getMinutes() > startTimeLimit) {
              completeTime = 1
            }
          }
          if (completeTime == 0) {
            minArrayList.push(startTimeLimit)
            html_Mins += "<option value='" + this.padnewone(startTimeLimit) + "'  >" + this.padnewone(startTimeLimit) + "</option>"
            endofMin = startTimeLimit
          }
        }
        startTimeLimit += parseInt(intervalTime)
      }

      var endcompleteTime = 0
      if (totdayDate.getHours() == select_Time && checkDate == selectDate) {
        if (totdayDate.getMinutes() > endMin) {
          endcompleteTime = 1
        }
      }
      if (minArrayList.indexOf(endMin) === -1 && startTime.getMinutes() !== endMin && endcompleteTime === 0 && endMin < endofMin) {
        html_Mins += "<option value='" + this.padnewone(endMin) + "'  >" + this.padnewone(endMin) + "</option>"
      }
    }
    return html_Mins
  }

  loadTimeSlotDropDown(daytxt) {
    var timeExceed = "No"
    var currentTime = new Date()
    if (this.state.serverTat !== "" && this.state.serverTat !== null && this.state.serverTat !== undefined && this.state.serverTat != "undefined") {
      var addhrToday = parseInt(currentTime.getMinutes()) + parseInt(this.state.serverTat)
      currentTime.setMinutes(addhrToday)
    }
    var timeSlotHtml = ""
    var selectDate = this.state.date_advanced.getDate() + "-" + this.state.date_advanced.getMonth() + "-" + this.state.date_advanced.getFullYear()

    var checkDate = currentTime.getDate() + "-" + currentTime.getMonth() + "-" + currentTime.getFullYear()
    var timeExist = 0
    if (this.state.timeandsecArrayset[daytxt] !== "" && this.state.timeandsecArrayset[daytxt] !== undefined) {
      this.state.timeandsecArrayset[daytxt].map(function (item) {
        timeExist = 0
        //Start Slot
        var slot_time1 = item.slot_time1.split(":")
        var startTimeVal = slot_time1.length > 0 ? slot_time1[0] : ""
        var startMinitVal = slot_time1.length > 0 ? slot_time1[1] : ""
        var strdatevalobj = new Date()
        strdatevalobj.setHours(startTimeVal)
        var stringrMinits = parseInt(startMinitVal)
        strdatevalobj.setMinutes(stringrMinits)

        //End Slot
        var endArr = item.slot_time2 !== "" ? item.slot_time2.split(":") : []
        var endTimeVal = endArr.length > 0 ? endArr[0] : ""
        var endMinitVal = endArr.length > 0 ? endArr[1] : ""
        var enddatevalobj = new Date()
        enddatevalobj.setHours(endTimeVal)
        var ingrendMinits = parseInt(endMinitVal)
        enddatevalobj.setMinutes(ingrendMinits)
        var lable = moment(strdatevalobj).format("hh:mm A") + " - " + moment(enddatevalobj).format("hh:mm A")
        var TimeValue = moment(strdatevalobj).format("HH:mm") + ":00" + "-" + moment(enddatevalobj).format("HH:mm") + ":00/" + moment(strdatevalobj).format("hh:mm A") + "-" + moment(enddatevalobj).format("hh:mm A")
        if (currentTime.getHours() >= strdatevalobj.getHours() && checkDate == selectDate) {
          timeExist = 1
        }
        if (timeExist == 0) {
          timeSlotHtml += "<option value='" + TimeValue + "'  >" + lable + "</option>"
        }
      })
    }
    var timeSlotDD = ""
    var currentselectdate = this.state.date_advanced
    if (timeSlotHtml !== "") {
      timeSlotDD = htmlToReactParser.parse(timeSlotHtml)
    } else {
      timeExceed = "Yes"
      var min_datetime = new Date(new Date(this.state.date_advanced).setDate(currentselectdate.getDate()))

      this.setState({ min_datetime_advanced: min_datetime })
      var addonday = parseInt(currentselectdate.getDate()) + 1
      var cudate = new Date(new Date(currentselectdate).setDate(addonday))

      this.setState({ date_advanced: cudate }, function () {
        this.loadTimeSlotDropDown(weekdayArr[cudate.getDay()])
      })
    }
    this.setState({ TimeSlotHtmlVal_advanced: timeSlotDD, timeExceed_advanced: timeExceed })
  }

  /* Disaple year */
  showTimeslotExceed() {
    if (this.state.timeExceed_advanced === "Yes") {
      if (APPID === "97347EE4-2845-4011-BCE6-9C0B806DA437" && (cookie.load("defaultAvilablityId") === "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11" || cookie.load("defaultAvilablityId") === "718B1A92-5EBB-4F25-B24D-3067606F67F0")) {
        if (cookie.load("defaultAvilablityId") === "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11") {
          return <div className="timeslot_exc">Delivery available only on Saturdays (12- 4) pm</div>
        } else if (cookie.load("defaultAvilablityId") === "718B1A92-5EBB-4F25-B24D-3067606F67F0") {
          return <div className="timeslot_exc">Takeaway available only on Saturdays (9 am to 2pm)</div>
        }
      } else if (APPID === "729A09D6-2A38-4E1C-ADB1-67A9242D2158" && (cookie.load("defaultAvilablityId") === "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11" || cookie.load("defaultAvilablityId") === "718B1A92-5EBB-4F25-B24D-3067606F67F0")) {
        if (cookie.load("defaultAvilablityId") === "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11") {
          return <div className="timeslot_exc">Delivery available only on Fri, Sat & Sun</div>
        } else if (cookie.load("defaultAvilablityId") === "718B1A92-5EBB-4F25-B24D-3067606F67F0") {
          return <div className="timeslot_exc">Pickup available only on Fri, Sat & Sun</div>
        }
      } else {
        return <div className="timeslot_exc"> Note: Please refer our operating hours. The time you are selecting is outside of our delivery timing. Apologies for the inconvenience.</div>
      }
    }
  }

  /* add zero */
  padnewone(d) {
    var dd = parseInt(d)
    return dd < 10 ? "0" + dd.toString() : dd.toString()
  }

  changeTimer(e) {
    var seletedTime = e.target.value
    this.setState({ selectTime_advanced: seletedTime, minsHtml: "" }, function () {
      this.minsDropDownFunction()
    })
  }

  /* chnage minutes */
  changeMins(e) {
    this.setState({ selectMins_advanced: e.target.value })
  }

  getRestimeData(outletIdtxt) {
    var qs = require("qs")
    var postObject = { app_id: APPID, outlet_id: outletIdtxt }
    var DisableDats = []
    axios
      .post(APIURL + "reservation/reservation_shop_closed_dates", qs.stringify(postObject))
      .then((response) => {
        if (response.data.status === "success") {
          var resultData = response.data.result_set
          resultData.map((loadData) => DisableDats.push(this.parseDate(loadData.res_closed_day)))
        } else {
          DisableDats = []
        }
        this.setState({ ReservationtimeData: DisableDats })
        this.getReservationtime(outletIdtxt, defaultDate)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  getReservationtime(selectResOutletIdtxt, resDateoptiontxt) {
    var qs = require("qs")
    var postObject = { app_id: APPID, outlet_id: selectResOutletIdtxt, availability_date: resDateoptiontxt }
    var resultDataHtm = ""
    axios
      .post(APIURLPRO + "reservation/reservation_availability_timesninjapro", qs.stringify(postObject))
      .then((response) => {
        if (response.data.status === "success") {
          resultDataHtm = response.data.result_set
        } else {
          resultDataHtm = "<option value=''>Select Time</option>"
        }
        var ReservationtimeDown = htmlToReactParser.parse(resultDataHtm)
        this.setState({ ReservationtimeHtml: ReservationtimeDown })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  changeResOutlet(event) {
    var seletedResOutletVal = event.target.value
    var splitedResOutletArr = seletedResOutletVal.split("~")
    var seletedResOutletid = splitedResOutletArr[0]
    var seletedResOutletAddr = splitedResOutletArr[3]
    var adultmax = splitedResOutletArr[1]
    var childmax = splitedResOutletArr[2]
    var ResOutletOptionTxt = event.target.options[event.target.selectedIndex].text
    this.setState({ selectResOutlet: seletedResOutletid })
    this.setState({ selectResOutletAdrr: seletedResOutletAddr })
    this.setState({ selectResOutletAdrrAndId: seletedResOutletVal })
    this.setState({ selectResOutletOptionTxt: ResOutletOptionTxt })
    this.setState({ resDate: defaultDate })
    this.getRestimeData(seletedResOutletid)

    var maxAdult = adultmax
    var maxChild = childmax

    var outlet_max_pax_limit = typeof event.target.options[event.target.selectedIndex].getAttribute("data-maxlimit") !== undefined ? event.target.options[event.target.selectedIndex].getAttribute("data-maxlimit") : 0
    this.setState({ outlet_max_pax_limit: outlet_max_pax_limit })

    var AdultHtml = ""
    var ChildrenHtml = ""

    for (var a = 0; a <= maxAdult; a++) {
      var adltxt = a > 1 ? "Adults" : "Adult"
      AdultHtml += "<option value='" + a + "'  >" + a + " " + adltxt + "</option>"
    }

    for (var a = 0; a <= maxChild; a++) {
      var chldtxt = a > 1 ? "Children" : "Child"
      ChildrenHtml += "<option value='" + a + "'  >" + a + " " + chldtxt + "</option>"
    }

    var AdultHtmlDropDown = htmlToReactParser.parse(AdultHtml)
    var ChildrenHtmlDropDown = htmlToReactParser.parse(ChildrenHtml)
    this.setState({ noofAdultHtml: AdultHtmlDropDown })
    this.setState({ noofChildrenHtml: ChildrenHtmlDropDown })

    this.setState({ selectResOutlet: seletedResOutletid }, function () {
      this.getTimesettingArrFun()
    })
  }

  changeResTimeset(event) {
    var seletedResTmval = event.target.value
    var seletedResTmTxt = event.target.options[event.target.selectedIndex].text
    this.setState({ resTimeval: seletedResTmval })
    this.setState({ resTimeText: seletedResTmTxt })
  }

  /* ViewProducts */
  ViewProducts(event) {
    var productSlug = event.value
    hashHistory.push("/product/" + productSlug)
  }

  reservationFirstli() {
    if (this.state.ResStageTxt === "info") {
      document.getElementById("InformationLi").className = ""
      document.getElementById("tab1").className = "tab-pane active"
      document.getElementById("tab2").className = "tab-pane"
      document.getElementById("goback_arwId").style.display = "none"
      //$('.goback_arwcls').hide();
    }
  }

  formatAMPM(date) {
    // console.log(date, "date");
    var hours = date
    var ampm = hours >= 12 ? "2" : "1"
    return ampm
  }

  /* Reservation Submission */
  reservationSubmtn() {
    this.setState({ manualLoading: true }, function () {
      this.loadManualLoading()
    })

    var errorTxt = ""
    var RsOutletVal = document.getElementById("resOutlets").value
    var RsNoofadultsVal = document.getElementById("noofadults").value
    var RsNoofchildrenVal = document.getElementById("noofchildren").value
    var RsResDatepkidVal = document.getElementById("resDatepkid").value
    /*var RsResTimeVal = document.getElementById("resTime").value;*/

    let RsresRemarkVal

    if (helper.disableSpecialInstructions(APPID)) {
      RsresRemarkVal = document.getElementById("resRemark").value
    }

    if (cookie.load("advanced_time_slot_reservation_enable") === "1") {
      var RsResTimeVal = this.state.time_slot_type_advanced == "1" ? document.getElementById("deliveryTime").value : document.getElementById("timeSlot").value
    } else {
      var RsResTimeVal = document.getElementById("resTime").value
    }

    // var RsresRemarkVal = document.getElementById("resRemark").value;

    if (cookie.load("advanced_time_slot_reservation_enable") === "1") {
      if (this.state.time_slot_type_advanced === "1") {
        var testing = document.getElementById("deliveryTime").value + ":" + document.getElementById("deliveryMins").value + ":00"
        var getValue = this.formatAMPM(RsResTimeVal)
        this.setState({ resTimeText: testing })
      } else {
        var timeslot_date = document.getElementById("timeSlot").value
        var split_time_slot = timeslot_date.split("/")
        var get_split_time = split_time_slot[0].split("-")
        this.setState({ resTimeText: split_time_slot[1] })
      }
    }

    this.setState({ resRemarkDes: RsresRemarkVal })
    cookie.save("reserVationClick", "")

    var calRsNoofadultsVal = RsNoofadultsVal !== "" ? parseInt(RsNoofadultsVal) : 0
    var calRsNoofchildrenVal = RsNoofchildrenVal !== "" ? parseInt(RsNoofchildrenVal) : 0
    /*var calResPeopleTxt = parseInt(calRsNoofadultsVal) + parseInt(calRsNoofchildrenVal);*/
    var adltext = "",
      chldtext = ""
    if (calRsNoofadultsVal > 0) {
      adltext = calRsNoofadultsVal > 1 ? calRsNoofadultsVal + " Adults " : calRsNoofadultsVal + " Adult "
    }
    if (calRsNoofchildrenVal > 0) {
      chldtext = calRsNoofchildrenVal > 1 ? calRsNoofchildrenVal + " Children" : calRsNoofchildrenVal + " Child"
    }
    var calResPeopleTxt = adltext + chldtext
    this.setState({ ResPeopleTxt: calResPeopleTxt })

    var calRsResDayText = ""
    var calRsResDatepkidVal = ""
    if (RsResDatepkidVal !== "") {
      var splitedDate = RsResDatepkidVal.split("/")
      var selDate = this.pad(splitedDate[0])
      var selMonth = this.pad(splitedDate[1])
      var calMnt = parseInt(splitedDate[1]) - 1
      var selMonthcal = this.pad(calMnt)
      var selYear = this.pad(splitedDate[2])
      this.setState({ ResselectedDateInst: selYear + "-" + selMonth + "-" + selDate })
      var caldatesplt = new Date(selYear, selMonthcal, selDate)
      calRsResDatepkidVal = ("0" + caldatesplt.getDate()).slice(-2) + " " + monthNamesArr[caldatesplt.getMonth()] + " " + caldatesplt.getFullYear()

      calRsResDayText = dayNamesArr[caldatesplt.getDay()]

      var calresvsateDate = this.state.ReservationtimeData
      var resvsateDateLen = calresvsateDate.length
      if (resvsateDateLen > 0) {
        for (var rd = 0; rd < resvsateDateLen; rd++) {
          if (dateFormat(caldatesplt, "d/m/yyyy") === dateFormat(calresvsateDate[rd], "d/m/yyyy")) {
            helper.showInfo("The selected date is a holiday. Please select another day")
            errorTxt = "yes"
          }
        }
      }
    }
    this.setState({ ResselectedDateTxt: calRsResDatepkidVal })
    this.setState({ ResselectedDayTxt: calRsResDayText })

    if (RsOutletVal === "" || RsOutletVal === "0" || RsOutletVal === "value") {
      helper.showInfo("Please Select Outlet")
      errorTxt = "yes"
      this.setState({ manualLoading: false }, function () {
        this.loadManualLoading()
      })
    } else if (RsNoofadultsVal === "" || RsNoofadultsVal === "0") {
      helper.showInfo("Please Enter No Of Adults")
      errorTxt = "yes"
      this.setState({ manualLoading: false }, function () {
        this.loadManualLoading()
      })
    } else if (RsNoofchildrenVal === "") {
      /* || RsNoofchildrenVal === '0'*/
      helper.showInfo("Please Enter No Of Children")
      errorTxt = "yes"
      this.setState({ manualLoading: false }, function () {
        this.loadManualLoading()
      })
    } else if (this.state.outlet_max_pax_limit > 0 && parseInt(RsNoofadultsVal) + parseInt(RsNoofchildrenVal) > this.state.outlet_max_pax_limit) {
      /* || RsNoofchildrenVal === '0'*/
      helper.showInfo("maximum allowed " + this.state.outlet_max_pax_limit + " members per reservation")
      errorTxt = "yes"
      this.setState({ manualLoading: false }, function () {
        this.loadManualLoading()
      })
    } else if (RsResDatepkidVal === "") {
      helper.showInfo("Please Select Date")
      errorTxt = "yes"
      this.setState({ manualLoading: false }, function () {
        this.loadManualLoading()
      })
    } else if (RsResTimeVal === "" || RsResTimeVal === "value") {
      helper.showInfo("Please Select Time")
      errorTxt = "yes"
      this.setState({ manualLoading: false }, function () {
        this.loadManualLoading()
      })
    }

    if (errorTxt === "") {
      this.setState({ ResStageTxt: "info", manualLoading: true }, function () {
        this.loadManualLoading()
      })

      //$('.goback_arwcls').show();

      if (cookie.load("advanced_time_slot_reservation_enable") === "1") {
        if (this.state.timeExceed_advanced === "No") {
          var current = this

          var DeliveryDate = this.state.date_advanced.getDate() + "/" + (parseInt(this.state.date_advanced.getMonth()) + 1) + "/" + this.state.date_advanced.getFullYear()
          var OrderTime = ""
          var timelimits = ""

          if (this.state.time_slot_type_advanced === "1") {
            var DeliveryTime = this.state.selectTime_advanced + ":" + this.state.selectMins_advanced

            var DeliveryDate1 = DeliveryDate.split("/")
            OrderTime = DeliveryDate1[2] + "-" + this.padnewone(DeliveryDate1[1]) + "-" + this.padnewone(DeliveryDate1[0]) + " " + DeliveryTime
          } else if (this.state.time_slot_type_advanced === "2") {
            var Timeslot = document.getElementById("timeSlot").value
            var splited = Timeslot.split("/")
            var startTime = splited[0].split("-")
            var DeliveryDate1 = DeliveryDate.split("/")
            OrderTime = DeliveryDate1[2] + "-" + this.padnewone(DeliveryDate1[1]) + "-" + this.padnewone(DeliveryDate1[0]) + " " + startTime[0]
            timelimits = "&order_pickup_time_slot_from=" + startTime[0] + "&order_pickup_time_slot_to=" + startTime[1]
          }

          const apiheaderauth = {
            headers: {
              Auth: cookie.load("Token"),
              "X-API-KEY": "8611E7D6-ECA7-4172-966C-4C10EA1E8C7N",
            },
          }

          axios
            .get(
              APIURLPRO +
                "ordersv1/validate_order_count_advanced?app_id=" +
                APPID +
                "&availability_id=" +
                config.ids.reservationId +
                "&outlet_id=" +
                this.state.selectResOutlet +
                "&order_date=" +
                OrderTime +
                timelimits +
                "&order_tat_time=" +
                this.state.serverTat +
                "&no_of_adult_pax=" +
                this.state.noofAdults +
                "&no_of_children_pax=" +
                this.state.noofChildren,
              apiheaderauth
            )
            .then((res) => {
              if (res.data.status === "ok") {
                document.getElementById("goback_arwId").style.display = "block"
                document.getElementById("loadIcon").style.display = "none"
                document.getElementById("InformationLi").className = "active"
                document.getElementById("tab1").className = "tab-pane"
                document.getElementById("tab2").className = "tab-pane active"

                this.setState({ manualLoading: false }, function () {
                  this.loadManualLoading()
                })
              } else {
                this.setState({ manualLoading: false }, function () {
                  this.loadManualLoading()
                })

                helper.showInfo(res.data.message)
              }
            })
          console.log("next-tab")
        }
      } else {
        document.getElementById("InformationLi").className = "active"
        document.getElementById("tab1").className = "tab-pane"
        document.getElementById("tab2").className = "tab-pane active"
        this.setState({ ResStageTxt: "info" })
        //$('.goback_arwcls').show();
        document.getElementById("goback_arwId").style.display = "block"
        console.log("next-tab")

        this.setState(
          {
            manualLoading: false,
          },
          function () {
            this.loadManualLoading()
          }
        )
      }
    }
  }

  orderComfirmSubmtn() {
    this.setState({ manualLoading: true }, function () {
      this.loadManualLoading()
    })
    document.getElementById("resvloadIcon").style.display = "block"

    let reservation_time = ""
    let reservation_slot_end_time = ""

    if (cookie.load("advanced_time_slot_reservation_enable") === "1") {
      if (this.state.time_slot_type_advanced === "1") {
        var RsResTimeVal = document.getElementById("deliveryTime").value
        var getValue = this.formatAMPM(RsResTimeVal)
        var testing = document.getElementById("deliveryTime").value + ":" + document.getElementById("deliveryMins").value + ":00"
        reservation_time = getValue + "-" + testing
        reservation_slot_end_time = testing
      } else {
        var timeslot_date = document.getElementById("timeSlot").value
        console.log(timeslot_date, "timeslot_date")

        var split_time_slot = timeslot_date.split("/")
        console.log(split_time_slot, "split_time_slot")
        var get_split_time = split_time_slot[0].split("-")

        console.log(get_split_time, "get_split_time")

        var getValue = this.formatAMPM(get_split_time[0].substring(0, 2))
        var getValue1 = this.formatAMPM(get_split_time[1].substring(0, 2))
        var prevTimeSlotValue = getValue === getValue1 ? getValue1 : getValue1
        reservation_time = getValue + "-" + get_split_time[0]
        reservation_slot_end_time = get_split_time[1]
      }
    } else {
      reservation_time = this.state.resTimeval
      reservation_slot_end_time = reservation_time
    }

    var qs = require("qs")
    var orderPostObject = {
      app_id: APPID,
      availability_id: config.ids.reservationId,
      order_source: "Web",
      reservation_status: "0",
      customer_id: cookie.load("UserId"),
      customer_fname: cookie.load("UserFname"),
      customer_lname: cookie.load("UserLname"),
      customer_email: cookie.load("UserEmail"),
      customer_mobile_no: cookie.load("UserMobile"),
      reservation_outlet_id: this.state.selectResOutlet,
      reservation_outlet_name: this.state.selectResOutletOptionTxt,
      reservation_datetime: this.state.ResselectedDateInst,
      reservation_outlet_time: reservation_time,
      reservation_no_of_adult_pax: this.state.noofAdults,
      reservation_no_of_children_pax: this.state.noofChildren,
      reservation_instruction: this.state.resRemarkDes,
      reservation_slot_end_time: reservation_slot_end_time,
      time_slot_type_advanced: cookie.load("advanced_time_slot_reservation_enable") === "1" ? this.state.time_slot_type_advanced : "",
    }

    axios
      .post(APIURL + "reservation/reservation_submit_order", qs.stringify(orderPostObject))
      .then((response) => {
        document.getElementById("resvloadIcon").style.display = "none"
        if (response.data.status === "ok") {
          this.setState({ resOrdermsg: response.data.message })
          this.setState({ resOrderPrmryId: response.data.common.order_primary_id })
          this.setState({ resOrderId: response.data.common.local_order_no })
          document.getElementById("ConfirmationLi").className = "active"
          document.getElementById("tab2").className = "tab-pane"
          document.getElementById("tab3").className = "tab-pane active"
          this.setState({ ResStageTxt: "" })
          //$('.goback_arwcls').hide();
          document.getElementById("goback_arwId").style.display = "none"

          this.setState(
            {
              manualLoading: false,
            },
            function () {
              this.loadManualLoading()
            }
          )
        }
      })
      .catch(function (error) {
        this.setState(
          {
            manualLoading: false,
          },
          function () {
            this.loadManualLoading()
          }
        )

        console.log(error)
      })
  }

  gotoMyAcct() {
    $("body").removeClass("reservationPopup")
    $("#ReservationMdl").modal("hide")
    if (typeof cookie.load("UserId") === "undefined" || (cookie.load("UserId") && cookie.load("UserId") === "")) {
      hashHistory.push(this.getSignInlink())
    } else {
      hashHistory.push("/my-account")
    }
  }

  closeResvPoup() {
    $("body").removeClass("reservationPopup")
    cookie.save("reserVationClick", "")
  }

  getSignInlink() {
    return parseInt(this.state.settings.client_enable_express_checkout) === 1 ? "/new-signin" : "/sign-in"
  }

  /* Menu open and close */
  openNavmenu() {
    //console.log('open');
    cookie.save("navmenuClk", "open")
    document.getElementById("mySidenav").className = "sidenav nav-width"
    document.getElementById("parentDisable").style.display = "block"
    this.setState({ openNav: "side-l side-l-tran" }) //rewadNav
    this.setState({ rewadNav: "side-l side-l-tran" })
    //	$("#promo_popup").modal("toggle");
  }

  closeNav() {
    if (cookie.load("navmenuClk") === "open") {
      cookie.save("navmenuClk", "no")
      document.getElementById("mySidenav").className = "sidenav"
      document.getElementById("parentDisable").style.display = "none"
      this.setState({ openNav: "side-l side-l-out" })
      this.setState({ rewadNav: "side-l side-l-out" })
    }
  }

  /* avilablity buttons */
  showAvilablityItems() {
    if (this.state.settings.availability) {
      return this.state.settings.availability.map((loaddata, index) => (
        <div className="modal-button" key={index}>
          {this.allowPickupOption(loaddata.availability_id)}
          {this.allowDeliveryOption(loaddata.availability_id)}
          {this.allowdineinOption(loaddata.availability_id)}
          {this.allowReservationOption(loaddata.availability_id)}
        </div>
      ))
    }

    /* return  (<div className="col-xs-12 modal-button">

		<button className={this.isActive(lang.delivery)}   type="button" id="delivery" name="delivery" onClick={this.changeAvilablityTemp.bind(this,config.ids.deliveryId,lang.delivery,lang.deliveryImageWhite)}> <img alt=""  src={this.activeImage(lang.delivery)} className="icon-img-small1"/> {" "} {lang.delivery} </button>
		
		    {this.allowPickupOption()}
               {this.allowdineinOption()} 
	 
		
		</div>)*/
  }

  /* function used to allow pickup */

  allowDeliveryOption(availId) {
    if (availId === config.ids.deliveryId) {
      return (
        <button className={this.isActive(lang.delivery)} type="button" id="delivery" name="delivery" onClick={this.changeAvilablityTemp.bind(this, config.ids.deliveryId, lang.delivery, lang.deliveryImageWhite)}>
          {" "}
          <img alt="" src={this.activeImage(lang.delivery)} className="icon-img-small1" /> {lang.delivery}{" "}
        </button>
      )
    }
  }

  /* this function allow dine-in option */
  allowdineinOption(availId) {
    if (availId === config.ids.dineinId) {
      return (
        <button className={this.isActive("Dine In")} type="button" id="dine-in" name="dine-in" onClick={this.changeAvilablityTemp.bind(this, config.ids.dineinId, "Dine In", lang.dineInImageWhite)}>
          {" "}
          <img alt="" src={this.activeImage("Dine In")} className="icon-img-small1" /> {"Dine In"}
        </button>
      )
    }
  }

  /* allow  pick up option */
  allowPickupOption(availId) {
    if (availId === config.ids.pickupId) {
      return (
        <button className={this.isActive(lang.takeaWay)} type="button" id="takeaway" name="takeaway" onClick={this.changeAvilablityTemp.bind(this, config.ids.pickupId, lang.takeaWay, lang.takeaWayImageWhite)}>
          {" "}
          <img alt="" src={this.activeImage(lang.takeaWay)} className="icon-img-small1" /> {lang.takeaWay}{" "}
        </button>
      )
    }
  }

  /* function used to allow reservation */

  allowReservationOption(availId) {
    if (availId === config.ids.reservationId) {
      return (
        <button className={this.isActive(lang.reservation)} type="button" id="reservation" name="reservation" onClick={this.changeAvilablityTemp.bind(this, config.ids.reservationId, lang.reservation, lang.reservationImageWhite)}>
          {" "}
          <img alt="" src={this.activeImage(lang.reservation)} className="icon-img-small1" /> {lang.reservation}{" "}
        </button>
      )
    }
  }

  berforeopenReserVtnPopup() {
    this.setState({ restimetxt: timetxt })
    var ReservationtimeDown = htmlToReactParser.parse("<option value=''>Select Time</option>")
    this.setState({ ReservationtimeHtml: ReservationtimeDown })

    this.setState({ selectResOutletAdrr: "" })
    this.setState({ selectResOutletAdrrAndId: "" })
    this.setState({ selectResOutletOptionTxt: "" })
    this.setState({ noofAdultHtml: "" })
    this.setState({ noofChildrenHtml: "" })
    this.setState({ ResPeopleTxt: "" })
    this.setState({ ResselectedDayTxt: "" })
    this.setState({ ResselectedDateTxt: "" })
    this.setState({ ResselectedDateInst: "" })
    this.setState({ resTimeText: "" })
    this.setState({ resRemarkDes: "" })
    this.setState({ ResStageTxt: "" })
    this.setState({ resOrderId: "" })
    this.getAllOutlets()
    this.getsettings()
  }

  openReserVtnPopup() {
    document.body.classList.add("reservationPopup")

    //$('.goback_arwcls').hide();
    document.getElementById("goback_arwId").style.display = "none"

    this.setState({ restimetxt: timetxt })
    var ReservationtimeDown = htmlToReactParser.parse("<option value=''>Select Time</option>")
    this.setState({ ReservationtimeHtml: ReservationtimeDown })

    this.setState({ selectResOutletAdrr: "" })
    this.setState({ selectResOutletAdrrAndId: "" })
    this.setState({ selectResOutletOptionTxt: "" })
    this.setState({ noofAdultHtml: "" })
    this.setState({ noofChildrenHtml: "" })
    this.setState({ ResPeopleTxt: "" })
    this.setState({ ResselectedDayTxt: "" })
    this.setState({ ResselectedDateTxt: "" })
    this.setState({ ResselectedDateInst: "" })
    this.setState({ resTimeText: "" })
    this.setState({ resRemarkDes: "" })
    this.setState({ ResStageTxt: "" })
    this.setState({ resOrderId: "" })

    this.getAllOutlets()

    $("#ReservationMdl").modal({
      backdrop: "static",
      keyboard: false,
    })
  }

  /* change Availability - default delievry  */
  changeAvilablityTemp(avail, availText, availImage) {
    $("body").removeClass("addresslistPopup")
    this.setState({ zoneOutletSeleted: "" })

    if (this.state.onetimeclickstate === "Yes") {
      this.setState({ onetimeclickstate: "No" })
      cookie.save("onetimeClick", "Yes", { expires })
    }

    if (avail === config.ids.reservationId) {
      cookie.save("itsfromreservation", "yes")
      document.getElementById("ReservationLiId").className = "active"
      document.getElementById("InformationLi").className = ""
      document.getElementById("ConfirmationLi").className = ""
      document.getElementById("tab1").className = "tab-pane active"
      document.getElementById("tab2").className = "tab-pane"
      document.getElementById("tab3").className = "tab-pane"

      if (typeof cookie.load("UserId") === "undefined" || (cookie.load("UserId") && cookie.load("UserId") === "")) {
        cookie.save("reserVationClick", "fromhome")
        hashHistory.push(this.getSignInlink())
      } else {
        this.openReserVtnPopup()
      }
    } else {
      cookie.save("itsfromreservation", "no")

      if (avail === config.ids.deliveryId) {
        this.getSecAddr()
      }

      this.setState({ avail: avail })
      this.setState({ availText: availText })
      this.setState({ availImage: availImage })

      //if(cookie.load('defaultAvilablityId')!== avail){
      if (cookie.load("cartValue") === "Yes") {
        //alert();

        $("#SwitchingError").modal("toggle")
      } else {
        this.clearCookieValues()
        this.changeAvilablity(avail, availText, availImage)
      }
      //}
    }
  }

  /* add zero */
  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString()
  }

  handleChangeDatefun = (item, value) => {
    this.setState({ resDate: value })
    this.getReservationtime(this.state.selectResOutlet, value)
  }

  noofAdultsfun = (itemvalue) => {
    this.setState({ noofAdults: itemvalue.target.value })
    cookie.save("reserVationClick", "")
  }

  noofChildrenfun = (itemvalue) => {
    this.setState({ noofChildren: itemvalue.target.value })
    cookie.save("reserVationClick", "")
  }

  changeRemarkDes = (itemvalue) => {
    this.setState({ resRemarkDes: itemvalue.target.value })
  }

  /* this function used to delte all  cart items */
  destroyCart() {
    var postObject = {}
    postObject = { app_id: config.ids.appId }
    if (typeof cookie.load("UserId") === "undefined") {
      var customerKey = "reference_id"
      var customerVal = getResult("referenceId")
    } else {
      customerKey = "customer_id"
      customerVal = cookie.load("UserId")
    }
    postObject[customerKey] = customerVal
    var qs = require("qs")
    axios.post(APIURL + "cart/destroy", qs.stringify(postObject)).then((res) => {
      cookie.save("cartValue", "No")
      if (res.data.status === "ok") {
        $(".carticon").attr("src", "/img/icons/shopping-cart.png")
      } else if (res.data.status === "error") {
      }
    })
  }

  /* used to delete all cookie values */
  clearCookieValues() {
    cookie.remove("orderOutletId")
    cookie.remove("orderZoneID")
    cookie.remove("orderOutletName")
    cookie.remove("orderPostalCode")
    cookie.remove("orderTAT")
    cookie.remove("orderDeliveryAddress")
    cookie.remove("orderHandled")
    cookie.remove("orderHandledByText")
  }

  /* change Availability - default delievry  */
  changeAvilablity(avail, availText, availImage) {
    this.savedefaultCookie(avail, availText, availImage)
    this.setState({ defaultAvilablity: availText })
    console.log(avail)
    this.loadMenuNavigation(avail, availText, availImage, "") /* removed Yes for loading issue */
    if (cookie.load("applyOutletId") === "yes") {
      this.openOrderTupeChangeFun()
    }
  }
  /* this function used to set default cookie values */
  savedefaultCookie(avail, availText, availImage) {
    cookie.save("defaultAvilablityId", avail, {})
    cookie.save("defaultAvilablity", availText, {})
    cookie.save("defaultAvilablityImage", availImage, {})
  }
  /* this function used to load cart icon image */
  loadCartImage() {
    return getResult("cartImage", "Yes")
  }

  /* this function used to load menu navigation */
  loadMenuNavigation(currentAvi, availText, availImage, loading) {
    if (loading === "Yes") {
      this.setState({ navStatus: "Loading" })
    }

    var orderOutletIdTxt = cookie.load("orderOutletId")
    var addqueryTxt = ""

    if (typeof orderOutletIdTxt !== "undefined" && orderOutletIdTxt !== "") {
      addqueryTxt = "&outletIdTxt=" + orderOutletIdTxt
    }

    axios.get(APIURL + "productsver3/navigation_menu?app_id=" + APPID + addqueryTxt + "&availability=" + currentAvi).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ navStatus: res.data.status })
        this.setState({ common: res.data.common })
        this.setState({ category: res.data.result_set })
        this.setState({ defaultAvilablityImage: availImage })
        this.setState({ defaultAvilablity: availText })

        // this.setstate({defaultAvilablityImage:availImage });
      } else {
        this.setState({ navStatus: res.data.status })
      }
    })
  }

  __showBanners() {
    this.__bxslider()

    if (this.state.banner) {
      return this.state.banner.map((loaddata, index) => (
        <div key={index}>
          <img src={this.state.bannersource + loaddata.banner_image} alt="Loading..." />
          {/* <p className="legend">{loaddata.banner_name}</p> */}
        </div>
      ))
    } else {
      return (
        <div>
          {" "}
          <img src="img/header-img.jpg" alt="Loading..." />{" "}
        </div>
      )
    }
  }

  /* listing navigation  items.. */
  __ProductListing() {
    if (this.state.navStatus === "Loading2") {
      return (
        <div className="home-load-div">
          {" "}
          <img src="/img/loader.gif" alt="" className="home-load-img" />{" "}
        </div>
      )
    } else if (this.state.navStatus === "ok") {
      return this.state.category.map((loaddata, index) => (
        <div className="col-xs-12 nopadding home-bottom-img" key={index}>
          <div className="gradient-img">
            {" "}
            <Link key={index} to={this.__navLink(loaddata.menu_type, loaddata.menu_category_id, loaddata.pro_subcate_id, index)}>
              <img src={this.__navImage(loaddata.menu_type, loaddata.pro_cate_image, loaddata.pro_subcate_image, this.state.common.category_image_url, this.state.common.subcategory_image_url)} alt="" className="img-responsive" />
              <p className="text-left font-bold text-uppercase pd-l-20">{strp.stripslashes(loaddata.menu_custom_title)}</p>
            </Link>
          </div>
        </div>
      ))
    } else {
      if (!helper.disableSpecialInstructions(APPID)) {
        return null
      } else {
        return (
          <div className="col-xs-12 nopadding home-bottom-img no_pro_home">
            {" "}
            <span> {lang.no_products_found} </span>{" "}
          </div>
        )
      }
    }
  }

  /* return navigation menu slug */
  __navLink(menu_type, CateId, subCateId, slideno) {
    return menu_type === "sub" ? "/products/subcategory/" + subCateId + "/" + slideno : "/products/category/" + CateId + "/" + slideno
  }

  /* return navigation image value.  like no image, category image, sub category image */
  __navImage(menuType, cateImgae, subCateImage, cateUrl, subcateUrl) {
    var image = menuType === "sub" ? subCateImage : cateImgae
    var imageUrl = menuType === "sub" ? subcateUrl : cateUrl
    return image === null || image === "" ? "/img/no-images/products-no-image.jpg" : config.path.tinThumpUrl + imageUrl + "/" + image + "&w=768&h=265&q=80"
  }

  /* this function used to add active class for selected avilablity */
  isActive(value) {
    //alert(value + "//" + this.state.defaultAvilablity);
    //value = (value === "Dine in"? "Dine In" : value);
    return value === this.state.defaultAvilablity ? "btn active" : "btn in-active"
  }

  /* this function used to show active images  */
  activeImage(value) {
    //return (value === this.state.defaultAvilablity? 'btn active': 'btn in-active');
    if (value === this.state.defaultAvilablity) {
      return value === "Dine In" ? lang.dineInImageWhiteDefault : "/img/icons/home-popup/" + this.state.defaultAvilablity + "_white.svg"
    } else {
      return value === "Dine In" ? lang.dineInImage : "/img/icons/home-popup/" + value + ".svg"
    }
  }

  /* login part */
  loginPart() {
    if (typeof cookie.load("UserId") !== "undefined" && this.state.login === "") {
      return (
        <div className="logout-menu">
          <Link className={this.state.openNav} onClick={this.logOut.bind(this)} style={{ cursor: "pointer" }}>
            <img alt="" src="/img/icons/logout.png" /> Logout
            <p className="subb">Signed in as {cookie.load("UserEmail")}</p>
          </Link>
        </div>
      )
    }
  }
  loginPartHeader() {
    if (typeof cookie.load("UserId") !== "undefined" && this.state.login === "") {
      return (
        <div className="login-header">
          <div className="member" style={{ flexGrow: "9" }}>
            <p> Hello, {cookie.load("UserFname")}</p>
            <img src="img/hi.gif" />
          </div>
          <div style={{ flexGrow: "3" }} className="sign-out">
            <Link onClick={this.logOut.bind(this)} style={{ cursor: "pointer" }}>
              {" "}
              Logout
            </Link>
          </div>
        </div>
      )
    }
  }
  signinHeader() {
    if (typeof cookie.load("UserId") === "undefined" || (cookie.load("UserId") && cookie.load("UserId") === "")) {
      return (
        <div className="signin-container">
          <div style={{ flexGrow: "4" }}></div>
          <div className="not-a-member" style={{ flexGrow: "6" }}>
            Signup as a member today!{" "}
          </div>
          <div className="sign-in" style={{ flexGrow: "2" }}>
            <Link to={this.getSignInlink()}>Signup</Link>
          </div>
        </div>
      )
    }
  }

  closeSwitcher() {
    $("#SwitchingError").modal("hide")
    document.body.classList.add("home_body")
  }

  closeAndBrowse() {
    $("#onload-screen").modal("hide")
    $(".modal-backdrop").remove()
  }
  switchAvailability() {
    var avail = this.state.avail
    var availText = this.state.availText
    var availImage = this.state.availImage
    this.changeAvilablity(avail, availText, availImage)
    this.destroyCart()

    /* remove default cokkie values */
    cookie.remove("orderOutletId")
    cookie.remove("orderOutletName")
    cookie.remove("orderPostalCode")
    cookie.remove("orderTAT")
    cookie.remove("orderDeliveryAddress")
    cookie.remove("orderHandled")
    cookie.remove("orderHandledByText")

    cookie.remove("outlet_tax_option")
    cookie.remove("outlet_tax_surcharge")
    cookie.remove("outlet_tax_surcharge_inclusive")

    cookie.remove("product_remarks")

    $("#SwitchingError").modal("toggle")
  }

  logOut() {
    //console.log('logout');
    cookie.remove("UserId")
    cookie.remove("UserFname")
    cookie.remove("UserLname")
    cookie.remove("UserMobile")
    cookie.remove("UserEmail")
    cookie.remove("userAccountType")
    cookie.remove("isAnonymousUser")
    cookie.remove("isTempUser")
    // this.closeNav();
    this.setState({ login: "No" })
    hashHistory.push(this.getSignInlink())
  }

  /* this function used to show office timing*/
  showTimer() {
    //console.log(this.state.settings.client_delivery_enable);
    /* if(parseFloat(this.state.settings. 	client_timer_enable) === 1 && this.state.settings.client_start_time!=='' && this.state.settings.client_end_time!=='') { 
	 return (<p className="b-txt text-right pd-r-20">{this.state.settings.ninja_pro_operation_time_start} - {this.state.settings.ninja_pro_operation_time_end} </p>);
	/*  }  */

    return helper.shopTiming(this.state.settings.ninja_pro_operation_time_start, this.state.settings.ninja_pro_operation_time_end)
  }
  /* open  direction link */
  targetMap() {
    if (typeof this.state.settings.ninja_pro_direction_url != "undefined" || this.state.settings.ninja_pro_direction_url !== "") {
      window.open(this.state.settings.ninja_pro_direction_url)
    }
  }

  /* load home banner image */
  homeBanner() {
    //img/logo.png
    return typeof this.state.settings.ninja_pro_home_banner === "undefined" || this.state.settings.ninja_pro_home_banner === "" ? "/img/no-images/products-no-image.jpg" : config.path.tinThumpUrl + this.state.settings.ninja_pro_home_banner + "&w=768&h=265&q=80"
  }
  /* load home logo  image */
  loadLogo() {
    //img/logo.png
    return typeof this.state.settings.ninja_pro_logo === "undefined" || this.state.settings.ninja_pro_logo === "" ? "/img/logo.png" : config.path.tinThumpUrl + this.state.settings.ninja_pro_logo + "&w=191&h=51&q=80"
  }

  /* this function used to load mobile number */
  loadNumber() {
    if (typeof this.state.settings.ninja_pro_phone_number != "undefined" || this.state.settings.ninja_pro_phone_number !== "") {
      return "tel:" + this.state.settings.ninja_pro_phone_number
    }
  }
  loadPopupLogoImage() {
    if (typeof this.state.settings.ninja_pro_welcome_logo === "undefined" || this.state.settings.ninja_pro_welcome_logo === "") {
      if (typeof this.state.settings.ninja_pro_logo === "undefined" || this.state.settings.ninja_pro_logo === "") {
        return "/img/logo.png"
      } else {
        return config.path.tinThumpUrl + this.state.settings.ninja_pro_logo + "&w=191&h=51&q=80"
      }
    } else {
      return config.path.tinThumpUrl + this.state.settings.ninja_pro_welcome_logo + "&w=370&h=94&q=80"
    }
    //return  (typeof this.state.settings.ninja_pro_welcome_logo === "undefined" || this.state.settings.ninja_pro_welcome_logo === "" )? "/img/logo.png" : config.path.tinThumpUrl+this.state.settings.ninja_pro_welcome_logo+"&w=370&h=94&q=80";
    //     return  (typeof this.state.settings.ninja_pro_logo === "undefined" || this.state.settings.ninja_pro_logo === "" )? "/img/logo.png" : config.path.tinThumpUrl+this.state.settings.ninja_pro_logo+"&w=191&h=51&q=80";
  }
  loadPopupBackgroundImage() {
    //img/logo.png
    return typeof this.state.settings.ninja_pro_bg_image === "undefined" || this.state.settings.ninja_pro_bg_image === "" ? "/img/popup-images/popup-bg.jpg" : this.state.settings.ninja_pro_bg_image
  }

  loadPopupWelcomeText() {
    var homeHeaderText = "How will you be enjoying <br/> your food today?"
    return htmlToReactParser.parse(this.state.settings.ninja_pro_welcome_text === null || this.state.settings.ninja_pro_welcome_text === "" ? homeHeaderText : this.state.settings.ninja_pro_welcome_text)
  }

  /* food types */
  foodTypes() {
    if (this.state.settings.ninja_pro_food_types && this.state.settings.ninja_pro_food_types.length > 0) {
      return this.state.settings.ninja_pro_food_types.map((loaddata, index) => (
        <button key={index} type="button" className="btn pull-right food-type-btn" style={{ cursor: "text" }}>
          {loaddata}
        </button>
      ))
    }
  }

  /* get Promo code  details */
  getPromocode() {
    var inputEmail = $("#promoEmail").val()
    if (this.isValidEmailAddress(inputEmail)) {
      var qs = require("qs")
      var promoPostObject = { app_id: config.ids.appId, email_address: inputEmail }
      document.getElementById("loadIcon").style.display = "block"
      axios.post(APIURL + "promotion/user_promotion", qs.stringify(promoPostObject)).then((res) => {
        document.getElementById("loadIcon").style.display = "none"
        $("#promo_popup").modal("hide")
        this.DisablePromoPopup()
        if (res.data.status === "ok") {
          helper.showInfo(alerts.promopopSuc)
        } else if (res.data.status === "error") {
          helper.showInfo(alerts.promopopFail)
        }
      })
    } else {
      helper.showInfo(alerts.invalidEmail)
    }
  }

  /* set promocode disable value */
  DisablePromoPopup() {
    cookie.save("promoPopup", "Yes")
  }

  /* validate email address */
  isValidEmailAddress(emailAddress) {
    var pattern = new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i)
    // alert( pattern.test(emailAddress) );
    return pattern.test(emailAddress)
  }

  /* Don't show promo popup */

  toggleCheckbox() {
    cookie.save("NormalPopup", "Yes")
    $("#normal_popup").modal("hide")
  }

  /* bx slider  - no pager class */
  bxNoPager() {
    var sliderLen = this.state.banner
    console.log(sliderLen.lenght)
    if (this.state.banner.lenght === 1) {
      return "bx-slide-single"
    }
  }

  /* Hide  drop down work space site */
  hidedropDown() {
    return APPID === apps.workspace ? "none" : "block"
  }

  /* continue with pickup success */
  pickupSuccess() {
    /* load menu items */
    this.loadMenuNavigation(config.ids.pickupId, lang.takeaWay, lang.takeaWayImageWhite, "")
    $("#Pickup-Sucess").modal("hide")
    $("#pickup-outlet-sucess").modal("hide")
    $("#onload-screen").modal("hide")
    $(".modal-backdrop").remove()
    this.setState({ onetimeclickstate: "No" })
    cookie.save("onetimeClick", "Yes", { expires })
  }

  /* continue with delivery success */
  deliverySuccess() {
    this.loadMenuNavigation(config.ids.deliveryId, lang.delivery, lang.deliveryImageWhite, "")
    $("body").removeClass("addresslistPopup")
    $("#DeliverySucess").modal("hide")

    this.setState({ load: "false" })
    $("#onload-screen").modal("hide")
    $(".modal-backdrop").remove()
    this.setState({ onetimeclickstate: "No" })
    cookie.save("onetimeClick", "Yes", { expires })
  }

  closeDeliveryErr() {
    this.setState({ load: "false" })
    $("#DeliveryError").modal("toggle")
  }

  closeShopClosedError() {
    this.setState({ load: "false" })
    $("#ShopClosedError").modal("toggle")
  }

  closeaddlist() {
    $("body").removeClass("addresslistPopup")
  }

  /* Open Zipcode popup */
  changeDelivery() {
    this.setState({ load: "false" })
    $("#DeliveryError").modal("hide")
    console.log("login")
    if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
      document.body.classList.add("addresslistPopup")
      $("#AddressList").modal({ backdrop: "static", keyboard: false })
    } else {
      $("#DeliveryAddress").modal({ backdrop: "static", keyboard: false })
    }
  }

  /* add outlet ids work spac */
  addWorkSpaceCookieValues(data) {
    var loaddata = data[0]
    var outlet_name = helper.stripslashes(loaddata.outlet_name)
    var outlet_id = loaddata.outlet_id,
      loaddata
    var outlet_address_line1 = loaddata.outlet_address_line1
    var outlet_address_line2 = loaddata.outlet_address_line2
    var outlet_postal_code = loaddata.outlet_postal_code
    var outlet_pickup_tat = loaddata.outlet_postal_code

    var orderHandled = outlet_name + " " + outlet_address_line1 + " " + outlet_address_line2 + ", Singapore " + outlet_postal_code

    this.setState({ orderHandled: orderHandled })
    cookie.save("orderOutletId", outlet_id)
    cookie.save("orderOutletName", outlet_name)
    cookie.save("orderPostalCode", outlet_postal_code)
    cookie.save("orderTAT", outlet_pickup_tat)
    cookie.save("orderHandled", orderHandled)
    cookie.save("orderHandledByText", orderHandled)
  }

  /* pick outlet */
  pickOutlet(outlet_id, outlet_name, outlet_postal_code, outlet_address_line1, outlet_address_line2, outlet_unit_number1, outlet_unit_number2, outlet_pickup_tat, outlet_tax_option, outlet_tax_surcharge, outlet_tax_surcharge_inclusive) {
    if (outlet_unit_number1 != "" && outlet_unit_number2 != "") {
      var unitTxt = ", #" + outlet_unit_number1 + "-" + outlet_unit_number2
      var orderHandled = outlet_name + " " + outlet_address_line1 + " " + outlet_address_line2 + unitTxt + ", Singapore " + outlet_postal_code
    } else if (outlet_unit_number1 != "" || outlet_unit_number2 != "") {
      var unitTxt = outlet_unit_number1 != "" ? ", #" + outlet_unit_number1 : ", #" + outlet_unit_number2
      var orderHandled = outlet_name + " " + outlet_address_line1 + " " + outlet_address_line2 + unitTxt + ", Singapore " + outlet_postal_code
    } else {
      var orderHandled = outlet_name + " " + outlet_address_line1 + " " + outlet_address_line2 + ", Singapore " + outlet_postal_code
    }

    this.setState({ orderHandled: orderHandled })
    cookie.save("orderOutletId", outlet_id)
    cookie.save("orderOutletName", outlet_name)
    cookie.save("orderPostalCode", outlet_postal_code)
    cookie.save("orderTAT", outlet_pickup_tat)
    cookie.save("orderHandled", orderHandled)
    cookie.save("orderHandledByText", orderHandled)

    cookie.save("outlet_tax_option", outlet_tax_option)
    cookie.save("outlet_tax_surcharge", outlet_tax_surcharge)
    cookie.save("outlet_tax_surcharge_inclusive", outlet_tax_surcharge_inclusive)

    $("#Pickup-OutletSelection").modal("hide")
    $("#Pickup-Sucess").modal({ backdrop: "static", keyboard: false })
  }

  openOrderTupeChangeFun() {
    if (cookie.load("applyOutletId") === "yes" && cookie.load("navmenuClk") === "no" && cookie.load("itsfromreservation") !== "yes") {
      console.log(1)
      if (cookie.load("defaultAvilablityId") === config.ids.deliveryId && cookie.load("defaultAvilablity") === lang.delivery) {
        console.log(2)
        if (typeof cookie.load("orderOutletId") === "undefined" || typeof cookie.load("orderPostalCode") === "undefined") {
          console.log(3)
          if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
            document.body.classList.add("addresslistPopup")
            if (parseInt(this.state.settings.client_outlet_zone) === 0) {
              $("#AddressList").modal({ backdrop: "static", keyboard: false })
            } else {
              if (this.state.zoneOutletSeleted == "") {
                if (parseInt(this.state.settings.client_zone_outlet_disable) === 1) {
                  $("#AddressList").modal({ backdrop: "static", keyboard: false })
                } else {
                  $("#delivery_outlet").modal({ backdrop: "static", keyboard: false })
                }
              }
            }
          } else {
            if (parseInt(this.state.settings.client_outlet_zone) === 0) {
              $("#DeliveryAddress").modal({ backdrop: "static", keyboard: false })
            } else {
              if (this.state.zoneOutletSeleted == "") {
                if (parseInt(this.state.settings.client_zone_outlet_disable) === 1) {
                  $("#DeliveryAddress").modal({ backdrop: "static", keyboard: false })
                } else {
                  $("#delivery_outlet").modal({ backdrop: "static", keyboard: false })
                }
              }
            }
          }
        }
      } else if (cookie.load("defaultAvilablityId") === config.ids.pickupId && cookie.load("defaultAvilablity") === lang.takeaWay) {
        cookie.save("dineinClick", "no")
        var pickupOutlets = this.state.Outlets
        if (typeof cookie.load("orderOutletId") === "undefined" || typeof cookie.load("orderPostalCode") === "undefined") {
          if (this.state.Outlets.length === 0) {
            this.getpickupOutletlist()
          } else if (pickupOutlets.length === 1) {
            if (pickupOutlets[0].outlet_unit_number1 != "" && pickupOutlets[0].outlet_unit_number2 != "") {
              var unitTxt = ", #" + pickupOutlets[0].outlet_unit_number1 + "-" + pickupOutlets[0].outlet_unit_number2
            } else if (pickupOutlets[0].outlet_unit_number1 != "" || pickupOutlets[0].outlet_unit_number2 != "") {
              var unitTxt = pickupOutlets[0].outlet_unit_number1 != "" ? ", #" + pickupOutlets[0].outlet_unit_number1 : ", #" + pickupOutlets[0].outlet_unit_number2
            }

            var orderHandled = pickupOutlets[0].outlet_name + unitTxt + ", " + pickupOutlets[0].outlet_address_line1 + " " + pickupOutlets[0].outlet_address_line2 + ", Singapore " + pickupOutlets[0].outlet_postal_code

            this.setState({ orderHandled: orderHandled })
            cookie.save("orderOutletId", pickupOutlets[0].outlet_id)
            cookie.save("orderOutletName", pickupOutlets[0].outlet_name)
            cookie.save("orderPostalCode", pickupOutlets[0].outlet_postal_code)
            cookie.save("orderTAT", pickupOutlets[0].outlet_pickup_tat)
            cookie.save("orderHandled", orderHandled)
            cookie.save("orderHandledByText", orderHandled)

            cookie.save("outlet_tax_option", pickupOutlets[0].outlet_tax_option)
            cookie.save("outlet_tax_surcharge", pickupOutlets[0].outlet_tax_surcharge)
            cookie.save("outlet_tax_surcharge_inclusive", pickupOutlets[0].outlet_tax_surcharge_inclusive)

            cookie.save("outlet_option_single", 1)

            $("#pickup-outlet-sucess").modal("toggle")
          } else {
            $("#Pickup-OutletSelection").modal({ backdrop: "static", keyboard: false })
          }
        }
      } else if (cookie.load("defaultAvilablityId") === config.ids.dineinId && cookie.load("defaultAvilablity") === "Dine In") {
        cookie.save("dineinClick", "yes")
        if (typeof cookie.load("orderOutletId") === "undefined" || typeof cookie.load("orderTableNo") === "undefined" || cookie.load("orderOutletId") === "" || cookie.load("orderTableNo") === "") {
          //cookie.save('dineinClick','yes');
          cookie.remove("orderTableNo")
          if (APPID === "287E5552-328E-4DBE-A3CA-E998ACCAC9BD") {
            helper.sarveshTableSetup()
          } else {
            hashHistory.push("/book-a-table")
          }
        }
      }
    }

    if (cookie.load("navmenuClk") === "close") {
      cookie.save("navmenuClk", "no")
    }
  }

  handleChange(event) {
    this.setState({ addressField: event.target.value })
  }

  handleChangeValue(event) {
    this.setState({ addressField: event.target.value })
  }

  /* Validate zipcode */
  validatezip(e) {
    const re = /[0-9A-F:]+/g
    if (!re.test(e.key)) {
      e.preventDefault()
    }
  }

  /* delivery timing */
  showDeliveryTiming() {
    if (APPID === apps.leanbento) {
      return (
        <p>
          <b>Your Order is handled by:</b>
          <br /> {this.state.orderHandled}
        </p>
      )
    }
  }

  /* used to get all secondary address list */
  getSecAddr() {
    axios.get(APIURL + "/customer/get_user_secondary_address?app_id=" + APPID + "&refrence=" + cookie.load("UserId")).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ secAddrData: res.data.result_set })
      }
    })
  }

  /* used to load address list */
  loadAddressList() {
    return this.state.secAddrData.map((item, index) => (
      <p key={index}>
        <input name="radio" value={item.postal_code} id={item.secondary_address_id} className="css-radio" type="radio" onChange={this.handleChange.bind(this)} />
        <label htmlFor={item.secondary_address_id} className="css-label ">
          {" "}
          {item.address + " Singapore " + item.postal_code}
        </label>
      </p>
    ))
  }

  /* show Address */
  showNewAddressFrmd(pstcode, address1, address2, unittxt1, unittxt2) {
    var addressLn = address2 != "" ? address1 + ", " + address2 : address1
    if (unittxt1 != "" && unittxt2 != "") {
      addressLn = addressLn + ", #" + unittxt1 + "-" + unittxt2
    } else if (unittxt1 != "" || unittxt2 != "") {
      addressLn = unittxt1 != "" ? addressLn + ", #" + unittxt1 : addressLn + ", #" + unittxt2
    }

    addressLn = addressLn + ", Singapore " + pstcode
    return addressLn
  }

  /* load outlets */
  __loadOutlets() {
    if (this.state.Outlets) {
      let list = helper.getSortedList(this.state.Outlets, this.props.coords)

      return (
        !isEmpty(list) &&
        list.map((loaddata, index) => (
          <div
            className="map-li"
            key={index}
            onClick={this.pickOutlet.bind(
              this,
              loaddata.outlet_id,
              loaddata.outlet_name,
              loaddata.outlet_postal_code,
              loaddata.outlet_address_line1,
              loaddata.outlet_address_line2,
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2,
              loaddata.outlet_pickup_tat,
              loaddata.outlet_tax_option,
              loaddata.outlet_tax_surcharge,
              loaddata.outlet_tax_surcharge_inclusive
            )}
          >
            <p className="map-h outlet-name">{helper.stripslashes(loaddata.outlet_name)}</p>
            <p className="map-sub">{this.showNewAddressFrmd(loaddata.outlet_postal_code, loaddata.outlet_address_line1, loaddata.outlet_address_line2, loaddata.outlet_unit_number1, loaddata.outlet_unit_number2)}</p>
            <Distancebar minDateValue={loaddata.min_date} isOutletBased={loaddata.is_outlet_based} distance={loaddata.distance} coords={this.props.coords} tat={loaddata.outlet_pickup_tat ? loaddata.outlet_pickup_tat : loaddata.outlet_delivery_timing} />
          </div>
        ))
      )
    }
  }
  /* Select outlet */
  selectOutlet(thisobject, retry = false, callbackPostalCode, addressObj) {
    var postalcode = ""
    let address = ""
    let googleRetryAttempt = 0
    if (retry === false) {
      if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
        postalcode = $("#postalcode").val() //"postalcode";
      } else {
        postalcode = $("#postalcode1").val() // "postalcode1";
      }
    } else {
      postalcode = callbackPostalCode
      address = addressObj
      googleRetryAttempt = 1
    }
    if (postalcode.length < 5) {
      return false
    }

    this.setState({ load: "true" })
    var findPostUrl = APIURL + "outlets/find_outlet?skip_timing=Yes&app_id=" + APPID + "&availability_id=" + cookie.load("defaultAvilablityId") + "&postal_code=" + postalcode
    if (parseInt(this.state.settings.client_outlet_zone) === 1) {
      findPostUrl =
        API_v4_URL + "test/outlets/findOutletZone?skip_timing=Yes&app_id=" + APPID + "&availability_id=" + cookie.load("defaultAvilablityId") + "&postal_code=" + postalcode + "&outlet_id=" + this.state.zoneDeliveryOutlet + "&googleRetryAttempt=" + googleRetryAttempt + "&addressObject=" + address
    }
    /*add loading */
    axios.get(findPostUrl).then((res) => {
      /* Success response */
      /* Success response  && Object.keys(res.data.result_set.postal_code_information).length !==0 */
      if (res.data.status === "ok") {
        var orderDeliveryAddress = res.data.result_set.postal_code_information.zip_buno + " " + res.data.result_set.postal_code_information.zip_sname
        var orderHandled = res.data.result_set.outlet_name + ", Crew will be seeing you in " + res.data.result_set.outlet_delivery_timing + " Minutes"
        this.setState({ orderDeliveryAddress: orderDeliveryAddress + " Singapore " + res.data.result_set.postal_code_information.zip_code })
        this.setState({ orderHandled: orderHandled })
        cookie.save("orderOutletId", res.data.result_set.outlet_id)
        cookie.save("orderOutletName", res.data.result_set.outlet_name)
        cookie.save("orderPostalCode", res.data.result_set.postal_code_information.zip_code)
        cookie.save("orderTAT", res.data.result_set.outlet_delivery_timing)
        cookie.save("orderDeliveryAddress", orderDeliveryAddress)
        cookie.save("orderHandled", orderHandled)

        cookie.save("outlet_tax_option", res.data.result_set.outlet_tax_option)
        cookie.save("outlet_tax_surcharge", res.data.result_set.outlet_tax_surcharge)
        cookie.save("outlet_tax_surcharge_inclusive", res.data.result_set.outlet_tax_surcharge_inclusive)

        var orderHandledText = res.data.result_set.outlet_name + " " + res.data.result_set.outlet_address_line1 + " " + res.data.result_set.outlet_address_line2 + ", Singapore " + postalcode
        cookie.save("orderHandledByText", orderHandledText)

        if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
          $("body").removeClass("addresslistPopup")
          $("#AddressList").modal("hide")
        } else {
          $("#DeliveryAddress").modal("hide")
        }
        $("#address-auto").modal("hide")
        $("#DeliverySucess").modal("show")

        if (parseInt(this.state.settings.client_outlet_zone) === 1) {
          cookie.save("orderZoneID", res.data.result_set.zone_id)
        }
      }

      /* Error response */
      if (res.data.status === "error") {
        this.setState({ load: "false" })

        /* check google api result */
        let google_api_result = false
        if (typeof res.data.not_fount_in_google !== "undefined" && res.data.not_fount_in_google === true) {
          this.setState({ enableAddressSearch: true })
          google_api_result = true
        } else {
          this.setState({ enableAddressSearch: false })
        }

        if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
          $("body").removeClass("addresslistPopup")
          $("#AddressList").modal("hide")
        } else {
          $("#DeliveryAddress").modal("hide")
        }
        // $("#DeliveryError").modal({ backdrop: 'static', keyboard: false });
        if (google_api_result === false) {
          $("#address-auto").modal("hide")
          if (res.data.shop_close === "Yes") {
            $("#ShopClosedError").modal({ backdrop: "static", keyboard: false })
          } else {
            $("#DeliveryError").modal({ backdrop: "static", keyboard: false })
          }
        } else {
          $("#address-auto").modal("show")
        }
      }
    })
  }

  showZonalPostalCode(outletId) {
    this.setState({ zoneOutletSeleted: "Yes" })
    $("#delivery_outlet").modal("toggle")
    if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
      $("#AddressList").modal("toggle")
    } else {
      $("#DeliveryAddress").modal("toggle")
    }

    this.setState({ zoneDeliveryOutlet: outletId })
  }
  __loadDeliveryOutlets() {
    if (this.state.deliveryOutlets) {
      let list = helper.getSortedList(this.state.deliveryOutlets, this.props.coords)

      if (!isEmpty(list)) {
        return list.map((loaddata, index) => (
          <div className="map-li" key={index} onClick={this.showZonalPostalCode.bind(this, loaddata.outlet_id)}>
            <p className="map-h outlet-name">{helper.stripslashes(loaddata.outlet_name)}</p>
            <p className="map-sub">
              {this.showNewAddressFrmd(loaddata.outlet_postal_code, loaddata.outlet_address_line1, "", "", "")} {loaddata.outlet_unit_number2}
            </p>
            <Distancebar minDateValue={loaddata.min_date} isOutletBased={loaddata.is_outlet_based} distance={loaddata.distance} coords={this.props.coords} tat={loaddata.outlet_delivery_tat ? loaddata.outlet_delivery_tat : loaddata.outlet_delivery_timing} />
          </div>
        ))
      } else {
        return <Outletclosedmessage messages={this.state.outletCloseddMessage} />
      }
    }
  }
  backToDeliveryBack() {
    $("#DeliveryAddress").modal("toggle")
    $("#delivery_outlet").modal("toggle")
  }

  showZonalBackButton() {
    if (parseInt(this.state.settings.client_outlet_zone) === 1 && parseInt(this.state.settings.client_zone_outlet_disable) === 0) {
      return (
        <div className="col-sm-6 popup-back-btn">
          <button type="button" onClick={this.backToDeliveryBack.bind(this)} className="btn btn-raised btn-info ">
            Go Back
          </button>
        </div>
      )
    }
  }
  /* reward menu */
  rewardMenu() {
    var usertype = typeof cookie.load("userAccountType") !== "undefined" ? cookie.load("userAccountType") : 0

    if (APPID !== apps.workspace && parseInt(usertype) !== 1 && helper.disableMenu(this.state.settings, "home_sub_rewards")) {
      return (
        <Link to="/rewards" className={this.state.openNav}>
          <img alt="" src="/img/icons/orders.png" /> {lang.navigation.viewrewards}
        </Link>
      )
    }
    return null
  }

  handleChange_advanced = (item, value) => {
    if (item == "date") {
      this.setState({ date_advanced: value, TimeSlotHtmlVal_advanced: "" }, function () {
        var selectDar = weekdayArr[value.getDay()]
        if (this.state.time_slot_type_advanced === "1") {
          this.timeDropDownFunction(selectDar)
        } else if (this.state.time_slot_type_advanced === "2") {
          this.loadTimeSlotDropDown(selectDar)
        }
      })
    } else {
      this.setState({ date_advanced: value })
    }
  }

  loadManualLoading() {
    if (this.state.manualLoading === true) {
      return (
        <div style={{ bottom: 0, left: 0, position: "fixed", right: 0, top: 0, margin: "auto" }}>
          {" "}
          <img src="/img/loader.gif" alt="Loading" style={{ bottom: 0, left: 0, position: "fixed", right: 0, top: 0, margin: "auto" }} />{" "}
        </div>
      )
    }
  }

  handleChange_maintain(event) {
    if (event.target.name === "client_password_maintain") {
      this.setState({ client_password_maintain: event.target.value })
    }
  }

  serviceMaintanance() {
    if (this.state.client_password_maintain !== "") {
      this.setState({ client_password_maintain_error: "" })

      var postObject = {
        app_id: APPID,
        client_password: this.state.client_password_maintain,
      }

      var qs = require("qs")

      axios.post(APIURLPRO + "settings/checking_maintenance", qs.stringify(postObject)).then((response) => {
        if (response.data.status === "ok") {
          $("#promo_popup-2").modal("hide")
          this.setState({ client_maintanace_popup_hide: true })
          cookie.save("site_maintenance_popup_open", "0", { path: "/" })
          cookie.save("client_site_maintenance_verify", "1", { path: "/" })
        } else {
          $("#promo_popup-2").modal("show")
          this.setState({ client_password_maintain_error: "2" })
          cookie.save("site_maintenance_popup_open", "1", { path: "/" })
          cookie.save("client_site_maintenance_verify", "0", { path: "/" })
        }
      })
    } else {
      cookie.save("site_maintenance_popup_open", "1", { path: "/" })
      cookie.save("client_site_maintenance_verify", "0", { path: "/" })
      this.setState({ client_password_maintain_error: "1" })
    }
  }

  render() {
    if (this.state.navStatus === "Loading") {
      return (
        <div className="container-fluid desktop-container position-rel" style={{ minHeight: this.state.windowHeightNormal }}>
          <div className="row">
            <Loading />
          </div>
        </div>
      )
    }

    let homeHeaderText
    if (APPID == "68009D56-9386-4ECE-92D8-6F81E11B8742") {
      homeHeaderText = "Nice to meat you. <br/>How will your meat get to you today?"
    } else if (APPID == "AA850867-4108-4EB8-872B-024903608E9F") {
      homeHeaderText = "Please select your dining preference"
    } else {
      homeHeaderText = "How will you be enjoying <br/>your food today?"
    }

    // const master_card_block = helper.getStaticBlock('master-card-popup');

    var site_maintenance_verify = cookie.load("client_site_maintenance_verify") !== "" && cookie.load("client_site_maintenance_verify") !== "undefined" && cookie.load("client_site_maintenance_verify") !== undefined ? cookie.load("client_site_maintenance_verify") : ""

    return (
      <div>
        {" "}
        <DocumentMeta {...meta} />
        <Alert />
        <LocationSearchInput loading={this.state.status} open={this.state.enableAddressSearch} parentCallback={this.selectOutlet} postalcode={this.state.addressField} />
        <div className="container-fluid desktop-container position-rel" style={{ minHeight: this.state.windowHeightNormal }}>
          <div id="parentDisable"></div>
          <div className="row">
            <div className="col-xs-12 head-dropdwn nopadding" style={{ display: this.hidedropDown() }}>
              <div className="dropdown center-block">
                <button type="button" className="btn home-modal-button" data-toggle="modal" data-target="#onload-screen" data-keyboard="false" data-backdrop="static">
                  <p className="text-center dine-in-text">
                    <img alt="" src={this.state.defaultAvilablityImage} className="icon-img-small" /> <span style={{ fontSize: "16px" }}>{this.state.defaultAvilablity}</span> <img alt="" src="/img/icons/arrow-down.png" className="icon-img-small" />
                  </p>
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 top-nav padding-0 head-main">
              <div className="col-xs-4 head-child">
                <span className="cursor-pointer side-l1" onClick={this.openNavmenu.bind(this)}>
                  <img alt="" src="/img/menu.png" className="icon-img-small5 menuicon" />
                </span>
              </div>

              <div className="col-xs-4 head-child">
                <img alt="" src={this.loadLogo()} className="logo-main center-block" />
              </div>

              <div className="col-xs-4 nopadding head-child">
                <p className="b-txt text-right pd-tr-12-20">
                  {config.enablescan === "Yes" && (
                    <a href="https://scan.ninjapro.co" className="scan-icon-a">
                      <img alt="" src="/img/icons/Scan-to-add-btn.png" className="icon-img-small-scan head_serch" />
                    </a>
                  )}

                  <Link className="rel search-icon" data-toggle="collapse" to={"#SearchBAR"} aria-expanded="false" aria-controls="collapseExample">
                    <img alt="" src="/img/icons/search-new.png" className="icon-img-small4 head_serch" />
                  </Link>

                  {"  "}
                  <Link to={"/cart"} className={helper.cartClass()}>
                    {helper.cartCount()}
                    <img alt="" src={getResult("cartImage")} className="icon-img-small-1 carticon" />{" "}
                  </Link>
                  {config.enablescan === "Yes" && (
                    <div className="scan-tooltip">
                      Click here to Scan QR Code &amp; Add to Cart{" "}
                      <a onClick={this.closeTooltip.bind(this)}>
                        <span>
                          <i className="material-icons">close</i>
                        </span>
                      </a>
                    </div>
                  )}
                </p>
              </div>
              <div className="collapse" id="SearchBAR">
                <div className="">
                  <div className="form-group SearchBAR">
                    <div className="input-group">
                      <Select.Async
                        name="form-field-name"
                        placeholder={APPID == "68009D56-9386-4ECE-92D8-6F81E11B8742" ? "what are you in the mood for today" : "Go ahead search for a dish..."}
                        noResultsText="No Products Found"
                        autoload={false}
                        loadOptions={this.getOptions.bind(this)}
                        onChange={this.ViewProducts.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <OutsideClickHandler onOutsideClick={this.closeNav.bind(this)}>
            <div id="mySidenav" className="sidenav">
              <br /> <br />
              <div className="side-nav-header">
                <div className={this.state.openNav}>
                  <img className="logo " alt="" src={this.loadLogo()} />
                </div>
                <a style={{ cursor: "pointer" }} className="closebtn" onClick={this.closeNav.bind(this)}>
                  <img alt="" src="/img/icons/menu2.png" id="close-btn-nav" />
                </a>
              </div>
              <Link to="/" onClick={this.closeNav.bind(this)} className={this.state.openNav}>
                <img alt="" src="/img/icons/home.png" /> {lang.navigation.home}
              </Link>
              {helper.disableMenu(this.state.settings, "home_sub_my_account") && (
                <Link to={typeof cookie.load("UserId") === "undefined" || cookie.load("UserId") === null ? this.getSignInlink() : "my-account"} className={this.state.openNav}>
                  <img alt="" src="/img/icons/user.png" /> {lang.navigation.myaccount}
                </Link>
              )}
              {helper.disableMenu(this.state.settings, "home_sub_orders") && (
                <Link to="/orders" className={this.state.openNav}>
                  <img alt="" src="/img/icons/orders.png" /> {lang.navigation.vieworders}
                </Link>
              )}
              {/*  <Link to="/myrewards" data-toggle="collapse" data-target="#toggleDemo" data-parent="#sidenav01" className={this.state.rewadNav}>
					<img alt=""  src="/img/icons/rewards.png"/> {" "}{lang.navigation.viewrewards}</Link> */}
              {this.rewardMenu()}
              {(helper.disableMenu(this.state.settings, "home_sub_contact_us") || helper.disableMenu(this.state.settings, "home_sub_about_us") || helper.disableMenu(this.state.settings, "home_sub_terms") || helper.disableMenu(this.state.settings, "home_sub_privacy")) && (
                <div className="grey-seperater"></div>
              )}
              {helper.disableMenu(this.state.settings, "home_sub_about_us") && (
                <Link to="pages/about-us" className={this.state.openNav}>
                  <img alt="" src="/img/icons/info.png" /> &nbsp;{lang.navigation.about}
                </Link>
              )}
              {helper.disableMenu(this.state.settings, "home_sub_terms") && (
                <Link to="pages/terms-conditions" className={this.state.openNav}>
                  <img alt="" src="/img/icons/info.png" /> &nbsp;Terms & Conditions
                </Link>
              )}
              {helper.disableMenu(this.state.settings, "home_sub_privacy") && (
                <Link to="pages/privacy-policy" className={this.state.openNav}>
                  <img alt="" src="/img/icons/lock.png" /> &nbsp;{lang.navigation.privacy}
                </Link>
              )}
              {APPID === "614F3FD1-8659-4FD0-BF07-0560BC039AE6" && helper.disableMenu(this.state.settings, "home_sub_contact_us") && (
                <Link to="module/contact-us" className={this.state.openNav}>
                  <img alt="" src="/img/icons/phone.png" /> &nbsp;Feedback
                </Link>
              )}
              {APPID === "614F3FD1-8659-4FD0-BF07-0560BC039AE6" && helper.disableMenu(this.state.settings, "home_sub_contact_us") && (
                <a href="https://api.whatsapp.com/send/?phone=%2B6593545155&text&app_absent=0" className={this.state.openNav} target="_blank">
                  <img alt="" src="/img/icons/phone.png" /> &nbsp;{lang.navigation.contact}
                </a>
              )}
              {APPID !== "614F3FD1-8659-4FD0-BF07-0560BC039AE6" && helper.disableMenu(this.state.settings, "home_sub_contact_us") && (
                <Link to="module/contact-us" className={this.state.openNav}>
                  <img alt="" src="/img/icons/phone.png" /> &nbsp;{lang.navigation.contact}
                </Link>
              )}
              {this.loginPart()}
            </div>
          </OutsideClickHandler>

          <div className="row">
            {/*	<img alt=""  src={this.homeBanner()} className="img-responsive banner-img"/> bx-slide-single */}
            <div className={this.state.noPagerClass}>
              <ul className={this.state.bxClass}>{this.__showBanners()}</ul>
            </div>

            <div className="account-section">
              {this.loginPartHeader()}
              {APPID !== "B401FC82-8E39-4076-838A-55192F765004" && APPID !== "6C0280A0-B655-4E0F-A43F-041C189683BA" && APPID !== "B93EC9A9-1A67-4EBA-ADB7-BC7C14FA88A4" && this.signinHeader()}
            </div>
            <div className="flex-container desktop_header">
              {helper.disableMenu(this.state.settings, "home_main_orders") && (
                <Link to="/orders" className="order_items">
                  <div className="order_promo">
                    <img alt="" src="/img/icons/shopping-bag.svg" />
                    {this.state.orderCount > 0 && <span className="badge">{this.state.orderCount}</span>}
                  </div>
                  <span>Orders</span>
                </Link>
              )}
              {APPID !== "8F8FE4E6-88C9-4071-9215-312E0F50016A" &&
                APPID !== "B401FC82-8E39-4076-838A-55192F765004" &&
                APPID !== "6C0280A0-B655-4E0F-A43F-041C189683BA" &&
                APPID !== "B93EC9A9-1A67-4EBA-ADB7-BC7C14FA88A4" &&
                APPID !== "33843AC9-EB47-42EE-8AAA-14CEA187D85A" &&
                APPID !== "4EDE9A25-89AA-4200-848D-AC4A5A54B7BC" &&
                helper.disableMenu(this.state.settings, "home_main_promos") && (
                  <Link to="/promotion" className="order_items">
                    <div className="order_promo">
                      <img alt="" src="/img/icons/megaphone.svg" />
                      {this.state.promotionCount > 0 && <span className="badge">{this.state.promotionCount}</span>}
                    </div>
                    <span>Promos</span>
                  </Link>
                )}
              {APPID !== "AA850867-4108-4EB8-872B-024903608E9F" &&
                APPID !== "8F8FE4E6-88C9-4071-9215-312E0F50016A" &&
                APPID !== "B401FC82-8E39-4076-838A-55192F765004" &&
                APPID !== "6C0280A0-B655-4E0F-A43F-041C189683BA" &&
                APPID !== "B93EC9A9-1A67-4EBA-ADB7-BC7C14FA88A4" &&
                APPID !== "33843AC9-EB47-42EE-8AAA-14CEA187D85A" &&
                APPID !== "4EDE9A25-89AA-4200-848D-AC4A5A54B7BC" &&
                helper.disableMenu(this.state.settings, "home_main_rewards") && (
                  <Link to="/rewards" className="order_items">
                    <div className="header_common_icon">
                      <img alt="" src="/img/icons/confetti.svg" />
                    </div>
                    <span>Rewards</span>
                  </Link>
                )}
              {APPID !== "AA850867-4108-4EB8-872B-024903608E9F" &&
                APPID !== "8F8FE4E6-88C9-4071-9215-312E0F50016A" &&
                APPID !== "B401FC82-8E39-4076-838A-55192F765004" &&
                APPID !== "33843AC9-EB47-42EE-8AAA-14CEA187D85A" &&
                APPID !== "6C0280A0-B655-4E0F-A43F-041C189683BA" &&
                APPID !== "4EDE9A25-89AA-4200-848D-AC4A5A54B7BC" &&
                helper.disableMenu(this.state.settings, "home_main_vouchers") && (
                  <Link to="/vouchers" className="order_items">
                    <div className="header_common_icon">
                      <img alt="" src="/img/icons/gift-voucher.svg" />
                    </div>
                    <span>E-Vouchers</span>
                  </Link>
                )}
              {APPID !== "AFA6F84D-423C-44AB-A47A-A1AF8813B387" && helper.disableMenu(this.state.settings, "home_main_call_us") && (
                <a href={this.loadNumber()} style={{ cursor: "pointer" }} className="order_items">
                  <div className="header_common_icon">
                    <img alt="" src="/img/icons/call.svg" />
                  </div>
                  <span>Call Us</span>
                </a>
              )}
              {APPID !== "B401FC82-8E39-4076-838A-55192F765004" && APPID !== "6C0280A0-B655-4E0F-A43F-041C189683BA" && APPID !== "B93EC9A9-1A67-4EBA-ADB7-BC7C14FA88A4" && helper.disableMenu(this.state.settings, "home_main_directions") && (
                <Link onClick={this.targetMap.bind(this)} style={{ cursor: "pointer" }} className="order_items">
                  <div className="header_common_icon">
                    <img alt="" src="/img/icons/compass.svg" />
                  </div>
                  <span>Direction</span>
                </Link>
              )}
            </div>
            <div className="col-xs-12 heading-div our_special_head">
              {/*<div className="col-xs-4 pd-l-20 our_menu_com our_special_titile"><h1 className="heading pull-left"   > Our Menu</h1></div>*/}
              <div className="col-xs-8 pd-r-20 our_menu_com">{this.foodTypes(this)}</div>
            </div>

            {/* <div className="col-xs-12 nopadding home-bottom-img"> <a href=""><img alt=""  src="/img/pasta.jpg" className="img-responsive" /></a>
					<p className="text-left font-bold text-uppercase pd-l-20">pasta</p>
				</div>
				<div className="col-xs-12 nopadding home-bottom-img"> <a href=""><img alt=""  src="/img/pizza.jpg" className="img-responsive" /></a>
					<p className="text-left font-bold text-uppercase pd-l-20">pizza</p>
				</div> */}
            {this.__ProductListing()}
          </div>
        </div>
        <div className="modal fade reservationmdlCls" id="ReservationMdl">
          <div className="modal-dialog PopupWidth">
            <div className="modal-content">
              <div className="modal-body">
                <div className="reservation-wrap-ija">
                  <div className="reserve-head">
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={this.closeResvPoup.bind(this)}>
                      <img src="/img/icons/close_popup.png" className="img-responsive" alt="" />
                    </button>
                    <a className="back-aw-t goback_arwcls" id="goback_arwId" style={{ display: "none" }} onClick={this.reservationFirstli.bind(this)}>
                      <img src="img/back_aw.png" />
                    </a>
                    <h4>Reservation</h4>
                    <p>
                      {this.state.selectResOutletOptionTxt} - {this.state.selectResOutletAdrr}
                    </p>
                  </div>
                  <div className="reserve-items-box">
                    <form id="wizard-1" noValidate="novalidate">
                      <div id="bootstrap-wizard-1" className="col-sm-12">
                        <div className="form-bootstrapWizard">
                          <ul className="bootstrapWizard form-wizard">
                            <li className="active" data-target="#step1" id="ReservationLiId">
                              <a>
                                {" "}
                                <span className="step">1</span> <span className="title">Reservation</span>{" "}
                              </a>
                            </li>
                            <li id="InformationLi">
                              <a>
                                {" "}
                                <span className="step">2</span> <span className="title">Information</span>{" "}
                              </a>
                            </li>
                            <li id="ConfirmationLi">
                              <a>
                                {" "}
                                <span className="step">3</span> <span className="title">Confirmation</span>{" "}
                              </a>
                            </li>
                          </ul>
                          <div className="clearfix" />
                        </div>
                        <div className="tab-content">
                          <div className="tab-pane active" id="tab1">
                            <div className="reserve-content cmn-grey-box">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <div className=" sct-aw">
                                      <select className={"form-control form-select"} value={this.state.selectResOutletAdrrAndId} onChange={this.changeResOutlet} id="resOutlets">
                                        {this.state.ResOutletDropDownHtml}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6 col-sm-6">
                                  <div className="form-group">
                                    <div className="icon-select sct-aw">
                                      <select className={"form-control form-select"} id="noofadults" onChange={this.noofAdultsfun.bind(this)} value={this.state.noofAdults}>
                                        {this.state.noofAdultHtml}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                  <div className="form-group">
                                    <div className="icon-select sct-aw">
                                      <select className={"form-control form-select"} id="noofchildren" onChange={this.noofChildrenfun.bind(this)} value={this.state.noofChildren}>
                                        {this.state.noofChildrenHtml}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {this.loadManualLoading()}
                              {cookie.load("advanced_time_slot_reservation_enable") === "1" ? (
                                <div>
                                  <div className="row">
                                    <div className="col-md-6 col-sm-6">
                                      <div className="form-group">
                                        <div className=" icon-date-cs sct-aw deliverydate">
                                          <ThemeProvider theme={theme}>
                                            <DatePicker
                                              placeholder="Date"
                                              inputFormat={(value) => `${value.getDate()}/${value.getMonth() + 1}/${value.getFullYear()}`}
                                              value={this.state.date_advanced}
                                              onChange={this.handleChange_advanced.bind(this, "date")}
                                              minDate={this.state.min_datetime_advanced}
                                              maxDate={this.state.max_datetime_advanced}
                                              sundayFirstDayOfWeek
                                              disabledDates={this.state.disableDate_advanced}
                                              id="resDatepkid"
                                              className="delivery_datepiker"
                                            />
                                          </ThemeProvider>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6">
                                      <div className="form-group label-floating is-empty card-no custom-time-label min-hgt-hour-t">
                                        <div className="input-group">
                                          <label className="control-label">{this.state.time_slot_type_advanced === "1" ? "Choose Time" : "Choose Time Slot"}</label>
                                          {this.state.time_slot_type_advanced === "1" && (
                                            <div>
                                              <select value={this.state.selectTime_advanced} onChange={this.changeTimer} id="deliveryTime">
                                                {this.state.timeHtml_advanced}
                                              </select>
                                              /
                                              <select id="deliveryMins" value={this.state.selectMins_advanced} onChange={this.changeMins}>
                                                {this.state.minsHtml}
                                              </select>
                                            </div>
                                          )}
                                          {this.state.time_slot_type_advanced === "2" && (
                                            <div>
                                              <select className={" customdd timelsotdd"} id="timeSlot">
                                                {this.state.TimeSlotHtmlVal_advanced}
                                              </select>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {this.showTimeslotExceed()}
                                </div>
                              ) : (
                                <div className="row">
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <div className=" icon-date-cs sct-aw">
                                        <ThemeProvider theme={theme}>
                                          <DatePicker
                                            placeholder="Date"
                                            inputFormat={(value) => `${value.getDate()}/${value.getMonth() + 1}/${value.getFullYear()}`}
                                            minDate={min_datetime}
                                            onChange={this.handleChangeDatefun.bind(this, "date")}
                                            value={this.state.resDate}
                                            disabledDates={this.state.ReservationtimeData}
                                            id="resDatepkid"
                                            className="delivery_datepiker"
                                          />
                                        </ThemeProvider>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <div className="clock-icon sct-aw">
                                        <select className={"form-control form-select"} value={this.state.resTimeval} onChange={this.changeResTimeset} id="resTime">
                                          {this.state.ReservationtimeHtml}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {this.state.resDate !== "" && typeof this.state.resDate != "undefined" && APPID === "DDAA254C-BD1E-49F0-AD1E-164A140421D3" && this.state.resDate.getDate() === 14 && this.state.resDate.getMonth() === 1 && (
                                <div className="timeslot_exc">Celebrate Valentine’s Day at Zazz Pizza! Special menu available for Valentine’s Night only</div>
                              )}

                              {helper.disableSpecialInstructions(APPID) && (
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <textarea className="form-control" id="resRemark" placeholder="Special Instruction" value={this.state.resRemarkDes} onChange={this.changeRemarkDes.bind(this)} />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="text-center">
                              <a className="btn-send-t" onClick={this.reservationSubmtn.bind(this)}>
                                Send
                              </a>
                            </div>
                          </div>
                          <div className="tab-pane" id="tab2">
                            <div className="reserve-content cmn-grey-box">
                              <p>You are making a reservation for</p>
                              <p>
                                <span>{this.state.ResPeopleTxt}</span> at <br />
                                <span>
                                  {this.state.settings.company_name} - {this.state.selectResOutletOptionTxt}
                                </span>{" "}
                                on <br />
                                <span>
                                  {this.state.ResselectedDateTxt}, {this.state.ResselectedDayTxt}, {this.state.resTimeText}
                                </span>
                              </p>
                              <p style={{ wordBreak: "break-all" }}>{this.state.resRemarkDes}</p>
                              <p className="disclaimerTxt">Special requests are not guaranteed and are subject to availability and restaurant discretion. Notes stating an alternative timing or party-size will not be accommodated.</p>
                            </div>
                            <div id="resvloadIcon" style={{ display: "none", bottom: 0, left: 0, position: "fixed", right: 0, top: 0, margin: "auto", zIndex: 99, background: "rgba(255, 255, 255, 0.8)" }}>
                              {" "}
                              <img style={{ bottom: 0, left: 0, position: "absolute", right: 0, top: 0, margin: "auto" }} src="/img/loader.gif" alt="Loading" />{" "}
                            </div>

                            {this.loadManualLoading()}

                            <div className="text-center">
                              <a className="btn-send-t" onClick={this.orderComfirmSubmtn.bind(this)}>
                                Confirm
                              </a>
                            </div>
                          </div>
                          <div className="tab-pane" id="tab3">
                            <div className="confirmation-bx">
                              <img src="img/success.png" />
                              <h4>Thank You</h4>
                              <h3>Your Reservation has been sent</h3>
                              <p>
                                Our staff member will contact you for
                                <br /> booking confirmation
                              </p>
                            </div>
                            <div className="cnfm-id-section">
                              <h5>
                                Confirmation ID : <span>{this.state.resOrderId}</span>
                              </h5>
                              <p>
                                <span>{this.state.ResPeopleTxt}</span> at <br />
                                <span>
                                  {this.state.settings.company_name} - {this.state.selectResOutletOptionTxt}
                                </span>{" "}
                                on <br />
                                <span>
                                  {this.state.ResselectedDateTxt}, {this.state.ResselectedDayTxt}, {this.state.resTimeText}
                                </span>
                              </p>
                            </div>
                            <div className="text-center">
                              <a className="btn-send-t bg-bck-color" onClick={this.gotoMyAcct.bind(this)}>
                                Go To My Account
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
        <div className="modal fade swithErrorCls" id="SwitchingError">
          <div className="modal-dialog PopupWidth">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
                </button>
                <h4 className="modal-title">
                  <img src="/img/icons/SwitchingError.png" className="img-responsive" alt="" />
                  Wait a second !
                </h4>
              </div>
              <div className="modal-body">
                <p>
                  By switching you are about to clear your cart. <br />
                  Do you wish to proceed ?
                </p>
                <br />
                <div className="col-sm-6">
                  <button type="button" onClick={this.closeSwitcher.bind(this)} className="btn btn-raised btn-info fullwidth">
                    Cancel
                  </button>
                </div>
                <div className="col-sm-6">
                  <button type="button" className="col-lg-6 btn btn-raised btn-info fullwidth " onClick={this.switchAvailability.bind(this)}>
                    Yes Proceed
                  </button>
                </div>
                <br />
                <br />
                <br />
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
        <div className="modal fade homepopup" id="onload-screen">
          <div className="modal-dialog index-modal">
            <img className="bg-img" src={this.loadPopupBackgroundImage()} />
            <div className="modal-sec">
              <div className="modal-content">
                <div className="modal-logo">
                  {/*<h3 className="modal-title ">{htmlToReactParser.parse(homeHeaderText)}</h3>*/}
                  <img alt="Ninja OS System" src={this.loadPopupLogoImage()} className="img-responsive" />
                </div>

                <div className="modal-body">
                  <h3 className="modal-title ">{this.loadPopupWelcomeText()}</h3>
                  {this.showAvilablityItems()}

                  {helper.disableSpecialInstructions(APPID) && (
                    <a className="modal-skip" style={{ cursor: "pointer" }} onClick={this.closeAndBrowse.bind(this)}>
                      Skip & Browse
                    </a>
                  )}
                </div>
                <div className="modal-footer">
                  <div className="powered-by">POWERED BY</div>
                  <div className="footer-logo">
                    <div>
                      {" "}
                      <img src="/img/popup-ninjaos-card.png" />{" "}
                    </div>
                    {htmlToReactParser.parse(this.state.masterCardLogo)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* poupup  - start */}
        <div className="modal fade normal_popup" id="normal_popup">
          <div className="modal-dialog">
            <div className="modal-content custom_popup_cms_promo">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={this.closeNornalPopup.bind()}>
                <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
              </button>
              {htmlToReactParser.parse(this.state.pageDesc)}
              <div className="promo_check">
                <label>
                  <input type="checkbox" value="Yes" name="option" value="" onClick={this.toggleCheckbox.bind(this)} />
                  Don't show this message again
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* popup - end */}
        {/*promo  poupup  - start */}
        <div className="modal fade normal_popup" id="promo_popup">
          <div className="modal-dialog">
            <div id="loadIcon" style={{ display: "none", bottom: 0, left: 0, position: "fixed", right: 0, top: 0, margin: "auto", zIndex: 99, background: "rgba(255, 255, 255, 0.8)" }}>
              {" "}
              <img style={{ bottom: 0, left: 0, position: "absolute", right: 0, top: 0, margin: "auto" }} src="/img/loader.gif" alt="Loading" />{" "}
            </div>

            <div className="modal-content custom_popup_cms_promo">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={this.DisablePromoPopup.bind(this)}>
                <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
              </button>

              <div className="promotion_t">
                <h4>PROMOTIONS</h4>
                <p>Enter your Email address to receive your promocode</p>
                <div className="form-group">
                  <input type="text" id="promoEmail" className="form-control" placeholder="Enter your Email address to receive your promocod" />
                  <button type="button" className="btn btn-primary" onClick={this.getPromocode.bind(this)}>
                    CHECK PROMOTION
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*promo popup - end */}
        {/* For takeaway popup */}
        <div className="modal common-distance" id="Pickup-OutletSelection">
          <div className="modal-dialog locWidth">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                </button>
                <h4 className="modal-title">Select Your Pickup Location</h4>
              </div>

              <div className="modal-body">
                <div className="map-loc">
                  <div className="map-box scrollbar" id="style-3">
                    {this.__loadOutlets()}
                  </div>

                  <br />
                  <br />
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
        {/* For takeaway popup - end */}
        {/* For Pickup Success */}
        <div className="modal" id="Pickup-Sucess">
          <div className="modal-dialog PopupWidth">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  <img src="/img/icons/DeliverySucess.png" className="img-responsive" alt="" />
                  Awesome, you can pickup !
                </h4>
              </div>
              <div className="modal-body text-center">
                {this.showDeliveryTiming()}
                <br />
                <button type="button" onClick={this.pickupSuccess.bind(this)} className="btn btn-raised btn-info ">
                  Continue
                </button>
                <br />
                <br />
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
        <div className="modal" id="pickup-outlet-sucess">
          <div className="modal-dialog PopupWidth">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
                </button>
                <h4 className="modal-title">
                  <img src="/img/icons/DeliverySucess.png" className="img-responsive" alt="" />
                  Awesome, you can pickup !
                </h4>
                <p>{cookie.load("orderHandled")}</p>
              </div>
              <div className="modal-body text-center">
                {this.showDeliveryTiming()}
                <br />
                <button type="button" onClick={this.pickupSuccess.bind(this)} className="btn btn-raised btn-info ">
                  Continue
                </button>
                <br />
                <br />
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
        {/* For Pickup Success - end */}
        {/* For delivery popup */}
        <div className="modal" id="DeliveryAddress">
          <div className="modal-dialog PopupWidth">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                </button>
                <br />
                <br />
                <h4 className="modal-title">Your Delivery Address</h4>
              </div>
              <div className="modal-body">
                <div className="form-group label-floating is-empty card-no">
                  <div className="input-group">
                    <label className="control-label">Enter Your Postal Code</label>
                    <input value={this.state.addressField} id="postalcode1" maxLength="6" className="form-control" onKeyPress={(e) => this.validatezip(e)} onChange={this.handleChangeValue.bind(this)} />
                  </div>
                </div>
                <br />
                <br />

                {this.showZonalBackButton()}
                <div className={parseInt(this.state.settings.client_outlet_zone) === 1 && parseInt(this.state.settings.client_zone_outlet_disable) === 0 ? "col-sm-6 popup-back-btn" : "col-sm-12"}>
                  <button type="button" onClick={this.selectOutlet.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                </div>
                {/*<button type="button" onClick={this.selectOutlet.bind(this)}  className="btn btn-raised btn-info ">Continue</button>
                 */}
                <br />
                <br />
                <br />
                <br />
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
        {/* delivery popup - end */}
        {/* For delivery outlets list  */}
        <div className="modal common-distance" id="delivery_outlet">
          <div className="modal-dialog locWidth">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                </button>
                <h4 className="modal-title">
                  Please Select Which Outlet is Nearest To Your
                  <br />
                  Delivery Location
                </h4>
              </div>
              <Overlay load={this.state.load} />
              <div className="modal-body">
                <div className="map-loc">
                  {/* <Map markers={this.state.markers} /> */}

                  <div className="map-box scrollbar" id="style-3">
                    {this.__loadDeliveryOutlets()}
                  </div>

                  <br />
                  <br />
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
        {/* delivery popup after login user */}
        <div className="modal" id="AddressList">
          <div className="modal-dialog PopupWidth">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" onClick={this.closeaddlist.bind(this)} data-dismiss="modal" aria-hidden="true">
                  <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                </button>
                <h4 className="modal-title">
                  <img src="/img/icons/DeliverySucess.png" className="img-responsive" alt="delivery success" />
                  Your Delivery Address
                </h4>
              </div>
              <div className="modal-body" style={{ textAlign: "left" }}>
                {this.loadAddressList()}

                <p>
                  <input value={this.state.addressField} onChange={this.handleChangeValue.bind(this)} id="postalcode" maxLength="6" placeholder="Enter Your Postal Code" className="add_list" onKeyPress={(e) => this.validatezip(e)} />
                </p>
                <br />

                <button type="button" onClick={this.selectOutlet.bind(this)} className="btn btn-raised btn-info ">
                  Continue
                </button>
                <br />
                <br />
                <br />
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
        {/* delivery popup after login popup - end */}
        {/* For Delivery Success */}
        <div className="modal" id="DeliverySucess">
          <div className="modal-dialog PopupWidth">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  <img src="/img/icons/DeliverySucess.png" className="img-responsive" alt="thumb-up" />
                  Awesome, we can deliver!
                </h4>
              </div>
              <div className="modal-body text-center">
                <p>
                  <b>Your Delivery Address: </b>
                  <br />
                  {this.state.orderDeliveryAddress}
                </p>
                {this.showdeliveryTiming}
                <br />
                <button type="button" onClick={this.deliverySuccess.bind(this)} className="btn btn-raised btn-info ">
                  Continue
                </button>
                <br />
                <br />
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
        {/* For Delivery Success - end */}
        {/* For Delivery error */}
        <div className="modal" id="DeliveryError">
          <div className="modal-dialog PopupWidth">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                </button>
                <h4 className="modal-title">
                  <img src="/img/icons/Cant_Deliver.gif" className="img-responsive" alt="" />
                  Sorry !
                </h4>
              </div>
              <div className="modal-body">
                <p>
                  Sorry we cant deliver to your address <br />
                  Do you want to change your address or check again later ?
                </p>
                <br />
                <div className="col-sm-3">&nbsp;</div>
                <div className="col-sm-6">
                  <button type="button" onClick={this.changeDelivery.bind(this)} className="col-lg-6 btn btn-raised btn-info fullwidth ">
                    Change Address
                  </button>
                </div>
                <div className="col-sm-3">&nbsp;</div>
                <br />
                <br />
                <br />
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
        {/* For Delivery error - end */}
        {/* For shop closed error */}
        <div className="modal" id="ShopClosedError">
          <div className="modal-dialog PopupWidth">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
                </button>
                <h4 className="modal-title">
                  <img src="/img/icons/Cant_Deliver.gif" className="img-responsive" alt="" />
                  Sorry !
                </h4>
              </div>
              <div className="modal-body">
                <p>
                  <b>Outlet Closed Temporarily. Please Try again later</b>{" "}
                </p>
                <br />
                <div className="col-sm-12">
                  <button type="button" onClick={this.closeShopClosedError.bind(this)} className="col-lg-6 btn btn-raised btn-info fullwidth ">
                    Check Later
                  </button>
                </div>

                <br />
                <br />
                <br />
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
        {this.state.client_maintenance_mode === "1" && cookie.load("site_maintenance_popup_open") === "1" && (site_maintenance_verify === "" || site_maintenance_verify === "0" || site_maintenance_verify === "undefined") && (
          <div className="modal fade promo_popup-2" id="promo_popup-2">
            <div className="modal-dialog">
              <div className="modal-content custom_popup_cms_promo">
                <div className="maintance-main-box">
                  <div className={this.state.client_maintenance_mode === "1" ? "maintance-popup" : "maintance-popup mfp-hide"}>
                    <div className="maintance-popup-container">
                      <div className="maintance-popup-inner-cnt">
                        <div className="mtnce-pup-imager">
                          <img className="mtnce-pup-img" src="/img/maintance.png" />
                        </div>
                        <div className="maintance-popup-contant">
                          <h3>site is under maintainance</h3>
                          <p> Please enter admin password to browse through website</p>

                          <div className="maintance-popup-input">
                            <input type="password" placeholder="Enter Admin Password" name="client_password_maintain" onChange={this.handleChange_maintain.bind(this)} value={this.state.client_password_maintain} />
                            <a className="button disbl_href_action" title="Continue" onClick={this.serviceMaintanance.bind(this)}>
                              Continue
                            </a>
                          </div>
                          {this.state.client_password_maintain_error === "1" && <span className="spn-error">This field is required. </span>}
                          {this.state.client_password_maintain_error === "2" && <span className="spn-error">Password is incorrect. </span>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          // <div className="maintance-main-box">
          // 		<div className="maintance-popup-main">
          // 			<div className={(this.state.client_maintenance_mode === "1") ? "maintance-popup" : "maintance-popup mfp-hide"}>
          // 				<div className="maintance-popup-container">
          // 					<div className="maintance-popup-inner-cnt">
          // 						<div className="mtnce-pup-imager">
          // 						<img className="mtnce-pup-img" src="/img/maintance.png" />
          // 						</div>
          // 						<div className="maintance-popup-contant">
          // 						<h3>site is under maintainance</h3>
          // 						<p> Please enter admin password to browse through website</p>

          // 						<div className="maintance-popup-input">
          // 							<input type="password" placeholder="Enter Admin Password" name="client_password_maintain" onChange={this.handleChange_maintain.bind(this)}value={this.state.client_password_maintain}/>
          // 							<a

          // 							className="button disbl_href_action"
          // 							title="Continue"
          // 							onClick={this.serviceMaintanance.bind(this)}
          // 							>
          // 							Continue
          // 							</a>
          // 						</div>
          // 						{this.state.client_password_maintain_error === "1" && ( <span className="error">This field is required. </span> )}
          //                         {this.state.client_password_maintain_error === "2" && ( <span className="error">Password is incorrect. </span> )}
          // 						</div>
          // 					</div>
          // 				</div>
          // 			</div>
          // 		</div>
          // 	</div>
        )}
        {this.checkPromoPopup()}
        {this.checkNormalPopup()}
        {this.triggerReservationPopup()}
        {/* this.openOrderTupeChangeFun() - comment not needed for initial load after new home page popup 27/02/2020 */}
        {this.clickOrderTypePopup()}
        {this.checkMaintenancePopup()}
      </div>
    )
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Home)

/* eslint-disable */
import React from "react"
import axios from "axios"
import { loadAnalytics, fbPixelCustomEvent } from "./withTracker"
import { Link, hashHistory } from "react-router"
import Loading from "./loading"

//import getResult from './Api';
import config from "./config"
import lang from "./lang"
//const APIURL = config.path.apiUrl;
const APIURLPRO = config.path.apiUrlPro
const apiUrlNotify = config.path.apiUrlNotify
const APPID = config.ids.appId
import DocumentMeta from "react-document-meta"
//var strp = require('strp');
import cookie from "react-cookie"
import Moment from "react-moment"
var currency = config.ids.currency
var strp = require("strp")
var helper = require("./Helper.js")
var HtmlToReactParser = require("html-to-react").Parser
var htmlToReactParser = new HtmlToReactParser()
const apps = require("./apps")

/* Load meta tags */
const meta = {
  title: lang.ordersuccess_meta_title + lang.site_title_with_separator,
  description: lang.ordersuccess_meta_title,
  meta: {
    name: {
      keywords: lang.ordersuccess_meta_title,
    },
  },
}

class Ordersuccess extends React.Component {
  constructor(props) {
    super(props)
    this.state = { cartData: [], cartItems: [], status: "Loading", availabilityId: "", windowHeightNormal: 0, windowHeight: 0, placed_order_id: "", order_total_amount: "" }

    /*add class */
    helper.addBodyClass()
  }

  componentDidMount() {
    loadAnalytics()

    var windowHeight = window.innerHeight
    this.setState({ windowHeight: windowHeight - 45 + "px" })
    this.setState({ windowHeightNormal: windowHeight + "px" })

    var orderId = this.props.params.orderId
    axios.get(APIURLPRO + "reports/order_history?app_id=" + APPID + "&local_order_no=" + orderId + "&customer_id=" + cookie.load("UserId")).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ status: res.data.status, order_total_amount: res.data.result_set[0].order_total_amount, placed_order_id: orderId })

        axios.get(APIURLPRO + "orders_duplicate/remove?app_id=" + APPID + "&order_total_amount=" + res.data.result_set[0].order_total_amount + "&local_order_no=" + orderId + "&customer_id=" + cookie.load("UserId") + "&order_date=" + res.data.result_set[0].order_date).then((res) => {})

        /*	this.setState({availabilityId: res.data.result_set[0]['order_availability_id']});
			 this.setState({subTotal: res.data.result_set[0]['order_sub_total']});
		 this.setState({grandTotal: res.data.result_set[0]['order_total_amount']}); */
        this.setState({ cartItems: res.data.result_set[0]["items"] })
        this.setState({ cartData: res.data.result_set[0] }, function () {
          this.sendNotification(res.data.result_set[0].order_local_no)
          fbPixelCustomEvent("Purchase", { currency: "SGD", value: typeof res.data.result_set[0].order_total_amount !== "undefined" ? res.data.result_set[0].order_total_amount : 0 })
        })
      } else {
        hashHistory.push("/")
      }
    })
  }

  sendNotification() {
    axios.get(apiUrlNotify + "OrderActivityJob/order_notify").then((res) => {})
  }

  /* Address Format */
  addressFormat(unitOne, unitTwo, addressOne, addressTwo, postCode, towerNo) {
    //alert(towerNo);
    var towerNumber = towerNo !== null && towerNo !== "" ? towerNo + "/" : ""
    var unit = unitTwo !== "" && unitOne !== "" ? "#" + towerNumber + unitOne + "-" + unitTwo + "," : unitOne !== "" ? "#" + towerNumber + unitOne + "," : ""
    /*return unit+addressOne;*/
    return addressOne + ", " + unit + " Singapore " + postCode
  }

  /* Delivery Address */
  deliveryAddress() {
    if (this.state.cartData.order_availability_id === config.ids.deliveryId) {
      return (
        <div className="col-xs-12 padd1 bg-gray name-div padding-b-0">
          <p>
            <span className="span-txt">Name :</span> {this.state.cartData.order_customer_fname + " " + this.state.cartData.order_customer_lname}
          </p>
          <p>
            <span className="span-txt">Delivery Address :</span>{" "}
            {this.addressFormat(
              this.state.cartData.order_customer_unit_no1,
              this.state.cartData.order_customer_unit_no2,
              this.state.cartData.order_customer_address_line1,
              this.state.cartData.order_customer_address_line2,
              this.state.cartData.order_customer_postal_code,
              this.state.cartData.order_customer_tower_number
            )}
          </p>
          <p>
            <span className="span-txt">Order Handled By : </span> {this.state.cartData.outlet_name}
          </p>
          {this.state.cartData.order_customer_send_gift === "Yes" && (
            <div>
              <p>
                <span className="span-txt">Recipient Name : </span> {this.state.cartData.order_recipient_name}
              </p>
              <p>
                <span className="span-txt">Recipient contact No. : </span> {this.state.cartData.order_recipient_contact_no}
              </p>
              <p>
                <span className="span-txt">Gift message : </span> {this.state.cartData.order_gift_message}
              </p>
            </div>
          )}
        </div>
      )
    } else if (this.state.cartData.order_availability_id === config.ids.dineinId) {
      return (
        <div className="col-xs-12 padd1 bg-gray name-div padding-b-0">
          <p>
            <span className="span-txt">Name :</span> {this.state.cartData.order_customer_fname + " " + this.state.cartData.order_customer_lname}
          </p>

          <p>
            <span className="span-txt">Dine In At : </span> {this.state.cartData.outlet_name}{" "}
          </p>
          <p>
            <span className="span-txt">Table : </span> {this.state.cartData.order_table_number}{" "}
          </p>
        </div>
      )
    } else if (this.state.cartData.order_availability_id === config.ids.pickupId) {
      return (
        <div className="col-xs-12 padd1 bg-gray name-div padding-b-0">
          <p>
            <span className="span-txt">Name :</span> {this.state.cartData.order_customer_fname + " " + this.state.cartData.order_customer_lname}
          </p>

          <p>
            <span className="span-txt" style={{ width: "23%", float: "left" }}>
              Pickup Location :{" "}
            </span>{" "}
            <span style={{ width: "77%", float: "left" }}>{/*this.state.cartData.outlet_name*/ this.outletNameText()}</span>{" "}
          </p>
        </div>
      )
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length
    var comboLen = combo.length
    var html = '<div class="cart_extrainfo">'

    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"]
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"]
        html += "<p><b>" + stripslashes(modName) + ":</b></p><p> " + stripslashes(modval) + "</p> "
      }
      html += "</div>"
      var reactElement = htmlToReactParser.parse(html)
      return reactElement
    } else if (comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"]
        var comboVal = this.showComboProducts(combo[i]["product_details"])

        html += "<p><b>" + comboName + ":</b></p><p> " + comboVal + " " + this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) + "</p> "
      }
      html += "</div>"
      var reactElement = htmlToReactParser.parse(html)
      return reactElement
    }
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length
    var html = "<div >"
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"]
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"]
        var modValPrice = modifiers[i]["modifiers_values"][0]["order_modifier_price"]
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : ""
        html += "<p><b>" + modName + ":</b></p><p> " + modval + newModValPrice + "</p> "
      }
      html += "</div>"

      return html
    }

    return ""
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length
    var html = " "
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]["menu_product_name"]
        var comboQty = combos[r]["menu_product_qty"]
        var comboPrice = combos[r]["menu_product_price"]
        var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : ""
        var comboQtyChk = comboQty !== "" ? parseInt(comboQty) : 0
        if (comboQtyChk > 0) {
          html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> "
        }
      }
      return html
    }
    return ""
  }

  loadProductsItems() {
    return this.state.cartItems.map((item, index) => (
      <tr key={index}>
        <td className="main-item tab_pad_bot">
          {item.item_qty} X {strp.stripslashes(item.item_name)}
          {this.loadModifierItems("Component", item.modifiers, item.set_menu_component)}
        </td>
        <td className="text-right tab_pad_bot">{item.item_total_amount}</td>
      </tr>
    ))
  }

  /* show delivery amount */
  deliveryPrice() {
    if (this.state.cartData.order_availability_id === config.ids.deliveryId) {
      return (
        <tr className="sub-total-tr">
          <td className="sub-total">Delivery</td>
          <td className="sub-total text-right">{this.state.cartData.order_delivery_charge}</td>
        </tr>
      )
    }
  }

  //additionaldeliveryPrice

  additionaldeliveryPrice() {
    var extraDel = parseFloat(this.state.cartData.order_additional_delivery)
    if (this.state.cartData.order_availability_id === config.ids.deliveryId && extraDel > 0) {
      return (
        <tr className="sub-total-tr">
          <td className="sub-total">Additional Surcharge</td>
          <td className="sub-total text-right">{extraDel.toFixed(2)}</td>
        </tr>
      )
    }
  }

  loadGstContent() {
    var gstAmount = parseFloat(this.state.cartData.order_tax_calculate_amount)
    var gstPercentage = parseFloat(this.state.cartData.order_tax_charge)

    if (gstAmount > 0) {
      return (
        <tr className="sub-total-tr">
          <td className="sub-total">GST ({gstPercentage}%)</td>
          <td className="sub-total text-right">{gstAmount.toFixed(2)}</td>
        </tr>
      )
    }
  }

  showGstInclusive() {
    var gstAmount = parseFloat(this.state.cartData.order_tax_calculate_amount)
    var displayInclusiveGst = cookie.load("gstInclusive")

    if (gstAmount === 0 && displayInclusiveGst != "" && displayInclusiveGst != "undefined") {
      return (
        <tr>
          <td className="text-right" colSpan={2}>
            {displayInclusiveGst}
          </td>
        </tr>
      )
    }
  }

  loadSurchargeContent() {
    var order_subcharge_amount = parseFloat(this.state.cartData.order_subcharge_amount)

    if (order_subcharge_amount > 0) {
      return (
        <tr className="sub-total-tr">
          <td className="sub-total">Surcharge</td>
          <td className="sub-total text-right">{order_subcharge_amount.toFixed(2)}</td>
        </tr>
      )
    }
  }

  loadServiceContent() {
    var order_service_charge_amount = parseFloat(this.state.cartData.order_service_charge_amount)
    var order_service_charge = parseFloat(this.state.cartData.order_service_charge)

    var ServiceChargeText = this.state.cartData.order_servicecharge_displaylabel !== "" && this.state.cartData.order_servicecharge_displaylabel !== null ? this.state.cartData.order_servicecharge_displaylabel : "Service Charge"

    if (order_service_charge_amount > 0) {
      return (
        <tr className="sub-total-tr">
          {order_service_charge === 0 ? (
            <td className="sub-total">{ServiceChargeText} </td>
          ) : (
            <td className="sub-total">
              {ServiceChargeText} ({order_service_charge}%)
            </td>
          )}
          <td className="sub-total text-right">{order_service_charge_amount.toFixed(2)}</td>
        </tr>
      )
    }
  }

  /* Show time slot */
  showTimeSlot() {
    //this.state.cartData.order_date
    if (this.state.cartData.order_is_timeslot === "Yes") {
      return <p className="date text-right">{this.state.cartData.FormatedFrom + "-" + this.state.cartData.FormatedTo}</p>
    } else {
      return <p className="date text-right">{moment(this.state.cartData.order_date).format("HH:mm")}</p>
    }
  }

  /* Display coupon details */

  couponApplied(amount, sts) {
    if (sts === "Yes") {
      return (
        <tr className="sub-total-tr">
          <td className="sub-total">Discount</td>
          <td className="sub-total text-right">{parseFloat(amount)}</td>
        </tr>
      )
    }
  }

  /*  order text */
  showDateText(type = null) {
    var returnText = type == 1 ? "Delivery Date" : "Delivery Time"
    if (this.state.cartData.order_availability_id === config.ids.pickupId) {
      returnText = type == 1 ? "Pickup Date" : "Pickup Time"
    } else if (this.state.cartData.order_availability_id === config.ids.dineinId) {
      returnText = type == 1 ? "Dine in Date" : "Dine in Time"
    }
    return returnText
  }

  /* order problem text */
  showproblemText() {
    if (apps.workspace === APPID) {
      return (
        <p className="order_success_icon">
          <a target="_blank" href="#">
            {" "}
            <i className="fa fa-facebook-official" aria-hidden="true"></i>{" "}
          </a>{" "}
          <a target="_blank" href="#">
            {" "}
            <i className="fa fa-instagram" aria-hidden="true"></i>{" "}
          </a>
        </p>
      )
    } else {
      return (
        <p className="text-center">
          Problem with your order ?<span className="grey left_span"></span>
          <span>
            <a href="/#/module/contact-us" target="_top">
              please inform us
            </a>
          </span>
        </p>
      )
    }
  }

  /*show outlet text for workspace */
  outletNameText() {
    if (apps.workspace === APPID) {
      return htmlToReactParser.parse("Workspace Espresso Bar <br/> 12 Marina Boulevard<br/> Marina Bay Financial Centre Tower 3<br/> #01-08 Singapore 018982")
    } else {
      return cookie.load("defaultAvilablityId") === config.ids.deliveryId ? cookie.load("orderDeliveryAddress") : cookie.load("orderHandled")
    }
  }

  render() {
    if (this.state.status === "Loading") {
      return (
        <div>
          <DocumentMeta {...meta} />
          <Loading />
        </div>
      )
    } else {
      return (
        <div className="container-fluid desktop-container" style={{ minHeight: this.state.windowHeightNormal }}>
          <DocumentMeta {...meta} />

          {APPID === "87E64237-E8D7-4CB6-ADC0-3E124D858641" && <img src={"https://marktamerica.go2cloud.org/aff_l?offer_id=14990" + "&adv_sub=" + this.state.placed_order_id + "&amount=" + this.state.order_total_amount} scrolling="no" frameborder="0" width="1" height="1" />}

          <div className="row">
            <div className="col-xs-12 main-title-bar">
              <Link to={"/"}>
                {" "}
                <img src="/img/left-arrow.png" className="pull-left icon-img-small" alt="left-arrow" />{" "}
              </Link>
              <h3 className="top-title">ORDER PLACED SUCCESSFULLY</h3>
            </div>
          </div>
          <div className="row">
            <div className="space-10  bg-gray" />
          </div>
          <div className="white-outer center-block">
            <div className="row">
              <div className="col-xs-12 padd2">
                <img src="/img/success-background.jpg" className="img-responsive" alt="success-background" />
                <div className="thank-you-div"></div>
              </div>
            </div>
            <div className="row ">
              <div className="col-xs-12 padd2">
                <h3 className="inner-title">Your Order Details</h3>
                <div className="col-xs-6 padd1 date-div">
                  <p className="date-title">{this.showDateText(1)}</p>
                  <p className="date">{moment(this.state.cartData.order_date).format("DD/MM/YYYY")}</p>
                </div>
                <div className="col-xs-6 padd1 date-div">
                  <p className="date-title text-right">{this.showDateText()}</p>
                  {this.showTimeSlot()}
                </div>
                {this.deliveryAddress()}
                <div className="col-xs-12 padd1 bg-gray order-div padding-b-0 padding-t-0">
                  <hr />
                  <h3>Order No - {this.state.cartData.order_local_no}</h3>
                  <p>Order placed at : {moment(this.state.cartData.order_created_on).format("DD/MM/YYYY hh:mm")}</p>

                  {APPID == "B637EE61-A5A9-4303-ACF7-4A3C055DC845" ? (
                    <h4 style={{ fontWeight: "bold" }}>PLEASE MAKE PAYMENT AT COUNTER</h4>
                  ) : (
                    APPID != "B401FC82-8E39-4076-838A-55192F765004" && (
                      <p>
                        Pay by : {this.state.cartData.order_method_name}{" "}
                        {this.state.cartData.order_payment_mode !== undefined && parseInt(this.state.cartData.order_payment_mode) === 1 && <span>Please pay cash at cash (for dine-in and takeaway order) or to delivery person (for delivery order).</span>}
                      </p>
                    )
                  )}

                  <hr />
                </div>
                <div className="col-xs-12 padd1 bg-gray price-list">
                  <table>
                    <tbody>
                      {this.loadProductsItems()}

                      <tr>
                        <td colSpan={2} />
                      </tr>
                      <tr className="sub-total-tr">
                        <td className="sub-total">Sub Total</td>
                        <td className="sub-total text-right">{this.state.cartData.order_sub_total}</td>
                      </tr>
                      {this.loadServiceContent()}

                      <tr>
                        <td colSpan={2} />
                      </tr>
                      {this.deliveryPrice()}
                      {this.additionaldeliveryPrice()}
                      {this.loadSurchargeContent()}
                      {this.couponApplied(this.state.cartData.order_discount_amount, this.state.cartData.order_discount_applied)}
                      {this.loadGstContent()}

                      <tr>
                        <td colSpan={2} />
                      </tr>
                      <tr>
                        <td className="total">Total</td>
                        <td className="total text-right">{currency + this.state.cartData.order_total_amount}</td>
                      </tr>
                      {this.showGstInclusive()}
                      <br />
                    </tbody>
                  </table>
                </div>
                <br />
                <div className="col-xs-12 padd1 price-list-footer">{this.showproblemText()}</div>
              </div>
            </div>
            <div className="space-50" />
            <div className="row">
              <Link to={"/orders"}>
                {" "}
                <button className="col-lg-12 continue-but desktop-container container-fluid">GO TO MY ACCOUNT</button>{" "}
              </Link>{" "}
            </div>
          </div>
        </div>
      )
    }
  }
}

export default Ordersuccess

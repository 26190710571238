/* eslint-disable */
import React from "react"
import axios from "axios"
import DocumentMeta from "react-document-meta"
import { Link, hashHistory } from "react-router"
import { RouteTransition } from "react-router-transition"
import cookie from "react-cookie"
import { loadAnalytics } from "./withTracker"
import getResult from "./Api"
import Loading from "./loading"

import config from "./config"
import lang from "./lang"
const APIURL = config.path.apiUrl
const APPID = config.ids.appId
var qs = require("qs")
var HtmlToReactParser = require("html-to-react").Parser
var htmlToReactParserTo = new HtmlToReactParser()

var helper = require("./Helper.js")
/* Load meta tags */
const meta = {
  title: lang.reservation + lang.site_title_with_separator,
  description: lang.reservation,
  meta: {
    name: {
      keywords: lang.reservation,
    },
  },
}
var deletevar = "No"
var monthNamesArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

var dayNamesArr = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
class Reservationaccept extends React.Component {
  constructor(props) {
    /*add class */
    helper.addBodyClass()

    super(props)
    console.log("props", this.props)
    var reservationID = this.props.params.reservationID !== "" && typeof this.props.params.reservationID !== undefined && this.props.params.reservationID !== "undefined" && this.props.params.reservationID !== undefined ? this.props.params.reservationID : ""
    var acceptType = ""
    if (this.props.route.path !== "" && typeof this.props.route.path !== undefined && this.props.route.path !== "undefined" && this.props.route.path !== undefined) {
      if (this.props.route.path == "/reservation_suggest/reject/:reservationID") {
        acceptType = "reject"
      } else {
        acceptType = "accept"
      }
    }
    /* Declare state */
    this.state = {
      windowHeightNormal: 0,
      windowHeight: 0,
      status: "Loading",
      settings: [],
      resstatus: 0,
      acceptType: acceptType,
      reservationID: reservationID,
    }

    this.acceptSuggest()

    /* API CALL FOR PRODUCT MENU NAVIGATION */
    var promise = getResult("settingsPro", "No")
    promise.then((res) => {
      this.setState({ settings: res.data.result_set })
    })
  }

  componentWillMount() {
    var windowHeight = window.innerHeight
    this.setState({ windowHeight: windowHeight - 45 + "px" })
    this.setState({ windowHeightNormal: windowHeight + "px" })
  }

  componentDidMount() {
    loadAnalytics()
  }

  acceptSuggest() {
    if (this.state.reservationID !== "") {
      var reservation_ID = this.encodedecode(this.state.reservationID, "decode")

      var qs = require("qs")
      var postObject = {
        app_id: APPID,
        sug_reser_id: reservation_ID,
      }
      var urlsucc = ""
      if (this.state.acceptType == "accept") {
        urlsucc = "reservation_suggest_accept"
      } else {
        urlsucc = "reservation_suggest_reject"
      }
      axios
        .post(APIURL + "reservation/" + urlsucc, qs.stringify(postObject))
        .then((response) => {
          this.setState({ status: "" })
          console.log("response", response.data)

          if (response.data.status === "success") {
            var result = response.data.result_set[0]
            var ResPeopleTxt = result.reservation_no_of_adult_pax
            var reservation_date = result.reservation_date
            var dateSplit = reservation_date.split(" ")
            var dateSplit1 = dateSplit[0].split("-")
            var selYear = dateSplit1[0]
            var selMonthcal1 = dateSplit1[1]
            var selDate = dateSplit1[2]
            var calMnt = parseInt(selMonthcal1) - 1
            var selMonthcal = this.pad(calMnt)
            var caldatesplt = new Date(selYear, selMonthcal, selDate)
            console.log("caldatesplt", caldatesplt)
            var calRsResDatepkidVal = ("0" + caldatesplt.getDate()).slice(-2) + " " + monthNamesArr[caldatesplt.getMonth()] + " " + caldatesplt.getFullYear()
            var calRsResDayText = dayNamesArr[caldatesplt.getDay()]
            this.setState({ ResselectedDateTxt: calRsResDatepkidVal })
            this.setState({ ResselectedDayTxt: calRsResDayText })

            var reservation_start_time = result.reservation_start_time
            var splitTime = reservation_start_time.split(":")
            var resTimeText = splitTime[0] + ":" + splitTime[1]
            if (splitTime[0] >= 12) {
              resTimeText += " PM"
            } else {
              resTimeText += " AM"
            }
            this.setState({ resTimeText: resTimeText })

            if (result.reservation_no_of_adult_pax > 1) {
              ResPeopleTxt += " Adults"
            } else {
              ResPeopleTxt += " Adult"
            }

            if (result.reservation_no_of_children_pax !== "" && result.reservation_no_of_children_pax > 0) {
              ResPeopleTxt += " " + result.reservation_no_of_children_pax
              if (result.reservation_no_of_adult_pax > 1) {
                ResPeopleTxt += " Children"
              } else {
                ResPeopleTxt += " Child"
              }
            }
            this.setState({
              selectResOutletOptionTxt: response.data.outlet_name,
              selectResOutletAdrr: response.data.outlet_address,
              resOrderId: result.reservation_local_order_id,
              ResPeopleTxt: ResPeopleTxt,
              resstatus: 1,
            })
          } else {
            this.setState({
              selectResOutletOptionTxt: response.data.outlet_name,
              selectResOutletAdrr: response.data.outlet_address,
              resstatus: 2,
            })
          }
          $("#ReservationMdl").modal("show")
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }

  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString()
  }
  encodedecode(string, type) {
    if (string !== "") {
      var Base64 = {
        // private property
        _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

        // public method for encoding
        encode: function (input) {
          var output = ""
          var chr1, chr2, chr3, enc1, enc2, enc3, enc4
          var i = 0

          input = Base64._utf8_encode(input)

          while (i < input.length) {
            chr1 = input.charCodeAt(i++)
            chr2 = input.charCodeAt(i++)
            chr3 = input.charCodeAt(i++)

            enc1 = chr1 >> 2
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
            enc4 = chr3 & 63

            if (isNaN(chr2)) {
              enc3 = enc4 = 64
            } else if (isNaN(chr3)) {
              enc4 = 64
            }

            output = output + this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) + this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4)
          }

          return output
        },

        // public method for decoding
        decode: function (input) {
          var output = ""
          var chr1, chr2, chr3
          var enc1, enc2, enc3, enc4
          var i = 0

          input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "")

          while (i < input.length) {
            enc1 = this._keyStr.indexOf(input.charAt(i++))
            enc2 = this._keyStr.indexOf(input.charAt(i++))
            enc3 = this._keyStr.indexOf(input.charAt(i++))
            enc4 = this._keyStr.indexOf(input.charAt(i++))

            chr1 = (enc1 << 2) | (enc2 >> 4)
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2)
            chr3 = ((enc3 & 3) << 6) | enc4

            output = output + String.fromCharCode(chr1)

            if (enc3 != 64) {
              output = output + String.fromCharCode(chr2)
            }
            if (enc4 != 64) {
              output = output + String.fromCharCode(chr3)
            }
          }

          output = Base64._utf8_decode(output)

          return output
        },

        // private method for UTF-8 encoding
        _utf8_encode: function (string) {
          string = string.replace(/\r\n/g, "\n")
          var utftext = ""

          for (var n = 0; n < string.length; n++) {
            var c = string.charCodeAt(n)

            if (c < 128) {
              utftext += String.fromCharCode(c)
            } else if (c > 127 && c < 2048) {
              utftext += String.fromCharCode((c >> 6) | 192)
              utftext += String.fromCharCode((c & 63) | 128)
            } else {
              utftext += String.fromCharCode((c >> 12) | 224)
              utftext += String.fromCharCode(((c >> 6) & 63) | 128)
              utftext += String.fromCharCode((c & 63) | 128)
            }
          }

          return utftext
        },

        // private method for UTF-8 decoding
        _utf8_decode: function (utftext) {
          var string = ""
          var i = 0
          var c1, c2, c3
          var c = (c1 = c2 = 0)

          while (i < utftext.length) {
            c = utftext.charCodeAt(i)

            if (c < 128) {
              string += String.fromCharCode(c)
              i++
            } else if (c > 191 && c < 224) {
              c2 = utftext.charCodeAt(i + 1)
              string += String.fromCharCode(((c & 31) << 6) | (c2 & 63))
              i += 2
            } else {
              c2 = utftext.charCodeAt(i + 1)
              c3 = utftext.charCodeAt(i + 2)
              string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63))
              i += 3
            }
          }

          return string
        },
      }
      if (type === "encode") {
        return Base64.encode(string)
      } else if (type === "decode") {
        return Base64.decode(string)
      }
    }
  }

  closeResvPoup() {
    $("body").removeClass("reservationPopup")
    $("#ReservationMdl").modal("hide")
    hashHistory.push("/")
  }
  gotoMyAcct() {
    $("body").removeClass("reservationPopup")
    $("#ReservationMdl").modal("hide")
    if (this.state.acceptType == "accept") {
      hashHistory.push("/my-account")
    } else {
      if (typeof cookie.load("UserId") === "undefined" || (cookie.load("UserId") && cookie.load("UserId") === "")) {
        hashHistory.push("/sign-in")
      } else {
        cookie.save("open_reservation", 1)
        hashHistory.push("/")
      }
    }
  }
  render() {
    if (this.state.status === "Loading") {
      return (
        <div className="container-fluid desktop-container position-rel" style={{ minHeight: this.state.windowHeightNormal }}>
          <div className="row">
            <Loading />
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <DocumentMeta {...meta} />
          <div className="container-fluid desktop-container" style={{ minHeight: this.state.windowHeight }}>
            <div className="row">
              <div className="col-xs-12 top-nav inner-top-nav head-main">
                <div className="col-xs-3 nav-head pd-l-20 head-child">
                  <Link to={"/"}>
                    <img src="/img/icons/arrow-right.png" className="icon-img-small-3" alt="left-arrow" />
                  </Link>
                </div>

                <div className="col-xs-6 nav-head  head-child">
                  <h1 className="main-title">Reservation</h1>
                </div>
                <div className="col-xs-3 nopadding  head-child"></div>
              </div>
            </div>

            <div className="row">
              <Link to={"/"}>
                <button className="col-lg-12 continue-but desktop-container main-title container-fluid">BACK</button>
              </Link>{" "}
            </div>
          </div>
          <div className="modal fade reservationmdlCls" id="ReservationMdl">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="reservation-wrap-ija">
                    <div className="reserve-head">
                      <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={this.closeResvPoup.bind(this)}>
                        <img src="/img/icons/close_popup.png" className="img-responsive" alt="" />
                      </button>
                      <h4>Reservation</h4>
                      <p>
                        {this.state.selectResOutletOptionTxt} - {this.state.selectResOutletAdrr}
                      </p>
                    </div>
                    <div className="reserve-items-box">
                      <form id="wizard-1" noValidate="novalidate">
                        <div id="bootstrap-wizard-1" className="col-sm-12">
                          <div className="tab-content">
                            <div className="tab-pane active" id="tab3">
                              <div className="confirmation-bx">
                                {this.state.resstatus === 1 && this.state.acceptType == "accept" && (
                                  <div>
                                    <img src="img/success.png" />
                                    <h4>Thank You</h4>
                                    <p>Your Reservation has been confirmed</p>
                                  </div>
                                )}
                                {this.state.resstatus === 2 && (
                                  <div>
                                    <img src="img/worried.png" />
                                    <p>Your Reservation already confirmed/rejected</p>
                                  </div>
                                )}
                                {this.state.resstatus === 1 && this.state.acceptType == "reject" && (
                                  <div>
                                    <img src="img/worried.png" />
                                    <p>Reservation Cancelled</p>
                                  </div>
                                )}
                              </div>
                              {this.state.resstatus === 1 && this.state.acceptType == "accept" && (
                                <div className="cnfm-id-section">
                                  <h5>
                                    Confirmation ID : <span>{this.state.resOrderId}</span>
                                  </h5>
                                  <p>
                                    <span>{this.state.ResPeopleTxt}</span> at <br />
                                    <span>
                                      {this.state.settings.company_name} - {this.state.selectResOutletOptionTxt}
                                    </span>{" "}
                                    on <br />
                                    <span>
                                      {this.state.ResselectedDateTxt}, {this.state.ResselectedDayTxt}, {this.state.resTimeText}
                                    </span>
                                  </p>
                                </div>
                              )}
                              <div className="text-center">
                                <a className="btn-send-t bg-bck-color" onClick={this.gotoMyAcct.bind(this)}>
                                  {this.state.acceptType == "reject" && <span>Create New Reservation Request</span>}
                                  {this.state.acceptType == "accept" && <span>Go To My Account</span>}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}
export default Reservationaccept

/* eslint-disable */
import React from "react"
import axios from "axios"
import { Link, hashHistory } from "react-router"
import getval from "./Api"
import cookie from "react-cookie"
import DocumentMeta from "react-document-meta"
import Distancebar from "./Distancebar"
import Outletclosedmessage from "./Outletclosedmessage"
import { isEmpty } from "lodash"
import { geolocated } from "react-geolocated"
import { loadAnalytics } from "./withTracker"
import { Scrollbars } from "react-custom-scrollbars"
import LocationSearchInput from "./LocationSearchInput"
import { API_v4_URL, MEDIA_SERVER_URL } from "./constants"
import Loading from "./loading"
import Alert from "./alert"
import Select from "react-select"
import Slider from "react-slick"
import Sticky from "react-sticky-el"

import config from "./config"
import lang from "./lang"
const APIURL = config.path.apiUrl
const APIURLv2 = config.path.apiUrlv2
const currency = config.ids.currency
const APPID = config.ids.appId
var strp = require("strp")
var ReactDOMServer = require("react-dom/server")
var HtmlToReactParser = require("html-to-react").Parser
var helper = require("./Helper.js")
import "react-select/dist/react-select.css"
const APIURLPRO = config.path.apiUrlPro
import getResult from "./Api"
/* Load meta tags */
const meta = {
  title: lang.prodcuts_meta_title + lang.site_title_with_separator,
  description: lang.prodcuts_meta_title,
  meta: {
    name: {
      keywords: lang.prodcuts_meta_title,
    },
  },
}

class Products extends React.Component {
  state = {
    slideIndex: 2,
    updateCount: 0,
  }

  constructor(props) {
    super(props)

    helper.addBodyClass()

    this.state = {
      goto: 1,
      stockEnabled: 0,
      zonePopupDisable: 0,
      zoneEnabled: 0,
      zoneOutletSeleted: "",
      zoneDeliveryOutlet: "",
      deliveryOutlets: [],
      zoneDetails: [],
      productHeightNormal: 0,
      windowHeightNormal: 0,
      windowHeight: 0,
      secAddrData: [],
      addressField: "",
      Outlets: [],
      category: [],
      products: [],
      products_common: [],
      urlparams: [],
      status: "Loading",
      navStatus: "",
      categoryName: "",
      categoryImSrc: config.noImages.product_listing,
      enableAddressSearch: false,
      outletCloseddMessage: "",
    }
    this.handleChangeValue = this.handleChangeValue.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.selectOutlet = this.selectOutlet.bind(this)
    /* set default avilablity */
    // var sqdasdasd = "test";
    axios.get(APIURLPRO + "settings/get_common_settings?app_id=" + APPID + "&postal_code=" + cookie.load("orderPostalCode") + "&postalFilter=No").then((res) => {
      this.setState({ zoneEnabled: parseInt(res.data.result_set.client_outlet_zone) })
      this.setState({ zonePopupDisable: parseInt(res.data.result_set.client_zone_outlet_disable) })
      this.setState({ stockEnabled: parseInt(res.data.result_set.client_product_stock) }, function () {
        console.log(this.state.stockEnabled)
      })

      var applyOutletIdtxt = res.data.result_set.ninja_outletbasedproduct_enable === "1" ? "yes" : "no"
      cookie.save("applyOutletId", applyOutletIdtxt)
      var avlId = res.data.result_set.ninja_pro_default_availability_id
      var avlText = res.data.result_set.ninja_pro_default_availability_name === "Pickup" ? "Takeaway" : res.data.result_set.ninja_pro_default_availability_name
      var avlImage = "/img/icons/" + res.data.result_set.ninja_pro_default_availability_id + ".png"
      helper.setDefaultAvailability(avlId, avlText, avlImage)

      var orderOutletIdTxt = cookie.load("orderOutletId")
      var addqueryTxt = ""

      if (typeof orderOutletIdTxt !== "undefined" && orderOutletIdTxt !== "") {
        addqueryTxt = "&outletIdTxt=" + orderOutletIdTxt
      }

      /* API CALL FOR PRODUCT MENU NAVIGATION */
      axios.get(APIURL + "productsver3/navigation_menu?app_id=" + APPID + addqueryTxt + "&availability=" + cookie.load("defaultAvilablityId")).then((res) => {
        this.setState({ navStatus: res.data.status })
        this.setState({ common: res.data.common })
        this.setState({ category: res.data.result_set })
      })
      /* Load Cart */
      this.loadCartData()

      /* get outlets lits */
      if ((cookie.load("defaultAvilablityId") === config.ids.pickupId && cookie.load("defaultAvilablity") === lang.takeaWay && typeof cookie.load("orderOutletId") === "undefined") || typeof cookie.load("orderPostalCode") === "undefined") {
        this.getpickupOutletlist()
      }

      /* get secondary address lits */
      if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "" && cookie.load("defaultAvilablityId") === config.ids.deliveryId) {
        if (typeof cookie.load("orderOutletId") === "undefined" || typeof cookie.load("orderPostalCode") === "undefined") {
          this.getSecAddr()
        }
      }
      /* get secondary address lits - end */

      /* load products */
      var categoryId = this.props.params.categoryId
      var categoryType = this.props.params.categoryType
      this.setState({ categoryId: categoryId })
      var bckurlTxt = categoryType === "category" ? "/products/category/" + categoryId : "/products/subcategory/" + categoryId
      cookie.save("bckurlTxt", bckurlTxt)

      this.loadData(categoryType, categoryId)

      /* JC */
      if (this.state.zoneEnabled === 1) {
        axios.get(API_v4_URL + "Getoutlets/getAllOutles/pickup_outlets?app_id=" + APPID + "&availability_id=" + config.ids.deliveryId).then((res) => {
          if (res.data.status === "ok") {
            this.setState({ deliveryOutlets: res.data.result_set })
          }
        })
      } /* JC*/

      if (res.data.result_set.client_tax_surcharge_inclusive > "0.00") {
        this.setState({ gstTaxText: "GST Inclusive(" + parseFloat(res.data.result_set.client_tax_surcharge_inclusive) + "%)" })
      } else if (res.data.result_set.client_tax_surcharge > 0) {
        this.setState({ gstTaxText: "Excluding Delivery Charges" })
      } else {
        this.setState({ gstTaxText: "" })
      }
    })
  }

  closeTooltip() {
    $(".scan-tooltip").hide()
  }

  /* BX slider */
  __bxslider() {
    console.log("bx loaded")
    var bxslider = $(".bxslider").bxSlider({
      infiniteLoop: false,
      auto: false,
      maxSlides: 4,
      slideWidth: 200,
      touchEnabled: true,
      pager: false,
      moveSlides: 1,
      minSlides: 3,
      pause: 4000,
    })
    $(".cstm-scroll-menu").mCustomScrollbar({
      axis: "x",
      theme: "dark-3",
      documentTouchScroll: true,
      autoHideScrollbar: true,
      contentTouchScroll: true,
      advanced: {
        autoExpandHorizontalScroll: false,
      },
    })
  }

  /* return navigation menu slug */
  navLinkType(menu_type) {
    return menu_type === "sub" ? "subcategory" : "category"
  }

  /* this function used reload products */
  reLoadProducts(menu_type, CateId, subCateId) {
    var categoryType = menu_type === "sub" ? "subcategory" : "category"
    var categoryId = menu_type === "sub" ? subCateId : CateId
    this.setState({ categoryId: categoryId })
    document.getElementById("loadIcon").style.display = "block"
    this.loadData(categoryType, categoryId)
  }
  /* return navigation menu slug */
  navSlug(menu_type, CateId, subCateId) {
    return menu_type === "sub" ? "/products/subcategory/" + subCateId : "/products/category/" + CateId
  }

  /* menu listing li  content */
  menuListing(showImage) {
    /*$(window).scroll(function () {

			var scroll = $(window).scrollTop();

			if (scroll >= 200) {
				$(".innerheader-fixed").addClass("scrollheader-fixed");
				$(".scrollmain").addClass("fixedscrollmain");
			} else {
				$(".innerheader-fixed").removeClass("scrollheader-fixed");
				$(".scrollmain").removeClass("fixedscrollmain");
			}

		});  */

    var categoryId = this.state.categoryId

    return this.state.category.map((loaddata, index) => (
      <div key={index} className={categoryId === loaddata.menu_category_id ? "active item_slide" : "item_slide"}>
        <Link onClick={this.reLoadProducts.bind(this, loaddata.menu_type, loaddata.menu_category_id, loaddata.pro_subcate_id)} style={{ cursor: "pointer" }}>
          {this.loadCategoryImage(true, loaddata)}
          <span>{strp.stripslashes(loaddata.menu_custom_title)}</span>
        </Link>
      </div>
    ))
  }

  /* menu listing li  content */
  menuListingdropDown(showImage) {
    var categoryId = this.state.categoryId

    return this.state.category.map((loaddata, index) => (
      <div key={index} className={categoryId === loaddata.menu_category_id ? "active item_slide" : "item_slide"}>
        <Link onClick={this.reLoadProducts.bind(this, loaddata.menu_type, loaddata.menu_category_id, loaddata.pro_subcate_id)} style={{ cursor: "pointer" }}>
          <span>{strp.stripslashes(loaddata.menu_custom_title)}</span>
        </Link>
      </div>
    ))
  }

  loadCategoryImage(showImage, loadData) {
    //config.path.tinThumpUrl+res.data.common.category_image_source+"/" + res.data.result_set[0].product_list[0][0].catgory_image+"&w=768&h=264&q=80"
    let categoryPath = "media/dev_team/products/category-image/"
    let subCategoryPath = "media/dev_team/products/subcategory-image/"
    let returnImage = "/img/no-images/category-slider-no-image.png"
    if (showImage === true) {
      if (loadData.pro_cate_image !== "" && loadData.pro_cate_image !== null) {
        let returnImageOne = MEDIA_SERVER_URL + categoryPath + "/" + loadData.pro_cate_image
        return <img src={config.path.tinThumpUrl + MEDIA_SERVER_URL + returnImageOne + "&w=250&h=170&q=80"}></img>
      } else if (loadData.pro_subcate_image != "" && loadData.pro_subcate_image !== null) {
        let returnImageTwo = MEDIA_SERVER_URL + subCategoryPath + "/" + loadData.pro_subcate_image
        return <img src={config.path.tinThumpUrl + MEDIA_SERVER_URL + returnImageTwo + "&w=250&h=170&q=80"}></img>
      } else {
        return <img src={returnImage}></img>
      }
    }
  }

  loadCartData() {
    /* API CALL FOR BANNERS */
    if (typeof cookie.load("UserId") === "undefined") {
      var customerParam = "&reference_id=" + getResult("referenceId")
    } else {
      customerParam = "&customer_id=" + cookie.load("UserId")
    }
    var postalCodeWhere = ""
    if (typeof cookie.load("orderPostalCode") !== "undefined") {
      postalCodeWhere = "&postal_code=" + cookie.load("orderPostalCode")
    }

    axios.get(APIURL + "cartver2/contents?status=A&app_id=" + APPID + customerParam + postalCodeWhere).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ status: "ok" })
        if (typeof res.data.result_set !== "undefined") {
          var cartDetails = res.data.result_set.cart_details
          console.log(cartDetails.cart_grand_total)
          cookie.save("cart_grand_total", cartDetails.cart_sub_total)
          cookie.save("cartValue", "Yes")
        } else {
          cookie.save("cartValue", "No")
        }
      } else {
        cookie.save("cartValue", "No")
      }
    })
  }

  /*Load cart image */
  loadCartImage() {
    return getval("cartImage")
  }

  componentDidMount() {
    var site_maintenance_verify = cookie.load("client_site_maintenance_verify") !== "" && cookie.load("client_site_maintenance_verify") !== "undefined" && cookie.load("client_site_maintenance_verify") !== undefined ? cookie.load("client_site_maintenance_verify") : ""

    var site_maintenance_popup_open = cookie.load("site_maintenance_popup_open") !== "" && cookie.load("site_maintenance_popup_open") !== "undefined" && cookie.load("site_maintenance_popup_open") !== undefined ? cookie.load("site_maintenance_popup_open") : ""

    if (site_maintenance_popup_open === "1" && (site_maintenance_verify === "" || site_maintenance_verify === "0")) {
      hashHistory.push("/")
      return
    }

    var slideno = this.props.params.slideno
    var slidegoto = 0

    if ($(window).width() >= 600) {
      if (slideno > 3) {
        slidegoto = slideno - 3
      }
      this.setState({ goto: Math.ceil(slidegoto) }, function () {
        console.log(this.state.goto)
      })
    } else if ($(window).width() <= 360) {
      slidegoto = slideno
      this.setState({ goto: Math.ceil(slidegoto) }, function () {
        console.log(this.state.goto)
      })
    } else if ($(window).width() <= 480) {
      if (slideno > 1) {
        slidegoto = slideno - 1
      }

      this.setState({ goto: Math.ceil(slidegoto) }, function () {
        console.log(this.state.goto)
      })
    } else if ($(window).width() < 600) {
      if (slideno > 1) {
        slidegoto = slideno - 1
      }

      this.setState({ goto: Math.ceil(slidegoto) }, function () {
        console.log(this.state.goto)
      })
    }
    loadAnalytics()

    /* outlet closed messages  */
    let outResult = helper.getStaticBlock("outlet-closed-message")
    outResult.then((stTes) => {
      if (stTes.data.status === "ok") {
        this.setState({ outletCloseddMessage: stTes.data.result_set[0].staticblocks_description })
      }
    })
  }

  componentWillMount() {
    var windowHeight = window.innerHeight
    this.setState({ windowHeight: windowHeight - 45 + "px" }) //productHeightNormal
    this.setState({ windowHeightNormal: windowHeight + "px" })
    //this.setState({ 'productHeightNormal': (windowHeight + 100) + 'px' });
  }

  /* this function used to menu listing */
  menuList() {
    if (this.state.navStatus === "ok") {
      return (
        <div className="row ">
          <div className="container">
            <div className="tigger-menu" id="tigger-menu">
              <div className="dropup" id="dropup">
                <a className="dropdown-toggle" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <div className="float-btn"></div>
                </a>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  {this.menuListingdropDown()}
                </ul>
              </div>
              <a href="#"></a>
            </div>
          </div>
        </div>
      )
    }
  }

  /* this function used to load all content */
  loadData(categoryType, categoryId) {
    /* set cookie values */
    cookie.save("categoryType", categoryType)
    cookie.save("categoryId", categoryId)
    this.setState({ productHeightNormal: "0px" })
    var query_param = categoryType === "category" ? "category_id=" + categoryId : "subcategory_id=" + categoryId

    var orderOutletIdtext = cookie.load("orderOutletId")
    var applyOutletIdtext = cookie.load("applyOutletId")

    if (typeof orderOutletIdtext !== "undefined" && orderOutletIdtext !== "" && applyOutletIdtext === "yes") {
      query_param = query_param + "&outlet=" + orderOutletIdtext + "&applyOutletId=yes"
    }

    var promise = getval("product", query_param)
    promise.then((res) => {
      if (res.data.status === "ok") {
        if (res.data.common.type === "Category") {
          this.setState({ categoryName: res.data.result_set[0].product_list[0][0].catgory_name })
          if (res.data.result_set[0].product_list[0][0].catgory_image !== null && res.data.result_set[0].product_list[0][0].catgory_image !== "") {
            this.setState({ categoryImSrc: config.path.tinThumpUrl + res.data.common.category_image_source + "/" + res.data.result_set[0].product_list[0][0].catgory_image + "&w=768&h=250&q=80" })
          } else {
            this.setState({ categoryImSrc: config.noImages.product_listing })
          }
        } else {
          /*	 console.log(res.data.common.subcategory_image_source +"/"+ res.data.result_set[0].product_list[0][0].subcatgory_image); */

          if (res.data.result_set[0].product_list[0][0].subcatgory_image !== null && res.data.result_set[0].product_list[0][0].subcatgory_image !== "") {
            this.setState({ categoryImSrc: res.data.common.subcategory_image_source + "/" + res.data.result_set[0].product_list[0][0].subcatgory_image })
          } else {
            this.setState({ categoryImSrc: config.noImages.product_listing })
          }
          this.setState({ categoryName: res.data.result_set[0].product_list[0][0].subcatgory_name })
        }

        this.setState({ products_common: res.data.common })
        this.setState({ products: res.data.result_set })
        this.setState({ status: res.data.status })
        if ((typeof orderOutletIdtext === "undefined" || orderOutletIdtext === "") && applyOutletIdtext === "yes") {
          this.openOrderTupeChangeFun()
        }
        document.getElementById("loadIcon").style.display = "none"
        let windowHeh = window.innerHeight
        let proHolderHeight = document.getElementById("productholder").clientHeight
        let minusHeight = windowHeh - 120
        let plusHeight = windowHeh + 100

        if (minusHeight <= proHolderHeight && proHolderHeight <= plusHeight) {
          proHolderHeight = windowHeh + 100
        }

        this.setState({ productHeightNormal: proHolderHeight + "px" })
      } else {
        this.setState({ status: "error" })
        document.getElementById("loadIcon").style.display = "none"
      }
    })
  }

  /* this function used  menu navigation  listing */
  __categoryList() {
    if (this.state.status === "ok") {
      return this.state.products.map((loaddata, index) => (
        <div id="productholder" className="row product-holder" key={index} style={this.state.products.length === index + 1 ? { marginBottom: "200px" } : {}}>
          <h1 className="inner-title categorytitle">
            <b>{strp.stripslashes(loaddata.pro_subcate_name)}</b>
          </h1>
          {!isEmpty(loaddata.pro_subcate_description) && (
            <p className="text-justify" style={{ textAlign: "justify", padding: "0px 0%" }}>
              {strp.stripslashes(loaddata.pro_subcate_description)}
            </p>
          )}
          {this.__productListing(loaddata.product_list[0])}
        </div>
      ))
    } else {
      return (
        <div className="row">
          {" "}
          <div className="col-xs-12 nav-head no_products_found  ">
            <h1 className="main-title">{lang.no_products_found} </h1>
          </div>{" "}
        </div>
      )
    }
  }

  /* image content */

  productImagecontent(product_slug, product_thumbnail, product_stock) {
    if (product_thumbnail !== "") {
      var image = config.path.tinThumpUrl + this.state.products_common.image_source + "/" + product_thumbnail + "&w=200&h=200&q=80"

      let mobileImage = config.path.tinThumpUrl + this.state.products_common.image_source + "/" + product_thumbnail + "&w=200&h=250&q=80"

      // console.log("WindowHWidth", window.innerWidth)

      let windowWidth = window.innerWidth

      if (windowWidth >= 480) {
        return (
          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 thumb-img-div nopad" onClick={this.product_click_page.bind(this, product_slug)}>
            {product_stock === "0" && this.state.stockEnabled === 1 ? (
              <img className="product-main-image" src={image} alt="" width="100%" />
            ) : (
              <Link to={"/product/" + product_slug}>
                {" "}
                <img className="product-main-image" src={image} alt="" width="100%" />{" "}
              </Link>
            )}
          </div>
        )
      } else if (windowWidth <= 481) {
        return (
          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 thumb-img-div nopad mobile-background">
            <div onClick={this.product_click_page.bind(this, product_slug)} style={{ backgroundImage: `url(${mobileImage})` }} className="product-backg-img"></div>
            {product_stock === "0" && this.state.stockEnabled === 1 ? (
              <img className="product-main-image" src={image} alt="" width="100%" />
            ) : (
              <Link to={"/product/" + product_slug}>
                {" "}
                <img className="product-main-image" src={image} alt="" width="100%" />{" "}
              </Link>
            )}
          </div>
        )
      }
    }
  }

  product_click_page(product_slug) {
    if (product_slug !== "") {
      hashHistory.push("/product/" + product_slug)
    }
  }

  getpickupOutletlist() {
    axios.get(API_v4_URL + "Getoutlets/pickup_outlets?app_id=" + APPID).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ Outlets: res.data.result_set })
      }
    })
  }

  /* imnage class */
  productClass(product_thumbnail) {
    return product_thumbnail !== "" ? "col-lg-9 col-md-9 col-sm-9 col-xs-9 product-cont" : "col-md-12 col-sm-12 col-xs-12"
  }

  productvoucherClass(product_thumbnail) {
    return product_thumbnail !== "" ? "col-lg-7 col-md-7 col-sm-7 col-xs-7" : "col-md-12"
  }

  productVoucherImagecontent(product_slug, product_thumbnail, product_stock) {
    if (product_thumbnail !== "") {
      var image = config.path.tinThumpUrl + this.state.products_common.image_source + "/" + product_thumbnail + "&w=288&h=144&q=80"
      return (
        <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 thumb-img-div">
          {product_stock === "0" && this.state.stockEnabled === 1 ? (
            <img src={image} alt="" width="100%" />
          ) : (
            <Link to={"/product/" + product_slug}>
              {" "}
              <img src={image} alt="" width="100%" />{" "}
            </Link>
          )}
        </div>
      )
    }
  }
  loadProductDate(date) {
    var product_date = new Date(date).toString().split(" ")
    return product_date[2] + "-" + product_date[1] + "-" + product_date[3]
  }

  /* product Listing */
  __productListing(proList) {
    return proList.map((product, index) => (
      <div
        key={index}
        className={
          this.state.stockEnabled === 1 && product.product_stock === "0"
            ? product.product_type == "5"
              ? "productparent flex-product greyed"
              : "productparent greyed mobile-fix col-md-12 col-lg-12 col-sm-12 col-xs-12"
            : product.product_type == "5"
            ? "productparent flex-product"
            : "productparent greyed mobile-fix col-md-12 col-lg-12 col-sm-12 col-xs-12"
        }
      >
        {product.product_type == "5" ? (
          <div className="flex-product-inner">
            {this.productVoucherImagecontent(product.product_slug, product.product_thumbnail, product.product_stock)}
            {this.state.stockEnabled === 1 && product.product_stock === "0" ? (
              <div className={this.productvoucherClass(product.product_thumbnail)}>
                <h1 className="head1 text-center"> {helper.truncate(strp.stripslashes(strp.ucfirst(helper.getAliasName(product.product_alias, product.product_name))), 1)}</h1>
                {this.showTags(product.indicators)}
                <div className="product-desction-section">
                  <div className="pro-des">
                    <p className="">{strp.stripslashes(helper.truncate(product.product_short_description))}</p>
                  </div>
                  {this.state.stockEnabled === 1 && product.product_stock === "0" && <div className="nostock inside-cont">SOLD OUT</div>}
                </div>
              </div>
            ) : (
              <div className={this.productvoucherClass(product.product_thumbnail)}>
                <Link className="product-title" to={"/product/" + product.product_slug}>
                  <h1 className="head1 text-center"> {helper.truncate(strp.stripslashes(strp.ucfirst(helper.getAliasName(product.product_alias, product.product_name))), 1)}</h1>
                  {this.showTags(product.indicators)}
                  <p className="text-center">{strp.stripslashes(helper.truncate(product.product_short_description))} </p>
                </Link>

                {product.product_type === "5" ? (
                  <div className="expiry-available-food">
                    <span>Expiry : {this.loadProductDate(product.product_voucher_expiry_date)}</span> | <span>{product.product_stock} Available</span>
                  </div>
                ) : (
                  ""
                )}

                {product.product_type === "5" ? <p className="text-center voucher_product_list">{currency + product.product_price}</p> : ""}
              </div>
            )}
          </div>
        ) : (
          <div className="row">
            {this.productImagecontent(product.product_slug, product.product_thumbnail, product.product_stock)}
            {this.state.stockEnabled === 1 && product.product_stock === "0" ? (
              <div className={this.productClass(product.product_thumbnail)}>
                <h1 className="head1">
                  {" "}
                  {helper.truncate(strp.stripslashes(strp.ucfirst(helper.getAliasName(product.product_alias, product.product_name))), 1)} <p className=" price pull-right">{product.product_price}</p>
                </h1>
                {this.showTags(product.indicators)}
                <div className="product-desction-section">
                  <div className="pro-des">
                    <p className="text-justify">{strp.stripslashes(helper.truncate(product.product_short_description))}</p>
                  </div>
                  {this.state.stockEnabled === 1 && product.product_stock === "0" && <div className="nostock inside-cont">SOLD OUT</div>}
                </div>
              </div>
            ) : (
              <div className={this.productClass(product.product_thumbnail)}>
                <Link className="product-title" to={"/product/" + product.product_slug}>
                  <h1 className="head1">
                    {" "}
                    {helper.truncate(strp.stripslashes(strp.ucfirst(helper.getAliasName(product.product_alias, product.product_name))), 1)}{" "}
                    <p className=" price pull-right">
                      {" "}
                      {/*<strike className="strike-txt">9.90</strike>*/} {product.product_price}
                    </p>
                  </h1>
                  {this.showTags(product.indicators)}
                  <p className="text-justify">{strp.stripslashes(helper.truncate(product.product_short_description))} </p>
                </Link>
              </div>
            )}
            <div className="clearfix"></div>
          </div>
        )}
      </div>
    ))
  }

  /* continue with pickup success */
  pickupSuccess() {
    /* load product items */
    var categoryId = this.props.params.categoryId
    var categoryType = this.props.params.categoryType
    this.loadMenuNav()
    this.loadData(categoryType, categoryId)
    $("#Pickup-Sucess").modal("hide")
    $("#pickup-outlet-sucess").modal("hide")
  }

  /* continue with delivery success */
  deliverySuccess() {
    /* load product items */
    var categoryId = this.props.params.categoryId
    var categoryType = this.props.params.categoryType
    this.loadMenuNav()
    this.loadData(categoryType, categoryId)
    $("#DeliverySucess").modal("hide")
  }

  loadMenuNav() {
    var orderOutletIdTxt = cookie.load("orderOutletId")
    var addqueryTxt = ""

    if (typeof orderOutletIdTxt !== "undefined" && orderOutletIdTxt !== "") {
      addqueryTxt = "&outletIdTxt=" + orderOutletIdTxt
    }

    /* API CALL FOR PRODUCT MENU NAVIGATION */
    axios.get(APIURL + "productsver3/navigation_menu?app_id=" + APPID + addqueryTxt + "&availability=" + cookie.load("defaultAvilablityId")).then((res) => {
      this.setState({ navStatus: res.data.status })
      this.setState({ common: res.data.common })
      this.setState({ category: res.data.result_set })
    })
  }

  closeDeliveryErr() {
    this.setState({ load: "false" })
    $("#DeliveryError").modal("toggle")
  }

  /* Open Zipcode popup */
  changeDelivery() {
    this.setState({ load: "false" })
    $("#DeliveryError").modal("hide")
    if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
      $("#AddressList").modal({ backdrop: "static", keyboard: false })
    } else {
      $("#DeliveryAddress").modal({ backdrop: "static", keyboard: false })
    }
  }

  handleChange(event) {
    this.setState({ addressField: event.target.value })
  }

  handleChangeValue(event) {
    this.setState({ addressField: event.target.value })
  }

  /* Validate zipcode */
  validatezip(e) {
    const re = /[0-9A-F:]+/g
    if (!re.test(e.key)) {
      e.preventDefault()
    }
  }

  /* delivery timing */
  showDeliveryTiming() {
    if (APPID === apps.leanbento) {
      return (
        <p>
          <b>Your Order is handled by:</b>
          <br /> {this.state.orderHandled}
        </p>
      )
    }
  }

  /* used to get all secondary address list */
  getSecAddr() {
    console.log("safsf")
    axios.get(APIURL + "/customer/get_user_secondary_address?app_id=" + APPID + "&refrence=" + cookie.load("UserId")).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ secAddrData: res.data.result_set })
      }
    })
  }

  /* used to load address list */
  loadAddressList() {
    return this.state.secAddrData.map((item, index) => (
      <p key={index}>
        <input name="radio" value={item.postal_code} id={item.secondary_address_id} className="css-radio" type="radio" onChange={this.handleChange} />
        <label htmlFor={item.secondary_address_id} className="css-label ">
          {" "}
          {item.address + " Singapore " + item.postal_code}
        </label>
      </p>
    ))
  }

  /* load outlets */
  __loadOutlets() {
    if (this.state.Outlets) {
      let list = helper.getSortedList(this.state.Outlets, this.props.coords)
      return (
        !isEmpty(list) &&
        list.map((loaddata, index) => (
          <div
            className="map-li "
            key={index}
            onClick={this.pickOutlet.bind(
              this,
              loaddata.outlet_id,
              loaddata.outlet_name,
              loaddata.outlet_postal_code,
              loaddata.outlet_address_line1,
              loaddata.outlet_address_line2,
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2,
              loaddata.outlet_pickup_tat,
              loaddata.outlet_tax_option,
              loaddata.outlet_tax_surcharge,
              loaddata.outlet_tax_surcharge_inclusive
            )}
          >
            <p className="map-h outlet-name">{helper.stripslashes(loaddata.outlet_name)}</p>
            <p className="map-sub">{loaddata.outlet_address_line1 + ", " + loaddata.outlet_address_line2 + " " + loaddata.outlet_postal_code}</p>
            <Distancebar minDateValue={loaddata.min_date} isOutletBased={loaddata.is_outlet_based} distance={loaddata.distance} coords={this.props.coords} tat={loaddata.outlet_pickup_tat ? loaddata.outlet_pickup_tat : loaddata.outlet_delivery_timing} />
          </div>
        ))
      )
    }
  }

  /* pick outlet */
  pickOutlet(outlet_id, outlet_name, outlet_postal_code, outlet_address_line1, outlet_address_line2, outlet_unit_number1, outlet_unit_number2, outlet_pickup_tat, outlet_tax_option, outlet_tax_surcharge, outlet_tax_surcharge_inclusive) {
    var orderHandled = outlet_name + " " + outlet_address_line1 + " " + outlet_address_line2 + ", Singapore " + outlet_postal_code

    this.setState({ orderHandled: orderHandled })
    cookie.save("orderOutletId", outlet_id)
    cookie.save("orderOutletName", outlet_name)
    cookie.save("orderPostalCode", outlet_postal_code)
    cookie.save("orderTAT", outlet_pickup_tat)
    cookie.save("orderHandled", orderHandled)
    cookie.save("orderHandledByText", orderHandled)

    cookie.save("outlet_tax_option", outlet_tax_option)
    cookie.save("outlet_tax_surcharge", outlet_tax_surcharge)
    cookie.save("outlet_tax_surcharge_inclusive", outlet_tax_surcharge_inclusive)

    $("#Pickup-OutletSelection").modal("hide")
    $("#Pickup-Sucess").modal({ backdrop: "static", keyboard: false })
  }

  /* Select outlet */
  selectOutlet(thisobject, retry = false, callbackPostalCode, addressObj) {
    var postalcode = ""
    let address = ""
    let googleRetryAttempt = 0
    if (retry === false) {
      if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
        postalcode = $("#postalcode").val() //"postalcode";
      } else {
        postalcode = $("#postalcode1").val() // "postalcode1";
      }
    } else {
      postalcode = callbackPostalCode
      address = addressObj
      googleRetryAttempt = 1
    }

    this.setState({ load: "true" })
    var findPostUrl = APIURL + "outlets/find_outlet?skip_timing=Yes&app_id=" + APPID + "&availability_id=" + cookie.load("defaultAvilablityId") + "&postal_code=" + postalcode
    if (parseInt(this.state.zoneEnabled) === 1) {
      findPostUrl =
        API_v4_URL + "test/outlets/findOutletZone?skip_timing=Yes&app_id=" + APPID + "&availability_id=" + cookie.load("defaultAvilablityId") + "&postal_code=" + postalcode + "&outlet_id=" + this.state.zoneDeliveryOutlet + "&googleRetryAttempt=" + googleRetryAttempt + "&addressObject=" + address
    }
    /*add loading */
    axios.get(findPostUrl).then((res) => {
      /* Success response */
      /* Success response  && Object.keys(res.data.result_set.postal_code_information).length !==0 */
      if (res.data.status === "ok") {
        var orderDeliveryAddress = res.data.result_set.postal_code_information.zip_buno + " " + res.data.result_set.postal_code_information.zip_sname
        var orderHandled = res.data.result_set.outlet_name + ", Crew will be seeing you in " + res.data.result_set.outlet_delivery_timing + " Minutes"
        this.setState({ orderDeliveryAddress: orderDeliveryAddress + " Singapore " + res.data.result_set.postal_code_information.zip_code })
        this.setState({ orderHandled: orderHandled })
        cookie.save("orderOutletId", res.data.result_set.outlet_id)
        cookie.save("orderOutletName", res.data.result_set.outlet_name)
        cookie.save("orderPostalCode", res.data.result_set.postal_code_information.zip_code)
        cookie.save("orderTAT", res.data.result_set.outlet_delivery_timing)
        cookie.save("orderDeliveryAddress", orderDeliveryAddress)
        cookie.save("orderHandled", orderHandled)

        cookie.save("outlet_tax_option", res.data.result_set.outlet_tax_option)
        cookie.save("outlet_tax_surcharge", res.data.result_set.outlet_tax_surcharge)
        cookie.save("outlet_tax_surcharge_inclusive", res.data.result_set.outlet_tax_surcharge_inclusive)

        var orderHandledText = res.data.result_set.outlet_name + " " + res.data.result_set.outlet_address_line1 + " " + res.data.result_set.outlet_address_line2 + ", Singapore " + postalcode
        cookie.save("orderHandledByText", orderHandledText)

        if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
          $("#AddressList").modal("hide")
        } else {
          $("#DeliveryAddress").modal("hide")
        }
        $("#DeliverySucess").modal("show")
        $("#address-auto").modal("hide")
        if (parseInt(this.state.zoneEnabled) === 1) {
          cookie.save("orderZoneID", res.data.result_set.zone_id)
        }
      }

      /* Error response */
      if (res.data.status === "error") {
        this.setState({ load: "false" })

        /* check google api result */
        let google_api_result = false
        if (typeof res.data.not_fount_in_google !== "undefined" && res.data.not_fount_in_google === true) {
          this.setState({ enableAddressSearch: true })
          google_api_result = true
        } else {
          this.setState({ enableAddressSearch: false })
        }
        if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
          $("#AddressList").modal("hide")
        } else {
          $("#DeliveryAddress").modal("hide")
        }
        if (google_api_result === false) {
          $("#address-auto").modal("hide")
          $("#DeliveryError").modal("show")
        } else {
          $("#address-auto").modal("show")
        }
      }
    })
  }

  openOrderTupeChangeFun() {
    if (cookie.load("applyOutletId") === "yes") {
      if (cookie.load("defaultAvilablityId") === config.ids.deliveryId && cookie.load("defaultAvilablity") === lang.delivery) {
        cookie.save("dineinClick", "no")
        if (typeof cookie.load("orderOutletId") === "undefined" || typeof cookie.load("orderPostalCode") === "undefined") {
          if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
            if (this.state.zoneEnabled === 1) {
              if (parseInt(this.state.zonePopupDisable) === 1) {
                $("#AddressList").modal({ backdrop: "static", keyboard: false })
              } else {
                $("#delivery_outlet").modal({ backdrop: "static", keyboard: false })
              }
            } else {
              $("#AddressList").modal({ backdrop: "static", keyboard: false })
            }
          } else {
            if (this.state.zoneEnabled === 1) {
              if (parseInt(this.state.zonePopupDisable) === 1) {
                $("#DeliveryAddress").modal({ backdrop: "static", keyboard: false })
              } else {
                $("#delivery_outlet").modal({ backdrop: "static", keyboard: false })
              }
            } else {
              $("#DeliveryAddress").modal({ backdrop: "static", keyboard: false })
            }
          }
        }
      } else if (cookie.load("defaultAvilablityId") === config.ids.pickupId && cookie.load("defaultAvilablity") === lang.takeaWay) {
        cookie.save("dineinClick", "no")

        var pickupOutlets = this.state.Outlets

        if (typeof cookie.load("orderOutletId") === "undefined" || typeof cookie.load("orderPostalCode") === "undefined") {
          if (this.state.Outlets.length === 0) {
            this.getpickupOutletlist()
          } else if (this.state.Outlets.length === 1) {
            var orderHandled = pickupOutlets[0].outlet_name + " " + pickupOutlets[0].outlet_address_line1 + " " + pickupOutlets[0].outlet_address_line2 + ", Singapore " + pickupOutlets[0].outlet_postal_code

            this.setState({ orderHandled: orderHandled })
            cookie.save("orderOutletId", pickupOutlets[0].outlet_id)
            cookie.save("orderOutletName", pickupOutlets[0].outlet_name)
            cookie.save("orderPostalCode", pickupOutlets[0].outlet_postal_code)
            cookie.save("orderTAT", pickupOutlets[0].outlet_pickup_tat)
            cookie.save("orderHandled", orderHandled)
            cookie.save("orderHandledByText", orderHandled)

            cookie.save("outlet_tax_option", pickupOutlets[0].outlet_tax_option)
            cookie.save("outlet_tax_surcharge", pickupOutlets[0].outlet_tax_surcharge)
            cookie.save("outlet_tax_surcharge_inclusive", pickupOutlets[0].outlet_tax_surcharge_inclusive)

            cookie.save("outlet_option_single", 1)

            $("#pickup-outlet-sucess").modal("toggle")
          } else {
            $("#Pickup-OutletSelection").modal({ backdrop: "static", keyboard: false })
          }
        }
      } else if (cookie.load("defaultAvilablityId") === config.ids.dineinId && cookie.load("defaultAvilablity") === "Dine In") {
        if (typeof cookie.load("orderOutletId") === "undefined" || typeof cookie.load("orderTableNo") === "undefined" || cookie.load("orderOutletId") === "" || cookie.load("orderTableNo") === "") {
          cookie.save("dineinClick", "yes")
          cookie.remove("orderTableNo")
          if (typeof cookie.load("UserId") === "undefined" || (cookie.load("UserId") && cookie.load("UserId") === "")) {
            hashHistory.push("/sign-in")
          } else {
            if (APPID === "287E5552-328E-4DBE-A3CA-E998ACCAC9BD") {
              helper.sarveshTableSetup()
            } else {
              hashHistory.push("/book-a-table")
            }
          }
        }
      }
    }
  }

  gobackHome() {
    hashHistory.push("/")
  }

  /* this fucntion used to load  product tags  */
  showTags(tags) {
    //console.log(tags);
    if (tags !== null) {
      var splited = tags.split(",")
      var tagsText = ""

      for (var i = 0, length = splited.length; i < length; i++) {
        tagsText += "<span className='label label-success text-uppercase'>" + splited[i] + "</span>"
        //tagsText+={"<p>"};
      }
      tagsText = '<p className="tags">' + tagsText + "</p>"
      var htmlToReactParser = new HtmlToReactParser()
      var reactElement = htmlToReactParser.parse(tagsText)
      //var reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);

      return reactElement //assert.equal(reactHtml, htmlInput);

      //return tagsText;
    }
  }

  getOptions(input) {
    var orderOutletIdtext = cookie.load("orderOutletId")
    var applyOutletIdtext = cookie.load("applyOutletId")
    var addquery_txt = ""

    if (applyOutletIdtext === "yes") {
      if (typeof orderOutletIdtext !== "undefined" && orderOutletIdtext !== "") {
        addquery_txt = "&outletId=" + orderOutletIdtext + "&applyOutletId=yes"
      }
    }

    var searchResult = []
    return axios.get(APIURLPRO + "/products/products_search?app_id=" + APPID + "&availability=" + cookie.load("defaultAvilablityId") + addquery_txt + "&search_value=" + input).then((response) => {
      if (response.data.status === "ok") {
        var resultData = response.data.result_set
        resultData.map((loadData) => searchResult.push({ value: loadData.product_slug, label: strp.stripslashes(helper.getAliasName(loadData.product_alias, loadData.product_name)) }))
      }

      return { options: searchResult }
    })
  }

  /* ViewProducts */
  ViewProducts(event) {
    var productSlug = event.value
    hashHistory.push("/product/" + productSlug)
  }

  /*this function used to shoe product thump image /
	__productImage(product_thumbnail,imagePath){
	   return  (product_thumbnail === "" )? config.noImages.product_thumbnail : config.path.tinThumpUrl+this.state.products_common.image_source+"/"+product_thumbnail+"&w=288&h=144&q=80";
	}
	 */

  showZonalPostalCode(outletId) {
    this.setState({ zoneOutletSeleted: "Yes" })
    $("#delivery_outlet").modal("toggle")
    if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
      $("#AddressList").modal("toggle")
    } else {
      $("#DeliveryAddress").modal("toggle")
    }

    this.setState({ zoneDeliveryOutlet: outletId })
  }
  __loadDeliveryOutlets() {
    if (this.state.deliveryOutlets) {
      let list = helper.getSortedList(this.state.deliveryOutlets, this.props.coords)
      if (!isEmpty(list)) {
        return list.map((loaddata, index) => (
          <div className="map-li" key={index} onClick={this.showZonalPostalCode.bind(this, loaddata.outlet_id)}>
            <p className="map-h outlet-name">{helper.stripslashes(loaddata.outlet_name)}</p>
            <p className="map-sub">
              {this.showNewAddressFrmd(loaddata.outlet_postal_code, loaddata.outlet_address_line1, "", "", "")} {loaddata.outlet_unit_number2}
            </p>
            <Distancebar minDateValue={loaddata.min_date} isOutletBased={loaddata.is_outlet_based} distance={loaddata.distance} coords={this.props.coords} tat={loaddata.outlet_delivery_tat ? loaddata.outlet_delivery_tat : loaddata.outlet_delivery_timing} />
          </div>
        ))
      } else {
        return <Outletclosedmessage messages={this.state.outletCloseddMessage} />
      }
    }
  }
  backToDeliveryBack() {
    $("#DeliveryAddress").modal("toggle")
    $("#delivery_outlet").modal("toggle")
  }

  showZonalBackButton() {
    if (parseInt(this.state.zoneEnabled) === 1 && this.state.zonePopupDisable === 0) {
      return (
        <div className="col-sm-6 popup-back-btn">
          <button type="button" onClick={this.backToDeliveryBack.bind(this)} className="btn btn-raised btn-info ">
            Go Back
          </button>
        </div>
      )
    }
  }
  showNewAddressFrmd(pstcode, address1, address2, unittxt1, unittxt2) {
    var addressLn = address2 != "" ? address1 + ", " + address2 : address1
    if (unittxt1 != "" && unittxt2 != "") {
      addressLn = addressLn + ", #" + unittxt1 + "-" + unittxt2
    } else if (unittxt1 != "" || unittxt2 != "") {
      addressLn = unittxt1 != "" ? addressLn + ", #" + unittxt1 : addressLn + ", #" + unittxt2
    }

    addressLn = addressLn + ", Singapore " + pstcode
    return addressLn
  }
  render() {
    var sliderCount = 5
    const windowWidth = window.innerWidth
    if (windowWidth <= 768 && windowWidth > 767) {
      sliderCount = 3
    } else if (windowWidth <= 767 && windowWidth > 479) {
      sliderCount = 3
    } else if (windowWidth <= 479) {
      sliderCount = 3
    }

    var settingsGallery = {
      infinite: false,
      arrows: true,
      slidesToShow: sliderCount,
      slidesToScroll: sliderCount,
      speed: 500,
      swipe: sliderCount,
      initialSlide: this.state.goto,
      ref: (ref) => (this.slider = ref),
      afterChange: () => this.setState((state) => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }

    if (this.state.status === "Loading") {
      return (
        <div className="container-fluid desktop-container position-rel" style={{ minHeight: this.state.windowHeightNormal }}>
          <div className="row">
            <Loading />
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <LocationSearchInput loading={this.state.status} open={this.state.enableAddressSearch} parentCallback={this.selectOutlet} postalcode={this.state.addressField} />
          <DocumentMeta {...meta} />

          <div id="loadIcon" style={{ display: "none", bottom: 0, left: 0, position: "fixed", right: 0, top: 0, margin: "auto", zIndex: 99, background: "rgba(255, 255, 255, 0.8)" }}>
            {" "}
            <img style={{ bottom: 0, left: 0, position: "absolute", right: 0, top: 0, margin: "auto" }} src="/img/loader.gif" alt="Loading" />{" "}
          </div>
          <div className="header-sticky">
            <div className="">
              <div className="container-fluid desktop-container">
                <div className="row">
                  <div className="col-xs-12 top-nav inner-top-nav head-main">
                    <div className="col-xs-2 nav-head pd-l-20 head-child">
                      {" "}
                      <Link to={"/"} className="backarrow">
                        <img src="/img/icons/arrow-right.png" className="left_arr icon-img-small-3" alt="left-arrow" />
                      </Link>
                    </div>

                    <div className="col-sm-6 col-xs-4 nav-head head-child"></div>
                    <div className="col-sm-4 col-xs-6 nopadding head-child">
                      <p className="b-txt text-right ">
                        {config.enablescan === "Yes" && (
                          <a href="https://scan.ninjapro.co" className="scan-icon-a">
                            <img alt="" src="/img/icons/Scan-to-add-btn.png" className="icon-img-small-scan head_serch" />
                          </a>
                        )}

                        <Link data-toggle="collapse" to={"#SearchBAR"} aria-expanded="false" aria-controls="collapseExample" className="headsearch-icon">
                          <img alt="" src="/img/icons/search-new.png" className="icon-img-small4 head_serch" />
                        </Link>
                        <Link to={"/cart"} className={helper.cartClass()}>
                          {" "}
                          {helper.cartCount()} <img alt="" src={this.loadCartImage()} className="icon-img-small-1 carticon" />{" "}
                        </Link>
                        {config.enablescan === "Yes" && (
                          <div className="scan-tooltip">
                            Click here to Scan QR Code &amp; Add to Cart{" "}
                            <a onClick={this.closeTooltip.bind(this)}>
                              <span>
                                <i className="material-icons">close</i>
                              </span>
                            </a>
                          </div>
                        )}
                      </p>
                    </div>
                    <div className="collapse" id="SearchBAR">
                      <div className="">
                        <div className="form-group SearchBAR">
                          <div className="input-group">
                            <Select.Async
                              name="form-field-name"
                              placeholder={APPID == "68009D56-9386-4ECE-92D8-6F81E11B8742" ? "what are you in the mood for today" : "Go ahead search for a dish..."}
                              noResultsText="No Products Found"
                              autoload={false}
                              loadOptions={this.getOptions.bind(this)}
                              onChange={this.ViewProducts.bind(this)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid desktop-container fixedheader-container" style={{ minHeight: this.state.windowHeightNormal }}>
            <Alert />
            <div id="parentDisable"></div>

            <div className="row">
              <div className="product-banner">
                <img alt="" src={this.state.categoryImSrc} className="img-responsive" />
              </div>
            </div>

            {/* Category Slider */}
            <div className="category_sticky">
              <Sticky topOffset={80} bottomOffset={80}>
                <div className="category_slider scrollmain">
                  <Slider ref={(slider) => (this.slider = slider)} {...settingsGallery}>
                    {this.menuListing()}
                  </Slider>
                </div>
              </Sticky>
            </div>
            {/* Category Slider End */}

            {/* listing part */}
            {this.__categoryList()}

            {/*this.menuList()*/}

            {/* <div className="row">
                            <div style={{height:'86px'}}></div>
                        
                            <div className="row price-sec-btm clearfix">
                                <div className="col-md-6 col-sm-6 col-xs-6">
                                    <span className="total-txt01">Total</span>
									<span className="total-price-01"><sup>$</sup>{cookie.load("cart_grand_total")}</span>
									<span className="include-delivery-txt">{this.state.gstTaxText}</span>
									
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-6 ">
                                 <a className="check-out-btn-btm" href="/#/cart">Checkout</a>
                                </div>
                               
                            </div>
                            
                            
                       
                    </div>  */}
          </div>
          {cookie.load("cartValue") === "Yes" && (
            <div className="category-footer">
              <div className="container-fluid desktop-container">
                <p className="carttotal">
                  <span>{parseInt(cookie.load("cartCount"))} Item (s) in Cart</span>
                  <label>
                    <sup>$</sup>
                    {cookie.load("cart_grand_total")}
                  </label>
                </p>
                <div className="checkout-button">
                  <button
                    onClick={() => {
                      hashHistory.push("/cart")
                    }}
                  >
                    Checkout
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* For takeaway popup */}

          <div className="modal common-distance" id="Pickup-OutletSelection">
            <div className="modal-dialog locWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" onClick={this.gobackHome.bind(this)} data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                  </button>
                  <h4 className="modal-title">Select Your Pickup Location</h4>
                </div>

                <div className="modal-body">
                  <div className="map-loc">
                    <div className="map-box scrollbar" id="style-3">
                      {this.__loadOutlets()}
                    </div>

                    <br />
                    <br />
                  </div>
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* For takeaway popup - end */}

          {/* For Pickup Success */}

          <div className="modal" id="Pickup-Sucess">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">
                    <img src="/img/icons/DeliverySucess.gif" className="img-responsive" alt="" />
                    Awesome, you can pickup !
                  </h4>
                </div>
                <div className="modal-body text-center">
                  {this.showDeliveryTiming}
                  <br />
                  <button type="button" onClick={this.pickupSuccess.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          <div className="modal" id="pickup-outlet-sucess">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/DeliverySucess.gif" className="img-responsive" alt="" />
                    Awesome, you can pickup !
                  </h4>
                  <p>{cookie.load("orderHandled")}</p>
                </div>
                <div className="modal-body text-center">
                  {this.showDeliveryTiming}
                  <br />
                  <button type="button" onClick={this.pickupSuccess.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* For Pickup Success - end */}

          {/* For delivery popup */}

          <div className="modal" id="DeliveryAddress">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" onClick={this.gobackHome.bind(this)} data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                  </button>
                  <h4 className="modal-title">Your Delivery Address</h4>
                </div>
                <div className="modal-body">
                  <div className="form-group label-floating is-empty card-no">
                    <div className="input-group">
                      <label className="control-label">Enter Your Postal Code</label>
                      <input value={this.state.addressField} id="postalcode1" maxLength="6" className="form-control" onKeyPress={(e) => this.validatezip(e)} onChange={this.handleChangeValue} />
                    </div>
                  </div>

                  {/*	<button type="button" onClick={this.selectOutlet.bind(this)}  className="btn btn-raised btn-info ">Continue</button>
                   */}
                  <br />
                  <br />

                  {this.showZonalBackButton()}
                  <div className={parseInt(this.state.zoneEnabled) === 1 && this.state.zonePopupDisable === 0 ? "col-sm-6 popup-back-btn" : "col-sm-12"}>
                    <button type="button" onClick={this.selectOutlet.bind(this)} className="btn btn-raised btn-info ">
                      Continue
                    </button>
                  </div>
                  {/*<button type="button" onClick={this.selectOutlet.bind(this)}  className="btn btn-raised btn-info ">Continue</button>
                   */}
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* delivery popup - end */}

          <div className="modal common-distance" id="delivery_outlet">
            <div className="modal-dialog locWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                  </button>
                  <h3 className="modal-title">
                    Please select which outlet is nearest to your
                    <br />
                    delivery location{" "}
                  </h3>
                </div>
                {/*	<Overlay load={this.state.load} /> */}
                <div className="modal-body">
                  <div className="map-loc">
                    {/* <Map markers={this.state.markers} /> */}

                    <div className="map-box scrollbar" id="style-3">
                      {this.__loadDeliveryOutlets()}
                    </div>

                    <br />
                    <br />
                  </div>
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* delivery popup after login user */}

          <div className="modal" id="AddressList">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" onClick={this.gobackHome.bind(this)} data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/DeliverySucess.gif" className="img-responsive" alt />
                    Your Delivery Address
                  </h4>
                </div>
                <div className="modal-body" style={{ textAlign: "left" }}>
                  {this.loadAddressList()}

                  <p>
                    <input value={this.state.addressField} onChange={this.handleChangeValue} id="postalcode" maxLength="6" placeholder="Enter Your Postal Code" className="add_list" onKeyPress={(e) => this.validatezip(e)} />
                  </p>
                  <br />

                  <button type="button" onClick={this.selectOutlet.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                  <br />
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* delivery popup after login popup - end */}

          {/* For Delivery Success */}

          <div className="modal" id="DeliverySucess">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">
                    <img src="/img/icons/DeliverySucess.gif" className="img-responsive" alt="thumb-up" />
                    Awesome, we can deliver!
                  </h4>
                </div>
                <div className="modal-body text-center">
                  <p>
                    <b>Your Delivery Address: </b>
                    <br />
                    {this.state.orderDeliveryAddress}
                  </p>
                  {this.showdeliveryTiming}
                  <br />
                  <button type="button" onClick={this.deliverySuccess.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* For Delivery Success - end */}

          {/* For Delivery error */}

          <div className="modal" id="DeliveryError">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" onClick={this.gobackHome.bind(this)} data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/Cant_Deliver.png" className="img-responsive" alt="" />
                    Sorry !
                  </h4>
                </div>
                <div className="modal-body">
                  <p>
                    Sorry we cant deliver to your address <br />
                    Do you want to change your address or check again later ?
                  </p>
                  <br />
                  <div className="col-sm-3">&nbsp;</div>
                  <div className="col-sm-6">
                    <button type="button" onClick={this.changeDelivery.bind(this)} className="col-lg-6 btn btn-raised btn-info fullwidth ">
                      Change Address
                    </button>
                  </div>
                  <div className="col-sm-3">&nbsp;</div>
                  <br />
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* For Delivery error - end */}
        </div>
      )
    } /* else {

			return (
				<div>
					<DocumentMeta  {...meta} />
					<div id="loadIcon" style={{ display: 'none', bottom: 0, left: 0, position: 'fixed', right: 0, top: 0, margin: 'auto', zIndex: 99, background: 'rgba(255, 255, 255, 0.8)' }} > <img style={{ bottom: 0, left: 0, position: 'absolute', right: 0, top: 0, margin: 'auto' }} src="/img/loader.gif" alt="Loading" /> </div>
					<div className="container-fluid desktop-container" style={{ minHeight: this.state.windowHeightNormal }}>
						<div id="parentDisable"></div>
						<div className="row">
							<div className="col-xs-12 top-nav inner-top-nav">
								<div className="col-xs-3 nav-head pd-l-20">
									<Link to="/">  <img alt="" src="/img/icons/arrow-right.png" className="icon-img-small-3" /></Link>
								</div>

								<div className="col-xs-6 nav-head ">
									<h1 className="main-title">{lang.no_products_found} </h1>
								</div>



							</div>
						</div>



		 
						{this.menuList()}



					</div>



				</div>);
		} */
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Products)

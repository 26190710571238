/* eslint-disable */
import React from "react"
import { loadAnalytics } from "./withTracker"
import { Link, hashHistory } from "react-router"
import ThemeProvider from "react-toolbox/lib/ThemeProvider"
import theme from "./react-toolbox/theme.js"
import "./react-toolbox/theme.css"
import DatePicker from "react-toolbox/lib/date_picker/DatePicker"
import DocumentMeta from "react-document-meta"
import cookie from "react-cookie"
import getResult from "./Api"
import moment from "moment"
// import TimePicker from "rc-time-picker"
import axios from "axios"

import lang from "./lang"
const apps = require("./apps")
import config from "./config"
const APPID = config.ids.appId
const APIURL = config.path.apiUrl
const APIURLV2 = config.path.apiUrlv2
const APIURLPRO = config.path.apiUrlPro
import "rc-time-picker/assets/index.css"

var helper = require("./Helper.js")
var dateFormat = require("dateformat")

var HtmlToReactParser = require("html-to-react").Parser
var tat = cookie.load("orderTAT") === "" ? 0 : cookie.load("orderTAT")
var htmlToReactParser = new HtmlToReactParser()

/* Load meta tags */
const meta = {
  title: lang.choose_delvery_date,
  description: lang.site_title,
  meta: {
    name: {
      keywords: lang.site_title,
    },
  },
}
var Product_LeadTime = 0
var TodayDate = new Date()
var autoFillDate = TodayDate
var getDate = TodayDate.getDate()
var min_datetime = new Date(new Date(TodayDate).setDate(getDate - 1))
var max_datetime = new Date(new Date(TodayDate).setDate(getDate))
var weekdayArr = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
class Advancedtimeslot extends React.Component {
  constructor(props) {
    super(props)

    /* check Authentication */
    if (getResult("Auth") === false) {
      hashHistory.push("/sign-in")
    }

    /* validate delivery avilablity */
    if (cookie.load("defaultAvilablityId") === config.ids.dineinId || typeof cookie.load("defaultAvilablityId") === "undefined" || cookie.load("defaultAvilablityId") === "") {
      hashHistory.push("/")
    }

    this.state = {
      settings: [],
      date: autoFillDate,
      selectTime: "",
      selectMins: "",
      disableDate: [],
      cutOfftime: "",
      cutoffnextday: "",
      cutofNextDayReached: 0,
      intervalTime: "",
      min_datetime: min_datetime,
      max_datetime: max_datetime,
      timeText: "Time (HH/MM)",
      timeHtml: "",
      timeExceed: "No",
      time_slot_type: "1",
      TimeSlotHtmlVal: "",
    }

    this.changeTimer = this.changeTimer.bind(this) //changeHrs
    this.changeMins = this.changeMins.bind(this) //changeMins

    if (typeof cookie.load("UserId") === "undefined") {
      var customerParam = "&reference_id=" + getResult("referenceId")
    } else {
      var customerParam = "&customer_id=" + cookie.load("UserId")
    }
    var postalCodeWhere = ""
    if (typeof cookie.load("orderPostalCode") !== "undefined") {
      postalCodeWhere = "&postal_code=" + cookie.load("orderPostalCode")
    }

    axios.get(APIURL + "cartver2/contents?status=A&app_id=" + APPID + customerParam + postalCodeWhere).then((res) => {
      if (res.data.status === "ok") {
        if (typeof res.data.result_set !== "undefined") {
          Product_LeadTime = parseInt(res.data.result_set.product_lead_time)
          this.getsettings()
        }
      }
    })
  }

  getsettings() {
    var promise = helper.getProSettings("", "No") //getResult('settings','');
    promise.then((res) => {
      this.setState({ settings: res.data.result_set })

      /* find current tat value */
      axios.get(APIURL + "outlets/gettat_details?app_id=" + APPID + "&availability_id=" + cookie.load("defaultAvilablityId") + "&outlet_id=" + cookie.load("orderOutletId")).then((res) => {
        if (res.data.status === "ok") {
          if (res.data.result_set !== "" && res.data.result_set !== null) {
            cookie.save("orderTat", res.data.tat)
            this.setState({ serverTat: res.data.tat }, function () {
              this.getTimesettingArrFun()
            })
          }

          // this.mainDateTimeFun();
        } else {
          helper.showInfo("Your selected outlet currently closed or disabled by an administrator. Please choose another outlet.")
          hashHistory.push("/")
        }
      })
      /* find current tat value - End  */

      /* Holiday  - start */
      /* var DisableDats = [];
      if (
        parseInt(res.data.result_set.client_holiday_enable) === 1 &&
        cookie.load("defaultAvilablityId") !== config.ids.dineinId
      ) {
        axios
          .get(
            APIURLPRO +
              "settings/get_holidaylist?app_id=" +
              APPID +
              "&availability=" +
              cookie.load("defaultAvilablityId") +
              "&outlet_id=" +
              cookie.load("orderOutletId")
          )
          .then((res) => {
            if (res.data.status === "ok") {
              
              var resultData = res.data.result_set;

              resultData.map((loadData) =>
                DisableDats.push(this.parseDate(loadData.date))
              );
              this.setState({
                hollidayList: resultData,
                allowHoliDay: "Yes",
                disableDate: DisableDats,
              });
            }
          });
      } */
    })
  }

  /* mainDateTimeFun() {
    axios
      .get(
        APIURL +
          "settings/deliverytime_slotminmax/?app_id=" +
          APPID +
          "&availability=" +
          cookie.load("defaultAvilablityId") +
          "&outlet_id=" +
          cookie.load("orderOutletId")
      )
      .then((res) => {
        if (res.data.status === "ok") {
          if (typeof res.data.result_set !== "undefined") {
            var result = res.data.result_set[0];
            var mindatetime = result.minimum_date;
            if (Product_LeadTime > 0) {
              if (mindatetime < Product_LeadTime) {
                mindatetime = Product_LeadTime;
              } else {
                mindatetime = mindatetime;
              }
            }
            mindatetime = parseInt(mindatetime) + parseInt(getDate);
            var currentDate = new Date(
              new Date(TodayDate).setDate(mindatetime)
            );

            min_datetime = new Date(
              new Date(TodayDate).setDate(mindatetime - 1)
            );
            var totalDays =
              parseInt(min_datetime.getDate()) +
              parseInt(1) +
              parseInt(Product_LeadTime) +
              parseInt(result.maximum_date);
            var max_datetime = new Date(new Date(TodayDate).setDate(totalDays));
            var cutOfftime =
              result.cut_off !== "" ? parseInt(result.cut_off) : "";
            this.setState(
              {
                min_datetime: min_datetime,
                max_datetime: max_datetime,
                date: currentDate,
                cutOfftime: cutOfftime,
              },
              function () {
                this.getTimesettingArrFun();
              }
            );
          } else {
            this.setState({ timeExceed: "Yes" });
          }
        } else {
          this.setState({ timeExceed: "Yes" });
        }
      });
  } */

  /*  getStartDate(mindatetime) {
    var startDate = new Date(new Date(TodayDate).setDate(mindatetime));
    var i = 0;
    if (this.state.disableDate !== "") {
      var current = this;
      this.state.disableDate.map(function (item, index) {
        var existDate =
          startDate.getDate() +
          "-" +
          startDate.getMonth() +
          "-" +
          startDate.getFullYear();
        var checkDate =
          item.getDate() + "-" + item.getMonth() + "-" + item.getFullYear();
        if (existDate == checkDate) {
          var newDate = new Date(
            new Date(TodayDate).setDate(parseInt(item.getDate()) + 1)
          );
          i++;
          current.getStartDate(newDate);
        }
      });
    }
    if (i === 0) {
      return startDate;
    }
  } */

  getTimesettingArrFun() {
    var selectedDate = this.state.date
    var currentThis = this
    if (this.state.cutOfftime !== "") {
      selectedDate.setDate(selectedDate.getDate() + 1)

      var min_datetime = this.state.min_datetime
      min_datetime.setDate(min_datetime.getDate() + 1)

      var max_datetime = this.state.max_datetime
      max_datetime.setDate(max_datetime.getDate() + 1)
      this.setState({
        date: selectedDate,
        min_datetime: min_datetime,
        max_datetime: max_datetime,
      })
    }

    axios.get(APIURLV2 + "settings/availableDatesAdvanced/?app_id=" + APPID + "&availability_id=" + cookie.load("defaultAvilablityId") + "&outletId=" + cookie.load("orderOutletId") + "&tatTime=" + this.state.serverTat).then((tmres) => {
      $(".custom-date-label").removeClass("loader-main-cls")
      $(".custom-date-label").find(".loader-sub-div").remove()
      $(".slot_loading_sec").hide()
      if (tmres.data.status === "success") {
        var result = tmres.data.timeslot_data.result_set[0]
        this.setState({ time_slot_type: result.time_slot_type })
        var mindatetime = result.minimum_date
        if (Product_LeadTime > 0) {
          if (mindatetime < Product_LeadTime) {
            mindatetime = Product_LeadTime
          } else {
            mindatetime = mindatetime
          }
        }
        mindatetime = parseInt(mindatetime) + parseInt(getDate)
        var currentDate = new Date(new Date(TodayDate).setDate(mindatetime))

        min_datetime = new Date(new Date(TodayDate).setDate(mindatetime - 1))
        var totalDays = parseInt(min_datetime.getDate()) + parseInt(1) + parseInt(Product_LeadTime) + parseInt(result.maximum_date)
        var max_datetime = new Date(new Date(min_datetime).setDate(totalDays))
        var cutOfftime = result.cut_off !== "" ? parseInt(result.cut_off) : ""
        var cutoffnextday = result.cut_off_next_day !== "" ? parseInt(result.cut_off_next_day) : ""

        /* Holliday Disable Dates */
        var hollidayList = tmres.data.timeslot_data.holidayresult
        var DisableDats = []
        if (hollidayList !== "") {
          hollidayList.map(function (loadData) {
            var checkingDate = currentDate.getFullYear() + "-" + currentThis.padnewone(parseInt(currentDate.getMonth()) + 1) + "-" + currentThis.padnewone(currentDate.getDate())
            if (loadData == checkingDate) {
              currentDate = new Date(new Date(TodayDate).setDate(parseInt(currentDate.getDate()) + 1))
            }

            DisableDats.push(currentThis.parseDate(loadData))
          })
        }

        if (cutOfftime !== "") {
          var cutofMindate = parseInt(min_datetime.getDate()) + 1
          var currentDateTime = new Date()
          var currentTimeDetails = currentDateTime.getHours()
          var currentDateDetails = currentDateTime.getDate()
          if (cutofMindate === currentDateDetails && currentTimeDetails >= cutOfftime && result.time_slot_type == "2") {
            min_datetime = new Date(new Date(min_datetime).setDate(cutofMindate))
            currentDate = new Date(new Date(min_datetime).setDate(parseInt(cutofMindate) + 1))
          }
        }

        var slot = tmres.data.timeslot_data.result_set[0].slot
        for (let k = mindatetime; k <= totalDays; k++) {
          var addDates = new Date(new Date(TodayDate).setDate(k))
          var addDay = weekdayArr[addDates.getDay()]
          if (slot[addDay] === "" || slot[addDay] === undefined) {
            var checking_Date = currentDate.getFullYear() + "-" + currentThis.padnewone(parseInt(currentDate.getMonth()) + 1) + "-" + currentDate.getDate()
            var hideDate = addDates.getFullYear() + "-" + this.padnewone(parseInt(addDates.getMonth()) + 1) + "-" + this.padnewone(addDates.getDate())
            if (hideDate == checking_Date) {
              currentDate = new Date(new Date(TodayDate).setDate(parseInt(currentDate.getDate()) + 1))
            }

            DisableDats.push(this.parseDate(hideDate))
          }
        }

        if (hollidayList !== "") {
          hollidayList.map(function (loadData) {
            var checkingDate = currentDate.getFullYear() + "-" + currentThis.padnewone(parseInt(currentDate.getMonth()) + 1) + "-" + currentThis.padnewone(currentDate.getDate())

            if (loadData === checkingDate) {
              currentDate = new Date(new Date(TodayDate).setDate(parseInt(currentDate.getDate()) + 1))
            }
          })
        }

        this.setState({
          min_datetime: min_datetime,
          max_datetime: max_datetime,
          date: currentDate,
          forcutoffstart: currentDate,
          cutOfftime: cutOfftime,
          cutoffnextday: cutoffnextday,
          timeExceed: "No",
          hollidayList: hollidayList,
          /*   allowHoliDay: "Yes", */
          disableDate: DisableDats,
        })
        var daytxt = weekdayArr[currentDate.getDay()]
        if (result.time_slot_type === "1") {
          this.setState(
            {
              intervalTime: tmres.data.timeslot_data.result_set[0].interval_time,
              timeandsecArrayset: slot,
            },
            function () {
              this.timeDropDownFunction(daytxt)
            }
          )
        } else if (result.time_slot_type === "2") {
          this.setState(
            {
              timeText: "Choose Time slot",
              timeandsecArrayset: tmres.data.timeslot_data.result_set[0].slot,
            },
            function () {
              this.loadTimeSlotDropDown(daytxt)
            }
          )
        }
      } else {
        this.setState({ intervalTime: "" })
        this.setState({ timeandsecArrayset: [] })
        this.setState({ timeHtml: "" })
        this.setState({ minsHtml: "" })
        this.setState({ timeExceed: "Yes" })
      }
    })
  }

  timeDropDownFunction(daytxt, skiptime = 0) {
    var enable_advance_time_slot_cutof_time =
      this.state.settings.enable_advance_time_slot_cutof_time !== "" && typeof this.state.settings.enable_advance_time_slot_cutof_time !== undefined && typeof this.state.settings.enable_advance_time_slot_cutof_time !== "undefined" ? this.state.settings.enable_advance_time_slot_cutof_time : 0
    var currentthis = this
    var timeExceed = "No"
    var TimeHtml = ""
    var changeTmtxt = ""
    var checkTimeArr = []
    var timethis = this
    var tatval = this.state.serverTat
    var cutOfftime = this.state.cutOfftime
    var totdayDate = new Date()
    var addHrs = parseInt(totdayDate.getMinutes()) + parseInt(this.state.serverTat)
    totdayDate.setMinutes(addHrs)
    var selectDate = this.state.date.getDate() + "-" + this.state.date.getMonth() + "-" + this.state.date.getFullYear()
    var j = 0
    var slotArr = []
    var addtionalArray = []
    var startList,
      endList = ""
    var intervalTime = this.state.intervalTime
    if (this.state.timeandsecArrayset[daytxt] !== "" && this.state.timeandsecArrayset[daytxt] !== undefined) {
      var cut_of_Reached = 0
      this.state.timeandsecArrayset[daytxt].map(function (item) {
        var cutofNextDayReached = 0
        if (currentthis.state.cutofNextDayReached === 1 && cut_of_Reached === 0 && currentthis.state.cutoffnextday !== "" && enable_advance_time_slot_cutof_time === "1") {
          currentthis.setState({ cutofNextDayReached: 0 })
          cutofNextDayReached = 1
        }
        if (cutofNextDayReached === 0) {
          //Start Slot
          var slot_time1 = item.slot_time1.split(":")
          var startTimeVal = slot_time1.length > 0 ? slot_time1[0] : ""
          var startMinitVal = slot_time1.length > 0 ? slot_time1[1] : ""
          var strdatevalobj = new Date()
          strdatevalobj.setHours(startTimeVal)
          var stringrMinits = parseInt(startMinitVal) + parseInt(tatval)
          strdatevalobj.setMinutes(stringrMinits)
          startTimeVal = strdatevalobj.getHours()
          startMinitVal = strdatevalobj.getMinutes()

          //End Slot
          var endArr = item.slot_time2 !== "" ? item.slot_time2.split(":") : []
          var endTimeVal = endArr.length > 0 ? endArr[0] : ""
          var endMinitVal = endArr.length > 0 ? endArr[1] : ""
          var enddatevalobj = new Date()
          enddatevalobj.setHours(endTimeVal)
          var ingrendMinits = parseInt(endMinitVal) + parseInt(tatval)
          enddatevalobj.setMinutes(ingrendMinits)
          endTimeVal = enddatevalobj.getHours()
          endMinitVal = enddatevalobj.getMinutes()

          var checkDate = totdayDate.getDate() + "-" + totdayDate.getMonth() + "-" + totdayDate.getFullYear()
          var slotcutOff = 0
          if (item.slot_cutt_off !== "" && item.slot_cutt_off !== null) {
            var slot_cutt_off = item.slot_cutt_off.split(":")
            if (enable_advance_time_slot_cutof_time === "1") {
              if (parseInt(slot_cutt_off[0]) >= 0 && checkDate === selectDate) {
                if (totdayDate.getHours() >= slot_cutt_off[0]) {
                  slotcutOff = 1
                }
              }
            }
          }
          if (slotcutOff === 0) {
            if (startTimeVal !== "" && endTimeVal !== "") {
              for (let valueTime = startTimeVal; valueTime <= endTimeVal; valueTime++) {
                var todaycond = 0
                if (checkDate === selectDate) {
                  if (valueTime < totdayDate.getHours()) {
                    todaycond = 1
                  }
                }
                if (checkTimeArr.indexOf(valueTime) >= 0 && todaycond === 0) {
                  addtionalArray[timethis.padnewone(valueTime)] = strdatevalobj + "-" + enddatevalobj
                }
                if (checkTimeArr.indexOf(valueTime) === -1 && todaycond === 0) {
                  if (skiptime <= valueTime) {
                    if (j === 0) {
                      timethis.setState({ selectTime: valueTime })
                    }

                    if (startTimeVal == timethis.padnewone(valueTime)) {
                      startList = startMinitVal
                    } else {
                      startList = 0
                    }
                    if (endTimeVal == valueTime) {
                      endList = endMinitVal
                    } else {
                      endList = Math.round((60 / intervalTime - 1) * intervalTime)
                    }
                    if (startList > endList) {
                      endList = startList
                    }
                    slotArr[timethis.padnewone(valueTime)] = startList + "-" + endList
                    checkTimeArr.push(valueTime)
                    if (j === 0) {
                      timethis.setState({
                        selectTime: timethis.padnewone(valueTime),
                      })
                    }
                    var cutoffexist = 0

                    if (cutOfftime !== "" && cutOfftime < valueTime && timethis.state.date.getDate() == timethis.state.forcutoffstart.getDate()) {
                      cutoffexist = 1
                    }
                    if (cutoffexist === 0) {
                      TimeHtml += "<option value='" + timethis.padnewone(valueTime) + "'  >" + timethis.padnewone(valueTime) + "</option>"
                      j++
                    }
                  }
                }
              }
            }
          }
        }
        cut_of_Reached++
      })
    }
    if (this.state.cutoffnextday !== "" && enable_advance_time_slot_cutof_time === "1") {
      var check_Date = totdayDate.getDate() + "-" + totdayDate.getMonth() + "-" + totdayDate.getFullYear()
      if (check_Date === selectDate && this.state.cutoffnextday <= totdayDate.getHours()) {
        this.setState({ cutofNextDayReached: 1 })
      }
    }
    var timeDropDown = ""
    var currentselectdate = this.state.date
    if (TimeHtml !== "") {
      timeDropDown = htmlToReactParser.parse(TimeHtml)
    } else {
      timeExceed = "Yes"

      var min_datetime = new Date(new Date(this.state.date).setDate(currentselectdate.getDate()))

      this.setState({ min_datetime: min_datetime })
      var addonday = parseInt(currentselectdate.getDate()) + 1
      var cudate = new Date(new Date(currentselectdate).setDate(addonday))

      if (this.state.hollidayList !== "" && typeof this.state.hollidayList !== undefined && typeof this.state.hollidayList !== "undefined") {
        var currentThis = this
        this.state.hollidayList.map(function (loadData) {
          var checkingDate = cudate.getFullYear() + "-" + currentThis.padnewone(parseInt(cudate.getMonth()) + 1) + "-" + currentThis.padnewone(cudate.getDate())
          if (loadData == checkingDate) {
            cudate = new Date(new Date(cudate).setDate(parseInt(cudate.getDate()) + 1))
          }
        })
      }

      this.setState({ date: cudate }, function () {
        this.timeDropDownFunction(weekdayArr[cudate.getDay()])
      })
    }

    this.setState(
      {
        timeHtml: timeDropDown,
        minsHtml: "",
        slotArr: slotArr,
        addtionalArray: addtionalArray,
        timeExceed: timeExceed,
      },
      function () {
        if (TimeHtml !== "") {
          this.minsDropDownFunction()
        }
      }
    )
  }

  minsDropDownFunction() {
    var slotArr = this.state.slotArr
    var intervalTime = this.state.intervalTime
    var selectTime = this.state.selectTime
    var timeExceed = "No"
    var startTimeLimit = 0
    var totdayDate = new Date()

    var addHrs = parseInt(totdayDate.getMinutes()) + parseInt(this.state.serverTat)

    totdayDate.setMinutes(addHrs)

    var selectDate = this.state.date.getDate() + "-" + this.state.date.getMonth() + "-" + this.state.date.getFullYear()

    var checkDate = totdayDate.getDate() + "-" + totdayDate.getMonth() + "-" + totdayDate.getFullYear()
    var endTimeLimit = (60 / intervalTime - 1) * intervalTime
    var startTime_Limit = startTimeLimit
    var endTime_Limit = endTimeLimit
    var slotArray = []
    while (startTime_Limit <= endTime_Limit) {
      slotArray.push(startTime_Limit)
      startTime_Limit += parseInt(intervalTime)
    }

    var k = 0
    var htmlMins = ""
    if (slotArr !== "" && slotArr[selectTime] !== "" && typeof slotArr[selectTime] !== undefined) {
      var slotArrNew = slotArr[selectTime].split("-")

      var startTimeLimitNew = parseInt(slotArrNew[0])
      var endTimeLimitNew = parseInt(slotArrNew[1])
      var minArrayList = []
      var startSelect = ""
      var startcompleteTime = 0
      if (totdayDate.getHours() == selectTime && checkDate == selectDate) {
        if (totdayDate.getMinutes() > startTimeLimitNew) {
          startcompleteTime = 1
        }
      }
      var currentEnd = 0
      if (slotArray.indexOf(startTimeLimitNew) === -1 && startcompleteTime === 0) {
        htmlMins += "<option value='" + this.padnewone(startTimeLimitNew) + "'  >" + this.padnewone(startTimeLimitNew) + "</option>"
        currentEnd = startTimeLimitNew
        startSelect = startTimeLimitNew
      }
      while (startTimeLimit <= endTimeLimit) {
        let completeTime = 0
        if (totdayDate.getHours() == selectTime && checkDate == selectDate) {
          if (totdayDate.getMinutes() > startTimeLimit) {
            completeTime = 1
          }
        }
        if (completeTime === 0) {
          if (startTimeLimitNew > startTimeLimit) {
            completeTime = 1
          }
        }
        if (completeTime === 0) {
          if (endTimeLimitNew < startTimeLimit) {
            completeTime = 1
          }
        }
        if (completeTime === 0) {
          if (k === 0) {
            this.setState({ selectMins: this.padnewone(startTimeLimit) })
          }
          minArrayList.push(startTimeLimit)
          htmlMins += "<option value='" + this.padnewone(startTimeLimit) + "'  >" + this.padnewone(startTimeLimit) + "</option>"
          currentEnd = startTimeLimit
          k++
        }
        startTimeLimit += parseInt(intervalTime)
      }

      var endcompleteTime = 0
      if (totdayDate.getHours() == selectTime && checkDate == selectDate) {
        if (totdayDate.getMinutes() > endTimeLimitNew) {
          endcompleteTime = 1
        }
      }

      if (minArrayList.indexOf(endTimeLimitNew) === -1 && startSelect !== endTimeLimitNew && endcompleteTime === 0) {
        htmlMins += "<option value='" + this.padnewone(endTimeLimitNew) + "'  >" + this.padnewone(endTimeLimitNew) + "</option>"
        currentEnd = endTimeLimitNew
      }
    }
    var loadAddtionalTime = this.loadAddtionalTime(currentEnd)
    htmlMins += loadAddtionalTime
    var minsDropDown = ""
    if (htmlMins !== "") {
      minsDropDown = htmlToReactParser.parse(htmlMins)
    } else {
      timeExceed = "Yes"
      var day_txt = weekdayArr[this.state.date.getDay()]
      var select_Time = parseInt(selectTime) + 1
      this.timeDropDownFunction(day_txt, select_Time)
    }
    this.setState({ minsHtml: minsDropDown, timeExceed: timeExceed })
  }
  loadAddtionalTime(currentEnd) {
    var html_Mins = ""
    var select_Time = this.state.selectTime
    var intervalTime = this.state.intervalTime
    var startTimeLimit = 0
    var endTimeLimit = (60 / intervalTime - 1) * intervalTime
    var addtional_Array = this.state.addtionalArray
    if (addtional_Array !== "" && addtional_Array[select_Time] !== "" && typeof addtional_Array[select_Time] !== undefined && typeof addtional_Array[select_Time] !== "undefined") {
      var totdayDate = new Date()

      var addHrs = parseInt(totdayDate.getMinutes()) + parseInt(this.state.serverTat)

      totdayDate.setMinutes(addHrs)

      var selectDate = this.state.date.getDate() + "-" + this.state.date.getMonth() + "-" + this.state.date.getFullYear()

      var checkDate = totdayDate.getDate() + "-" + totdayDate.getMonth() + "-" + totdayDate.getFullYear()

      var add_start_time = addtional_Array[select_Time].split("-")

      var startTime = new Date(add_start_time[0].trim())
      var endTime = new Date(add_start_time[1].trim())
      var endMin = endTimeLimit
      if (endTime.getHours() == select_Time) {
        endMin = endTime.getMinutes()
      }
      var completeTime = 0
      if (totdayDate.getHours() == select_Time && checkDate == selectDate) {
        if (totdayDate.getMinutes() > startTimeLimit) {
          completeTime = 1
        }
      }
      if (completeTime === 0) {
        html_Mins += "<option value='" + this.padnewone(startTime.getMinutes()) + "'  >" + this.padnewone(startTime.getMinutes()) + "</option>"
      }
      var minArrayList = []
      var endofMin = 0
      while (startTimeLimit <= endTimeLimit) {
        if (currentEnd < startTimeLimit && startTime.getMinutes() < startTimeLimit) {
          let completeTime = 0
          if (totdayDate.getHours() == select_Time && checkDate == selectDate) {
            if (totdayDate.getMinutes() > startTimeLimit) {
              completeTime = 1
            }
          }
          if (completeTime == 0) {
            minArrayList.push(startTimeLimit)
            html_Mins += "<option value='" + this.padnewone(startTimeLimit) + "'  >" + this.padnewone(startTimeLimit) + "</option>"
            endofMin = startTimeLimit
          }
        }
        startTimeLimit += parseInt(intervalTime)
      }

      var endcompleteTime = 0
      if (totdayDate.getHours() == select_Time && checkDate == selectDate) {
        if (totdayDate.getMinutes() > endMin) {
          endcompleteTime = 1
        }
      }
      if (minArrayList.indexOf(endMin) === -1 && startTime.getMinutes() !== endMin && endcompleteTime === 0 && endMin < endofMin) {
        html_Mins += "<option value='" + this.padnewone(endMin) + "'  >" + this.padnewone(endMin) + "</option>"
      }
    }
    return html_Mins
  }

  loadTimeSlotDropDown(daytxt) {
    var currentthis = this
    var enable_advance_time_slot_cutof_time =
      this.state.settings.enable_advance_time_slot_cutof_time !== "" && typeof this.state.settings.enable_advance_time_slot_cutof_time !== undefined && typeof this.state.settings.enable_advance_time_slot_cutof_time !== "undefined" ? this.state.settings.enable_advance_time_slot_cutof_time : 0
    var timeExceed = "No"
    var currentTime = new Date()
    var totdayDate = new Date()
    if (this.state.serverTat !== "" && this.state.serverTat !== null && this.state.serverTat !== undefined && this.state.serverTat != "undefined") {
      var addhrToday = parseInt(currentTime.getMinutes()) + parseInt(this.state.serverTat)
      currentTime.setMinutes(addhrToday)
    }
    var timeSlotHtml = ""
    var selectDate = this.state.date.getDate() + "-" + this.state.date.getMonth() + "-" + this.state.date.getFullYear()

    var checkDate = currentTime.getDate() + "-" + currentTime.getMonth() + "-" + currentTime.getFullYear()
    var cutOfftime = this.state.cutOfftime
    var timeExist = 0
    if (this.state.timeandsecArrayset[daytxt] !== "" && this.state.timeandsecArrayset[daytxt] !== undefined) {
      var cut_of_Reached = 0
      this.state.timeandsecArrayset[daytxt].map(function (item) {
        var cutofNextDayReached = 0
        if (currentthis.state.cutofNextDayReached === 1 && cut_of_Reached === 0 && currentthis.state.cutoffnextday !== "" && enable_advance_time_slot_cutof_time === "1") {
          currentthis.setState({ cutofNextDayReached: 0 })
          cutofNextDayReached = 1
        }
        if (cutofNextDayReached === 0) {
          timeExist = 0
          //Start Slot
          var slot_time1 = item.slot_time1.split(":")
          var startTimeVal = slot_time1.length > 0 ? slot_time1[0] : ""
          var startMinitVal = slot_time1.length > 0 ? slot_time1[1] : ""
          var strdatevalobj = new Date()
          strdatevalobj.setHours(startTimeVal)
          var stringrMinits = parseInt(startMinitVal)
          strdatevalobj.setMinutes(stringrMinits)

          //End Slot
          var endArr = item.slot_time2 !== "" ? item.slot_time2.split(":") : []
          var endTimeVal = endArr.length > 0 ? endArr[0] : ""
          var endMinitVal = endArr.length > 0 ? endArr[1] : ""
          var enddatevalobj = new Date()
          enddatevalobj.setHours(endTimeVal)
          var ingrendMinits = parseInt(endMinitVal)
          enddatevalobj.setMinutes(ingrendMinits)
          var lable = moment(strdatevalobj).format("hh:mm A") + " - " + moment(enddatevalobj).format("hh:mm A")
          var TimeValue = moment(strdatevalobj).format("HH:mm") + ":00" + "-" + moment(enddatevalobj).format("HH:mm") + ":00/" + moment(strdatevalobj).format("hh:mm A") + "-" + moment(enddatevalobj).format("hh:mm A")

          var checkDate = totdayDate.getDate() + "-" + totdayDate.getMonth() + "-" + totdayDate.getFullYear()
          var slotcutOff = 0
          if (item.slot_cutt_off !== "" && item.slot_cutt_off !== null) {
            var slot_cutt_off = item.slot_cutt_off.split(":")
            if (enable_advance_time_slot_cutof_time === "1") {
              if (parseInt(slot_cutt_off[0]) != "00" && parseInt(slot_cutt_off[0]) >= 0 && checkDate === selectDate) {
                if (totdayDate.getHours() >= slot_cutt_off[0]) {
                  slotcutOff = 1
                }
              }
            }
          }

          if (slotcutOff === 0) {
            if (currentTime.getHours() >= strdatevalobj.getHours() && checkDate == selectDate) {
              timeExist = 1
            }
            if (timeExist == 0) {
              timeSlotHtml += "<option value='" + TimeValue + "'  >" + lable + "</option>"
            }
          }
        }
        cut_of_Reached++
      })
    }
    if (this.state.cutoffnextday !== "" && enable_advance_time_slot_cutof_time === "1") {
      var check_Date = totdayDate.getDate() + "-" + totdayDate.getMonth() + "-" + totdayDate.getFullYear()
      console.log(check_Date, selectDate, this.state.cutoffnextday, totdayDate.getHours(), "totdayDate.getHours()")
      if (check_Date === selectDate && this.state.cutoffnextday <= totdayDate.getHours()) {
        this.setState({ cutofNextDayReached: 1 })
      }
    }
    var timeSlotDD = ""
    var currentselectdate = this.state.date
    if (timeSlotHtml !== "") {
      timeSlotDD = htmlToReactParser.parse(timeSlotHtml)
    } else {
      timeExceed = "Yes"
      var min_datetime = new Date(new Date(this.state.date).setDate(currentselectdate.getDate()))

      this.setState({ min_datetime: min_datetime })
      var addonday = parseInt(currentselectdate.getDate()) + 1
      var cudate = new Date(new Date(currentselectdate).setDate(addonday))

      if (this.state.hollidayList !== "" && typeof this.state.hollidayList !== undefined && typeof this.state.hollidayList !== "undefined") {
        var currentThis = this
        this.state.hollidayList.map(function (loadData) {
          var checkingDate = cudate.getFullYear() + "-" + currentThis.padnewone(parseInt(cudate.getMonth()) + 1) + "-" + currentThis.padnewone(cudate.getDate())
          if (loadData == checkingDate) {
            cudate = new Date(new Date(cudate).setDate(parseInt(cudate.getDate()) + 1))
          }
        })
      }

      this.setState({ date: cudate }, function () {
        this.loadTimeSlotDropDown(weekdayArr[cudate.getDay()])
      })
    }
    this.setState({ TimeSlotHtmlVal: timeSlotDD, timeExceed: timeExceed })
  }

  /* Disaple year */
  showTimeslotExceed() {
    if (this.state.timeExceed === "Yes") {
      if (APPID === "97347EE4-2845-4011-BCE6-9C0B806DA437" && (cookie.load("defaultAvilablityId") === "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11" || cookie.load("defaultAvilablityId") === "718B1A92-5EBB-4F25-B24D-3067606F67F0")) {
        if (cookie.load("defaultAvilablityId") === "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11") {
          return <div className="timeslot_exc">Delivery available only on Saturdays (12- 4) pm</div>
        } else if (cookie.load("defaultAvilablityId") === "718B1A92-5EBB-4F25-B24D-3067606F67F0") {
          return <div className="timeslot_exc">Takeaway available only on Saturdays (9 am to 2pm)</div>
        }
      } else if (APPID === "729A09D6-2A38-4E1C-ADB1-67A9242D2158" && (cookie.load("defaultAvilablityId") === "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11" || cookie.load("defaultAvilablityId") === "718B1A92-5EBB-4F25-B24D-3067606F67F0")) {
        if (cookie.load("defaultAvilablityId") === "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11") {
          return <div className="timeslot_exc">Delivery available only on Fri, Sat & Sun</div>
        } else if (cookie.load("defaultAvilablityId") === "718B1A92-5EBB-4F25-B24D-3067606F67F0") {
          return <div className="timeslot_exc">Pickup available only on Fri, Sat & Sun</div>
        }
      } else {
        return <div className="timeslot_exc"> Note: Please refer our operating hours. The time you are selecting is outside of our delivery timing. Apologies for the inconvenience.</div>
      }
    }
  }

  componentDidMount() {
    loadAnalytics()
    var windowHeight = window.innerHeight
    this.setState({ windowHeight: windowHeight - 45 + "px" })

    $(".custom-date-label").addClass("loader-main-cls").append('<div class="loader-sub-div"></div>')
    $(".slot_loading_sec").show()
  }

  /* store unit no's */
  stroteUnitNoOne(event) {
    cookie.save("unitNoOne", event.target.value)
  }

  /* store unit no's */
  stroteUnitNoTwo(event) {
    cookie.save("unitNoTwo", event.target.value)
  }

  /* store unit no's */
  strotetowerNo(event) {
    cookie.save("towerNo", event.target.value)
  }

  /* Submit Order Date */
  checkDeliveryDate() {
    if (this.state.timeExceed === "No") {
      var DeliveryDate = this.state.date.getDate() + "/" + (parseInt(this.state.date.getMonth()) + 1) + "/" + this.state.date.getFullYear()
      var OrderTime = ""
      var timelimits = ""
      if (this.state.time_slot_type === "1") {
        cookie.save("isSletedTimeSlot", "No")
        cookie.save("DeliveryDate", DeliveryDate)
        var DeliveryTime = this.state.selectTime + ":" + this.state.selectMins
        var DeliveryDate1 = DeliveryDate.split("/")
        OrderTime = DeliveryDate1[2] + "-" + this.padnewone(DeliveryDate1[1]) + "-" + this.padnewone(DeliveryDate1[0]) + " " + DeliveryTime
        cookie.save("DeliveryTime", DeliveryTime)
      } else if (this.state.time_slot_type === "2") {
        var Timeslot = document.getElementById("timeSlot").value
        var splited = Timeslot.split("/")
        var startTime = splited[0].split("-")
        cookie.save("isSletedTimeSlot", "Yes")
        cookie.save("DeliveryDate", DeliveryDate)
        cookie.save("DeliveryTimeSlotFormated", splited[1])
        cookie.save("DeliveryTime", startTime[0])
        cookie.save("DeliveryTimeSlot", splited[0])
        var DeliveryDate1 = DeliveryDate.split("/")
        OrderTime = DeliveryDate1[2] + "-" + this.padnewone(DeliveryDate1[1]) + "-" + this.padnewone(DeliveryDate1[0]) + " " + startTime[0]
        timelimits = "&order_pickup_time_slot_from=" + startTime[0] + "&order_pickup_time_slot_to=" + startTime[1]
      }

      //Check unit number validation
      if (cookie.load("defaultAvilablityId") === config.ids.deliveryId) {
        var AlertOne = document.getElementById("unitone").value
        var AlertTwo = document.getElementById("unittwo").value
        if (AlertOne === "" || AlertTwo === "") {
          var res = confirm("You did not enter a Floor No / Unit No. \n Do you want to continue")
          if (!res) {
            error = "1"
            return false
          }
        }
      }

      axios.get(APIURLPRO + "ordersv1/validate_order_count_advanced?app_id=" + APPID + "&availability_id=" + cookie.load("defaultAvilablityId") + "&outlet_id=" + cookie.load("orderOutletId") + "&order_date=" + OrderTime + timelimits + "&order_tat_time=" + this.state.serverTat).then((res) => {
        if (res.data.status === "ok") {
          hashHistory.push("/checkout")
        } else {
          helper.showInfo(res.data.message)
        }
      })

      //hashHistory.push("/checkout");
    } else {
      helper.showInfo("Please select another date and time")
      return false
    }
  }

  changeTimer(e) {
    var seletedTime = e.target.value
    this.setState({ selectTime: seletedTime, minsHtml: "" }, function () {
      this.minsDropDownFunction()
    })
  }

  /* chnage minutes */
  changeMins(e) {
    this.setState({ selectMins: e.target.value })
  }

  /* add zero */
  padnewone(d) {
    var dd = parseInt(d)
    return dd < 10 ? "0" + dd.toString() : dd.toString()
  }

  /* format date'es */
  parseDate(input) {
    var parts = input.match(/(\d+)/g)
    return new Date(parts[0], parts[1] - 1, parts[2]) // months are 0-based
  }

  /*show outlet text for workspace */
  outletNameText() {
    if (apps.workspace === APPID) {
      return htmlToReactParser.parse("Workspace Espresso Bar <br/> 12 Marina Boulevard<br/> Marina Bay Financial Centre Tower 3<br/> #01-08 Singapore 018982")
    } else {
      /*return (cookie.load('defaultAvilablityId') === config.ids.deliveryId? cookie.load('orderDeliveryAddress'): cookie.load('orderHandled') );*/
      return cookie.load("defaultAvilablityId") === config.ids.deliveryId ? cookie.load("orderDeliveryAddress") + ", Singapore " + cookie.load("orderPostalCode") : cookie.load("orderHandled")
    }
  }

  handleChange = (item, value) => {
    if (item == "date") {
      this.setState({ [item]: value, TimeSlotHtmlVal: "" }, function () {
        var selectDar = weekdayArr[value.getDay()]
        if (this.state.time_slot_type === "1") {
          this.timeDropDownFunction(selectDar)
        } else if (this.state.time_slot_type === "2") {
          this.loadTimeSlotDropDown(selectDar)
        }
      })
    } else {
      this.setState({ [item]: value })
    }
  }

  render() {
    return (
      <div>
        {" "}
        <DocumentMeta {...meta} />
        <div className="container-fluid desktop-container" style={{ minHeight: this.state.windowHeight }}>
          <div className="row">
            <div className="col-xs-12 top-nav inner-top-nav head-main">
              <div className="col-xs-3 nav-head pd-l-20  head-child">
                <Link to={"/cart"}>
                  <img src="/img/icons/arrow-right.png" alt="left-arrow" className="icon-img-small-3" />
                </Link>
              </div>
              <div className="col-xs-6 nav-head head-child">
                <h1 className="main-title">{cookie.load("defaultAvilablityId") === config.ids.deliveryId ? "Delivery" : "PICKUP"}</h1>
              </div>
              <div className="col-xs-3 nopadding head-child">
                {/* <p className="b-txt text-right ">
                <Link to={"/cart"} >  <img alt=""  src={this.loadCartImage()}className="icon-img-small-1"/> </Link>
              </p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 calender-area">
              <div className="col-xs-12 calender-area-sub">
                <div className="col-xs-offset-2 col-xs-8 cal-div">
                  <h1 className="sub-heading" style={{ textAlign: "center" }}>
                    {cookie.load("defaultAvilablityId") === config.ids.deliveryId ? "SELECT YOUR DELIVERY DATE AND TIME" : "SELECT YOUR PICKUP DATE AND TIME"}
                  </h1>
                  <div className="form-group label-floating is-empty card-no custom-date-label">
                    <div className="input-group deliverydate">
                      <ThemeProvider theme={theme}>
                        <DatePicker
                          label="Date (DD/MM/YYYY)"
                          className="delivery_datepiker"
                          inputFormat={(value) => `${value.getDate()}/${value.getMonth() + 1}/${value.getFullYear()}`}
                          value={this.state.date}
                          onChange={this.handleChange.bind(this, "date")}
                          minDate={this.state.min_datetime}
                          maxDate={this.state.max_datetime}
                          sundayFirstDayOfWeek
                          disabledDates={this.state.disableDate}
                        />
                      </ThemeProvider>
                    </div>
                  </div>
                  <p className="text-center sub-heading-2 slot_loading_sec" style={{ display: "none" }}>
                    <br />
                    <b>Please wait...Checking the next available slots..</b>
                  </p>
                  <div className="form-group label-floating is-empty card-no custom-time-label min-hgt-hour-t">
                    <div className="input-group">
                      <label className="control-label">{this.state.timeText}</label>
                      {this.state.time_slot_type === "1" && (
                        <div>
                          <select
                            value={this.state.selectTime}
                            onChange={this.changeTimer}
                            id="deliveryTime"
                            /*  className={disabledClass + " customdd"}
                       
                       */
                          >
                            {this.state.timeHtml}
                          </select>{" "}
                          /
                          <select
                            id="deliveryMins"
                            value={this.state.selectMins}
                            onChange={this.changeMins}
                            /* 
                        className={
                          disabledClassMins + " " + disabledClass + " customdd"
                        }
                         */
                          >
                            {this.state.minsHtml}
                          </select>
                        </div>
                      )}
                      {this.state.time_slot_type === "2" && (
                        <div>
                          <select className={" customdd timelsotdd"} id="timeSlot">
                            {this.state.TimeSlotHtmlVal}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                  {this.showTimeslotExceed()}

                  <div className="all-time-text">{APPID == "CD9D3E7B-C4E4-4FF0-B751-A7FE97524F53" && moment(this.state.date).format("YYYY-M-D") == moment(new Date()).format("YYYY-M-D") ? "Today's orders will only be  available till 9pm" : ""}</div>

                  <div className="space-35"></div>
                </div>
              </div>
              <div className="col-xs-12 calender-area-sub-2">
                <h1 className="sub-heading" style={{ textAlign: "center" }}>
                  {cookie.load("defaultAvilablityId") === config.ids.deliveryId ? "DELIVER TO" : "PICKUP AT"}
                </h1>
                <h1 className="sub-heading-2" style={{ textAlign: "center" }}>
                  {this.outletNameText()}
                </h1>

                {cookie.load("defaultAvilablityId") === config.ids.deliveryId ? (
                  <div className="col-xs-12 unit-no-div">
                    <div className="form-group col-sm-4 col-xs-12 no-margin">
                      <div className="form-group label-floating is-empty card-no">
                        <div className="input-group">
                          <label className="control-label">Building Name/ Tower</label>
                          <input type="text" id="towerno" className="form-control" onChange={this.strotetowerNo} />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-sm-4 col-xs-12 no-margin">
                      <div className="form-group label-floating is-empty card-no">
                        <div className="input-group">
                          <label className="control-label">Floor No</label>
                          <input type="text" id="unitone" className="form-control" onChange={this.stroteUnitNoOne} />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-sm-4 col-xs-12 no-margin">
                      <div className="form-group label-floating is-empty card-no">
                        <div className="input-group">
                          <label className="control-label">Unit No</label>
                          <input type="text" id="unittwo" className="form-control" onChange={this.stroteUnitNoTwo} />
                        </div>
                      </div>
                      <br />
                    </div>

                    <div className="clearfix"></div>
                  </div>
                ) : (
                  <div className="space-30"></div>
                )}
              </div>
              <div className="space-30"></div>
            </div>
          </div>

          <div className="space-30"></div>

          <div className="row">
            <Link onClick={this.checkDeliveryDate.bind(this)}>
              <button className="col-lg-12 continue-but desktop-container container-fluid">Continue</button>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
export default Advancedtimeslot

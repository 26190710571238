import PropTypes from "prop-types"
import React from "react"
import { createClient, CheckoutContainer } from "@koomipay/react-legacy"
const helper = require("./Helper")

export default class KoomipayCheckout extends React.Component {
  static propTypes = {
    clientKey: PropTypes.string,
    paymentAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    order: PropTypes.object,
    onComplete: PropTypes.func,
  }
  constructor(props) {
    super(props)
    this.koomipayClient = null
  }

  state = {
    paymentMethods: [],
    amount: {},
    currentPaymentMethod: null,
    isValid: false,
    paymentData: {},
    redirectUrl: "",
    payment: null,
    processing: false,
  }

  initKoomipayClient = async () => {
    const { paymentAmount, clientKey } = this.props
    const price = Number(paymentAmount)
    if (clientKey && price && !this.koomipayClient) {
      this.koomipayClient = createClient({ apiKey: clientKey })
      const amount = {
        currency: "SGD",
        price,
      }
      const { paymentMethods, success } = await this.koomipayClient.getPaymentMethods({
        amount,
        countryCode: "SG",
      })
      if (success && paymentMethods.length) {
        this.setState({
          paymentMethods,
          currentPaymentMethod: paymentMethods[0].type === "scheme" ? paymentMethods[0] : null,
          amount,
        })
      }
    }
  }

  componentDidMount() {
    this.initKoomipayClient()
    window.addEventListener("message", this.handle3DSCompleteEvent)
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handle3DSCompleteEvent)
  }

  async sleep(time) {
    return new Promise((resolve) => {
      setTimeout(resolve, time)
    })
  }

  handleCompleteCheckout = async (order) => {
    const { onComplete } = this.props
    if (onComplete && typeof onComplete === "function") {
      onComplete(order)
    }
  }

  handle3DSCompleteEvent = async (event) => {
    $("#3ds").modal("hide")
    this.setState({ redirectUrl: "" })
    if (event.origin !== document.location.origin || !event.data || event.data.type !== "3DS-complete") return

    const result = event.data && event.data.result
    if (result === "success") {
      setTimeout(async () => {
        this.setState({ processing: true, paymentData: {} })
        await this.sleep(2000)
        this.handleCompleteCheckout(this.state.payment)
      }, 500)
    } else {
      this.setState({ processing: false })
      alert("Proceed payment failed, please try again\n\nError: " + event.data && event.data.refusalReason)
    }
  }

  payNow = async () => {
    const { order } = this.props
    if (this.koomipayClient && order) {
      const { order_id_for_koomipay, grand_total, products } = order
      const total = Number(grand_total)
      if (!order_id_for_koomipay || !total) {
        return
      }

      let items = []
      try {
        items = JSON.parse(products)
      } catch (error) {
        items = []
      }
      this.setState({ processing: true })

      const { browserInfo, ...params } = this.state.paymentData
      const response = await this.koomipayClient.checkout({
        orderID: order_id_for_koomipay,
        paymentMethod: params,
        browserInfo: browserInfo,
        amount: this.state.amount,
        items: (items || []).map((i) => ({
          productID: i.product_id,
          productName: i.product_name,
          quantity: Number(i.product_qty),
          price: Number(i.product_unit_price),
        })),
        returnUrl: document.location.origin + "/#/checkout/koomipay-complete",
      })

      const data = response.data || {}

      if (data && data.success) {
        const redirectUrl = data.data && data.data.redirect3ds
        if (redirectUrl) {
          this.setState({ redirectUrl, payment: data.data }, () => {
            $("#3ds").modal("show")
            $("#3ds").on("hidden.bs.modal", () => {
              this.setState({ redirectUrl: null, processing: false })
            })
          })
        } else {
          if (data.data?.resultCode === "Authorised") {
            await this.sleep(2000)
            this.setState({ payment: data.data })
            this.handleCompleteCheckout(data.data)
            return null
          } else {
            this.setState({ processing: false })
            helper.showInfo(data.data?.additionalData?.refusalReasonRaw, "Error")
          }
        }
      }
    }
  }

  render() {
    const { currentPaymentMethod, amount, isValid, redirectUrl, processing } = this.state
    return (
      <div className="">
        <div className="koomipay-checkout">
          <div className="modal fade" id="3ds">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">3DS Authentication</h4>
                </div>
                <div className="modal-body">
                  <iframe src={redirectUrl} title={"3DS Authentication"} height={700} width={"100%"}></iframe>
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
        </div>
        {!currentPaymentMethod ? (
          <div className="inset-0 flex flex-col gap-3 items-center justify-center pointer-events-none">
            <div className="w-16 h-16">
              <svg className="w-full h-full animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          </div>
        ) : (
          <div className="alt_btns">
            <form onSubmit={(e) => e.preventDefault()} action="#">
              <div className="paymentsection cccard_paysec">
                <CheckoutContainer client={this.koomipayClient} amount={amount} paymentMethod={currentPaymentMethod} onValid={(v) => this.setState({ isValid: v })} onChange={(data) => this.setState({ paymentData: data })} />

                {!!amount && (
                  <button onClick={this.payNow} className="col-lg-12 continue-but desktop-container container-fluid" disabled={!isValid || processing}>
                    {processing ? "PROCESSING PAYMENT..." : "CHECKOUT"}
                  </button>
                  // <button className="button" disabled={!isValid}>
                  //   Pay {amount.currency} {parseFloat(amount.price).toFixed(2)}
                  // </button>
                )}
              </div>
            </form>
          </div>
        )}
      </div>
    )
  }
}

/* eslint-disable */
import React from "react"
import { loadAnalytics } from "./withTracker"
import { Link, hashHistory } from "react-router"
import cookie from "react-cookie"
import axios from "axios"
import Moment from "react-moment"
import DocumentMeta from "react-document-meta"
import getResult from "./Api"
import Loading from "./loading"

import config from "./config"
import lang from "./lang"
const APIURL = config.path.apiUrl
const APIURLPRO = config.path.apiUrlPro
const APPID = config.ids.appId
var currency = config.ids.currency

var helper = require("./Helper.js")
var strp = require("strp")
var qs = require("qs")

/* Load meta tags */
const meta = {
  title: "My Rewards",
  description: "My Rewards",
  meta: {
    name: {
      keywords: "My Rewards",
    },
  },
}

class Rewards extends React.Component {
  constructor(props) {
    /*add class */
    helper.addBodyClass()

    super(props)
    this.state = { redeem_list_flag: "", advocadostatus: "", advocado_id: 0, advocadoHistory: [], enable_advocado: "No", credit: [], debit: [], status: "Loading", windowHeightNormal: 0, windowHeight: 0, points: 0, creditLimit: 5, debitLimit: 5, promotion_count: 0 }

    /* check Authentication */
    if (getResult("Auth") === false) {
      hashHistory.push("/sign-in")
    }

    helper.isAnonymousUserRedirect()

    axios.all([axios.get(APIURL + "customer/customerdetail?app_id=" + APPID + "&refrence=" + cookie.load("UserId"))]).then(
      axios.spread((customer) => {
        this.setState({ status: "ok" })
        if (customer.data.status === "ok") {
          /*this.setState({ points : customer.data.result_set.customer_reward_point, advocado_id:customer.data.result_set.customer_advocado_id, enable_advocado:customer.data.result_set.enable_advocado });*/

          this.setState(
            { points: customer.data.result_set.customer_reward_point, advocado_id: customer.data.result_set.customer_advocado_id, enable_advocado: customer.data.result_set.enable_advocado },
            function () {
              this.getNinjaProCustPoints(customer.data.result_set.enable_advocado)
            }.bind(this)
          )

          if (customer.data.result_set.enable_advocado === "Yes") {
            this.rewardTransactionHistory()
          } else {
            this.loadData()
          }
        }
      })
    )
  }

  getNinjaProCustPoints(advocadoFlag) {
    if (advocadoFlag !== "Yes") {
      var inputKeys = ["promotionwithoutuqc", "reward", "testtxt"]
      inputKeys = JSON.stringify(inputKeys)

      axios.all([axios.get(APIURL + "reports/activity_counts1?app_id=" + APPID + "&status=A&customer_id=" + cookie.load("UserId") + "&act_arr=" + inputKeys)]).then(
        axios.spread((activityCnt) => {
          if (activityCnt.data.status === "ok") {
            this.setState({ points: activityCnt.data.result_set.reward_ponits, promotion_count: activityCnt.data.result_set.promotionwithoutuqc })
          }
        })
      )
    }
  }

  rewardTransactionHistory(cLimit = null, dLimit = null) {
    var limitOne = cLimit !== null ? cLimit : this.state.creditLimit
    var limitTwo = dLimit !== null ? dLimit : this.state.debitLimit
    var advocadoObj = {}
    advocadoObj = {
      app_id: APPID,
      customer_id: cookie.load("UserId"),
    }
    axios.post(APIURL + "customer/advocado_transaction_history", qs.stringify(advocadoObj)).then((res) => {
      console.log(res)
      if (res.data.status === "ok") {
        this.setState({ status: "ok" })
        this.setState({ advocadostatus: res.data.result_set.status, redeem_list_flag: res.data.redeem_list_flag, advocadoHistory: res.data.result_set.data.hits })
      } else {
      }
    })
  }

  loadData(cLimit = null, dLimit = null) {
    var limitOne = cLimit !== null ? cLimit : this.state.creditLimit
    var limitTwo = dLimit !== null ? dLimit : this.state.debitLimit

    axios
      .all([
        axios.get(APIURL + "loyalty/customer_earned_rewardpoint_histroyv1?app_id=" + APPID + "&customer_id=" + cookie.load("UserId") + "&limit=" + limitOne),

        axios.get(APIURL + "Loyalty/customer_redeemed_rewardpoint_histroy?status=A&app_id=" + APPID + "&customer_id=" + cookie.load("UserId") + "&limit=" + limitTwo),
      ])
      .then(
        axios.spread((credit, debit) => {
          this.setState({ status: "ok" })

          if (credit.data.status === "ok") {
            this.setState({ credit: credit.data.result_set })
          }

          if (debit.data.status === "ok") {
            this.setState({ debit: debit.data.result_set })
          }
        })
      )
  }

  componentWillMount() {
    var windowHeight = window.innerHeight
    this.setState({ windowHeight: windowHeight - 45 + "px" })
    this.setState({ windowHeightNormal: windowHeight + "px" })
  }

  /* Change earend records */
  handleChange(
    event //handleChangereedem
  ) {
    this.setState({ creditLimit: event.target.value })
    this.loadData(event.target.value, null)
  }

  /* Change redeem records */
  handleChangeRedeem(
    event //handleChangereedem
  ) {
    this.setState({ debitLimit: event.target.value })
    this.loadData(null, event.target.value)
  }

  loadCartImage() {
    return getResult("cartImage", "Yes")
  }

  /* this function used to load earn advocado points history */
  loadadvocadoearnHistory() {
    if (this.state.advocadostatus === "success" && this.state.advocadoHistory.length > 0) {
      return this.state.advocadoHistory.map((cData, index) => (
        <div key={index}>
          {" "}
          {cData.earnedAmount !== undefined && (
            <div className="order_list_rewards">
              <div className="rws_list_title">
                <p>
                  Order Id - {cData.receiptNumber}, <Moment format="DD/MM/YYYY HH:mm">{cData.date}</Moment>
                </p>
                {/*  <a >7.45

                  View Receipt
                </a> */}
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <p className="price_pt">Order Amount : {currency + cData.spendingAmount} </p>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 text-right">
                  <p className="price_pt">
                    <span>Earned</span> ${cData.earnedAmount} Cashback
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      ))
    } else {
      return <div className="order_list_rewards"> No records found. </div>
    }
  }

  /* this function used to load redeem advocado points history */
  loadadvocadoRedeemHistory() {
    if (this.state.advocadostatus === "success" && String(this.state.redeem_list_flag) === "1" && this.state.advocadoHistory.length > 0) {
      return this.state.advocadoHistory.map((cData, index) => (
        <div key={index}>
          {cData.redeemAmount !== undefined && (
            <div className="order_list_rewards">
              <div className="rws_list_title">
                <p>
                  Order Id - {cData.receiptNumber}, <Moment format="DD/MM/YYYY HH:mm">{cData.date}</Moment>
                </p>
                {/*  <a >7.45

                  View Receipt
                </a> */}
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <p className="price_pt">
                    <span>Redeemed</span> ${cData.redeemAmount}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      ))
    } else {
      return <div className="order_list_rewards"> No records found. </div>
    }
  }

  getDtFrmt(dateTxt) {
    var runDtTxt = ""
    if (dateTxt != "") {
      var dateTmArr = dateTxt.split(" ")
      var dateArr = dateTmArr[0].split("-")
      var TmArr = dateTmArr[1].split(":")
      runDtTxt = dateArr[2] + "/" + dateArr[1] + "/" + dateArr[0] + " " + TmArr[0] + ":" + TmArr[1]
    }

    return runDtTxt
  }

  getEprDate(dateTxt) {
    if (dateTxt != "") {
      var dateTmArr = dateTxt.split(" ")
      var expDate = new Date(dateTmArr[0])
      var nowDate = new Date()
      if (expDate > nowDate) {
        return <p style={{ float: "right" }}>Expiry On : {this.getDtFrmt(dateTxt)}</p>
      } else {
        return <p style={{ float: "right" }}>Expired</p>
      }
    } else {
      return <p style={{ float: "right" }}>Expired</p>
    }
  }

  /* this function used to load earn  points history */
  loadearnHistory() {
    if (this.state.credit.length > 0) {
      return this.state.credit.map((cData, index) => (
        <div className="order_list_rewards" key={index}>
          <div className="rws_list_title">
            {/*(cData.lh_from === 'pos')?<p>{cData.lh_reason}</p>:<p>Order Id - {cData.order_local_no},   <Moment format="DD/MM/YYYY HH:mm">{cData.order_date}</Moment></p>*/}
            {cData.lh_from === "pos" ? (
              <p>{cData.lh_reason}</p>
            ) : (
              <p>
                Order1 Id - {cData.order_local_no}, {this.getDtFrmt(cData.order_date)}
              </p>
            )}
            {this.getEprDate(cData.lh_expiry_on)}
          </div>
          <div className="row">
            {cData.lh_from === "pos" ? (
              <div className="col-md-6 col-sm-6 col-xs-12">
                <p className="price_pt">Added By : Admin </p>
                <span></span>
              </div>
            ) : (
              <div className="col-md-6 col-sm-6 col-xs-12">
                <p className="price_pt">Order Amount : {currency + cData.order_total_amount} </p>
                <span>{cData.order_availability_name}</span>
              </div>
            )}
            <div className="col-md-6 col-sm-6 col-xs-12 text-right">
              <p className="price_pt">
                <span>Earned</span> {cData.lup_customer_points} Points
              </p>
            </div>
          </div>
        </div>
      ))
    } else {
      return <div className="order_list_rewards"> No records found. </div>
    }
  }

  /* this function used to load redeem  points history */
  loadredeemHistory() {
    if (this.state.debit.length > 0) {
      return this.state.debit.map((dData, index) => (
        <div className="order_list_rewards" key={index}>
          <div className="rws_list_title">
            {/*<p>Order Id - {dData.order_local_no},   <Moment format="DD/MM/YYYY HH:mm">{dData.order_date}</Moment></p>*/}
            <p>
              Order Id - {dData.order_local_no}, {this.getDtFrmt(dData.order_date)}
            </p>
            {/*  <a >7.45

                  View Receipt
                </a> */}
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <p className="price_pt">Order Amount : {currency + dData.order_total_amount} </p>
              <span>{dData.order_availability_name}</span>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12 text-right">
              <p className="price_pt">
                <span></span> Redeemed {dData.lh_redeem_point} Points
              </p>
            </div>
          </div>
        </div>
      ))
    } else {
      return <div className="order_list_rewards"> No records found. </div>
    }
  }

  componentDidMount() {
    loadAnalytics()

    var promise = getResult("settingsPro", "No")
    promise.then((res) => {
      if (!helper.disableMenu(res.data.result_set, "myaccount_rewards")) {
        hashHistory.push("/")
        return false
      }
    })
  }

  render() {
    if (this.state.status === "Loading") {
      return (
        <div className="container-fluid desktop-container" style={{ minHeight: this.state.windowHeightNormal }}>
          <div className="row"></div>
          <div className="row">
            <div className="space-10 bg-gray"></div>
          </div>
          <div className=" ">
            <div className="col-xs-10 div-center">
              <div className="space-5"></div>
              <Loading />
              <div className="space-5"></div>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-5"></div>
          <div className="space-5"></div>
          <div className="space-30"></div> <div className="space-30"></div> <div className="space-30"></div> <div className="space-30"></div>
        </div>
      )
    } else {
      return (
        <div>
          <DocumentMeta {...meta} />
          <div className="container-fluid   ninja-theme-v2  desktop-container rewards-section-top" style={{ minHeight: this.state.windowHeightNormal }}>
            <div className="row">
              <div className="col-xs-12 top-nav inner-top-nav head-main">
                <div className="col-xs-3 nav-head pd-l-20  head-child">
                  {" "}
                  <Link to={"/my-account"}>
                    <img src="/img/icons/arrow-right.png" alt="left-arrow" className="icon-img-small-3" />
                  </Link>
                </div>
                <div className="col-xs-6 nav-head   head-child">
                  <h1 className="main-title">My Rewards</h1>
                </div>
                <div className="col-xs-3 nopadding   head-child">
                  <p className="b-txt text-right ">
                    <Link to={"/cart"} className={helper.cartClass()}>
                      {helper.cartCount()} <img alt="" src={this.loadCartImage()} className="icon-img-small-1" />{" "}
                    </Link>
                  </p>
                </div>
              </div>
            </div>

            <div className="rewards-wrap">
              <div className="rewards_banner">
                <img src="img/rewards_banner.jpg" />
                <div className="rewards-content">
                  <h4>{this.state.enable_advocado === "Yes" ? "$" + this.state.points + " Cashback" : this.state.points + " Points"}</h4>
                  <p>Available for your redemption</p>
                </div>
              </div>
              <div className="tabbable-panel">
                <div className="tabbable-line">
                  <ul className="nav nav-tabs ">
                    <li className="active">
                      <a href="#tab_default_1" data-toggle="tab">
                        Rewards Earned
                      </a>
                    </li>
                    <li>
                      <a href="#tab_default_2" data-toggle="tab">
                        Rewards Redeemed{" "}
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className="tab-pane fade in active" id="tab_default_1">
                      {/*	<div className="pagination-rewards">
              <span className="show_pgn">Show</span>
              <div className="pagination_number">
                <select value={this.state.creditLimit} onChange={this.handleChange.bind(this)} > <option value="5"> 5</option> <option value="10"> 10 </option> <option value="25"> 25 </option>  <option value="50"> 50 </option> </select>
				<div className="pagination-num-list">
                  <ul>
                    <li>5</li>
                    <li>10</li>
                    <li>15</li>
                  </ul>
				</div>  
              </div>
              <span>Per Page</span>
            </div> */}
                      {this.state.enable_advocado === "Yes" && this.loadadvocadoearnHistory()}
                      {this.state.enable_advocado === "No" && this.loadearnHistory()}
                    </div>
                    <div className="tab-pane fade" id="tab_default_2">
                      {/*<div className="pagination-rewards">
              <span className="show_pgn">Show</span>
              <div className="pagination_number">
                <select value={this.state.debitLimitLimit} onChange={this.handleChangeRedeem.bind(this)} > <option value="5"> 5 </option> <option value="10"> 10 </option> <option value="25"> 25 </option>  <option value="50"> 50 </option> </select>
               
		  
              </div>
              <span>Per Page</span>
			</div> */}

                      {this.state.enable_advocado === "Yes" && this.loadadvocadoRedeemHistory()}
                      {this.state.enable_advocado === "No" && this.loadredeemHistory()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <a><button className="col-xs-12 continue-but desktop-container container-fluid">REQUEST</button>
  </a> */}
          </div>
        </div>
      )
    }
  }
}
export default Rewards

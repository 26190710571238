/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import { Link, hashHistory } from "react-router"
import update from "immutability-helper"
import { loadAnalytics, fbPixelCustomEvent } from "./withTracker"
import validator from "validator"
import { validated } from "react-custom-validation"
//import 'react-date-picker/index.css';
import axios from "axios"
import DocumentMeta from "react-document-meta"
import ThemeProvider from "react-toolbox/lib/ThemeProvider"
import theme from "./react-toolbox/theme.js"
import "./react-toolbox/theme.css"
// import DatePicker from "react-toolbox/lib/date_picker/DatePicker"
import cookie from "react-cookie"
import getResult from "./Api"
import Loading from "./loading"

import config from "./config"
import lang from "./lang"
const APIURL = config.path.apiUrl
const APPID = config.ids.appId
const datetime = new Date(2015, 10, 16)
var helper = require("./Helper.js")
helper.addBodyClass()
/* Load meta tags */
const meta = {
  title: lang.signup_meta_title,
  description: lang.signup_meta_title,
  meta: {
    name: {
      keywords: lang.signup_meta_title,
    },
  },
}

class Signup extends React.Component {
  state = {
    fields: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      rePassword: "",
      mobile: "",
      company: "",
      settings: "",
      portmakan_company: "",
      customer_otp_val: "",
      terms: 1,
      accepted_coupons: "",
      accepted_alcohol: "",
      accepted_terms_cond: 0,
    },
    status: "Loading",
    /*add class */
  }
  componentWillMount() {
    this.setState({ status: "ok" })
    var promise = getResult("settings", "")
    promise.then((res) => {
      this.setState({ settings: res.data.result_set })
      cookie.save("client_company_name", res.data.result_set.company_name)
      cookie.save("client_site_url", res.data.result_set.client_site_url)
    })
  }
  fieldChange = (field, value) => {
    if (field == "accepted_alcohol" && APPID === "2AE048E7-2D84-42BE-9BB5-22016FC4DFBC" && APPID === "F34559CA-596A-4F2E-8547-17B2AEBB3326") {
      value = $("#accepted_alcohol").prop("checked")
    }
    if (field == "terms") {
      value = $("#terms").prop("checked")
    }
    if (field == "accepted_coupons") {
      value = $("#accepted_coupons").prop("checked")
    }
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }))

    if (field === "portmakan_company" && value != "") {
      document.getElementById("spn-portmakan_company-error").innerHTML = ""
    } else if (field === "portmakan_company" && value == "") {
      document.getElementById("spn-portmakan_company-error").innerHTML = '<span class="spn-error">Select your company</span>'
    }
  }
  setStateLoading(sts) {
    this.setState({ status: sts })
  }

  componentDidMount() {
    var site_maintenance_verify = cookie.load("client_site_maintenance_verify") !== "" && cookie.load("client_site_maintenance_verify") !== "undefined" && cookie.load("client_site_maintenance_verify") !== undefined ? cookie.load("client_site_maintenance_verify") : ""

    var site_maintenance_popup_open = cookie.load("site_maintenance_popup_open") !== "" && cookie.load("site_maintenance_popup_open") !== "undefined" && cookie.load("site_maintenance_popup_open") !== undefined ? cookie.load("site_maintenance_popup_open") : ""

    if (site_maintenance_popup_open === "1" && (site_maintenance_verify === "" || site_maintenance_verify === "0")) {
      hashHistory.push("/")
      return
    }

    /* update settings values */
    var promise = getResult("settings", "")
    promise.then((res) => {
      this.setState({ settings: res.data.result_set })
      cookie.save("client_company_name", res.data.result_set.company_name)
      cookie.save("client_site_url", res.data.result_set.client_site_url)
    })
    loadAnalytics()
  }

  handleFormSubmit = () => {
    const formPayload = this.state.fields
    /*  var des = document.getElementsByClassName('birthdate') 
	var input = des[0].getElementsByTagName('input');
	formPayload.birthday = input[0].value; 
    formPayload.gender = document.getElementsByClassName("select-gender")[0].value;  */

    if (document.getElementById("spn-email-error").innerHTML === '<span class="spn-error">This email already exists</span>') {
      return false
    }
    if (document.getElementById("spn-mobile-error").innerHTML === '<span class="spn-error">This mobile number already exists</span>') {
      return false
    }

    if (APPID === "CD8D5FE3-0586-44DD-9360-EE9DEF351223" && formPayload.portmakan_company == "") {
      document.getElementById("spn-portmakan_company-error").innerHTML = '<span class="spn-error">Select your company</span>'
      return false
    }

    /*var terms_condtions = document.getElementById("accepted_button");
	if (terms_condtions.checked == true){
		document.getElementById("spn-accept-error").innerHTML = '';
	} else {
		document.getElementById("spn-accept-error").innerHTML = '<span class="spn-error accedpted_error">Accept the terms of service and privacy policy</span>';
		setTimeout(function(){document.getElementById("spn-accept-error").innerHTML='' }, 3000);
		return false;
	}*/

    var company_otp_verification = this.state.settings.client_otp_verification_enable
    var company_otp_via = this.state.settings.client_otp_verification_via

    document.getElementById("loadIcon").style.display = "block"

    if (company_otp_verification === "1") {
      var qs = require("qs")
      var postObject = {
        app_id: APPID,
        customer_first_name: formPayload.firstname,
        customer_last_name: formPayload.lastname,
        customer_email: formPayload.email,
        customer_phone: formPayload.mobile,
        customer_otp_type: company_otp_via,
        accepted_coupons: formPayload.accepted_coupons === true ? 1 : 0,
      }

      cookie.save("customer_first_name", formPayload.firstname)
      cookie.save("customer_last_name", formPayload.lastname)
      cookie.save("customer_email", formPayload.email)
      cookie.save("customer_password", formPayload.password)
      cookie.save("customer_phone", formPayload.mobile)
      cookie.save("customer_portmakan_company", formPayload.portmakan_company)

      axios.post(APIURL + "customer/send_customer_otp", qs.stringify(postObject)).then((response) => {
        if (response.data.status === "ok") {
          document.getElementById("otp_verification_option").style.display = "block"
          if (company_otp_via === "1") {
            helper.showInfo("OTP has been sent to your email id.")
          } else {
            helper.showInfo("OTP has been sent to your mobile number.")
          }
          document.getElementById("loadIcon").style.display = "none"
          document.getElementById("register_form_option").style.display = "none"
          //  return false;
        } else {
          this.setStateLoading("error")
          document.getElementById("form-error").innerHTML = '<div class="box_error"><ul><li>' + response.data.message + "</li></ul></div>"
          document.getElementById("loadIcon").style.display = "none"
        }
        return false
      })
    } else {
      /* narmal register */

      var qs = require("qs")
      var postObject = {
        app_id: APPID,
        type: "web",
        customer_first_name: formPayload.firstname,
        customer_last_name: formPayload.lastname,
        customer_email: formPayload.email,
        customer_password: formPayload.password,
        customer_phone: formPayload.mobile,
        customer_portmakan_company: formPayload.portmakan_company,
        customer_verification: "normal",
        accepted_coupons: formPayload.accepted_coupons === true ? 1 : 0,
        /*  "customer_birthdate" : formPayload.birthday, 
			'customer_company_name': formPayload.company, 
			'customer_gender': formPayload.gender, */
        site_url: this.state.settings.client_site_url + "#/",
      }

      axios
        .post(APIURL + "customer/registration", qs.stringify(postObject))
        .then((response) => {
          if (response.data.status === "ok") {
            cookie.remove("isTempUser")
            //this.setStateLoading('ok');
            //console.log(lang.signup_success);
            //cookie.save('alertSuccess',lang.signup_success);
            fbPixelCustomEvent("RegistrationCompleted", {})

            /** Skip reg process */

            if (typeof response.data.skip_registration !== "undefined" && response.data.skip_registration === true) {
              var mobileno = ""
              if (response.data.result_set.customer_phone !== "" || response.data.result_set.customer_phone !== null || typeof response.data.result_set.customer_phone !== "undefined") {
                mobileno = response.data.result_set.customer_phone
              }

              /* set User cookie values - Start */
              cookie.save("UserId", response.data.result_set.customer_id)
              cookie.save("UserFname", response.data.result_set.customer_first_name !== "" ? response.data.result_set.customer_first_name : "")
              cookie.save("UserLname", response.data.result_set.customer_last_name != "" ? response.data.result_set.customer_last_name : "")
              cookie.save("UserMobile", mobileno)
              cookie.save("UserEmail", response.data.result_set.customer_email)
              cookie.save("UserDefaultAddress", response.data.result_set.customer_address_name)
              cookie.save("UserDefaultUnitOne", response.data.result_set.customer_address_line1)
              cookie.save("UserDefaultUnitTwo", response.data.result_set.customer_address_line2)
              cookie.save("UserDefaultPostalCode", response.data.result_set.customer_postal_code)
              /* set User cookie values - End */

              /* set redirection */

              /* upadte customer info */
              var qs = require("qs")
              var postObject = {
                app_id: APPID,
                reference_id: getResult("referenceId"),
                customer_id: response.data.result_set.customer_id,
                availability_id: cookie.load("defaultAvilablityId"),
              }

              axios.post(APIURL + "cart/update_customer_info", qs.stringify(postObject)).then((res) => {
                if (res.data.status === "ok" && cookie.load("reserVationClick") !== "fromhome") {
                  /* check FB login function */
                  cookie.save("cartValue", "Yes")
                  if (cookie.load("defaultAvilablityId") === config.ids.dineinId) {
                    if (APPID === "287E5552-328E-4DBE-A3CA-E998ACCAC9BD") {
                      helper.sarveshTableSetup()
                      // this.setStateLoading('ok');
                    } else {
                      //this.setStateLoading('ok');
                      if (typeof cookie.load("orderOutletId") !== "undefined" && cookie.load("orderOutletId") !== "" && typeof cookie.load("orderTableNo") !== "undefined" && cookie.load("orderTableNo") !== "") {
                        hashHistory.push("/checkout")
                      } else {
                        hashHistory.push("/book-a-table")
                      }
                    }
                  } else {
                    //this.setStateLoading('ok');
                    document.getElementById("loadIcon").style.display = "none"
                    hashHistory.push("/delivery-date")
                  }
                } else {
                  if (cookie.load("reserVationClick") === "fromhome") {
                    hashHistory.push("/")
                    cookie.save("reserVationClick", "fromlogin")
                  } else if (cookie.load("dineinClick") === "yes" && cookie.load("defaultAvilablityId") === config.ids.dineinId) {
                    if (APPID === "287E5552-328E-4DBE-A3CA-E998ACCAC9BD") {
                      helper.sarveshTableSetup()
                    } else {
                      this.setStateLoading("ok")
                      hashHistory.push("/book-a-table")
                    }
                  } else {
                    //hashHistory.push("/my-account");
                    hashHistory.push("/")
                    cookie.save("cartValue", "No")
                  }
                }
              })

              /*  if( cookie.load('cartValue') === "Yes"){  
					 if(cookie.load('defaultAvilablityId') === config.ids.dineinId){  console.log(2); 
							hashHistory.push("/book-a-table");
						   }
			  }else{
			 	hashHistory.push("/#/dashboard");
			  }   */
            } else {
              /** skip ref Process */
              helper.showInfo(lang.signup_success)

              hashHistory.push("/sign-in")
            }
            //  $("#Register-Sucess").modal("toggle");
            //  return false;
          } else {
            this.setStateLoading("error")
            if (response.data.form_error) {
              document.getElementById("form-error").innerHTML = '<div class="box_error"><ul><li>' + response.data.form_error + "</li></ul></div>"
              document.getElementById("loadIcon").style.display = "none"
            } else {
              document.getElementById("form-error").innerHTML = '<div class="box_error"><ul><li>' + response.data.message + "</li></ul></div>"
              document.getElementById("loadIcon").style.display = "none"
            }
          }
          return false
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }

  render() {
    if (this.state.status === "Loading") {
      return <Loading />
    } else {
      return <Form fields={this.state.fields} onChange={this.fieldChange} onValid={this.handleFormSubmit} onInvalid={() => console.log("Form invalid!")} />
    }
  }
}

const phonenumberPattern = /^[0-9]{6,14}$/

const isEmpty = (value) => (value === "" ? "This field is required." : null)

const isEmail = (email) => (validator.isEmail(email) ? null : "This is not a valid email.")

const isMobile = (mobile) => (mobile.match(phonenumberPattern) ? null : "This is not a valid mobile number.")

const isMobileDigt = (mobile) => (mobile.length === 8 ? null : "This is not a valid mobile number.")

const isUnique = (email) => {
  var qs = require("qs")
  var postObject = {
    app_id: APPID,
    type: "web",
    customer_email: email,
  }
  axios.post(APIURL + "customer/email_exist", qs.stringify(postObject)).then((response) => {
    if (response.data.status === "ok") {
      document.getElementById("spn-email-error").innerHTML = ""
    } else {
      document.getElementById("spn-email-error").innerHTML = '<span class="spn-error">This email already exists</span>'
    }
  })
}

const isPhonenumerExist = (mobile) => {
  var qs = require("qs")
  var postObject = {
    app_id: APPID,
    type: "web",
    customer_phone: mobile,
  }
  axios.post(APIURL + "customer/phoneno_exist", qs.stringify(postObject)).then((response) => {
    if (response.data.status === "ok") {
      document.getElementById("spn-mobile-error").innerHTML = ""
    } else {
      document.getElementById("spn-mobile-error").innerHTML = '<span class="spn-error">This mobile number already exists</span>'
    }
  })
}

const minLength = (password, length) => (password.length >= length ? null : "This field must be at least 6 characters.")

const areSame = (password, rePassword) => (password === rePassword ? null : "Passwords do not match.")

const acceptedAlcohol = (accepted_alcohol) => {
  if (APPID === "2AE048E7-2D84-42BE-9BB5-22016FC4DFBC" || APPID === "F34559CA-596A-4F2E-8547-17B2AEBB3326") {
    if (accepted_alcohol) {
      return null
    } else {
      return "This field is required."
    }
  }
}

const isChecked = (terms) => (terms ? null : "Please accept terms and conditions")

function validationConfig(props) {
  const { firstname, lastname, email, password, rePassword, mobile, accepted_alcohol, terms, accepted_coupons } = props.fields

  return {
    fields: ["firstname", "lastname", "email", "password", "rePassword", "mobile", "accepted_alcohol", "terms", "accepted_coupons"],

    validations: {
      firstname: [[isEmpty, firstname]],
      lastname: [[isEmpty, lastname]],
      email: [
        [isEmail, email],
        [isUnique, email],
      ],
      password: [[minLength, password, 6]],
      rePassword: {
        rules: [[areSame, password, rePassword]],
        fields: ["password", "rePassword"],
      },
      mobile: [
        [isEmpty, mobile],
        [isMobileDigt, mobile],
        [isMobile, mobile],
        [isPhonenumerExist, mobile],
      ],
      accepted_alcohol: [[acceptedAlcohol, accepted_alcohol, 1]],
      terms: [[isChecked, terms, 1]],
    },
  }
}

class Form extends React.Component {
  state = { date2: datetime }
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    /* Declare state */
    this.state = { windowHeightNormal: 0, windowHeight: 0 }
  }

  componentDidMount() {
    var windowHeight = window.innerHeight
    this.setState({ windowHeight: windowHeight - 45 + "px" })
    this.setState({ windowHeightNormal: windowHeight + "px" })

    /*var select = document.getElementsByClassName('select-gender')[0];
		select.style.background='none';
		select.style.margin='0 0 0 6px';
		select.style.fontWeight='bold';
		select.style.fontSize='14px';
		select.style.padding='8px 0 0 0';
		document.getElementsByClassName('gender')[0].classList.remove("is-empty"); 
		var des = document.getElementsByClassName('birthdate');
		var fc = des[0].getElementsByTagName('div');
		var se = fc[0].getElementsByTagName('div');
		se[0].style.padding='0px';
		var input = se[0].getElementsByTagName('input'); 
		input[0].style.borderBottom='0px';
		var label = se[0].getElementsByTagName('label');
		label[0].style.top='6px'; */
    var list = document.getElementsByClassName("input-form-group")
    var n
    for (n = 0; n < list.length; ++n) {
      if (list[n].getElementsByTagName("input")[0].value !== "") {
        list[n].classList.remove("is-empty")
      } else {
        list[n].classList.add("is-empty")
      }
    }
  }

  handleChange = (item, value) => {
    this.setState({ ...this.state, [item]: value })
    var des = document.getElementsByClassName("birthdate")
    var label = des[0].getElementsByTagName("label")
    label[0].style.top = "0px"
    label[0].style.fontSize = "10px"
  }
  refreshPage() {
    //this.context.router.push('/sign-in');
    location.reload()
  }

  handleChangeTxt = (item, event) => {
    this.setState({ [item]: event.target.value })
    /*var terms_condtions = document.getElementById("accepted_button");
		if (terms_condtions.checked == true){
			cookie.save('accepted_terms_cond',1);
		  } else {
			cookie.save('accepted_terms_cond',0);
		  }*/
    cookie.save("accepted_terms_cond", 1)
    document.getElementById("spn-otp-error").innerHTML = ""
  }

  submit_otp_verification() {
    var customer_otp_val = this.state.customer_otp_val

    if (customer_otp_val != "" && customer_otp_val != undefined) {
      document.getElementById("loadIcon").style.display = "block"

      var qs = require("qs")
      var postObject = { app_id: APPID, customer_otp_val: customer_otp_val, customer_email_id: cookie.load("customer_email"), verify_type: "register" }

      axios.post(APIURL + "customer/customer_otp_verification", qs.stringify(postObject)).then((response) => {
        if (response.data.status === "ok") {
          //helper.showInfo(response.data.message);

          var qs = require("qs")
          var postObject = {
            app_id: APPID,
            type: "web",
            customer_first_name: cookie.load("customer_first_name"),
            customer_last_name: cookie.load("customer_last_name"),
            customer_email: cookie.load("customer_email"),
            customer_password: cookie.load("customer_password"),
            customer_phone: cookie.load("customer_phone"),
            customer_portmakan_company: cookie.load("customer_portmakan_company"),
            customer_verification: "otp",
            site_url: cookie.load("client_site_url") + "#/",
          }

          axios
            .post(APIURL + "customer/registration", qs.stringify(postObject))
            .then((response) => {
              if (response.data.status === "ok") {
                cookie.remove("isTempUser")
                helper.showInfo("Your account has been activated successfully." + "<br/>" + "Please login to continue")
                cookie.remove("customer_first_name")
                cookie.remove("customer_last_name")
                cookie.remove("customer_email")
                cookie.remove("customer_password")
                cookie.remove("customer_phone")
                cookie.remove("customer_portmakan_company")
                cookie.remove("client_site_url")
                hashHistory.push("/sign-in")
                document.getElementById("loadIcon").style.display = "none"
              } else {
                this.setStateLoading("error")
                if (response.data.form_error) {
                  document.getElementById("form-error").innerHTML = '<div class="box_error"><ul><li>' + response.data.form_error + "</li></ul></div>"
                  document.getElementById("loadIcon").style.display = "none"
                } else {
                  document.getElementById("form-error").innerHTML = '<div class="box_error"><ul><li>' + response.data.message + "</li></ul></div>"
                  document.getElementById("loadIcon").style.display = "none"
                }
              }
              return false
            })
            .catch(function (error) {
              console.log(error)
            })
        } else {
          document.getElementById("spn-otp-error").innerHTML = '<span class="spn-error">' + response.data.message + "</span>"
          document.getElementById("loadIcon").style.display = "none"
        }
      })
    } else {
      document.getElementById("spn-otp-error").innerHTML = '<span class="spn-error">Enter your valid OTP.</span>'
      document.getElementById("loadIcon").style.display = "none"
    }
  }

  handleResendotp() {
    var qs = require("qs")
    var postObject = { app_id: APPID, customer_email: cookie.load("customer_email"), customer_mobile: cookie.load("customer_phone"), customer_first_name: cookie.load("customer_first_name"), customer_last_name: cookie.load("customer_last_name") }

    document.getElementById("loadIcon").style.display = "block"

    axios.post(APIURL + "customer/resend_otp", qs.stringify(postObject)).then((response) => {
      if (response.data.status === "ok") {
        document.getElementById("loadIcon").style.display = "none"
        document.getElementById("resend_otp_button").style.display = "none"
        helper.showInfo(response.data.message)
      } else {
        document.getElementById("loadIcon").style.display = "none"
        document.getElementById("spn-otp-verified-error").innerHTML = '<span class="spn-error">' + response.data.message + "</span>"
        setTimeout(function () {
          document.getElementById("spn-otp-verified-error").innerHTML = ""
        }, 3000)
      }
    })
  }

  backto_register() {
    document.getElementById("register_form_option").style.display = "block"
    document.getElementById("otp_verification_option").style.display = "none"
    document.getElementById("resend_otp_button").style.display = "block"
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } = this.props
    const spanStyle = {
      clear: "both",
    }
    let errMsgFirstName, errMsgLastName, errMsgEmail, errMsgPassword, errMsgrePassword, errMsgMobile, errMsgAlcohol, errMsgTerms

    if ($validation.firstname.error.reason !== undefined) {
      errMsgFirstName = $validation.firstname.show && <span className="spn-error">{$validation.firstname.error.reason}</span>
    }
    if ($validation.lastname.error.reason !== undefined) {
      errMsgLastName = $validation.lastname.show && <span className="spn-error">{$validation.lastname.error.reason}</span>
    }
    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && <span className="spn-error">{$validation.email.error.reason}</span>
    }
    if ($validation.password.error.reason !== undefined) {
      errMsgPassword = $validation.password.show && <span className="spn-error">{$validation.password.error.reason}</span>
    }
    if ($validation.rePassword.error.reason !== undefined) {
      errMsgrePassword = $validation.rePassword.show && <span className="spn-error">{$validation.rePassword.error.reason}</span>
    }
    if ($validation.mobile.error.reason !== undefined) {
      errMsgMobile = $validation.mobile.show && <span className="spn-error">{$validation.mobile.error.reason}</span>
    }
    if (APPID === "2AE048E7-2D84-42BE-9BB5-22016FC4DFBC" || APPID === "F34559CA-596A-4F2E-8547-17B2AEBB3326") {
      if ($validation.accepted_alcohol.error.reason !== undefined) {
        errMsgAlcohol = $validation.accepted_alcohol.show && <span className="accepted_checkbox_error">{$validation.accepted_alcohol.error.reason}</span>
      }
    }

    if ($validation.terms.error.reason !== undefined) {
      errMsgTerms = $validation.terms.show && <span className="accepted_checkbox_error">{$validation.terms.error.reason}</span>
    }

    return (
      <ThemeProvider theme={theme}>
        <div>
          <DocumentMeta {...meta} />
          <div id="loadIcon" style={{ display: "none", bottom: 0, left: 0, position: "fixed", right: 0, top: 0, margin: "auto", zIndex: 99, background: "rgba(255, 255, 255, 0.8)" }}>
            {" "}
            <img style={{ bottom: 0, left: 0, position: "absolute", right: 0, top: 0, margin: "auto" }} src="/img/loader.gif" alt="Loading" />{" "}
          </div>
          <div className="container-fluid desktop-container" style={{ minHeight: this.state.windowHeightNormal }}>
            <div id="register-form">
              <div className="row">
                <div className="col-xs-12 main-title-bar">
                  <Link to={"/sign-in"}>
                    {" "}
                    <img src="/img/left-arrow.png" className="pull-left icon-img-small" alt="left-arrow" />{" "}
                  </Link>
                  <h3 className="top-title main-title padding-right-20 text-uppercase">Account Information</h3>
                </div>
              </div>
              <div className="row">
                <div className="space-10 bg-gray"></div>
              </div>
              <div className="white-outer center-block">
                <div className="col-xs-10 div-center">
                  <div className="register_form_option" id="register_form_option">
                    <div className="space-20"></div>
                    <p className="text-center font-bold font-size-18">Register Your Account</p>
                    <div className="space-5"></div>
                    <div id="form-error"></div>
                    <div className="form-group label-floating card-no input-form-group">
                      <div className="input-group">
                        <label className="control-label">
                          First Name<span>*</span>
                        </label>
                        <input type="text" className="form-control" value={fields.firstname} {...$field("firstname", (e) => onChange("firstname", e.target.value))} />
                        {errMsgFirstName}
                      </div>
                    </div>
                    <div className="form-group label-floating card-no input-form-group">
                      <div className="input-group">
                        <label className="control-label">
                          Last Name<span>*</span>
                        </label>
                        <input type="text" className="form-control" value={fields.lastname} {...$field("lastname", (e) => onChange("lastname", e.target.value))} />
                        {errMsgLastName}
                      </div>
                    </div>
                    <div className="form-group label-floating card-no input-form-group">
                      <div className="input-group">
                        <label className="control-label">
                          Email Address<span>*</span>
                        </label>
                        <input type="text" className="form-control" value={fields.email} {...$field("email", (e) => onChange("email", e.target.value))} />
                        <div id="spn-email-error">{errMsgEmail}</div>
                      </div>
                    </div>
                    <div className="form-group label-floating card-no input-form-group">
                      <div className="input-group">
                        <label className="control-label">
                          Password<span>*</span>
                        </label>
                        <input type="password" className="form-control" value={fields.password} {...$field("password", (e) => onChange("password", e.target.value))} />
                        {errMsgPassword}
                      </div>
                    </div>
                    <div className="form-group label-floating card-no input-form-group">
                      <div className="input-group">
                        <label className="control-label">
                          Re Enter Password<span>*</span>
                        </label>
                        <input type="password" className="form-control" value={fields.rePassword} {...$field("rePassword", (e) => onChange("rePassword", e.target.value))} />
                        {errMsgrePassword}
                      </div>
                    </div>
                    <div className="form-group label-floating card-no input-form-group">
                      <div className="input-group">
                        <label className="control-label">
                          Mobile Number<span>*</span>
                        </label>
                        <input type="text" className="form-control" value={fields.mobile} {...$field("mobile", (e) => onChange("mobile", e.target.value))} />
                        <div id="spn-mobile-error">{errMsgMobile}</div>
                      </div>
                    </div>

                    {APPID === "CD8D5FE3-0586-44DD-9360-EE9DEF351223" ? (
                      <div className="select_box_input_group ">
                        <label className="select_box_label"></label>
                        <select className="select_box_form_control" name="portmakan_company" value={fields.portmakan_company} {...$field("portmakan_company", (e) => onChange("portmakan_company", e.target.value))}>
                          <option value="">Select Company</option>
                          <option value="PSA">PSA</option>
                          <option value="HTPS">HT Ports Services Pte Ltd</option>
                          <option value="BFL">Big Foot Logistics Pte Ltd</option>
                          <option value="SFS">Seafront Support Pte Ltd</option>
                          <option value="BJ">BJ Harvest Services Pte Ltd</option>
                          <option value="GIL">Greensafe International Pte Ltd</option>
                          <option value="RGH">Royal Greyhound Pte Ltd</option>
                          <option value="KET">Koon Seng Enterprises Pte Ltd</option>
                          <option value="HOY">Hoy San Stevedoring Pte Ltd</option>
                          <option value="CLA">Call Lade Enterprises Pte Ltd</option>
                          <option value="SRM">SMS Marine Pte Ltd</option>
                          <option value="TNO">TNS Ocean Lines (S) Pte Ltd</option>
                        </select>
                        <div id="spn-portmakan_company-error"></div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/*   <div className="form-group label-floating card-no input-form-group">
                        <div className="input-group birthdate"> 
                            <DatePicker className="birthdate" label='Birthday' sundayFirstDayOfWeek onChange={this.handleChange.bind(this, 'date1')} value={this.state.date1} />                             
                            <div id="spn-birthday-error" style={spanStyle}></div>
                        </div>
                    </div>
                    <div className="form-group label-floating card-no gender">
						<div className="input-group"> 
							<label className="control-label">Gender</label>
							<select className="form-control select-gender">
							  <option value="M">Male</option>
							  <option value="F">Female</option>
							</select>
						</div>
					</div> 
                    <div className="clearfix"></div>

                    <div className="form-group label-floating card-no input-form-group">
                        <div className="input-group">
                            <label className="control-label">Company Name</label>
                            <input type="text" className="form-control" value={fields.company} {...$field('company', (e) => onChange('company', e.target.value))}/>
                        </div>
                    </div>
	  <div className="space-20" ></div> */}

                    <div className="cbox_agree_items_wrap">
                      {APPID === "2AE048E7-2D84-42BE-9BB5-22016FC4DFBC" || APPID === "F34559CA-596A-4F2E-8547-17B2AEBB3326" ? (
                        <div className="checkbox_sign_up">
                          <br />
                          <input type="checkbox" id="accepted_alcohol" checked={fields.accepted_alcohol} {...$field("accepted_alcohol", (e) => onChange("accepted_alcohol", e.target.value), null, false)} />

                          <p>You agree that you are of legal age to purchase and consume alcohol in the locale in which the alcohol is delivered and acknowledge and agree to provide valid government-issued identification upon delivery</p>
                          {errMsgAlcohol}
                        </div>
                      ) : (
                        <div>
                          <input type="hidden" id="accepted_alcohol" value="1" {...$field("accepted_alcohol", (e) => onChange("accepted_alcohol", e.target.value), null, false)} />
                        </div>
                      )}

                      <div className="checkbox_sign_up">
                        {APPID !== "2AE048E7-2D84-42BE-9BB5-22016FC4DFBC" && APPID !== "F34559CA-596A-4F2E-8547-17B2AEBB3326" && <br />}
                        <input type="checkbox" id="terms" checked={fields.terms} defaultChecked={true} disabled="disabled" {...$field("terms", (e) => onChange("terms", e.target.value), null, false)} />

                        <p>I agree to the Privacy Policy and Terms of Service of {cookie.load("client_company_name")}</p>
                        {errMsgTerms}
                      </div>
                      <div className="checkbox_sign_up">
                        <input type="checkbox" id="accepted_coupons" checked={fields.accepted_coupons} {...$field("accepted_coupons", (e) => onChange("accepted_coupons", e.target.value), null, false)} />
                        <p>I consent to receive coupons and promotions news from {cookie.load("client_company_name")}, its franchisees and affiliates, and understand and agree that my information will be for the above. </p>
                        <div id="spn-accept-error"></div>
                      </div>
                      {/*<div className="checkbox_sign_up">
					       <input type="checkbox" defaultValue />
                               <p>Yes, I would like to receive promotions and updates from pastaMania sent to email address and telephone number(s) provided above.</p>
                        </div>
                        <form role="form">
                    <div className="checkbox">
                    <label><input type="checkbox" value=""/></label>
                    <p>Yes, I have read and agree to pastaMania's <a >Terms & Conditions</a> and <a >Privacy Policy</a></p>
                    </div>
                    <div className="checkbox">
                    <label><input type="checkbox" value=""/></label>
                    <p>Yes, I would like to receive promotions and updates from pastaMania sent to email address and telephone number(s) provided above.</p>
                    </div>
                        </form>
             
                    <div className="checkbox">
                    <label><input type="checkbox" value="" />Yes, I have read and agree to pastaMania's <a >Terms & Conditions</a> and <a >Privacy Policy</a></label>
                    </div>
                    <div className="checkbox">
                    <label><input type="checkbox" value="" />Yes, I would like to receive promotions and updates from pastaMania sent to email address and telephone number(s) provided above.</label>
                    </div> 
						*/}
                    </div>
                    <div className="mandatory">
                      <span>*</span> Mandatory Fields
                    </div>
                    <div className="text-center ">
                      <button
                        type="button"
                        className="btn btn-raised btn-info full-width-btn-2 text-uppercase"
                        onClick={(e) => {
                          e.preventDefault()
                          this.props.$submit(onValid, onInvalid)
                        }}
                      >
                        Register
                      </button>
                    </div>
                  </div>
                  <div className="space-20" style={{ display: "none" }} id="otp_verification_option">
                    <a className="back_to_register" onClick={this.backto_register.bind(this)}>
                      Back to Register
                    </a>
                    <p className="text-center font-bold font-size-18">OTP Verification</p>
                    <form
                      className="customer_otp"
                      id="customer_otp"
                      onSubmit={(e) => {
                        this.submit_otp_verification(this)
                        e.preventDefault()
                      }}
                    >
                      <div id="spn-otp-verified-error"></div>
                      <div className="form-group label-floating card-no input-form-group">
                        <div className="input-group">
                          <label className="control-label">
                            Enter your OTP<span>*</span>
                          </label>
                          <input type="text" name="customer_otp_val" className="form-control" value={this.state.customer_otp_val} onChange={this.handleChangeTxt.bind(this, "customer_otp_val")} />
                          <div id="spn-otp-error"></div>
                        </div>
                      </div>

                      <div className="text-center ">
                        <button type="button" className="btn btn-raised btn-info full-width-btn-2 text-uppercase" onClick={this.submit_otp_verification.bind(this)}>
                          Submit
                        </button>
                      </div>
                    </form>

                    <a className="resent_otp" id="resend_otp_button" onClick={this.handleResendotp.bind(this)}>
                      Resent OTP
                    </a>
                  </div>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    )
  }
}
Form = validated(validationConfig)(Form)

export default Signup

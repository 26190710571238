/* eslint-disable */
import React from "react"
import ReactDOM from "react-dom"
import { Router, Route, IndexRoute, hashHistory } from "react-router"
import App from "./App"
import "./index.css"
import Home from "./Home"
import Pages from "./Pages"
import Contactus from "./Contactus"
import Products from "./Products"
import Productsdetails from "./Productsdetails" //Cartitemsedit
import Cartitemsedit from "./Cartitemsedit" //Cartitemsedit
import Cart from "./Cart"
import Login from "./Signin"
import NewSignin from "./Otpsignin"
import Signup from "./Signup"
import Forgotpassword from "./Forgotpassword"
import Resetpassword from "./Resetpassword"
import Myaccount from "./Myaccount"
import Addons from "./Addons"
import Qrcodescan from "./Qrcodescan"
import QrCodeReader from "./QrCodeReader"
import Chekout from "./Chekout"
import Placeorder from './Placeorder'
import Payment from "./Payment"
import Ordersuccess from "./Ordersuccess"
import StripeCheckout from "./StripeCheckout"
import Orders from "./Orders"
import DeliveryDatePicker from "./DeliveryDatePicker"
import DeliveryDatePickerNew from "./DeliveryDatePickerNew"
import Advancedtimeslot from "./Advancedtimeslot"
import NotFound from "./NotFound"
import Activation from "./Activation"
import Unsubscribe from "./Unsubscribe"
import Rewards from "./Rewards"
import Reservation from "./Reservation"
import ReservationActivation from "./ReservationActivation"
import Promotion from "./Promotion"
import Promobuddytracking from "./Promobuddytracking"
import Reservationaccept from "./Reservationaccept"
import TagManager from "react-gtm-module"
import cookie from "react-cookie"
import Quickbook from "./Quickbook"
import Vouchers from "./Vouchers"
import Paymentrequest from "./Paymentrequest"

import KoomipayComplete from "./KoomipayComplete"

/* for GTM */
var gtmID = typeof cookie.load("gtm_id") !== null ? cookie.load("gtm_id") : ""
if (gtmID) {
  const tagManagerArgs = {
    gtmId: gtmID,
  }
  TagManager.initialize(tagManagerArgs)
}

ReactDOM.render(
  <Router history={hashHistory}>
    {/*  <Route path="*" component={App}>  </Route> */}
    <Route path="/" component={App}>
      <IndexRoute component={Home} />
      <Route path="/home" component={Home} />
      <Route path="/promotion" component={Promotion} />
      {/* <Route path="/about-us" component={Aboutus} />
		  <Route path="/terms-conditions" component={Terms} /> */}
      <Route path="/pages/:pageSlug" component={Pages} />
      <Route path="/module/:pageSlug" component={Contactus} />
      <Route path="/products/:categoryType/:categoryId" component={Products} />
      <Route path="/products/:categoryType/:categoryId/:slideno" component={Products} />
      <Route path="/product/:productSlug" component={Productsdetails} />
      <Route path="/product/:productSlug/:type/:cartItemId" component={Productsdetails} />
      <Route path="/cart" component={Cart} />
      <Route path="/add-on" component={Addons} />
      <Route path="/sign-in" component={Login} />
      <Route path="/new-signin" component={NewSignin} />
      <Route path="/sign-up" component={Signup} />
      <Route path="/account/activation/:activationId" component={Activation} />
      <Route path="/unsubscribe/:activationId" component={Unsubscribe} />
      <Route path="/account/resetpassword/:resetId" component={Resetpassword} />
      <Route path="/forgot-password" component={Forgotpassword} />
      <Route path="/book-a-table" component={Qrcodescan} />
      <Route path="/book-a-table/:tableNo" component={Qrcodescan} />
      <Route path="/qrcode-scan" component={QrCodeReader} />
      <Route path="/checkout/koomipay-complete" component={KoomipayComplete} />
      <Route path="/checkout" component={Chekout} />
      <Route path="/placeorder" component={Placeorder} />
      <Route path="/order-success/:orderId" component={Ordersuccess} />
      <Route path="/StripeCheckout" component={StripeCheckout} />
      <Route path="/Promobuddytracking/:token/:tokenId" component={Promobuddytracking} />
      <Route path="/chopchoptracking/:token/:tokenId" component={Promobuddytracking} />
      <Route path="/my-account" component={Myaccount} />
      <Route path="/orders" component={Orders} />
      <Route path="/rewards" component={Rewards} />
      <Route path="/reservation" component={Reservation} />
      <Route path="/dashboard/:UserId" component={Myaccount} />
      <Route path="/pay-now" component={Payment} />
      <Route path="/delivery-date" component={DeliveryDatePicker} />
      <Route path="/delivery-date-new" component={DeliveryDatePickerNew} />
      <Route path="/delivery-date-advanced" component={Advancedtimeslot} />
      <Route path="/cartreservation_suggest/:type/:keyval" component={ReservationActivation} />
      <Route path="/reservation_suggest/accept/:reservationID" component={Reservationaccept} />
      <Route path="/reservation_suggest/reject/:reservationID" component={Reservationaccept} />
      <Route path="/payment-request/:orderId/:paymentId" component={Paymentrequest} />
      <Route path="/quickbook/:tableNo" component={Quickbook} />
      <Route path="/vouchers" component={Vouchers} />
      <Route path="*" component={NotFound} />
    </Route>
  </Router>,

  document.getElementById("root")
)

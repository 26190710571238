/* eslint-disable */
import React from "react"
import { loadAnalytics } from "./withTracker"
import { Link } from "react-router"
import update from "immutability-helper"
import validator from "validator"
import { validated } from "react-custom-validation"
import axios from "axios"
import DocumentMeta from "react-document-meta"
import getResult from "./Api"
// import Loading from "./loading"
import Alert from "./alert"
// import cookie from "react-cookie"

import config from "./config"
import lang from "./lang"
//const APIURL = config.path.apiUrl;
const APIURLPRO = config.path.apiUrlPro
const APPID = config.ids.appId
var helper = require("./Helper.js")
const initialState = {
  fields: {
    email: "",
    status: "Loading",
    settings: [],
  },
}

/* Load meta tags */
const meta = {
  title: lang.forgotpassword_meta_title,
  description: lang.forgotpassword_meta_title,
  meta: {
    name: {
      keywords: lang.forgotpassword_meta_title,
    },
  },
}
class Forgotpassword extends React.Component {
  state = {
    fields: {
      email: "",
      status: "Loading",
    },
  }

  componentWillMount() {
    this.setState({ status: "ok" })

    /*add class */
    helper.addBodyClass()
  }

  componentDidMount() {
    /* update settings values */
    var promise = getResult("settings", "")
    promise.then((res) => {
      console.log(res.data.result_set)
      this.setState({ settings: res.data.result_set })
    })

    loadAnalytics()
  }

  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }))
  }
  setStateLoading(sts) {
    this.setState({ status: sts })
  }
  handleFormSubmit = () => {
    const formPayload = this.state.fields

    var qs = require("qs")
    var postObject = {
      app_id: APPID,
      type: "web",
      email_address: formPayload.email,
      site_url: this.state.settings.client_site_url + "#/",
    }
    this.setStateLoading("Loading")
    document.getElementById("loadIcon").style.display = "block"
    axios
      .post(APIURLPRO + "customer/forgot_password", qs.stringify(postObject))
      .then((response) => {
        document.getElementById("loadIcon").style.display = "none"
        if (response.data.status === "ok") {
          this.setStateLoading("ok")
          //document.getElementById("form-msg").innerHTML='<div class="alert-box" id="AlertBox" style="display: block;"><p class="success-msg alert-msg">An email has been sent to your mail with reset password link<a class="close-icon"><i class="fa fa-times-circle-o" aria-hidden="true"></i></a></p></div>';
          //cookie.save('alertSuccess','An email has been sent to your mail with reset password link');
          //$("#Forgot-Success").modal("toggle");
          helper.showInfo("An email address has been sent to your mail with reset password link.")
          this.setState(initialState)
          var list = document.getElementsByClassName("spn-error")
          var n
          for (n = 0; n < list.length; ++n) {
            list[n].remove()
          }
          var list1 = document.getElementsByClassName("form-group")
          var n1
          for (n1 = 0; n1 < list1.length; ++n1) {
            list1[n1].classList.add("is-empty")
          }
        } else {
          this.setStateLoading("error")
          //$("#Forgot-Error").modal("toggle");
          //document.getElementById("form-msg").innerHTML='<div class="box_error"><ul><li>'+response.data.message+'</li></ul></div>';
          helper.showInfo(response.data.message)
          var list1 = document.getElementsByClassName("form-group")
          var n1
          for (n1 = 0; n1 < list1.length; ++n1) {
            list1[n1].classList.remove("is-empty")
          }
        }
        document.getElementById("loadIcon").style.display = "none"
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  render() {
    return <Form fields={this.state.fields} onChange={this.fieldChange} onValid={this.handleFormSubmit} onInvalid={() => console.log("Form invalid!")} />
  }
}
const isEmpty = (value) => (value === "" ? "This field is required." : null)

const isEmail = (email) => (validator.isEmail(email) ? null : "This is not a valid email.")

function validationConfig(props) {
  const { email } = props.fields

  return {
    fields: ["email"],

    validations: {
      email: [
        [isEmpty, email],
        [isEmail, email],
        //[isUnique, email]
      ],
    },
  }
}

class Form extends React.Component {
  constructor(props) {
    super(props)
    /* Declare state */
    this.state = { windowHeightNormal: 0, windowHeight: 0 }
  }

  componentWillMount() {
    var windowHeight = window.innerHeight
    this.setState({ windowHeight: windowHeight - 45 + "px" })
    this.setState({ windowHeightNormal: windowHeight + "px" })
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } = this.props
    let errMsgEmail
    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && <span className="spn-error">{$validation.email.error.reason}</span>
    }
    return (
      <div>
        {" "}
        <DocumentMeta {...meta} />
        <Alert />
        <div id="loadIcon" style={{ display: "none", bottom: 0, left: 0, position: "fixed", right: 0, top: 0, margin: "auto", zIndex: 99, background: "rgba(255, 255, 255, 0.8)" }}>
          {" "}
          <img style={{ bottom: 0, left: 0, position: "absolute", right: 0, top: 0, margin: "auto" }} src="/img/loader.gif" alt="Loading" />{" "}
        </div>
        <div className="container-fluid desktop-container" style={{ minHeight: this.state.windowHeightNormal }}>
          <div className="row">
            <div className="col-xs-12 main-title-bar">
              <Link to={"/sign-in"}>
                {" "}
                <img src="/img/left-arrow.png" className="pull-left icon-img-small" alt="left-arrow" />{" "}
              </Link>
              <h3 className="top-title main-title padding-right-20 text-uppercase">forget password</h3>
            </div>
          </div>
          <div className="row">
            <div className="space-10 bg-gray"></div>
          </div>

          <div className="white-outer center-block" id="forgotpassword-form">
            <div className="col-xs-10 div-center">
              <div className="space-20"></div>
              <p className="text-center font-bold font-size-18">Enter Your Email Address</p>
              <div id="form-msg"></div>
              <div className="space-5"></div>
              <div className="form-group label-floating is-empty card-no">
                <div className="input-group">
                  <label className="control-label" htmlFor="card">
                    Email Address
                  </label>
                  <input type="text" className="form-control" value={fields.email} {...$field("email", (e) => onChange("email", e.target.value))} />
                  {errMsgEmail}
                </div>
              </div>
              <div className="space-20"></div>
              <div className="text-center ">
                <button
                  type="button"
                  className="btn btn-raised btn-info full-width-btn text-uppercase"
                  onClick={(e) => {
                    e.preventDefault()
                    this.props.$submit(onValid, onInvalid)
                  }}
                >
                  send<div className="ripple-container"></div>
                </button>
                <br />
              </div>
              <div className="space-20"></div>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-10"></div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-30"></div>
        </div>
        {/* For Forgot Success */}
        <div className="modal" id="Forgot-Success">
          <div className="modal-dialog PopupWidth">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
                </button>
              </div>
              <div className="modal-body text-center">
                <p>
                  <b>An email address has been sent to your mail with reset password link.</b> <br /> <br />
                  <br />
                </p>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
        {/* For Forgot Error */}
        <div className="modal" id="Forgot-Error">
          <div className="modal-dialog PopupWidth">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
                </button>
              </div>
              <div className="modal-body text-center">
                <p>
                  <b>An email address was not found in our records.</b> <br /> <br />
                  <br />
                </p>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
Form = validated(validationConfig)(Form)

export default Forgotpassword

/* eslint-disable */
import React from "react"
import { loadAnalytics } from "./withTracker"
import { Link, hashHistory } from "react-router"
import update from "immutability-helper"
import { validated } from "react-custom-validation"
import axios from "axios"
import DocumentMeta from "react-document-meta"
import cookie from "react-cookie"

import lang from "./lang"
import config from "./config"
const APIURL = config.path.apiUrl
const APPID = config.ids.appId
var helper = require("./Helper.js")
helper.addBodyClass()
/* Load meta tags */
const meta = {
  title: lang.reset_meta_title,
  description: lang.reset_meta_title,
  meta: {
    name: {
      keywords: lang.reset_meta_title,
    },
  },
}

const initialState = {
  fields: {
    password: "",
    rePassword: "",
    activationId: "",
  },
}
class Resetpassword extends React.Component {
  state = {
    fields: {
      password: "",
      rePassword: "",
    },
  }

  componentDidMount() {
    loadAnalytics()
    var qs = require("qs")
    var activationId = this.props.params.resetId
    this.setState({ activationId: activationId })

    /* for account activation - start */
    if (typeof activationId === "undefined" || activationId === "") {
      helper.showInfo(lang.reset_key_invalid)
      hashHistory.push("/sign-in")
    }
  }
  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }))
  }

  handleFormSubmit = () => {
    const formPayload = this.state.fields
    var qs = require("qs")
    var postObject = {
      app_id: APPID,
      type: "web",
      key: this.state.activationId,
      password: formPayload.password,
      confirmpassword: formPayload.rePassword,
    }
    document.getElementById("loadIcon").style.display = "block"
    axios
      .post(APIURL + "customer/resetpassword", qs.stringify(postObject))
      .then((response) => {
        if (response.data.status === "ok") {
          // cookie.save('alertSuccess',response.data.message);
          helper.showInfo("Password has been updated successfully.")
          hashHistory.push("/sign-in")
          //  $("#Reset-Success").modal("toggle");
          return false
        } else if (response.data.status === "error") {
          //cookie.save('alertError',response.data.message);
          helper.showInfo("The verification link has been expired.")
          hashHistory.push("/sign-in")
          //$("#Reset-Error").modal("toggle");
          return false
        }
        document.getElementById("loadIcon").style.display = "none"
        return false
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  render() {
    return <Form fields={this.state.fields} onChange={this.fieldChange} onValid={this.handleFormSubmit} onInvalid={() => console.log("Form invalid!")} />
  }
}
const isEmpty = (value) => (value === "" ? "This field is required." : null)

const minLength = (password, length) => (password.length >= length ? null : "Password is too short.")

const areSame = (password, rePassword) => (password === rePassword ? null : "Passwords do not match.")

function validationConfig(props) {
  const { password, rePassword } = props.fields

  return {
    fields: ["password", "rePassword"],

    validations: {
      password: [
        [isEmpty, password],
        [minLength, password, 6],
      ],
      rePassword: {
        rules: [
          [isEmpty, rePassword],
          [areSame, password, rePassword],
        ],
        fields: ["password", "rePassword"],
      },
    },
  }
}

class Form extends React.Component {
  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } = this.props
    let errPassword, errConfirmPassword
    if ($validation.password.error.reason !== undefined) {
      errPassword = $validation.password.show && <span className="spn-error">{$validation.password.error.reason}</span>
    }

    if ($validation.rePassword.error.reason !== undefined) {
      errConfirmPassword = $validation.rePassword.show && <span className="spn-error">{$validation.rePassword.error.reason}</span>
    }

    return (
      <div className="container-fluid desktop-container">
        <DocumentMeta {...meta} />
        <div id="loadIcon" style={{ display: "none", bottom: 0, left: 0, position: "fixed", right: 0, top: 0, margin: "auto", zIndex: 99, background: "rgba(255, 255, 255, 0.8)" }}>
          {" "}
          <img style={{ bottom: 0, left: 0, position: "absolute", right: 0, top: 0, margin: "auto" }} src="/img/loader.gif" alt="Loading" />{" "}
        </div>
        <div className="row">
          <div className="col-xs-12 main-title-bar">
            <Link to={"/sign-in"}>
              {" "}
              <img src="/img/left-arrow.png" className="pull-left icon-img-small" alt="left-arrow" />{" "}
            </Link>
            <h3 className="top-title main-title padding-right-20 text-uppercase">reset password</h3>
          </div>
        </div>
        <div className="row">
          <div className="space-10 bg-gray"></div>
        </div>
        <div className="white-outer center-block" id="form-success"></div>
        <div className="white-outer center-block" id="resetpassword-form">
          <div className="col-xs-10 div-center">
            <div className="space-20"></div>
            <p className="text-center font-bold font-size-18">Enter Your new password</p>
            <div className="space-5"></div>
            <div className="form-group label-floating is-empty card-no">
              <div className="input-group">
                <label className="control-label">Password</label>
                <input type="password" className="form-control" value={fields.password} {...$field("password", (e) => onChange("password", e.target.value))} />
                {errPassword}
              </div>
            </div>
            <div className="form-group label-floating is-empty card-no">
              <div className="input-group">
                <label className="control-label">Re Enter Password</label>
                <input type="password" className="form-control" value={fields.rePassword} {...$field("rePassword", (e) => onChange("rePassword", e.target.value))} />
                {errConfirmPassword}
              </div>
            </div>
            <div className="space-20" id="form-msg"></div>
            <div className="text-center ">
              <button
                type="button"
                className="btn btn-raised btn-info full-width-btn text-uppercase"
                onClick={(e) => {
                  e.preventDefault()
                  this.props.$submit(onValid, onInvalid)
                }}
              >
                Reset<div className="ripple-container"></div>
              </button>
              <br />
            </div>
            <div className="space-20"></div>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="space-30"></div>
        <div className="space-30"></div>
        <div className="space-10"></div>
      </div>
    )
  }
}
Form = validated(validationConfig)(Form)

export default Resetpassword

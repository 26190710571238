/* eslint-disable */
import React from "react"
import { loadAnalytics } from "./withTracker"
import { Link, hashHistory } from "react-router"
import update from "immutability-helper"
import validator from "validator"
import { validated } from "react-custom-validation"
import { DateField } from "react-date-picker"
// import 'react-date-picker/dist/DatePicker.css'
import axios from "axios"
import cookie from "react-cookie"
import getResult from "./Api"
import DocumentMeta from "react-document-meta"
import ThemeProvider from "react-toolbox/lib/ThemeProvider"
import theme from "./react-toolbox/theme.js"
import "./react-toolbox/theme.css"
import DatePicker from "react-toolbox/lib/date_picker/DatePicker"
import { ShareButtons, generateShareIcon } from "react-share"
import Loading from "./loading"
import Alert from "./alert"

import config from "./config"
const APIURL = config.path.apiUrl
const APIURLPRO = config.path.apiUrlPro
const APPID = config.ids.appId
import lang from "./lang"
const datetime = new Date(2015, 10, 16)
var helper = require("./Helper.js")
const apps = require("./apps")
//const social = require('./Social');

const { FacebookShareButton, GooglePlusShareButton, TwitterShareButton, WhatsappShareButton } = ShareButtons
const FacebookIcon = generateShareIcon("facebook")
const WhatsappIcon = generateShareIcon("whatsapp")
const TwitterIcon = generateShareIcon("twitter")
const LinkedinIcon = generateShareIcon("linkedin")
const GooglePlusIcon = generateShareIcon("google")

/* Load meta tags */
/* test commit
sdsadasd
sdsdsdsd
*/
const meta = {
  title: lang.myaccount_meta_title,
  description: lang.myaccount_meta_title,
  meta: {
    name: {
      keywords: lang.myaccount_meta_title,
    },
  },
}

class Myaccount extends React.Component {
  constructor(props) {
    super(props)

    /*add class */
    helper.addBodyClass()

    this.state = {
      fields: {
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        postal: "",
        unitnumber2: "",
        unitnumber1: "",
        company: "",
        birthday: "",
      },
      status: "Loading",
      reservationAvailable: "",
      defaultDispaly: "",
      settings: {},
    }

    /* check Authentication */
    if (getResult("Auth") === false) {
      hashHistory.push("/sign-in")
    }

    helper.isAnonymousUserRedirect()
    this.isTempUserRedirect()
  }

  isTempUserRedirect() {
    var isTempUser = parseInt(cookie.load("isTempUser"))
    console.log("isTempUser", isTempUser)
    if (isTempUser === 1) {
      cookie.remove("UserId")
      cookie.remove("UserFname")
      cookie.remove("UserLname")
      cookie.remove("UserMobile")
      cookie.remove("UserEmail")
      cookie.remove("userAccountType")
      cookie.remove("isAnonymousUser")
      this.setState({ login: "No" })
      setTimeout(function () {
        cookie.remove("isTempUser")
      }, 240000)

      hashHistory.push("/sign-in")
      helper.showInfo("YOU ARE TRYING TO ACCESS PRIVILAGED INFO , PLEASE USE YOUR PHONE NUMBER AND ATTACHED EMAIL TO ACCESS THIS PAGE.")
    }
  }

  componentDidMount() {
    /* Add script */
    loadAnalytics()

    /* API CALL FOR CUSTOMER DETAIL*/
    axios.get(APIURL + "/customer/customerdetail?app_id=" + APPID + "&refrence=" + cookie.load("UserId")).then((res) => {
      if (res.data.status === "ok") {
        this.setState({
          fields: {
            firstname: res.data.result_set.customer_first_name,
            lastname: res.data.result_set.customer_last_name,
            email: res.data.result_set.customer_email,
            mobile: res.data.result_set.customer_phone,
            postal: res.data.result_set.customer_postal_code,
            unitnumber2: res.data.result_set.customer_address_line2,
            unitnumber1: res.data.result_set.customer_address_line1,
            company: res.data.result_set.customer_company_name,
          },
          status: res.data.status,
        })
        var des = document.getElementsByClassName("birthdate")
        var fc = des[0].getElementsByTagName("div")
        var se = fc[0].getElementsByTagName("div")
        var input = se[0].getElementsByTagName("input")
        //alert(res.data.result_set.customer_birthdate);
        if (res.data.result_set.customer_birthdate == null) {
          input[0].value = ""
        } else {
          input[0].value = new Date(res.data.result_set.customer_birthdate)
        }
        if (res.data.result_set.customer_gender == "M") {
          document.getElementById("female").removeAttribute("selected")
          document.getElementById("male").setAttribute("selected", "selected")
        } else {
          document.getElementById("male").removeAttribute("selected")
          document.getElementById("female").setAttribute("selected", "selected")
        }
        var label = des[0].getElementsByTagName("label")
        label[0].style.top = "0px"
        label[0].style.fontSize = "10px"
        document.getElementById("postal-address").value = res.data.result_set.customer_address_name
        document.getElementById("postal-address").setAttribute("value", res.data.result_set.customer_address_name)
        var vDefaultAddr = ""
        if (res.data.result_set.customer_address_name !== "" && res.data.result_set.customer_address_name !== null) {
          vDefaultAddr += '<p><b>Previous Order Address</b></p><p id="user-default-address" >' + res.data.result_set.customer_address_name + "<br />"
        }
        if (res.data.result_set.customer_address_line1 !== "" && res.data.result_set.customer_address_line1 !== null) {
          vDefaultAddr += res.data.result_set.customer_address_line1 + "<br />"
        }
        if (res.data.result_set.customer_address_line2 !== "" && res.data.result_set.customer_address_line2 !== null) {
          vDefaultAddr += res.data.result_set.customer_address_line2 + "<br />"
        }
        if (res.data.result_set.customer_postal_code !== "" && res.data.result_set.customer_postal_code !== null) {
          vDefaultAddr += res.data.result_set.customer_postal_code
        }
        vDefaultAddr += "</p>"
        /*document.getElementById("user-default-billing-address").innerHTML=vDefaultAddr;*/
        var list = document.getElementsByClassName("form-group-input")
        var n
        for (n = 0; n < list.length; ++n) {
          if (list[n].getElementsByTagName("input")[0].value !== "") {
            list[n].classList.remove("is-empty")
          } else {
            list[n].classList.add("is-empty")
          }
        }
      } else {
        cookie.remove("UserId")
        cookie.remove("UserFname")
        cookie.remove("UserLname")
        cookie.remove("UserMobile")
        cookie.remove("UserEmail")
        hashHistory.push("/sign-in")
        //this.setState({status: res.data.status});
      }
    })

    axios.get(APIURLPRO + "reservation/check_availability?app_id=" + APPID + "&availability_id=" + config.ids.reservationId).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ reservationAvailable: "Yes" })
      }
    })
  }

  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }))
  }
  setStateLoading(sts) {
    this.setState({ status: sts })
  }
  handleFormSubmit = () => {
    const formPayload = this.state.fields
    var des = document.getElementsByClassName("birthdate")
    var input = des[0].getElementsByTagName("input")
    formPayload.birthday = input[0].value
    formPayload.address = document.getElementById("postal-address").value
    formPayload.gender = document.getElementsByClassName("select-gender")[0].value
    /*if(document.getElementById("user-default-address-id").value!== null){
			formPayload.defaultAddressId = document.getElementById("user-default-address-id").value;
		} else {
			formPayload.defaultAddressId = 0;
		}
		if(formPayload.birthday===''){
			document.getElementById("spn-birthday-error").innerHTML='<span class="spn-error">This field is required.</span>';
			return false;
		} else {
			document.getElementById("spn-birthday-error").innerHTML='';
		}

		if(formPayload.address===''){
			document.getElementById("spn-address-error").innerHTML='<span class="spn-error">This field is required.</span>';
			return false;
		} else {
			document.getElementById("spn-address-error").innerHTML='';
		}*/

    if (formPayload.unitnumber1 !== "") {
      if (isNaN(formPayload.unitnumber1) === true) {
        document.getElementById("spn-unitno1-error").innerHTML = '<span class="spn-error">This is not a valid number.</span>'
        vsuccess = false
      } else {
        document.getElementById("spn-unitno1-error").innerHTML = ""
      }
    }

    if (formPayload.unitnumber2 !== "") {
      if (isNaN(formPayload.unitnumber2) === true) {
        document.getElementById("spn-unitno2-error").innerHTML = '<span class="spn-error">This is not a valid number.</span>'
        vsuccess = false
      } else {
        document.getElementById("spn-unitno2-error").innerHTML = ""
      }
    }
    var qs = require("qs")
    var postObject = {
      app_id: APPID,
      type: "web",
      customer_first_name: formPayload.firstname,
      customer_last_name: formPayload.lastname,
      customer_phone: formPayload.mobile,
      customer_email: formPayload.email,
      customer_birthdate: formPayload.birthday,
      customer_postal_code: formPayload.postal,
      customer_address_name: formPayload.address,
      customer_company_name: formPayload.company,
      customer_gender: formPayload.gender,
      customer_address_line1: formPayload.unitnumber1,
      customer_address_line2: formPayload.unitnumber2,
      customer_id: cookie.load("UserId"),
    }
    this.setStateLoading("Loading")
    axios
      .post(APIURL + "customer/updateprofile", qs.stringify(postObject))
      .then((response) => {
        if (response.data.status === "ok") {
          this.setStateLoading("ok")
          //cookie.save("UserId",response.data.result_set.customer_id);
          cookie.save("UserFname", response.data.result_set.customer_first_name)
          cookie.save("UserLname", response.data.result_set.customer_last_name)
          cookie.save("UserMobile", response.data.result_set.customer_phone)
          cookie.save("UserDefaultAddress", response.data.result_set.customer_address_name)
          cookie.save("UserDefaultUnitOne", response.data.result_set.customer_address_line1)
          cookie.save("UserDefaultUnitTwo", response.data.result_set.customer_address_line2)
          cookie.save("UserDefaultPostalCode", response.data.result_set.customer_postal_code)

          /* Redirect to checkou page .. */
          if (cookie.load("isChecoutRedirect") === "Yes" && cookie.load("cartValue") === "Yes") {
            cookie.save("isChecoutRedirect", "No")
            // hashHistory.push("/checkout");
            if (cookie.load("defaultAvilablityId") === config.ids.dineinId) {
              hashHistory.push("/book-a-table")
            } else {
              hashHistory.push("/delivery-date")
            }
          }

          document.getElementById("AlertBox1").style.display = "block"
          document.getElementById("msg-text").innerHTML = response.data.message
          setTimeout(
            function () {
              document.getElementById("msg-text").innerHTML = ""
              document.getElementById("AlertBox1").style.display = "none"
            }.bind(this),
            3000
          )
        } else {
          this.setStateLoading("error")

          if (response.data.form_error) {
            document.getElementById("form-msg").innerHTML = '<div class="box_error"><ul><li>' + response.data.form_error + "</li></ul></div>"
          } else {
            document.getElementById("form-msg").innerHTML = '<div class="box_error"><ul><li>' + response.data.message + "</li></ul></div>"
          }
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  render() {
    if (this.state.status === "Loading") {
      return (
        <div className="container-fluid desktop-container">
          <div className="row"></div>
          <div className="row">
            <div className="space-10 bg-gray"></div>
          </div>
          <div className=" ">
            <div className="col-xs-10 div-center">
              <div className="space-5"></div>
              <Loading />
              <div className="space-5"></div>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-30"></div>
          <div className="space-5"></div>
          <div className="space-5"></div>
          <div className="space-30"></div> <div className="space-30"></div> <div className="space-30"></div> <div className="space-30"></div>
        </div>
      )
    } else {
      return (
        <div>
          <Alert />
          <DocumentMeta {...meta} />
          <Form fields={this.state.fields} onChange={this.fieldChange} onValid={this.handleFormSubmit} onInvalid={() => console.log("Form invalid!")} />
        </div>
      )
    }
  }
}

const phonenumberPattern = /^[0-9]{6,14}$/
const postalcodePattern = /^\d{6}$/

const isEmpty = (value) => (value === "" ? "This field is required." : null)

const isEmail = (email) => (validator.isEmail(email) ? null : "This is not a valid email.")

const isMobile = (mobile) => {
  if (mobile) {
    mobile.match(phonenumberPattern) ? null : "This is not a valid mobile number."
  }
}

const isMobileDigt = (mobile) => (mobile != null && mobile.length === 8 ? null : "This is not a valid mobile number.")

function validationConfig(props) {
  const { firstname, lastname, email, mobile } = props.fields

  return {
    fields: ["firstname", "lastname", "email", "mobile"],

    validations: {
      firstname: [[isEmpty, firstname]],
      lastname: [[isEmpty, lastname]],
      email: [[isEmail, email]],
      mobile: [
        [isEmpty, mobile],
        [isMobileDigt, mobile],
        [isMobile, mobile],
      ],
    },
  }
}

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: "Loading",
      secAddrData: [],
      date1: datetime,
      settings: {},
    }
  }

  handleChange = (item, value) => {
    this.setState({ ...this.state, [item]: value })
    var des = document.getElementsByClassName("birthdate")
    var label = des[0].getElementsByTagName("label")
    label[0].style.top = "0px"
    label[0].style.fontSize = "10px"
  }
  componentWillMount() {
    this.setStateLoading("ok")
    this.getSecAddr()

    var promise = getResult("settingsPro", "No")
    promise.then((res) => {
      this.setState({ settings: res.data.result_set })
    })
  }
  loadSecAddr(dataArr) {
    var htmlStr = dataArr.map(
      function (data, index) {
        return (
          <p id="user-default-address" key={index}>
            <a
              onClick={() => {
                this.DeleteSecAddr(data.secondary_address_id)
              }}
              title="Delete"
              style={{ width: "20px", float: "right", cursor: "pointer", paddingLeft: "10px" }}
            >
              <i className="fa fa-trash"></i>
            </a>
            <a
              onClick={() => {
                this.LoadEditSecAddr(data)
              }}
              title="Edit"
              style={{ width: "20px", float: "right", cursor: "pointer", marginRight: "10px" }}
            >
              <i className="fa fa-pencil"></i>
            </a>
            {data.address}
            {data.unit_code ? <br /> : ""}
            {data.unit_code ? helper.unitFormat(data.unit_code, data.unit_code2) : ""}

            {data.postal_code ? <br /> : ""}
            {data.postal_code ? data.postal_code : ""}
          </p>
        )
      }.bind(this)
    )
    return htmlStr
  }

  LoadEditSecAddr(dataArr) {
    $("html,body").animate({ scrollTop: $(".disabled-continue-as-a-guest").offset().top }, "slow")
    this.openNewAddress()
    document.getElementById("customer_postal_code").value = dataArr.postal_code
    document.getElementById("customer_address_line1").value = dataArr.address
    document.getElementById("customer_address_name").value = dataArr.unit_code
    document.getElementById("customer_address_name2").value = dataArr.unit_code2
    document.getElementById("address_id").value = dataArr.secondary_address_id
    var list = document.getElementsByClassName("address-form-group")
    var n
    for (n = 0; n < list.length; ++n) {
      if (list[n].getElementsByTagName("input")[0].value !== "") {
        list[n].classList.remove("is-empty")
      } else {
        list[n].classList.add("is-empty")
      }
    }
  }
  DeleteSecAddr(pmId) {
    this.setStateLoading("Loading")
    var qs = require("qs")
    var postObject = {
      app_id: APPID,
      type: "web",
      secondary_address_id: pmId,
      refrence: cookie.load("UserId"),
    }

    axios
      .post(APIURL + "customer/secondary_address_remove", qs.stringify(postObject))
      .then((response) => {
        if (response.data.status === "ok") {
          this.setStateLoading("ok")
          //cookie.save('alertSuccess',response.data.message);
          document.getElementById("AlertBox1").style.display = "block"
          document.getElementById("msg-text").innerHTML = response.data.message
          setTimeout(
            function () {
              document.getElementById("msg-text").innerHTML = ""
              document.getElementById("AlertBox1").style.display = "none"
            }.bind(this),
            3000
          )
          this.setState({ secAddrData: [] })
          this.getSecAddr()
        } else {
          this.setStateLoading("error")
          //document.getElementById("form-msg").innerHTML='<p class="spn-error">'+response.data.message+'</p>';
          document.getElementById("form-msg").innerHTML = '<div class="box_error"><ul><li>' + response.data.message + "</li></ul></div>"
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  loadPostalAddress() {
    var postal = document.getElementById("customer_postal_code").value
    if (postal.match(postalcodePattern)) {
      axios.get(APIURL + "/settings/get_address?app_id=" + APPID + "&zip_code=" + postal).then((res) => {
        if (res.data.status === "ok") {
          document.getElementById("customer_address_line1").value = res.data.result_set.zip_buno + " " + res.data.result_set.zip_sname
          //  document.getElementById("customer_address_line1").setAttribute("value",res.data.result_set.zip_sname);
          document.getElementById("spn-postalcode-error").innerHTML = ""
          var list = document.getElementsByClassName("form-group")
          var n
          for (n = 0; n < list.length; ++n) {
            if (document.getElementById("customer_address_line1").value !== "") {
              list[n].classList.remove("is-empty")
            } else {
              list[n].classList.add("is-empty")
            }
          }
        } else {
          document.getElementById("spn-postalcode-error").innerHTML = '<span class="spn-error">This is not a valid postal code.</span>'
          document.getElementById("customer_postal_code").value = ""
          document.getElementById("customer_postal_code").setAttribute("value", "")
          document.getElementById("customer_address_line1").value = ""
          document.getElementById("customer_address_line1").setAttribute("value", "")
        }
      })
    }
  }
  openNewAddress() {
    document.getElementsByClassName("new-user-reg")[0].style.display = "block"
    document.getElementById("checkout").classList.remove("disable-btn")
    document.getElementById("customer_postal_code").value = ""
    document.getElementById("customer_address_line1").value = ""
    document.getElementById("customer_address_name").value = ""
    document.getElementById("customer_address_name2").value = ""
    document.getElementById("address_id").value = ""
    var list = document.getElementsByClassName("address-form-group")
    var n
    for (n = 0; n < list.length; ++n) {
      list[n].classList.add("is-empty")
    }
  }
  setStateLoading(sts) {
    this.setState({ status: sts })
  }
  saveNewAddress() {
    var vsuccess = true
    var vPostalCode = document.getElementById("customer_postal_code").value
    var vAddress = document.getElementById("customer_address_line1").value
    var vUnitNo1 = document.getElementById("customer_address_name").value
    var vUnitNo2 = document.getElementById("customer_address_name2").value
    if (vPostalCode === "") {
      document.getElementById("spn-postalcode-error").innerHTML = '<span class="spn-error">This field is required.</span>'
      vsuccess = false
    } else if (vPostalCode !== "" && vPostalCode.match(postalcodePattern) === null) {
      document.getElementById("spn-postalcode-error").innerHTML = '<span class="spn-error">This is not a valid postal code.</span>'
      vsuccess = false
    } else {
      document.getElementById("spn-postalcode-error").innerHTML = ""
    }
    if (vAddress === "") {
      document.getElementById("spn-addr-error").innerHTML = '<span class="spn-error">This field is required.</span>'
      vsuccess = false
    } else {
      document.getElementById("spn-addr-error").innerHTML = ""
    }

    if (vUnitNo1 !== "") {
      if (isNaN(vUnitNo1) === true) {
        document.getElementById("spn-unit1-error").innerHTML = '<span class="spn-error">This is not a valid number.</span>'
        vsuccess = false
      } else {
        document.getElementById("spn-unit1-error").innerHTML = ""
      }
    }

    if (vUnitNo2 !== "") {
      if (isNaN(vUnitNo2) === true) {
        document.getElementById("spn-unit2-error").innerHTML = '<span class="spn-error">This is not a valid number.</span>'
        vsuccess = false
      } else {
        document.getElementById("spn-unit2-error").innerHTML = ""
      }
    }

    if (vsuccess) {
      this.setStateLoading("Loading")

      var qs = require("qs")

      if (document.getElementById("address_id").value !== "") {
        var PostUrl = APIURL + "customer/secondary_address_edit"
        var postObject = {
          app_id: APPID,
          type: "web",
          customer_first_name: cookie.load("UserFname"),
          customer_last_name: cookie.load("UserLname"),
          customer_email: cookie.load("UserEmail"),
          customer_address_line1: document.getElementById("customer_address_line1").value,
          customer_postal_code: document.getElementById("customer_postal_code").value,
          customer_address_name: document.getElementById("customer_address_name").value,
          customer_address_name2: document.getElementById("customer_address_name2").value,
          secondary_address_id: document.getElementById("address_id").value,
          refrence: cookie.load("UserId"),
        }
      } else {
        var PostUrl = APIURL + "customer/secondary_address_add"
        var postObject = {
          app_id: APPID,
          type: "web",
          customer_first_name: cookie.load("UserFname"),
          customer_last_name: cookie.load("UserLname"),
          customer_email: cookie.load("UserEmail"),
          customer_address_line1: document.getElementById("customer_address_line1").value,
          customer_postal_code: document.getElementById("customer_postal_code").value,
          customer_address_name: document.getElementById("customer_address_name").value,
          customer_address_name2: document.getElementById("customer_address_name2").value,
          refrence: cookie.load("UserId"),
        }
      }
      axios
        .post(PostUrl, qs.stringify(postObject))
        .then((response) => {
          if (response.data.status === "ok") {
            this.setStateLoading("ok")
            //cookie.save('alertSuccess',response.data.message);
            document.getElementById("AlertBox1").style.display = "block"
            document.getElementById("msg-text").innerHTML = response.data.message
            this.getSecAddr()
            setTimeout(
              function () {
                document.getElementById("msg-text").innerHTML = ""
                document.getElementById("AlertBox1").style.display = "none"
              }.bind(this),
              3000
            )
          } else {
            this.setStateLoading("error")
            //document.getElementById("form-msg").innerHTML='<p class="spn-error">'+response.data.message+'</p>';
            document.getElementById("form-msg").innerHTML = '<div class="box_error"><ul><li>' + response.data.message + "</li></ul></div>"
          }
          //this.openNewAddress();
        })
        .catch(function (error) {
          console.log(error)
        })
    }
    var select = document.getElementsByClassName("select-gender")[0]
    select.style.background = "none"
    select.style.margin = "0 0 0 6px"
    select.style.fontWeight = "bold"
    select.style.fontSize = "14px"
    select.style.padding = "8px 0 0 0"
    return false
  }
  getSecAddr() {
    axios.get(APIURL + "/customer/get_user_secondary_address?app_id=" + APPID + "&refrence=" + cookie.load("UserId")).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ secAddrData: res.data.result_set })
        var select = document.getElementsByClassName("select-gender")[0]
        select.style.background = "none"
        select.style.margin = "0 0 0 6px"
        select.style.fontWeight = "bold"
        select.style.fontSize = "14px"
        select.style.padding = "8px 0 0 0"
        document.getElementsByClassName("gender")[0].classList.remove("is-empty")
        var des = document.getElementsByClassName("birthdate")
        var fc = des[0].getElementsByTagName("div")
        var se = fc[0].getElementsByTagName("div")
        se[0].style.padding = "0px"
        var input = se[0].getElementsByTagName("input")
        input[0].style.borderBottom = "0px"
        var label = des[0].getElementsByTagName("label")
        label[0].style.top = "0px"
        label[0].style.fontSize = "10px"
      }
    })

    /* API CALL FOR CUSTOMER DETAIL*/
    axios.get(APIURL + "/customer/customerdetail?app_id=" + APPID + "&refrence=" + cookie.load("UserId")).then((res) => {
      if (res.data.status === "ok") {
        var des = document.getElementsByClassName("birthdate")
        var fc = des[0].getElementsByTagName("div")
        var se = fc[0].getElementsByTagName("div")
        var input = se[0].getElementsByTagName("input")
        if (res.data.result_set.customer_birthdate == "0000-00-00" || res.data.result_set.customer_birthdate == null) {
          input[0].value = res.data.result_set.customer_birthdate
          input[0].setAttribute("value", res.data.result_set.customer_birthdate)

          this.setState({ date1: "" })
        } else {
          input[0].value = res.data.result_set.customer_birthdate
          input[0].setAttribute("value", res.data.result_set.customer_birthdate)

          this.setState({ date1: new Date(res.data.result_set.customer_birthdate) })
        }
        if (res.data.result_set.customer_gender == "M") {
          document.getElementById("female").removeAttribute("selected")
          document.getElementById("male").setAttribute("selected", "selected")
        } else {
          document.getElementById("male").removeAttribute("selected")
          document.getElementById("female").setAttribute("selected", "selected")
        }
        var label = des[0].getElementsByTagName("label")
        label[0].style.top = "0px"
        label[0].style.fontSize = "10px"
        document.getElementById("postal-address").value = res.data.result_set.customer_address_name
        document.getElementById("postal-address").setAttribute("value", res.data.result_set.customer_address_name)
        var vDefaultAddr = ""
        if (res.data.result_set.customer_address_name !== "" && res.data.result_set.customer_address_name !== null) {
          vDefaultAddr += '<p><b>Previous Order Address</b></p><p id="user-default-address" >' + res.data.result_set.customer_address_name + "<br />"
        }
        if (res.data.result_set.customer_address_line1 !== "" && res.data.result_set.customer_address_line1 !== null) {
          vDefaultAddr += helper.unitFormat(res.data.result_set.customer_address_line1, res.data.result_set.customer_address_line2) + "<br />"
        }
        if (res.data.result_set.customer_postal_code !== "" && res.data.result_set.customer_postal_code !== null) {
          vDefaultAddr += res.data.result_set.customer_postal_code
        }
        vDefaultAddr += "</p>"
        //document.getElementById("user-default-billing-address").innerHTML=vDefaultAddr;
        var list = document.getElementsByClassName("form-group-input")
        var n
        for (n = 0; n < list.length; ++n) {
          if (list[n].getElementsByTagName("input")[0].value !== "") {
            list[n].classList.remove("is-empty")
          } else {
            list[n].classList.add("is-empty")
          }
        }
      } else {
        cookie.remove("UserId")
        cookie.remove("UserFname")
        cookie.remove("UserLname")
        cookie.remove("UserMobile")
        cookie.remove("UserEmail")
        hashHistory.push("/sign-in")
      }
      var select = document.getElementsByClassName("select-gender")[0]
      select.style.background = "none"
      select.style.margin = "0 0 0 6px"
      select.style.fontWeight = "bold"
      select.style.fontSize = "14px"
      select.style.padding = "8px 0 0 0"
      document.getElementsByClassName("gender")[0].classList.remove("is-empty")
      var des = document.getElementsByClassName("birthdate")
      var fc = des[0].getElementsByTagName("div")
      var se = fc[0].getElementsByTagName("div")
      se[0].style.padding = "0px"
      var input = se[0].getElementsByTagName("input")
      input[0].style.borderBottom = "0px"
      var label = des[0].getElementsByTagName("label")
      label[0].style.top = "0px"
      label[0].style.fontSize = "10px"
    })
  }

  setBirthday() {
    var des = document.getElementsByClassName("birthdate")
    var input = des[0].getElementsByTagName("input")
    if (input[0].value === "") {
      axios.get(APIURL + "/customer/customerdetail?app_id=" + APPID + "&refrence=" + cookie.load("UserId")).then((res) => {
        if (res.data.status === "ok") {
          var des1 = document.getElementsByClassName("birthdate")
          var fc1 = des1[0].getElementsByTagName("div")
          var se1 = fc1[0].getElementsByTagName("div")
          var input1 = se1[0].getElementsByTagName("input")
          if (res.data.result_set.customer_birthdate == "0000-00-00" || res.data.result_set.customer_birthdate == null) {
            input[0].value = res.data.result_set.customer_birthdate
            input[0].setAttribute("value", res.data.result_set.customer_birthdate)

            this.setState({ date1: "" })
          } else {
            input[0].value = res.data.result_set.customer_birthdate
            input[0].setAttribute("value", res.data.result_set.customer_birthdate)

            this.setState({ date1: new Date(res.data.result_set.customer_birthdate) })
          }
          var label = des1[0].getElementsByTagName("label")
          label[0].style.top = "0px"
          label[0].style.fontSize = "10px"
        }
      })
    } else {
      var des1 = document.getElementsByClassName("birthdate")
      var fc1 = des1[0].getElementsByTagName("div")
      var se1 = fc1[0].getElementsByTagName("div")
      var input1 = se1[0].getElementsByTagName("input")
      input1[0].value = input[0].value
      input1[0].setAttribute("value", input[0].value)
      this.setState({ date1: new Date(input[0].value) })
      var label = des1[0].getElementsByTagName("label")
      label[0].style.top = "0px"
      label[0].style.fontSize = "10px"
    }
  }

  componentDidMount() {
    var select = document.getElementsByClassName("select-gender")[0]
    select.style.background = "none"
    select.style.margin = "0 0 0 6px"
    select.style.fontWeight = "bold"
    select.style.fontSize = "14px"
    select.style.padding = "8px 0 0 0"
    document.getElementsByClassName("gender")[0].classList.remove("is-empty")
    var des = document.getElementsByClassName("birthdate")
    var fc = des[0].getElementsByTagName("div")
    var se = fc[0].getElementsByTagName("div")
    se[0].style.padding = "0px"
    var input = se[0].getElementsByTagName("input")
    input[0].style.borderBottom = "0px"
    var label = des[0].getElementsByTagName("label")
    label[0].style.top = "0px"
    label[0].style.fontSize = "10px"
  }

  clearSuccesMsg() {
    document.getElementById("msg-text").innerHTML = ""
    document.getElementById("AlertBox1").style.display = "none"
  }

  /* show and hide */
  showAddThisDiv() {
    if (document.getElementById("sharediv").style.display === "none" || document.getElementById("sharediv").style.display === "") {
      this.setState({ defaultDispaly: "inline-block" })
    } else {
      this.setState({ defaultDispaly: "none" })
    }
  }

  loadCartImage() {
    return getResult("cartImage", "Yes")
  }

  loaddaofbirth() {
    if (this.state.date1 !== "0000-00-00") {
      return (
        <DatePicker
          className="birthdate"
          label="Birthday"
          sundayFirstDayOfWeek
          autoOk
          inputFormat={(value) => `${value.getDate() < 10 ? "0" + value.getDate() : value.getDate()}-${value.getMonth() + 1 < 10 ? "0" + (value.getMonth() + 1) : value.getMonth() + 1}-${value.getFullYear()}`}
          onChange={this.handleChange.bind(this, "date1")}
          value={this.state.date1}
        />
      )
    } else {
      return (
        <DatePicker
          className="birthdate"
          label="Birthday"
          sundayFirstDayOfWeek
          autoOk
          inputFormat={(value) => `${value.getDate() < 10 ? "0" + value.getDate() : value.getDate()}-${value.getMonth() + 1 < 10 ? "0" + (value.getMonth() + 1) : value.getMonth() + 1}-${value.getFullYear()}`}
          onChange={this.handleChange.bind(this, "date1")}
        />
      )
    }
  }

  /* promoMenu */
  rewardMenu() {
    var usertype = typeof cookie.load("userAccountType") !== "undefined" ? cookie.load("userAccountType") : 0
    if (APPID !== apps.workspace) {
      return (
        <Link to={"/rewards"}>
          {" "}
          <img src="/img/icons/view-rewards.png" alt="" />
          <h4>Rewards</h4>{" "}
        </Link>
      )
    }
  }

  vouchersMenu() {
    var usertype = typeof cookie.load("userAccountType") !== "undefined" ? cookie.load("userAccountType") : 0
    if (APPID !== apps.workspace) {
      return (
        <Link to={"/vouchers"}>
          {" "}
          <img src="/img/icons/gift-voucher.svg" alt="" />
          <h4>e-Vouchers</h4>{" "}
        </Link>
      )
    }
  }

  promoMenu() {
    var usertype = typeof cookie.load("userAccountType") !== "undefined" ? cookie.load("userAccountType") : 0
    if (APPID !== apps.workspace) {
      return (
        <Link to={"/promotion"}>
          {" "}
          <img src="/img/icons/view-promotions.png" alt="" />
          <h4>Promotion</h4>{" "}
        </Link>
      )
    }
  }

  /**/
  reservationMenu() {
    var usertype = typeof cookie.load("userAccountType") !== "undefined" ? cookie.load("userAccountType") : 0

    /*if(parseInt(usertype) !== 1  ) { */
    return (
      <Link to={"/reservation"}>
        {" "}
        <img src="/img/icons/view-reservation.png" alt="" />
        <h4>Reservation</h4>{" "}
      </Link>
    )
    /*}*/
  }

  render() {
    var displaySts = this.state.defaultDispaly === "" ? "none" : this.state.defaultDispaly
    if (this.state.status === "Loading") {
      return <Loading />
    } else {
      const { fields, onChange, onValid, onInvalid, $field, $validation } = this.props

      let PreviousOrderAddressTitle, errMsgFirstname, errMsgLastname, errMsgEmail, errMsgMobile, errMsgPostal

      if ($validation.firstname.error.reason !== undefined) {
        errMsgFirstname = $validation.firstname.show && <span className="spn-error">{$validation.firstname.error.reason}</span>
      }

      if ($validation.lastname.error.reason !== undefined) {
        errMsgLastname = $validation.lastname.show && <span className="spn-error">{$validation.lastname.error.reason}</span>
      }

      if ($validation.email.error.reason !== undefined) {
        errMsgEmail = $validation.email.show && <span className="spn-error">{$validation.email.error.reason}</span>
      }

      if ($validation.mobile.error.reason !== undefined) {
        errMsgMobile = $validation.mobile.show && <span className="spn-error">{$validation.mobile.error.reason}</span>
      }
      var opts = {}
      opts["readOnly"] = "readOnly"
      if (this.state.secAddrData.length > 0) {
        PreviousOrderAddressTitle = (
          <div id="user-default-billing-address">
            <p>
              <b>Previous Order Address</b>
            </p>
          </div>
        )
      }
      return (
        <ThemeProvider theme={theme}>
          <div className="container-fluid desktop-container">
            <div className="alert-box" id="AlertBox1" style={{ display: "none" }}>
              <p className="success-msg alert-msg">
                <span id="msg-text"></span>
                <a onClick={this.clearSuccesMsg.bind(this)} className="close-icon">
                  <i className="fa fa-times-circle-o"></i>
                </a>
              </p>
            </div>
            <div className="row">
              <div className="col-xs-12 top-nav inner-top-nav head-main">
                <div className="col-xs-3 nav-head pd-l-20 head-child">
                  {" "}
                  <Link to={"/"}>
                    <img src="/img/icons/arrow-right.png" alt="left-arrow" className="icon-img-small-3" /> Back
                  </Link>
                </div>
                <div className="col-xs-6 nav-head  head-child">
                  <h1 className="main-title">my account</h1>
                </div>
                <div className="col-xs-3 nopadding  head-child"></div>
              </div>
            </div>
            <div className="row login-container color-grey">
              <div className="col-lg-12 myAccount">
                <div className="profileimage">
                  <img src="/img/no-images/myaccount_icon.jpg" style={{ width: "150px", height: "150px", borderRadius: "100px" }} alt="profile" />
                  <h1>
                    {fields.firstname} {fields.lastname}
                  </h1>
                  <h5>{fields.email}</h5>
                  {/*<img src="/img/100but.png" alt="profile" />*/}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="myAccountTabs">
                  <div className="col-xs-12 col-md-12 col-lg-12">
                    <Link to={"/orders"}>
                      <img src="/img/icons/view-oder.png" alt="" />
                      <h4>Orders</h4>
                    </Link>
                    {APPID !== "AA850867-4108-4EB8-872B-024903608E9F" &&
                      APPID !== "8F8FE4E6-88C9-4071-9215-312E0F50016A" &&
                      APPID !== "B401FC82-8E39-4076-838A-55192F765004" &&
                      APPID !== "6C0280A0-B655-4E0F-A43F-041C189683BA" &&
                      APPID !== "B93EC9A9-1A67-4EBA-ADB7-BC7C14FA88A4" &&
                      APPID !== "88E232C9-8943-4042-B4D4-3413E394007B" &&
                      helper.disableMenu(this.state.settings, "myaccount_reservation") &&
                      this.reservationMenu()}
                    {APPID !== "B401FC82-8E39-4076-838A-55192F765004" &&
                      APPID !== "6C0280A0-B655-4E0F-A43F-041C189683BA" &&
                      APPID !== "B93EC9A9-1A67-4EBA-ADB7-BC7C14FA88A4" &&
                      APPID !== "33843AC9-EB47-42EE-8AAA-14CEA187D85A" &&
                      APPID !== "4EDE9A25-89AA-4200-848D-AC4A5A54B7BC" &&
                      helper.disableMenu(this.state.settings, "myaccount_promos") &&
                      this.promoMenu()}
                    {APPID !== "AA850867-4108-4EB8-872B-024903608E9F" &&
                      APPID !== "8F8FE4E6-88C9-4071-9215-312E0F50016A" &&
                      APPID !== "B401FC82-8E39-4076-838A-55192F765004" &&
                      APPID !== "33843AC9-EB47-42EE-8AAA-14CEA187D85A" &&
                      APPID !== "6C0280A0-B655-4E0F-A43F-041C189683BA" &&
                      APPID !== "4EDE9A25-89AA-4200-848D-AC4A5A54B7BC" &&
                      helper.disableMenu(this.state.settings, "myaccount_vouchers") &&
                      this.vouchersMenu()}
                    {APPID !== "AA850867-4108-4EB8-872B-024903608E9F" &&
                      APPID !== "8F8FE4E6-88C9-4071-9215-312E0F50016A" &&
                      APPID !== "B401FC82-8E39-4076-838A-55192F765004" &&
                      APPID !== "6C0280A0-B655-4E0F-A43F-041C189683BA" &&
                      APPID !== "B93EC9A9-1A67-4EBA-ADB7-BC7C14FA88A4" &&
                      APPID !== "33843AC9-EB47-42EE-8AAA-14CEA187D85A" &&
                      APPID !== "4EDE9A25-89AA-4200-848D-AC4A5A54B7BC" &&
                      helper.disableMenu(this.state.settings, "myaccount_rewards") &&
                      this.rewardMenu()}
                    {APPID !== "B401FC82-8E39-4076-838A-55192F765004" && APPID !== "6C0280A0-B655-4E0F-A43F-041C189683BA" && APPID !== "B93EC9A9-1A67-4EBA-ADB7-BC7C14FA88A4" && helper.disableMenu(this.state.settings, "myaccount_share") && (
                      <Link className="share_button" onClick={this.showAddThisDiv.bind(this)}>
                        {" "}
                        <img src="/img/icons/share.png" alt="" style={{ cursor: "pointer" }} />
                        <h4>Share</h4>{" "}
                      </Link>
                    )}
                  </div>
                  {/*<div className="col-md-4 col-xs-4">
							   <img src="/img/icons/view-rewards.png" alt="" />
							   <h4>View Rewards</h4>
							</div> col-xs-6 col-md-6 col-lg-6 
							<div className="col-xs-6 col-md-6 col-lg-64">
							 <Link className="share_button" onClick={this.showAddThisDiv.bind(this)} >  <img src="/img/icons/share.png" alt="" />
							   <h4>Share</h4> </Link>
							   
							</div>*/}
                  <div className="share_wrapper" style={{ display: displaySts }} id="sharediv">
                    <FacebookShareButton url={lang.fb?.fbURL} title={lang.fb?.fbTitile} description={lang.fb?.fbDesc} picture={lang.fb?.fbImage}>
                      <FacebookIcon size={32} />
                    </FacebookShareButton>

                    {/* <GooglePlusShareButton url={lang.gp.gpURL}   ><GooglePlusIcon  size={32}   /></GooglePlusShareButton>
										 
										  <WhatsappShareButton url={social.wa.waURL}  
										   ><WhatsappIcon   size={32} title="Yes ! I love the food here, and did I say its also a great place to eat ! Check out :"   /></WhatsappShareButton> */}

                    <TwitterShareButton url={lang.tw?.twURL} title={lang.tw?.twTitile} via={lang.tw?.twvia}>
                      <TwitterIcon size={32} />
                    </TwitterShareButton>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 myAccountForm">
                <div id="form-msg"></div>

                <h1>ACCOUNT INFORMATION</h1>
                <div className="Form-holder" id="myaccount-form">
                  <div className="form-group-input form-group label-floating card-no">
                    <div className="input-group">
                      <label className="control-label">First Name</label>
                      <input type="text" className="form-control" value={fields.firstname || ""} {...$field("firstname", (e) => onChange("firstname", e.target.value))} />
                      {errMsgFirstname}
                    </div>
                  </div>
                  <div className="form-group-input form-group label-floating card-no">
                    <div className="input-group">
                      <label className="control-label">Last Name</label>
                      <input type="text" className="form-control" value={fields.lastname || ""} {...$field("lastname", (e) => onChange("lastname", e.target.value))} />
                      {errMsgLastname}
                    </div>
                  </div>
                  <div className="form-group-input form-group label-floating card-no">
                    <div className="input-group">
                      <label className="control-label">Email Address</label>
                      <input type="text" className="form-control" value={fields.email || ""} {...$field("email", (e) => onChange("email", e.target.value))} {...opts} disabled="true" />
                      {errMsgEmail}
                    </div>
                  </div>
                  <div className="form-group-input form-group label-floating card-no">
                    <div className="input-group">
                      <label className="control-label">Mobile Number</label>
                      <input type="text" className="form-control" value={fields.mobile || ""} {...$field("mobile", (e) => onChange("mobile", e.target.value))} />
                      {errMsgMobile}
                    </div>
                  </div>
                  <div className="form-group-input form-group label-floating card-no">
                    <div className="input-group birthdate">
                      {this.loaddaofbirth()}
                      <div id="spn-birthday-error" style={{ clear: "both" }}></div>
                    </div>
                  </div>
                  <div className="form-group label-floating card-no gender">
                    <div className="input-group">
                      <label className="control-label">Gender</label>
                      <select className="form-control select-gender">
                        <option value="M" id="male">
                          Male
                        </option>
                        <option value="F" id="female">
                          Female
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="form-group-input form-group label-floating card-no" style={{ height: "0px", margin: "0px", visibility: "hidden" }}>
                    <div className="input-group">
                      <label className="control-label">Postal Code</label>
                      <input id="postal-code" type="text" className="form-control" value={fields.postal || ""} {...$field("postal", (e) => onChange("postal", e.target.value))} />
                      <div id="spn-postal-error">{errMsgPostal}</div>
                    </div>
                  </div>
                  <div className="form-group-input form-group label-floating card-no form-addressfield" style={{ height: "0px", margin: "0px", visibility: "hidden" }}>
                    <div className="input-group">
                      <label className="control-label">Address</label>
                      <input id="postal-address" type="text" className="form-control" {...opts} disabled="true" />
                      <div id="spn-address-error"></div>
                    </div>
                  </div>
                  <div className="form-group-input form-group label-floating card-no col-xs-6" style={{ height: "0px", margin: "0px", visibility: "hidden" }}>
                    <div className="input-group">
                      <label className="control-label">Unit Number 1</label>
                      <input type="text" className="form-control" value={fields.unitnumber1 || ""} {...$field("unitnumber1", (e) => onChange("unitnumber1", e.target.value))} />
                      <div id="spn-unitno1-error"></div>
                    </div>
                  </div>
                  <div className="form-group-input form-group label-floating card-no col-xs-6 unitno" style={{ height: "0px", margin: "0px", visibility: "hidden" }}>
                    <div className="input-group">
                      <label className="control-label">Unit Number 2</label>
                      <input type="text" className="form-control" value={fields.unitnumber2 || ""} {...$field("unitnumber2", (e) => onChange("unitnumber2", e.target.value))} />
                      <div id="spn-unitno2-error"></div>
                    </div>
                  </div>
                  <div className="form-group-input form-group label-floating card-no mt80" style={{ marginTop: "20px" }}>
                    <div className="input-group">
                      <label className="control-label">Company Name</label>
                      <input type="text" className="form-control" value={fields.company || ""} {...$field("company", (e) => onChange("company", e.target.value))} />
                    </div>
                  </div>
                  <div className="space-20"></div>
                  <div className="text-center ">
                    <button
                      type="button"
                      className="btn btn-raised btn-info full-width-btn text-uppercase color-green"
                      onClick={(e) => {
                        e.preventDefault()
                        this.props.$submit(onValid, onInvalid)
                      }}
                    >
                      update profile
                      <div className="ripple-container"></div>
                    </button>
                    <br />
                  </div>
                  <div className="text-left row" id="default-address">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      {PreviousOrderAddressTitle}
                      {this.loadSecAddr(this.state.secAddrData)}
                    </div>
                  </div>
                  <div className="row disabled-continue-as-a-guest">
                    <div className="col-lg-12 text-center">
                      <button
                        type="button"
                        id="checkout"
                        onClick={() => {
                          this.openNewAddress()
                        }}
                        className="btn btn-raised btn-info full-width-btn text-uppercase disable-btn focusgrey"
                      >
                        Add new Address
                      </button>
                      <br />
                    </div>
                    <div className="space-50 hide-on-click"></div>
                  </div>
                  <input type="hidden" id="address_id" />
                  <div className="new-user-reg">
                    <div className="form-group label-floating card-no address-form-group">
                      <div className="input-group">
                        <label className="control-label">Postal Code</label>
                        <input
                          type="text"
                          id="customer_postal_code"
                          className="form-control"
                          onChange={() => {
                            this.loadPostalAddress()
                          }}
                        />
                        <div id="spn-postalcode-error"></div>
                      </div>
                    </div>
                    <div className="form-group label-floating card-no address-form-group">
                      <div className="input-group">
                        <label className="control-label">Address1</label>
                        <input type="text" id="customer_address_line1" className="form-control" />
                        <div id="spn-addr-error"></div>
                      </div>
                    </div>
                    <div className="form-group label-floating card-no col-xs-6 address-form-group">
                      <div className="input-group">
                        <label className="control-label"> Floor No</label>
                        <input type="text" id="customer_address_name" className="form-control" />
                        <div id="spn-unit1-error"></div>
                      </div>
                    </div>
                    <div className="form-group label-floating card-no col-xs-6 unitno address-form-group">
                      <div className="input-group">
                        <label className="control-label">Unit No</label>
                        <input type="text" id="customer_address_name2" className="form-control" />
                        <div id="spn-unit2-error"></div>
                      </div>
                    </div>
                    <div className="space-20" id="form-msg1"></div>
                    <div className="text-center">
                      <button type="button" onClick={this.saveNewAddress.bind(this)} className="btn btn-raised btn-info full-width-btn text-uppercase">
                        save
                        <div className="ripple-container"></div>
                      </button>
                      <br />
                    </div>
                    <div className="space-50"></div>
                    <div className="space-10"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ThemeProvider>
      )
    }
  }
}
Form = validated(validationConfig)(Form)

export default Myaccount

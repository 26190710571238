import React from 'react';
/* var Loader = require('react-loader'); */
 
/*
if (location.protocol !== 'https:' &&  location.host !== "localhost:3000" )
{
  location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
}
*/


 class App extends React.Component {
 
  render() {
    return (
	   <div> {this.props.children}  </div>
	 ); 
	 }
 }
    
export default App;
/* eslint-disable */
import React from "react"
import axios from "axios"
import { loadAnalytics } from "./withTracker"
import { Link, hashHistory } from "react-router"
import getResult from "./Api"
import cookie from "react-cookie"
import DocumentMeta from "react-document-meta"
import Loading from "./loading"
import Overlay from "./Overlay"

var strp = require("strp")
import config from "./config"
import lang from "./lang"
const APIURL = config.path.apiUrl
const APIURLPRO = config.path.apiUrlPro
const APPID = config.ids.appId
var qs = require("qs")
var helper = require("./Helper.js")

//import Map from './Map';

/* Load meta tags */
const meta = {
  title: lang.home_meta_title,
  description: lang.home_meta_title,
  meta: {
    name: {
      keywords: lang.home_meta_title,
    },
  },
}

//var Loading = require('react-loading');
class Cartitemsedit extends React.Component {
  constructor(props) {
    super(props)
    /* set default avilablity */
    helper.setDefaultAvailability()

    this.state = { details: [], orderHandled: "", load: "", Outlets: [], markers: [], details_common: [], cartCount: 1, status: "Loading", allowCart: "Yes", productPrice: "", itemType: "Simple", ModifierCount: "", windowHeight: 0, secAddrData: [], addressField: "" }

    /* set default cookie values */
    cookie.save("modiferCount", 0)
    cookie.save("itemType", "Simple")
    this.handleChange = this.handleChange.bind(this) //handleChangeValue
    this.handleChangeValue = this.handleChangeValue.bind(this)
  }

  componentDidMount() {
    /* set default height */
    //this.setState('windowHeight':winHeight);
    // this.setState({windowHeight:(window.innerHeight-45)+'px'});

    /* API CALL FOR BANNERS */
    const productSlug = this.props.params.productSlug
    axios.get(APIURL + "productsver2/products_list?status=A&app_id=" + APPID + "&availability=" + cookie.load("defaultAvilablityId") + "&product_slug=" + productSlug).then((res) => {
      this.setState({ status: res.data.status })
      if (res.data.status === "ok") {
        this.setState({ status: res.data.status })
        this.setState({ details_common: res.data.common })
        this.setState({ productPrice: res.data.result_set[0].product_price })
        this.setState({ details: res.data.result_set[0] })
        // this.setState({productPrice: res.data.result_set[0]});
      }
    })

    if (cookie.load("defaultAvilablityId") === config.ids.pickupId && cookie.load("defaultAvilablity") === lang.takeaWay) {
      axios.get(APIURL + "outlets/pickup_outlets?app_id=" + APPID).then((res) => {
        // console.log('banner result loading done');
        if (res.data.status === "ok") {
          this.setState({ Status: res.data.status })
          this.setState({ Outlets: res.data.result_set })
          //this.loadMarkers();
        } else {
          this.setState({ Status: res.data.status })
        }
      })
    }

    /* get secondary address lits */
    if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
      this.getSecAddr()
    }
    loadAnalytics()
  }

  /* used to get all secondary address list */
  getSecAddr() {
    axios.get(APIURL + "/customer/get_user_secondary_address?app_id=" + APPID + "&refrence=" + cookie.load("UserId")).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ secAddrData: res.data.result_set })
      }
    })
  }

  handleChange(event) {
    //alert(event.target.value);
    this.setState({ addressField: event.target.value })
  }

  handleChangeValue(event) {
    this.setState({ addressField: event.target.value })
  }
  /* used to load address list */
  loadAddressList() {
    return this.state.secAddrData.map((item, index) => (
      <p key={index}>
        <input name="radio" value={item.postal_code} id={item.secondary_address_id} className="css-radio" type="radio" onChange={this.handleChange} />
        <label htmlFor={item.secondary_address_id} className="css-label ">
          {" "}
          {item.address + " Singapore " + item.postal_code}
        </label>
      </p>
    ))
  }
  /* this function used to load default billing address */
  loadDefaultAddress() {
    if (typeof cookie.load("UserDefaultAddress") !== "undefined" && cookie.load("UserDefaultAddress") !== "" && typeof cookie.load("UserDefaultPostalCode") !== "undefined" && cookie.load("UserDefaultPostalCode") !== "") {
      return (
        <p>
          <input name="radio" value={cookie.load("UserDefaultPostalCode")} id="defaultAdd" className="css-radio" type="radio" onChange={this.handleChange} />
          <label htmlFor="defaultAdd" className="css-label ">
            {" "}
            {cookie.load("UserDefaultAddress") + " Singapore " + cookie.load("UserDefaultPostalCode")}
          </label>
        </p>
      )
    }
  }

  /* this function used to load cart icon image */
  loadCartImage() {
    return getResult("cartImage", "Yes")
  }

  /* show product thump image */
  __productImage(product_thumbnail) {
    return product_thumbnail === "" ? config.noImages.product_listing : this.state.details_common.image_source + "/" + product_thumbnail
  }
  /* load product image */
  loadProductImage(product_thumbnail) {
    if (product_thumbnail !== "" && typeof product_thumbnail !== "undefined") {
      return (
        <div className="row no-bar-image">
          {" "}
          <img src={config.path.tinThumpUrl + this.state.details_common.image_source + "/" + product_thumbnail + "&w=768&h=264&q=80"} alt="banner" className="img-responsive" />{" "}
        </div>
      )
    }
  }

  loadMarkers() {
    var markers = []
    this.state.Outlets.map(function (data, index) {
      var markerinfo = {
        position: new google.maps.LatLng(data.outlet_marker_latitude, data.outlet_marker_longitude),
        showInfo: false,
        infoContent: <div>{data.outlet_name}</div>,
      }
      markers[index] = markerinfo
    })
    this.setState({ markers: markers })
  }

  /* checkActive */
  checkActive() {}

  /* show modifiers values */
  showModifiers(modVal) {
    //console.log(modVal);
    if (modVal) {
      var modLen = modVal.length
      return modVal.map((item, index) => (
        <div className="panel panel-default" key={index}>
          <div className="panel-heading" role="tab" id={"modHead" + index}>
            <h4 className="panel-title">
              <Link className="collapsed" onClick={this.checkActive.bind()} data-toggle="collapse" data-parent="#accordion" to={"#collapse" + index} aria-expanded="true" aria-controls="collapseTwo">
                {strp.stripslashes(item.pro_modifier_name)}
              </Link>
            </h4>
          </div>
          <div id={"collapse" + index} className="panel-collapse collapse in" role="tabpanel" aria-labelledby={"modHead" + index}>
            <div className="panel-body">{this.showModifierItems(item.modifiers_values, index, modLen, item.pro_modifier_name, item.pro_modifier_id)}</div>
          </div>
        </div>
      ))
    }
  }

  /* show set products values */
  showSetMeal(isCombo, mealVal) {
    if (isCombo === "Yes") {
      var mealLength = mealVal.length

      if (mealLength > 0) {
        return mealVal.map((item, index) => (
          <div className="panel panel-default" key={index}>
            <div className="panel-heading" role="tab" id={"headingTwo" + index}>
              <h4 className="panel-title">
                <Link className="collapsed" onClick={this.checkActive.bind()} data-toggle="collapse" data-parent="#accordion" to={"#collapse" + index} aria-expanded="true" aria-controls="collapseTwo">
                  {strp.stripslashes(item.menu_set_component_name)}
                </Link>
              </h4>
            </div>
            <div id={"collapse" + index} className="panel-collapse collapse in" role="tabpanel" aria-labelledby={"headingTwo" + index}>
              <div className="panel-body">
                {this.showSetMealProducts(item.product_details, index, mealLength, item.menu_component_name, item.menu_component_id, item.menu_component_default_select, item.menu_component_min_select, item.menu_component_max_select)}
                {/* M od */}

                {/* End of mod*/}
              </div>
            </div>
          </div>
        ))
      }
    }
  }

  /* show & hide set meal modifier child products */
  showModDiv(modifiers, setIndex, mindex) {
    var modLen = modifiers !== null ? modifiers.length : 0
    var elems = document.getElementsByClassName("modDiv" + setIndex)
    for (var i = 0; i < elems.length; i++) {
      elems[i].style.display = "none"
    }
    if (modLen > 0) {
      var showEle = document.getElementsByClassName("modDiv_" + setIndex + mindex)
      showEle[0].style.display = "block"
    }
  }

  /* if modifier products selected show modifer div */
  modifierDefault(isDefault, productId, modVal) {
    var modLen = modVal !== null ? modVal.length : 0
    return isDefault === productId && modLen > 0 ? "block" : "none"
  }

  /* this fucntion used to show set items list */
  showSetMealProducts(products, mIndex, length, comboName, comboId, isDefault, minSelect, maxSelect) {
    //console.log(products);
    var totalLen = products.length
    var modItemLen = totalLen - 1
    if (totalLen === 0) {
      alert("Products items missing")
      hashHistory.push("/")
    }

    /*  Modifer count */
    cookie.save("comboCount", length)
    cookie.save("itemType", "Combo")

    return products.map((item, index) => (
      <div className={this.showArrow(modItemLen, index)} key={index}>
        <h1 className="bb-txt2 margin-15">
          {strp.stripslashes(item[0].product_name)}
          <div className="radio  pull-right">
            <input
              className="css-radio"
              type="radio"
              value={comboName + "~" + comboId + "~" + item[0].product_name + "~" + item[0].product_id + "~" + item[0].product_sku}
              id={item[0].product_id + "_" + mIndex + "_" + index}
              name={"comboVal_" + mIndex}
              defaultChecked={this.defaultSetMeal(isDefault, item[0].product_id)}
              onChange={this.showModDiv.bind(this, item[0].modifiers, mIndex, index)}
            />
            <label htmlFor={item[0].product_id + "_" + mIndex + "_" + index} className="css-label"></label>
          </div>
        </h1>

        {/* Mod Start */}
        <div style={{ display: this.modifierDefault(isDefault, item[0].product_id, item[0].modifiers) }} className={"modDiv" + mIndex + " modDiv_" + mIndex + index} id={"parDiv" + item[0].product_id + mIndex}>
          {this.showSetMealModifeirs(item[0].modifiers, item[0].product_id, mIndex, index)}
        </div>
        {/* Mod End */}
      </div>
    ))
  }

  /* load set meal modifiers items */
  showSetMealModifeirs(modifiers, productId, mIndex, pIndex) {
    var modLen = modifiers !== null ? modifiers.length : 0
    if (modLen > 0) {
      return modifiers.map((modItems, index) => (
        <div className={"fancy-collapse-panel fancy-collapse-inner" + "  setModValClass" + productId + mIndex} key={index}>
          <div className="panel-group" id={"accordion2" + index + mIndex} role="tablist" aria-multiselectable="true">
            <div className="panel panel-default">
              <div className="panel-heading" role="tab" id={"modHeadingTwo" + index + mIndex + pIndex}>
                <h4 className="panel-title">
                  <a className="collapsed" data-toggle="collapse" data-parent={"#accordion2" + index + mIndex} href={"#collapse_flavour" + index + mIndex + pIndex} aria-expanded="true" aria-controls="collapseTwo">
                    <img src="/img/share.png" />
                    {modItems.pro_modifier_name}
                  </a>
                </h4>
              </div>
              {/* mod itesm*/}
              <div id={"collapse_flavour" + index + mIndex + pIndex} className={"panel-collapse collapse in "} role="tabpanel" aria-labelledby={"modHeadingTwo" + index + mIndex + pIndex}>
                <div className="panel-body">{this.showSetMealModifierItems(modItems.modifiers_values, index, modLen, modItems.pro_modifier_name, modItems.pro_modifier_id, productId, mIndex, pIndex)}</div>
              </div>
            </div>
          </div>
        </div>
      ))
    }
  }

  /* this function used to show  set meal products modifier items */

  /* this fucntion used to show modifer items */
  showSetMealModifierItems(modVal, mIndex, length, modName, modId, productId, setIndex, pIndex) {
    //alert(setIndex);
    var totalLen = modVal.length
    var modItemLen = totalLen - 1
    if (totalLen === 0) {
      alert("Modifier items missing")
      hashHistory.push("/")
    }
    /* set modifier cosunt value */
    //alert(productId+"->"+length);
    //this.setState({productId: length});
    /* set modifier count cooki values */
    //this.setState({productId: length})
    /*
  if(typeof cookie.load(productId)==="undefined" || cookie.load(productId) ==="" ){
  this.setState({productId: length})  
  }else {
  alert(cookie.load(productId));
  } */
    //cookie.save(length,length  );
    //this.setState({"productID": '4'})
    /*onChange={this.validateModifier.bind(this,length,modName,modId,item.pro_modifier_value_name,item.pro_modifier_value_id,item.pro_modifier_value_price,productId)} */
    /*  Modifer count */
    //cookie.save('modiferCount',length  );
    // cookie.save('itemType','Modifier' );

    return modVal.map((item, index) => (
      <div className={this.showArrow(modItemLen, index)} key={index}>
        <h1 className="bb-txt2 margin-15">
          {strp.stripslashes(item.pro_modifier_value_name)}
          <div className="radio  pull-right">
            <input
              className="css-radio"
              type="radio"
              value={modName + "~" + modId + "~" + item.pro_modifier_value_name + "~" + item.pro_modifier_value_id + "~" + item.pro_modifier_value_price}
              id={item.pro_modifier_value_id + "_" + setIndex + "_" + pIndex + "_" + mIndex + "_" + index}
              name={"modValItems" + productId + "-" + setIndex + "-" + mIndex}
              defaultChecked={this.isDefault(item.pro_modifier_value_is_default)}
            />
            <label htmlFor={item.pro_modifier_value_id + "_" + setIndex + "_" + pIndex + "_" + mIndex + "_" + index} className="css-label"></label>
          </div>
        </h1>
      </div>
    ))
  }

  /* choose defaultg set meal product */
  defaultSetMeal(isDefault, productId) {
    return isDefault === productId ? true : false
  }

  /* load outlets */
  __loadOutlets() {
    if (this.state.Outlets) {
      return this.state.Outlets.map((loaddata, index) => (
        <div
          className="map-li"
          key={index}
          onClick={this.pickOutlet.bind(
            this,
            loaddata.outlet_id,
            loaddata.outlet_name,
            loaddata.outlet_postal_code,
            loaddata.outlet_address_line1,
            loaddata.outlet_address_line2,
            loaddata.outlet_unit_number1,
            loaddata.outlet_unit_number2,
            loaddata.outlet_pickup_tat,
            loaddata.outlet_tax_option,
            loaddata.outlet_tax_surcharge,
            loaddata.outlet_tax_surcharge_inclusive
          )}
        >
          <p className="map-h">{loaddata.outlet_name}</p>
          <p className="map-sub">{loaddata.outlet_address_line1 + ", " + loaddata.outlet_address_line2 + " " + loaddata.outlet_postal_code}</p>
        </div>
      ))
    }
  }

  /* this fucntion used to show modifer items */
  showModifierItems(modVal, mIndex, length, modName, modId) {
    //console.log(modVal);
    var totalLen = modVal.length
    var modItemLen = totalLen - 1
    if (totalLen === 0) {
      alert("Modifier items missing")
      hashHistory.push("/")
    }

    /*  Modifer count */
    cookie.save("modiferCount", length)
    cookie.save("itemType", "Modifier")

    return modVal.map((item, index) => (
      <div className={this.showArrow(modItemLen, index)} key={index}>
        <h1 className="bb-txt2 margin-15">
          {strp.stripslashes(item.pro_modifier_value_name) + this.modifierPrice(item.pro_modifier_value_price)}
          <div className="radio  pull-right">
            <input
              className="css-radio"
              type="radio"
              value={modName + "~" + modId + "~" + item.pro_modifier_value_name + "~" + item.pro_modifier_value_id + "~" + item.pro_modifier_value_price}
              id={item.pro_modifier_value_id}
              name={"modVal_" + mIndex}
              defaultChecked={this.isDefault(item.pro_modifier_value_is_default, modName, modId, item.pro_modifier_value_name, item.pro_modifier_value_id, item.pro_modifier_value_price)}
              onChange={this.validateModifier.bind(this, length, modName, modId, item.pro_modifier_value_name, item.pro_modifier_value_id, item.pro_modifier_value_price, "")}
            />
            <label htmlFor={item.pro_modifier_value_id} className="css-label"></label>
          </div>
        </h1>
      </div>
    ))
  }

  /* show  modifier extra amount */
  modifierPrice(price) {
    return price > 0 ? " (+" + price + ")" : ""
  }

  /* this function used to validate modifer values */
  validateModifier(ModifierLen, modName, modId, modValName, modValId, modValPrice, productId) {
    //alert(productId);
    var allModVal = ""
    var commaVal = ModifierLen - 1
    var sts = "Fail"
    //console.log(modName + "_"+modId+" - "+modValName+"-"+modValId+"-"+modValPrice);
    for (var i = 0, length = ModifierLen; i < length; i++) {
      var inputName = "modVal_" + i
      if (document.querySelector("input[name = " + inputName + "]:checked")) {
        var modVal = document.querySelector("input[name = " + inputName + "]:checked").value
        var res = modVal.split("~")
        if (modVal === "") {
          sts = "Fail"
          document.getElementById("addToCart").disabled = true
          alert(lang.select_all_modofier)
          break
        } else {
          sts = "Success"
          document.getElementById("addToCart").disabled = false
          var strt = i === commaVal ? res[3] : res[3] + ";"
          allModVal += strt
          /* post data */
        }
      } else {
        sts = "Fail"
        document.getElementById("addToCart").disabled = true
        alert(lang.select_all_modofier)
        break
      }
    }
    if (sts === "Success") {
      /* for normal modifiers get state value, set meal product pass param */
      productId = productId === "" ? this.state.details.product_id : productId

      //alert(productId);
      axios.get(APIURLPRO + "products/validate_product?app_id=" + APPID + "&product_id=" + productId + "&modifier_value_id=" + allModVal).then((res) => {
        /* Success response */
        if (res.data.status === "ok") {
          this.setState({ sts: "No" })
          this.setState({ allowCart: "Yes" })
          this.setState({ productPrice: res.data.result_set[0].product_price })
        }
        /* Error response */
        if (res.data.status === "error") {
          alert(res.data.message)
          this.setState({ allowCart: "No" })
          document.getElementById("addToCart").disabled = true
        }
      })
    }
  }

  /* this function used to checked radio value */
  isDefault(select) {
    return select === "Yes" ? true : false
  }

  /* this function used to show show  arrow*/
  showArrow(len, index) {
    return len === index ? "" : "acrow"
  }

  /* increase cart qty */
  iQty() {
    this.setState({ cartCount: this.state.cartCount + 1 })
  }

  /* decrease cart qty */
  dQty() {
    if (this.state.cartCount !== 1) {
      this.setState({ cartCount: this.state.cartCount - 1 })
    }
  }

  /* pick outlet */
  pickOutlet(outlet_id, outlet_name, outlet_postal_code, outlet_address_line1, outlet_address_line2, outlet_unit_number1, outlet_unit_number2, outlet_pickup_tat, outlet_tax_option, outlet_tax_surcharge, outlet_tax_surcharge_inclusive) {
    var orderHandled = outlet_name + " " + outlet_address_line1 + " " + outlet_address_line2 + ", Singapore " + outlet_postal_code
    //console.log(outlet_id+outlet_postal_code)
    this.setState({ orderHandled: orderHandled })
    cookie.save("orderOutletId", outlet_id)
    cookie.save("orderOutletName", outlet_name)
    cookie.save("orderPostalCode", outlet_postal_code)
    cookie.save("orderTAT", outlet_pickup_tat)
    cookie.save("orderHandled", orderHandled)

    cookie.save("outlet_tax_option", outlet_tax_option)
    cookie.save("outlet_tax_surcharge", outlet_tax_surcharge)
    cookie.save("outlet_tax_surcharge_inclusive", outlet_tax_surcharge_inclusive)

    $("#Pickup-OutletSelection").modal("toggle")
    $("#Pickup-Sucess").modal("toggle")
  }

  /* continue with pickup success */
  pickupSuccess() {
    var productId = this.state.productId
    var proName = this.state.proName
    var prosku = this.state.prosku
    var proPrice = this.state.proPrice
    var proQty = this.state.proQty
    var proImage = this.state.proImage
    var allowCart = this.state.allowCart
    $("#Pickup-Sucess").modal("toggle")
    this.addToCart(productId, proName, prosku, proPrice, proQty, proImage, allowCart)
  }

  /* continue with delivery success */
  deliverySuccess() {
    var productId = this.state.productId
    var proName = this.state.proName
    var prosku = this.state.prosku
    var proPrice = this.state.proPrice
    var proQty = this.state.proQty
    var proImage = this.state.proImage
    var allowCart = this.state.allowCart
    $("#DeliverySucess").modal("toggle")
    this.addToCart(productId, proName, prosku, proPrice, proQty, proImage, allowCart)
  }

  /* Select outlet */

  selectOutlet() {
    if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
      var postalcode = $("#postalcode").val() //"postalcode";
    } else {
      postalcode = $("#postalcode1").val() // "postalcode1";
    }

    if (postalcode.length < 5) {
      return false
    }

    this.setState({ load: "true" })

    /*add loading */
    axios.get(APIURL + "outlets/find_outlet?app_id=" + APPID + "&availability_id=" + cookie.load("defaultAvilablityId") + "&postal_code=" + postalcode).then((res) => {
      /* Success response */
      /* Success response  && Object.keys(res.data.result_set.postal_code_information).length !==0 */
      if (res.data.status === "ok") {
        /*if(Object.keys(res.data.result_set.postal_code_information).length !==0)
					{ */
        var orderDeliveryAddress = res.data.result_set.postal_code_information.zip_buno + " " + res.data.result_set.postal_code_information.zip_sname
        var orderHandled = res.data.result_set.outlet_name + ", Crew will be seeing you in " + res.data.result_set.outlet_delivery_timing + " Minutes"
        this.setState({ orderDeliveryAddress: orderDeliveryAddress + " Singapore " + res.data.result_set.postal_code_information.zip_code })
        this.setState({ orderHandled: orderHandled })
        cookie.save("orderOutletId", res.data.result_set.outlet_id)
        cookie.save("orderOutletName", res.data.result_set.outlet_name)
        cookie.save("orderPostalCode", res.data.result_set.postal_code_information.zip_code)
        cookie.save("orderTAT", res.data.result_set.outlet_delivery_tat)
        cookie.save("orderDeliveryAddress", orderDeliveryAddress)
        cookie.save("orderHandled", orderHandled)

        cookie.save("outlet_tax_option", res.data.result_set.outlet_tax_option)
        cookie.save("outlet_tax_surcharge", res.data.result_set.outlet_tax_surcharge)
        cookie.save("outlet_tax_surcharge_inclusive", res.data.result_set.outlet_tax_surcharge_inclusive)

        if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
          $("#AddressList").modal("toggle")
        } else {
          $("#DeliveryAddress").modal("toggle")
        }
        $("#DeliverySucess").modal("toggle")

        /* }else{
						 $("#DeliveryAddress").modal("toggle");
						 $("#DeliveryError").modal("toggle");
					 } */
      }
      /* Error response */
      if (res.data.status === "error") {
        if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
          $("#AddressList").modal("toggle")
        } else {
          $("#DeliveryAddress").modal("toggle")
        }
        $("#DeliveryError").modal("toggle")
      }
    })
  }

  /* Open Zipcode popup */

  changeDelivery() {
    this.setState({ load: "false" })
    $("#DeliveryError").modal("toggle")
    if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
      $("#AddressList").modal("toggle")
    } else {
      $("#DeliveryAddress").modal("toggle")
    }

    //$("#DeliveryAddress").modal("toggle");
  }
  closeDeliveryErr() {
    this.setState({ load: "false" })
    $("#DeliveryError").modal("toggle")
  }
  /* Validate zipcode */
  validatezip(e) {
    const re = /[0-9A-F:]+/g
    if (!re.test(e.key)) {
      e.preventDefault()
    }
  }

  /* formated  string */
  formatString(str) {
    var stringVal = window.btoa(str)
    return stringVal.replace(/\=/g, "")
  }

  /* add to cart */
  addToCart(productId, proName, prosku, proPrice, proQty, proImage, allowCart) {
    this.setState({ productId: productId })
    this.setState({ proName: proName })
    this.setState({ prosku: prosku })
    this.setState({ proPrice: proPrice })
    this.setState({ proQty: proQty })
    this.setState({ proImage: proImage })
    this.setState({ allowCart: allowCart })

    if (cookie.load("defaultAvilablityId") === config.ids.deliveryId && cookie.load("defaultAvilablity") === lang.delivery) {
      if (typeof cookie.load("orderOutletId") === "undefined" || typeof cookie.load("orderPostalCode") === "undefined") {
        if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
          $("#AddressList").modal("toggle")
          return false
        } else {
          $("#DeliveryAddress").modal("toggle")
          return false
        }
      }
    } else if (cookie.load("defaultAvilablityId") === config.ids.pickupId && cookie.load("defaultAvilablity") === lang.takeaWay) {
      if (typeof cookie.load("orderOutletId") === "undefined" || typeof cookie.load("orderPostalCode") === "undefined") {
        $("#Pickup-OutletSelection").modal("toggle")
        return false
      }
    }

    /* check add to cart permission */
    if (allowCart === "No") {
      alert(lang.modifier_missing)
      return false
    }

    /* Post Modifier part */

    var modifier = []
    //console.log(cookie.load('itemType') +"<---->"+ cookie.load('modiferCount'));
    if (cookie.load("itemType") === "Modifier" && cookie.load("modiferCount") > 0) {
      //  console.log('is allow');
      for (var i = 0, lengthMod = cookie.load("modiferCount"); i < lengthMod; i++) {
        var inputName = "modVal_" + i
        if (document.querySelector("input[name = " + inputName + "]:checked")) {
          var modVal = document.querySelector("input[name = " + inputName + "]:checked").value
          if (modVal !== "") {
            var res = modVal.split("~")
            var modifierVal = []
            modifierVal.push({ modifier_value_id: res[3], modifier_value_qty: "1", modifier_value_name: res[2], modifier_value_price: res[4] })
            modifier.push({ modifier_id: res[1], modifier_name: res[0], modifiers_values: modifierVal })
          } else {
            alert(lang.modifier_missing)
            return false
          }
        } else {
          alert(lang.modifier_missing)
          return false
        }
      }
    }

    /* set meat products array */
    var combo = []
    var comborVal = []
    var comboSelction = "Fail"
    var modifierSelction = "Fail"
    if (cookie.load("itemType") === "Combo" && cookie.load("comboCount") > 0) {
      // alert(cookie.load('comboCount'));
      for (var m = 0, length = cookie.load("comboCount"); m < length; m++) {
        //alert('  combo' + m);
        var inputName = "comboVal_" + m
        var comboRes = ""
        if (document.querySelector("input[name = " + inputName + "]:checked")) {
          var comboVal_ = document.querySelector("input[name = " + inputName + "]:checked").value
          if (comboVal_ !== "") {
            comboSelction = "Success"
            comboRes = comboVal_.split("~")
            var product_id = comboRes[3]

            /* Validate modififer selection - start */
            var comboModifier = []
            var modLength = 0
            //alert("mod count" + m);
            var modLength = document.querySelectorAll(".setModValClass" + product_id + m).length
            //alert('.setModValClass'+product_id+m)
            if (modLength > 0) {
              //  alert(modLength);
              //alert(modLength + comboRes[2] + "modifier count");
              comboModifier = []
              for (var n = 0, forLen = modLength; n < forLen; n++) {
                var inputName = "modValItems" + product_id + "-" + m + "-" + n
                if (document.querySelector("input[name = " + inputName + "]:checked")) {
                  var modComboVal = document.querySelector("input[name = " + inputName + "]:checked").value
                  if (modComboVal !== "") {
                    modifierSelction = "success"
                    var comboModRes = modComboVal.split("~")
                    var comboModifierVal = []
                    comboModifierVal.push({ modifier_value_id: comboModRes[3], modifier_value_qty: "1", modifier_value_name: comboModRes[2], modifier_value_price: comboModRes[4] })
                    comboModifier.push({ modifier_id: comboModRes[1], modifier_name: comboModRes[0], modifiers_values: comboModifierVal })
                  } else {
                    modifierSelction = "Fail"
                  }
                } else {
                  modifierSelction = "Fail"
                }

                /* if modifier selection misiing show alert */
                if (modifierSelction === "Fail") {
                  alert(lang.modifier_missing)
                  return false
                }
              } /* end of second modifer for */
            }

            /* Validate modififer selection - End  */

            /* object formation  - start */
            var comborVal = []
            comborVal.push({ product_id: comboRes[3], product_name: comboRes[2], product_sku: comboRes[4], modifiers: comboModifier })
            combo.push({ menu_component_id: comboRes[1], menu_component_name: comboRes[0], product_details: comborVal })
            /* object formation - End */
          } else {
            comboSelction = "Fail"
          }
        } else {
          comboSelction = "Fail"
        }

        /* combo selection is missing show alert */
        if (comboSelction === "Fail") {
          alert(lang.combo_missing)
          return false
        }
      }
    }

    var totalPrice = proQty * proPrice

    var postObject = {}
    postObject = {
      app_id: config.ids.appId,
      product_id: productId,
      product_name: proName,
      product_sku: prosku,
      product_image: proImage,
      availability_id: cookie.load("defaultAvilablityId"),
      product_unit_price: proPrice,
      product_qty: proQty,
      product_total_price: totalPrice,
      modifiers: JSON.stringify(modifier),
      menu_set_component: JSON.stringify(combo),
    }

    if (typeof cookie.load("UserId") === "undefined") {
      postObject["reference_id"] = getResult("referenceId")
    } else {
      postObject["customer_id"] = cookie.load("UserId")
    }

    //console.log(postObject);
    /*add loading */
    this.setState({ status: "Loading" })
    axios.post(APIURLPRO + "cart/insert", qs.stringify(postObject)).then((res) => {
      this.setState({ status: "ok" })
      if (res.data.status === "ok") {
        cookie.save("cartValue", "Yes")

        /* find previous page */
        var slugType = cookie.load("categoryType")
        var slugValue = cookie.load("categoryId")
        if (typeof slugType !== "undefined" && typeof slugValue !== "undefined" && slugType !== "" && slugValue !== "") {
          var keyValue = slugType === "category" ? "category" : "subcategory"
          var reDirectUrl = "/products/" + keyValue + "/" + slugValue
        } else {
          reDirectUrl = "/"
        }
        cookie.save("alertSuccess", lang.cart_success)
        hashHistory.push(reDirectUrl)
      } else if (res.data.status === "error") {
        alert(res.data.message)
      }
    })
  }

  /* use to  back URL */
  goBack() {
    var slugType = cookie.load("categoryType")
    var slugValue = cookie.load("categoryId")
    if (typeof slugType !== "undefined" && typeof slugValue !== "undefined" && slugType !== "" && slugValue !== "") {
      var keyValue = slugType === "category" ? "category" : "subcategory"
      var reDirectUrl = "/products/" + keyValue + "/" + slugValue
    } else {
      reDirectUrl = "/"
    }

    return reDirectUrl
  }

  render() {
    var proImg = this.__productImage(this.state.details.product_thumbnail)
    if (this.state.status === "Loading") {
      return (
        <div>
          <Loading />
        </div>
      )
    } else if (this.state.status === "ok") {
      return (
        <div>
          <DocumentMeta {...meta} />

          {/*	 <div className="my_height_class" style={{height:this.state.windowHeight}}> */}
          <div className="container-fluid desktop-container">
            <div className="row">
              <div className="col-xs-12 top-nav inner-top-nav head-main">
                <div className="col-xs-3 nav-head pd-l-20 head-child">
                  <Link to={this.goBack()} style={{ cursor: "pointer" }}>
                    <img src="/img/icons/arrow-right.png" className="icon-img-small-3" alt="left-arrow" />
                  </Link>
                </div>

                <div className="col-xs-6 nav-head head-child">
                  <h1 className="main-title"></h1>
                </div>
                <div className="col-xs-3 nopadding head-child">
                  <p className="b-txt text-right ">
                    <Link to={"/cart"}>
                      {" "}
                      <img src={this.loadCartImage()} className="icon-img-small-1 carticon" alt="shopping-cart" />{" "}
                    </Link>
                  </p>
                </div>
              </div>
            </div>

            {this.loadProductImage(this.state.details.product_thumbnail)}

            <div className="row ">
              <div className="col-lg-12 col-md-12 col-sm-12 ">
                <h1 className="head2 ">{this.state.details.product_name} </h1>
                <p className="subh">{this.state.details.product_short_description}</p>
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-12 col-md-12 col-sm-12 ">
                <div className="">
                  <div className="btn-div">
                    <Link className="btn btn-group-mini">
                      <i className="material-icons" onClick={this.dQty.bind(this)}>
                        {" "}
                        remove
                      </i>
                    </Link>
                    &nbsp; {this.state.cartCount} &nbsp;
                    <Link className="btn btn-group-sm ">
                      {" "}
                      <i className="material-icons" onClick={this.iQty.bind(this)}>
                        add
                      </i>
                    </Link>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>

          <div className="container-fluid desktop-container">
            <div className="row ">
              <div className="col-lg-12 col-md-12 col-sm-12 nopadding my-bg">
                <div className="fancy-collapse-panel">
                  <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    {this.showModifiers(this.state.details.modifiers)}
                    {this.showSetMeal(this.state.details.product_is_combo, this.state.details.set_menu_component)}
                  </div>
                </div>
              </div>
            </div>
            {/*  </div>				 */}
          </div>

          <div className="container-fluid ">
            <div className="space-30"></div>
            <div className="row ">
              <Link>
                <button
                  id="addToCart"
                  className="col-xs-12 continue-but desktop-container container-fluid bg-red "
                  onClick={this.addToCart.bind(this, this.state.details.product_id, this.state.details.product_name, this.state.details.product_sku, this.state.productPrice, this.state.cartCount, proImg, this.state.allowCart)}
                >
                  {lang.add_to_cart}( {this.state.cartCount} )<p className="bb-txt pull-right no-margin pd-r-25">{this.state.productPrice}</p>
                </button>
              </Link>
            </div>
            <div className="clearfix"></div>
          </div>

          {/* For Pickup Success */}

          <div className="modal" id="Pickup-Sucess">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/DeliverySucess.png" className="img-responsive" alt="" />
                    Awesome, you can pickup !
                  </h4>
                </div>
                <div className="modal-body text-center">
                  <p>
                    <b>Your Order is handled by: </b>
                    <br />
                    {this.state.orderHandled}
                  </p>
                  <br />
                  <button type="button" onClick={this.pickupSuccess.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* For Delivery Success */}

          <div className="modal" id="DeliverySucess">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/DeliverySucess.png" className="img-responsive" alt="thumb-up" />
                    Awesome, we can deliver!
                  </h4>
                </div>
                <div className="modal-body text-center">
                  <p>
                    <b>Your Delivery Address: </b>
                    <br />
                    {this.state.orderDeliveryAddress}
                  </p>

                  <p>
                    <b>Your Order is handled by:</b>
                    <br />
                    {this.state.orderHandled}
                  </p>
                  <br />
                  <button type="button" onClick={this.deliverySuccess.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* For Delivery error */}

          <div className="modal" id="DeliveryError">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/Cant_Deliver.png" className="img-responsive" alt="" />
                    Sorry !
                  </h4>
                </div>
                <div className="modal-body">
                  <p>
                    Sorry we cant deliver to your address <br />
                    Do you want to change your address or check again later ?
                  </p>
                  <br />
                  <div className="col-sm-6">
                    <button type="button" onClick={this.closeDeliveryErr.bind(this)} className="col-lg-6 btn btn-raised btn-info fullwidth ">
                      Check Later
                    </button>
                  </div>
                  <div className="col-sm-6">
                    <button type="button" onClick={this.changeDelivery.bind(this)} className="col-lg-6 btn btn-raised btn-info fullwidth ">
                      Change Address
                    </button>
                  </div>
                  <br />
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* For takeaway popup */}

          <div className="modal" id="Pickup-OutletSelection">
            <div className="modal-dialog locWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                  </button>
                  <h4 className="modal-title">Select Your Pickup Location</h4>
                </div>
                <Overlay load={this.state.load} />
                <div className="modal-body">
                  <div className="map-loc">
                    {/* <Map markers={this.state.markers} /> */}

                    <div className="map-box scrollbar" id="style-3">
                      {this.__loadOutlets()}
                    </div>

                    <br />
                    <br />
                  </div>
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* For delivery popup */}
          <div className="modal" id="DeliveryAddress">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                  </button>
                  <h4 className="modal-title">Your Delivery Address</h4>
                </div>
                <Overlay load={this.state.load} />
                <div className="modal-body">
                  <div className="form-group label-floating is-empty card-no">
                    <div className="input-group">
                      <label className="control-label">Enter Your Postal Code</label>
                      <input value={this.state.addressField} id="postalcode1" maxLength="6" className="form-control" onKeyPress={(e) => this.validatezip(e)} onChange={this.handleChangeValue} />
                    </div>
                  </div>
                  <br />
                  <button type="button" onClick={this.selectOutlet.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* after login user popup */}

          <div className="modal" id="AddressList">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/DeliverySucess.png" className="img-responsive" alt />
                    Your Delivery Address
                  </h4>
                </div>
                <Overlay load={this.state.load} />
                <div className="modal-body" style={{ textAlign: "left" }}>
                  {this.loadDefaultAddress()}
                  {this.loadAddressList()}

                  <p>
                    <input value={this.state.addressField} onChange={this.handleChangeValue} id="postalcode" maxLength="6" placeholder="Enter Your Postal Code" className="add_list" onKeyPress={(e) => this.validatezip(e)} />
                  </p>
                  <br />

                  <button type="button" onClick={this.selectOutlet.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                  <br />
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* end of after login popup*/}
        </div>
      )
    } else {
      return (
        <div>
          {" "}
          <div className="container-fluid desktop-container">
            <div className="row">{lang.no_products_found} </div>{" "}
          </div>{" "}
        </div>
      )
      //	hashHistory.push("/");
    }
  }
}

export default Cartitemsedit

import React from "react"
import axios from "axios"
import { loadAnalytics } from "./withTracker"
import { Link, hashHistory } from "react-router"
import getResult from "./Api"
import cookie from "react-cookie"
import DocumentMeta from "react-document-meta"

import config from "./config"
import lang from "./lang"
const APIURL = config.path.apiUrl
const APPID = config.ids.appId
var strp = require("strp")
var helper = require("./Helper.js")

/* Load meta tags */
const meta = {
  title: lang.addons_meta_title + lang.site_title_with_separator,
  description: lang.addons_meta_title,
  meta: {
    name: {
      keywords: lang.addons_meta_title,
    },
  },
}
class Addons extends React.Component {
  constructor(props) {
    super(props)
    this.state = { products: [], products_common: [], status: "Loading" }

    /*add class */
    helper.addBodyClass()
  }

  componentDidMount() {
    axios.get(APIURL + "productsver2/products_list?status=A&app_id=" + APPID + "&availability=" + cookie.load("defaultAvilablityId") + "&apply_addon=Yes").then((res) => {
      this.setState({ status: res.data.status })
      if (res.data.status === "ok") {
        this.setState({ status: res.data.status })
        this.setState({ products_common: res.data.common })
        this.setState({ products: res.data.result_set })
      } else {
        this.setState({ status: res.data.status })
      }
    })

    loadAnalytics()
  }

  handleChange(event) {
    var query = document.getElementById("searchInput").value

    this.setState({ searchText: query })
  }

  /* load product Image*/
  __productImage(product_thumbnail, imagePath) {
    return product_thumbnail === "" ? "/img/no-images/productthump-no-image.jpg" : this.state.products_common.image_source + "/" + product_thumbnail
  }

  /* imnage class */
  productClass(product_thumbnail) {
    return product_thumbnail !== "" ? "col-lg-7 col-md-7 col-sm-7 col-xs-7" : "col-md-12"
  }

  /* image content */

  productImagecontent(product_slug, product_thumbnail) {
    if (product_thumbnail !== "") {
      var image = config.path.tinThumpUrl + this.state.products_common.image_source + "/" + product_thumbnail + "&w=288&h=144&q=80"
      return (
        <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 thumb-img-div">
          <Link to={"/product/" + product_slug}>
            {" "}
            <img src={image} alt="" width="100%" />{" "}
          </Link>
        </div>
      )
    }
  }

  /* this function used to load  products */
  showProducts() {
    return this.state.products.map((product, index) =>
      (() => {
        if (typeof this.state.searchText !== "undefined") {
          if (product.product_name.toLowerCase().indexOf(this.state.searchText.toLowerCase()) !== -1) {
            return (
              <div className="productparent" key={index}>
                {this.productImagecontent(product.product_slug, product.product_thumbnail)}

                <div className={this.productClass(product.product_thumbnail)}>
                  <Link to={"/product/" + product.product_slug}>
                    <h1 className="head1">
                      {" "}
                      {strp.stripslashes(product.product_name)} <p className=" price pull-right"> {config.ids.currency + product.product_price}</p>
                    </h1>
                    <p className="text-justify">{strp.stripslashes(product.product_short_description)}} </p>
                  </Link>
                </div>
                <div className="clearfix" />
              </div>
            )
          }
        } else {
          return (
            <div className="productparent" key={index}>
              {this.productImagecontent(product.product_slug, product.product_thumbnail)}
              <div className={this.productClass(product.product_thumbnail)}>
                <Link to={"/product/" + product.product_slug}>
                  <h1 className="head1">
                    {" "}
                    {strp.stripslashes(product.product_name)} <p className=" price pull-right"> {config.ids.currency + product.product_price}</p>
                  </h1>
                  <p className="text-justify">{strp.stripslashes(product.product_short_description)} </p>
                </Link>
              </div>
              <div className="clearfix" />
            </div>
          )
        }
      })()
    )
  }

  /* show next page link */
  nextpage() {
    if (typeof cookie.load("UserId") === "undefined" || (cookie.load("UserId") && cookie.load("UserId") === "")) {
      hashHistory.push("/sign-in")
    } else {
      if (cookie.load("defaultAvilablityId") === config.ids.dineinId) {
        /* only for sarvesh app */
        if (APPID === "287E5552-328E-4DBE-A3CA-E998ACCAC9BD") {
          helper.sarveshTableSetup()
        } else {
          hashHistory.push("/book-a-table")
        }
      } else {
        hashHistory.push("/delivery-date")
      }
    }
  }

  /* this function used to load cart icon image */
  loadCartImage() {
    return getResult("cartImage", "Yes")
  }
  render() {
    if (this.state.status === "Loading") {
      return (
        <div style={{ bottom: 0, left: 0, position: "fixed", right: 0, top: 0, margin: "auto" }}>
          {" "}
          <img src="/img/loader.gif" alt="Loading" style={{ bottom: 0, left: 0, position: "fixed", right: 0, top: 0, margin: "auto" }} />{" "}
        </div>
      )
    } else if (this.state.status === "ok") {
      return (
        <div>
          <DocumentMeta {...meta} />
          <div className="container-fluid desktop-container">
            <div id="parentDisable" />
            <div className="row">
              <div className="col-xs-12 top-nav inner-top-nav  head-main">
                <div className="col-xs-3 nav-head pd-l-20  head-child">
                  {" "}
                  <Link to={"/cart"}>
                    <img src="/img/icons/arrow-right.png" alt="left-arrow" className="icon-img-small-3" />
                  </Link>
                </div>
                <div className="col-xs-6 nav-head  head-child">
                  <h1 className="main-title">Complete Your Meal</h1>
                </div>
                <div className="col-xs-3 nopadding  head-child">
                  <p className="b-txt text-right ">
                    <Link data-toggle="collapse" to={"#SearchBAR"} aria-expanded="false" aria-controls="collapseExample">
                      <img alt="" src="/img/icons/search.png" className="icon-img-small4 head_serch" />
                    </Link>
                    <Link to={"/cart"}>
                      {" "}
                      <img alt="" src={this.loadCartImage()} className="icon-img-small-1 carticon" />{" "}
                    </Link>
                  </p>
                </div>
                <div className="collapse" id="SearchBAR">
                  <div className="">
                    <div className="form-group SearchBAR">
                      <div className="input-group">
                        <input type="text" id="searchInput" onChange={this.handleChange.bind(this)} ref="searchInput" className="form-control" placeholder="Go ahead search for a dish..." />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*Inner page slider start*/}
            <div className="row">
              <img src="/img/pasta-1.jpg" alt="pasta" className="img-responsive" />
            </div>
            {/*Inner page slider end*/}
            {/*Products start*/}
            <div className="product-holder">{this.showProducts()}</div>
            {/*Products End*/}
            <div className="row ">
              <div className="container">
                {/*  <div className="tigger-menu" id="tigger-menu">
			  <div className="dropup" id="dropup">
				<a className="dropdown-toggle" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				  <div className="float-btn" />
				</a>
				<ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
				  <li><a href="#">Pasta</a></li>
				  <li><a href="#">Appetiser</a></li>
				  <li><a href="#">Pizza</a></li>
				  <li><a href="#">Appetiser</a></li>
				  <li><a href="#">Drinks</a></li>
				  <li><a href="#">Kids Meal</a></li>
				  <li><a href="#">Pasta</a></li>
				  <li><a href="#">Appetiser</a></li>
				  <li><a href="#">Pizza</a></li>
				  <li><a href="#">Kids MealPasta</a></li>
				  <li><a href="#">Appetiser</a></li>
				  <li><a href="#">pizza</a></li>
				</ul>
			  </div>
			  <a href="#" />
			</div> */}
                <div className="row">
                  <Link>
                    <button className="col-lg-12 continue-but desktop-container container-fluid" onClick={this.nextpage.bind()}>
                      Continue
                    </button>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <DocumentMeta {...meta} />
          <div className="container-fluid desktop-container">
            <div id="parentDisable" />
            <div className="row">
              <div className="col-xs-12 top-nav inner-top-nav head-main">
                <div className="col-xs-3 nav-head pd-l-20 head-child">
                  {" "}
                  <Link to={"/"}>
                    <img src="/img/icons/arrow-right.png" alt="left-arrow" className="icon-img-small-3" />
                  </Link>
                </div>
                <div className="col-xs-6 nav-head  head-child">
                  <h1 className="main-title">Complete Your Meal</h1>
                </div>
                <div className="col-xs-3 nopadding  head-child">
                  <p className="b-txt text-right ">
                    <Link to={"/cart"}>
                      {" "}
                      <img src={this.loadCartImage()} className="icon-img-small-1-inner" alt="shopping-cart" />{" "}
                    </Link>
                  </p>
                </div>
              </div>
            </div>

            {/*Products start*/}
            <div className="row product-holder">
              <br />
              <br />
              <p> {lang.no_products_found} </p>
              <div className="clearfix" />
            </div>
            {/*Products End*/}
            <div className="row ">
              <div className="container">
                <div className="row">
                  <Link>
                    <button className="col-lg-12 continue-but desktop-container container-fluid" onClick={this.nextpage.bind()}>
                      Continue
                    </button>
                  </Link>{" "}
                </div>
              </div>
            </div>
            <br />
          </div>{" "}
        </div>
      )
    }
  }
}

export default Addons

/* eslint-disable */
import React from "react"
import axios from "axios"
import { loadAnalytics } from "./withTracker"
import getResult from "./Api"
import DocumentMeta from "react-document-meta"
import { Link, hashHistory } from "react-router"
import cookie from "react-cookie"
import "./assets/css/App.css"
import { RouteTransition } from "react-router-transition"
import Loading from "./loading"
import Overlay from "./Overlay"
import lang from "./lang"

import config from "./config"
const APIURL = config.path.apiUrl
const APPID = config.ids.appId
const APIURLv2 = config.path.apiUrlv2
const apps = require("./apps")
const currency = config.ids.currency
var qs = require("qs")
var HtmlToReactParser = require("html-to-react").Parser
var htmlToReactParserTo = new HtmlToReactParser()
var strp = require("strp")
var helper = require("./Helper.js")
var zonalEnable = 1

/* Load meta tags */
console.log(lang)
const meta = {
  title: lang.cart_page_title + lang.site_title_with_separator,
  description: lang.cart_page_description,
  meta: {
    name: {
      keywords: lang.cart_page_keywords,
    },
  },
}
var deletevar = "No"
class Cart extends React.Component {
  constructor(props) {
    /*add class */
    helper.addBodyClass()

    super(props)
    /* Declare state */
    this.state = {
      zoneEnabled: 0,
      outbasedProducts: 0,
      windowHeightNormal: 0,
      windowHeight: 0,
      cartDetails: [],
      cartItems: [],
      secAddrData: [],
      status: "Loading",
      cartCount: "No",
      settings: [],
      delPercentage: 0,
      minOrderPercentage: 0,
      remaingAmnt: 0,
      minOrderRemaingAmnt: 0,
      additnl_deliveryAmnt_cal: 0,
      zoneDetails: [],
      incrementValue: 0,
      checkItems: [],
      checkDetails: [],
    }
    /* get settings values */
    var postalParam = cookie.load("defaultAvilablityId") === config.ids.deliveryId ? "Yes" : "No"

    /*11/28/2018*/
    var zoneDetails = ""

    if (typeof cookie.load("orderZoneID") !== "undefined" && cookie.load("orderZoneID") !== "" && cookie.load("defaultAvilablityId") === config.ids.deliveryId) {
      axios.get(APIURLv2 + "outlets/getZoneDetails/?app_id=" + APPID + "&availability=" + config.ids.deliveryId + "&ZoneId=" + cookie.load("orderZoneID") + "&outletId=" + cookie.load("orderOutletId")).then((res) => {
        if (res.data.status === "ok") {
          this.setState({ zoneDetails: res.data.result_set[0] })
        } else {
          alert(res.data.message)
          hashHistory.push("/")
        }
      })
    }

    this.loadCartData()

    /*11/28/2018*/

    var promise = getResult("settingsPro", postalParam)
    promise.then((res) => {
      var settingResult = res.data.result_set
      this.setState({ zoneEnabled: parseInt(settingResult.client_outlet_zone) })
      this.setState({ outbasedProducts: parseInt(settingResult.ninja_outletbasedproduct_enable) })
      if (parseInt(settingResult.client_outlet_zone) === 1 && cookie.load("defaultAvilablityId") === config.ids.deliveryId) {
        if (typeof this.state.zoneDetails === "undefined" || this.state.zoneDetails === "") {
          alert("something went wrong")
          hashHistory.push("")
        } else {
          if ((this.state.zoneEnabled === 1 && cookie.load("defaultAvilablityId") === config.ids.deliveryId && this.state.zoneDetails["zone_delivery_charge"] > 0) || this.state.zoneDetails["zone_min_amount"] > 0) {
            settingResult["callcnter_min_amount"] = this.state.zoneDetails["zone_min_amount"]
            settingResult["client_free_delivery_amount"] = this.state.zoneDetails["zone_free_delivery"]
            settingResult["client_delivery_surcharge"] = this.state.zoneDetails["zone_delivery_charge"]
            settingResult["additional_delivery_charge"] = this.state.zoneDetails["zone_additional_delivery_charge"]
          }
        }
      }

      this.setState({ settings: settingResult })
      this.loadCartData()
      this.setState({ additnl_deliveryAmnt_cal: settingResult.additional_delivery_charge })
      cookie.save("siteURL", settingResult.client_site_url)
    })

    this.handleChange1 = this.handleChange1.bind(this) //handleChangeValue
    this.handleChangeValue = this.handleChangeValue.bind(this)

    axios.get(APIURL + "outlets/pickup_outlets?app_id=" + APPID).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ Outlets: res.data.result_set })
        //this.loadMarkers();
      }
    })

    axios.get(APIURL + "/customer/get_user_secondary_address?app_id=" + APPID + "&refrence=" + cookie.load("UserId")).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ secAddrData: res.data.result_set })
      }
    })
  }

  /* Open Zipcode popup */

  getSignInlink() {
    return parseInt(this.state.settings.client_enable_express_checkout) === 1 ? "/new-signin" : "/sign-in"
  }

  changeDelivery() {
    this.setState({ load: "false" })
    $("#DeliveryError").modal("toggle")
    if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
      $("#AddressList").modal("toggle")
    } else {
      $("#DeliveryAddress").modal("toggle")
    }

    //$("#DeliveryAddress").modal("toggle");
  }

  changeDeliveryAddr() {
    $("#SwitchingError").modal("toggle")

    console.log(cookie.load("defaultAvilablityId"))
    if (cookie.load("defaultAvilablityId") === config.ids.deliveryId && cookie.load("defaultAvilablity") === lang.delivery) {
      $("#Delivery-OutletSelection").modal("toggle")
    } else if (cookie.load("defaultAvilablityId") === config.ids.pickupId && cookie.load("defaultAvilablity") === lang.takeaWay) {
      $("#Pickup-OutletSelection").modal("toggle")
    }
  }

  handleChange1(event) {
    //alert(event.target.value);
    this.setState({ addressField: event.target.value })
  }

  handleChangeValue(event) {
    this.setState({ addressField: event.target.value })
  }

  closeDeliveryErr() {
    this.setState({ load: "false" })
    $("#DeliveryError").modal("toggle")
  }

  /* Validate zipcode */
  validatezip(e) {
    const re = /[0-9A-F:]+/g
    if (!re.test(e.key)) {
      e.preventDefault()
    }
  }

  /* load outlets */
  __loadOutlets() {
    if (this.state.Outlets) {
      return this.state.Outlets.map((loaddata, index) => (
        <div
          className="map-li"
          key={index}
          onClick={this.pickOutlet.bind(
            this,
            loaddata.outlet_id,
            loaddata.outlet_name,
            loaddata.outlet_postal_code,
            loaddata.outlet_address_line1,
            loaddata.outlet_address_line2,
            loaddata.outlet_unit_number1,
            loaddata.outlet_unit_number2,
            loaddata.outlet_pickup_tat,
            loaddata.outlet_tax_option,
            loaddata.outlet_tax_surcharge,
            loaddata.outlet_tax_surcharge_inclusive
          )}
        >
          <p className="map-h">{loaddata.outlet_name}</p>
          <p className="map-sub">{loaddata.outlet_address_line1 + ", " + loaddata.outlet_address_line2 + " " + loaddata.outlet_postal_code}</p>
        </div>
      ))
    }
  }

  loadAddressList() {
    return this.state.secAddrData.map((item, index) => (
      <p key={index}>
        <input name="radio" value={item.postal_code} id={item.secondary_address_id} className="css-radio" type="radio" onChange={this.handleChange1} />
        <label htmlFor={item.secondary_address_id} className="css-label ">
          {" "}
          {item.address + " Singapore " + item.postal_code}
        </label>
      </p>
    ))
  }

  /* Select outlet */

  selectOutlet() {
    if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
      var postalcode = $("#postalcode").val() //"postalcode";
    } else {
      postalcode = $("#postalcode1").val() // "postalcode1";
    }

    if (postalcode.length < 5) {
      return false
    }

    this.setState({ load: "true" })

    /*add loading */
    axios.get(APIURL + "outlets/find_outlet?skip_timing=Yes&app_id=" + APPID + "&availability_id=" + cookie.load("defaultAvilablityId") + "&postal_code=" + postalcode).then((res) => {
      /* Success response */
      /* Success response  && Object.keys(res.data.result_set.postal_code_information).length !==0 */
      if (res.data.status === "ok") {
        /*if(Object.keys(res.data.result_set.postal_code_information).length !==0)
					   { */
        var orderDeliveryAddress = res.data.result_set.postal_code_information.zip_buno + " " + res.data.result_set.postal_code_information.zip_sname
        var orderHandled = res.data.result_set.outlet_name + ", Crew will be seeing you in " + res.data.result_set.outlet_delivery_timing + " Minutes"
        this.setState({ orderDeliveryAddress: orderDeliveryAddress + " Singapore " + res.data.result_set.postal_code_information.zip_code })
        this.setState({ orderHandled: orderHandled })
        cookie.save("orderOutletId", res.data.result_set.outlet_id)
        cookie.save("orderOutletName", res.data.result_set.outlet_name)
        cookie.save("orderPostalCode", res.data.result_set.postal_code_information.zip_code)
        cookie.save("orderTAT", res.data.result_set.outlet_delivery_timing)
        cookie.save("orderDeliveryAddress", orderDeliveryAddress)
        cookie.save("orderHandled", orderHandled)

        cookie.save("outlet_tax_option", res.data.result_set.outlet_tax_option)
        cookie.save("outlet_tax_surcharge", res.data.result_set.outlet_tax_surcharge)
        cookie.save("outlet_tax_surcharge_inclusive", res.data.result_set.outlet_tax_surcharge_inclusive)

        var orderHandledText = res.data.result_set.outlet_name + " " + res.data.result_set.outlet_address_line1 + " " + res.data.result_set.outlet_address_line2 + ", Singapore " + postalcode
        cookie.save("orderHandledByText", orderHandledText)

        if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
          $("#AddressList").modal("toggle")
        } else {
          $("#DeliveryAddress").modal("toggle")
        }
        $("#DeliverySucess").modal("toggle")

        /* }else{
							$("#DeliveryAddress").modal("toggle");
							$("#DeliveryError").modal("toggle");
						} */
      }
      /* Error response */
      if (res.data.status === "error") {
        this.setState({ load: "false" })

        if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
          $("#AddressList").modal("toggle")
        } else {
          $("#DeliveryAddress").modal("toggle")
        }
        if (res.data.shop_close === "Yes") {
          $("#ShopClosedError").modal("toggle")
        } else {
          $("#DeliveryError").modal("toggle")
        }
      }
    })
  }

  closeShopClosedError() {
    this.setState({ load: "false" })
    $("#ShopClosedError").modal("toggle")
  }

  /* pick outlet */
  pickOutlet(outlet_id, outlet_name, outlet_postal_code, outlet_address_line1, outlet_address_line2, outlet_unit_number1, outlet_unit_number2, outlet_pickup_tat, outlet_tax_option, outlet_tax_surcharge, outlet_tax_surcharge_inclusive) {
    var orderHandled = outlet_name + " " + outlet_address_line1 + " " + outlet_address_line2 + ", Singapore " + outlet_postal_code
    this.setState({ orderHandled: orderHandled })
    cookie.save("orderOutletId", outlet_id)
    cookie.save("orderOutletName", outlet_name)
    cookie.save("orderPostalCode", outlet_postal_code)
    cookie.save("orderTAT", outlet_pickup_tat)
    cookie.save("orderHandled", orderHandled)
    cookie.save("orderHandledByText", orderHandled)

    cookie.save("outlet_tax_option", outlet_tax_option)
    cookie.save("outlet_tax_surcharge", outlet_tax_surcharge)
    cookie.save("outlet_tax_surcharge_inclusive", outlet_tax_surcharge_inclusive)

    $("#Pickup-OutletSelection").modal("toggle")
    $("#Pickup-Sucess").modal("toggle")
  }

  /* continue with pickup success */
  pickupSuccess() {
    var productId = this.state.productId
    var proName = this.state.proName
    var prosku = this.state.prosku
    var proPrice = this.state.proPrice
    var proQty = this.state.proQty
    var proImage = this.state.proImage
    var allowCart = this.state.allowCart
    var applyMinmaxSelect = this.state.applyMinmaxSelect
    $("#Pickup-Sucess").modal("toggle")
    this.destroyCart("No")
  }

  /* continue with delivery success */
  deliverySuccess() {
    var productId = this.state.productId
    var proName = this.state.proName
    var prosku = this.state.prosku
    var proPrice = this.state.proPrice
    var proQty = this.state.proQty
    var proImage = this.state.proImage
    var allowCart = this.state.allowCart
    var applyMinmaxSelect = this.state.applyMinmaxSelect
    $("#DeliverySucess").modal("toggle")
    this.destroyCart("No")
  }

  closeSwitcher() {
    $("#SwitchingError").modal("toggle")
    document.body.classList.add("home_body")
  }

  switchAvailability() {
    /* remove default cokkie values */
    cookie.remove("orderOutletId")
    cookie.remove("orderOutletName")
    cookie.remove("orderPostalCode")
    cookie.remove("orderTAT")
    cookie.remove("orderDeliveryAddress")
    cookie.remove("orderHandled")
    cookie.remove("orderHandledByText")
    cookie.remove("orderZoneID")

    cookie.remove("outlet_tax_option")
    cookie.remove("outlet_tax_surcharge")
    cookie.remove("outlet_tax_surcharge_inclusive")

    cookie.remove("product_remarks")

    $("#SwitchingError").modal("toggle")
    //document.body.classList.add('home_body');

    if (cookie.load("defaultAvilablityId") === config.ids.deliveryId && cookie.load("defaultAvilablity") === lang.delivery) {
      if (parseInt(this.state.zoneEnabled) === 1 /*&& parseInt(this.state.outbasedProducts) === 1 */) {
        this.destroyCart(this, "Yes")
        hashHistory.push("/")
      } else {
        if (typeof cookie.load("UserId") !== "undefined" && cookie.load("UserId") !== "") {
          $("#AddressList").modal("toggle")
        } else {
          $("#DeliveryAddress").modal("toggle")
        }
      }
    } else if (cookie.load("defaultAvilablityId") === config.ids.pickupId && cookie.load("defaultAvilablity") === lang.takeaWay) {
      $("#Pickup-OutletSelection").modal("toggle")
    }
  }

  componentWillMount() {
    var windowHeight = window.innerHeight
    //alert(windowHeight);
    this.setState({ windowHeight: windowHeight - 45 + "px" })
    this.setState({ windowHeightNormal: windowHeight + "px" })
  }

  loadCartData() {
    /* API CALL FOR BANNERS */
    if (typeof cookie.load("UserId") === "undefined") {
      var customerParam = "&reference_id=" + getResult("referenceId")
    } else {
      customerParam = "&customer_id=" + cookie.load("UserId")
    }
    var zoneparam = ""
    if (cookie.load("orderZoneID") !== "" && typeof cookie.load("orderZoneID") !== undefined && typeof cookie.load("orderZoneID") !== "undefined") {
      zoneparam = "&zone_id=" + cookie.load("orderZoneID")
    }

    var deliveryDate = ""
    var deliveryTime = cookie.load("DeliveryTime") !== undefined ? cookie.load("DeliveryTime") : ""
    var deliveryDateTxt = cookie.load("DeliveryDate") !== undefined ? cookie.load("DeliveryDate") : ""
    if (deliveryDateTxt != "") {
      var deliveryDateArr = deliveryDateTxt.split("/")
      deliveryDate = deliveryDateArr[2] + "-" + deliveryDateArr[1] + "-" + deliveryDateArr[0]
    }

    if (deliveryTime == "" && deliveryDateTxt == "") {
      var today = new Date()
      var dd = String(today.getDate()).padStart(2, "0")
      var mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
      var yyyy = today.getFullYear()
      var todayDate = yyyy + "-" + mm + "-" + dd
      var timeNow = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
      deliveryDate = todayDate
      deliveryTime = timeNow
    }
    var postalCodeWhere = ""
    if (typeof cookie.load("orderPostalCode") !== "undefined") {
      postalCodeWhere = "&postal_code=" + cookie.load("orderPostalCode")
    }
    axios.get(APIURL + "cartver2/contents?status=A&app_id=" + APPID + customerParam + zoneparam + "&orderdate=" + deliveryDate + "&ordertime=" + deliveryTime + "&stockvalide=yes" + postalCodeWhere).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ status: "ok" })
        if (typeof res.data.result_set !== "undefined") {
          var cartDetails = res.data.result_set.cart_details
          var cartItems = res.data.result_set.cart_items

          if (this.state.zoneEnabled === 1 && cookie.load("defaultAvilablityId") === config.ids.deliveryId && this.state.settings.zone_order_value_delivery_charge === "1") {
            var zone_Details = this.state.zoneDetails
            zone_Details["zone_delivery_charge"] = cartDetails["cart_zone_delivery_charge"]
            this.setState({ zoneDetails: zone_Details })
          }

          if (this.state.zoneEnabled === 1 && cookie.load("defaultAvilablityId") === config.ids.deliveryId && this.state.zoneDetails["zone_delivery_charge"] > 0) {
            cartDetails["cart_delivery_charge"] = this.state.zoneDetails["zone_delivery_charge"]
            cartDetails["cart_grand_total"] = parseFloat(cartDetails["cart_sub_total"]) + parseFloat(this.state.zoneDetails["zone_delivery_charge"])
          }
          this.setState(
            {
              checkItems: cartItems,
              checkDetails: cartDetails,
            },
            function () {
              this.findvocuherItems()
            }
          )
          this.setStateSuccess("ok", cartDetails, cartItems)
          this.cartCountSts("Yes")
        } else {
          this.cartCountSts("No")
          this.setState({ status: "error" })
        }
      } else {
        this.cartCountSts("No")
        this.setState({ status: "error" })
      }
    })
  }

  /* Delete cart items */
  deleteOrderCookie() {
    cookie.save("cartValue", "No")
    cookie.remove("orderPaymentMode")
    cookie.remove("orderOutletId")
    cookie.remove("orderTableNo")
    cookie.remove("product_remarks")
    cookie.remove("orderOutletName")
    cookie.remove("orderOutletId")
    cookie.remove("orderZoneID")

    /* Delivery avilablity */
    cookie.remove("DeliveryDate")
    cookie.remove("DeliveryTime")
    cookie.remove("unitNoOne")
    cookie.remove("unitNoTwo")

    cookie.remove("outlet_tax_option")
    cookie.remove("outlet_tax_surcharge")
    cookie.remove("outlet_tax_surcharge_inclusive")
  }
  /* this function used to delte all  cart items */
  destroyCart(deletevar) {
    var postObject = {}
    postObject = { app_id: config.ids.appId }

    if (typeof cookie.load("UserId") === "undefined") {
      postObject["reference_id"] = getResult("referenceId")
    } else {
      postObject["customer_id"] = cookie.load("UserId")
    }

    axios.post(APIURL + "cart/destroy", qs.stringify(postObject)).then((res) => {
      cookie.remove("promotion_code")
      if (res.data.status === "ok") {
        this.cartCountSts("No")

        /* remove */
        if (deletevar == "Yes") {
          this.deleteOrderCookie()
        }

        this.setStateSuccess("error", "[]", "[]")
      } else if (res.data.status === "error") {
        helper.showInfo(res.data.message)
      }
    })
  }

  /* this function used to delte single cart items */
  deleteCartItem(cartItemId) {
    var postObject = {}
    postObject = { app_id: config.ids.appId, cart_item_id: cartItemId }

    if (typeof cookie.load("UserId") === "undefined") {
      postObject["reference_id"] = getResult("referenceId")
    } else {
      postObject["customer_id"] = cookie.load("UserId")
    }
    if (cookie.load("orderZoneID") !== "" && typeof cookie.load("orderZoneID") !== undefined && typeof cookie.load("orderZoneID") !== "undefined") {
      postObject["zone_id"] = cookie.load("orderZoneID")
    }
    axios.post(APIURL + "cart/delete", qs.stringify(postObject)).then((res) => {
      if (res.data.status === "ok") {
        if (res.data.contents) {
          this.cartCountSts("Yes")

          var cartDetails = res.data.contents.cart_details
          var cartItems = res.data.contents.cart_items

          this.setState(
            {
              checkItems: cartItems,
              checkDetails: cartDetails,
            },
            function () {
              this.findvocuherItems()
            }
          )

          if (this.state.zoneEnabled === 1 && cookie.load("defaultAvilablityId") === config.ids.deliveryId && this.state.settings.zone_order_value_delivery_charge === "1") {
            var zone_Details = this.state.zoneDetails
            zone_Details["zone_delivery_charge"] = cartDetails["cart_zone_delivery_charge"]
            this.setState({ zoneDetails: zone_Details })
          }

          if (this.state.zoneEnabled === 1 && cookie.load("defaultAvilablityId") === config.ids.deliveryId && this.state.zoneDetails["zone_delivery_charge"] > 0) {
            cartDetails["cart_delivery_charge"] = this.state.zoneDetails["zone_delivery_charge"]
            cartDetails["cart_grand_total"] = parseFloat(cartDetails["cart_sub_total"]) + parseFloat(this.state.zoneDetails["zone_delivery_charge"])
          }

          this.setStateSuccess("ok", cartDetails, cartItems)
        } else {
          this.cartCountSts("No")
          this.deleteOrderCookie()
          this.setStateSuccess("error", "[]", "[]")
        }
      } else if (res.data.status === "error") {
        helper.showInfo(res.data.message)
      }
    })
  }

  /* this function used increase cart item qty */
  incQty(cartItemId, qty, productId, type, order_id) {
    if (type === "add") {
      var cartQty = Number(qty) + 1
    } else {
      if (Number(qty) === 1) {
        return false
      } else {
        cartQty = Number(qty) - 1
      }
    }

    var postObject = {}

    if (order_id !== "" && order_id !== "undefined" && order_id !== null && order_id !== "0") {
      postObject = { app_id: config.ids.appId, cart_item_id: cartItemId, product_id: productId, product_qty: cartQty, voucher_order_id: order_id }
    } else {
      postObject = { app_id: config.ids.appId, cart_item_id: cartItemId, product_id: productId, product_qty: cartQty }
    }

    if (typeof cookie.load("UserId") === "undefined") {
      postObject["reference_id"] = getResult("referenceId")
    } else {
      postObject["customer_id"] = cookie.load("UserId")
    }
    if (cookie.load("orderZoneID") !== "" && typeof cookie.load("orderZoneID") !== undefined && typeof cookie.load("orderZoneID") !== "undefined") {
      postObject["zone_id"] = cookie.load("orderZoneID")
    }

    axios.post(APIURL + "cart/update", qs.stringify(postObject)).then((res) => {
      if (res.data.status === "ok") {
        cookie.remove("tempPromoCode")
        cookie.remove("tempPoints")
        this.cartCountSts("Yes")
        var cartDetails = res.data.contents.cart_details
        var cartItems = res.data.contents.cart_items

        this.setState(
          {
            checkItems: cartItems,
            checkDetails: cartDetails,
          },
          function () {
            this.findvocuherItems()
          }
        )

        if (this.state.zoneEnabled === 1 && this.state.settings.zone_order_value_delivery_charge === "1") {
          var zone_Details = this.state.zoneDetails
          zone_Details["zone_delivery_charge"] = cartDetails["cart_zone_delivery_charge"]
          this.setState({ zoneDetails: zone_Details })
        }

        if (this.state.zoneEnabled === 1 && cookie.load("defaultAvilablityId") === config.ids.deliveryId && this.state.zoneDetails["zone_delivery_charge"] > 0) {
          cartDetails["cart_delivery_charge"] = this.state.zoneDetails["zone_delivery_charge"]
          cartDetails["cart_grand_total"] = parseFloat(cartDetails["cart_sub_total"]) + parseFloat(this.state.zoneDetails["zone_delivery_charge"])
        }
        this.setStateSuccess("ok", cartDetails, cartItems)
      } else if (res.data.status === "error") {
        helper.showInfo(res.data.message)
      }
    })
  }

  /* store special instruction  cokie value */
  handleChange(event) {
    cookie.save("product_remarks", event.target.value)
  }

  /* this function used to set default succes  state value */
  setStateSuccess(sts, cartDetails, cartItms) {
    this.setState({ status: sts })
    this.setState({ cartDetails: cartDetails })
    this.setState({ cartItems: cartItms })

    /* store orginal data for refference */
    //this.setState({cartDetailsSource: cartDetails});
    //this.setState({cartItemsSource: cartItms})

    if (sts === "ok") {
      /* calculate free delivery amount calculation */
      var freeDeliveryAmnt = parseFloat(this.state.settings.client_free_delivery_amount)
      var DeliveryAmnt = parseFloat(this.state.settings.client_delivery_surcharge)
      var AdditnlDeliveryAmnt = parseFloat(this.state.settings.additional_delivery_charge)
      this.setState({ additnl_deliveryAmnt_cal: AdditnlDeliveryAmnt })

      /* min order perentage */
      let minAmount = parseFloat(this.state.settings.callcnter_min_amount)
      if (cartDetails.cart_availability_id === config.ids.deliveryId && minAmount > 0) {
        var MinOrdersubTotal = parseFloat(cartDetails.cart_sub_total)
        var minpercentage = (MinOrdersubTotal * 100) / minAmount
        minpercentage = Math.round(minpercentage)
        var minremaingAmnt = parseFloat(minAmount - MinOrdersubTotal).toFixed(2)
        if (minremaingAmnt > 0) {
          this.setState({ minOrderRemaingAmnt: minremaingAmnt })
          this.setState({ minOrderPercentage: minpercentage })
        } else {
          this.setState({ minOrderRemaingAmnt: 0 })
          this.setState({ minOrderPercentage: 0 })
        }
      }

      if (cartDetails.cart_availability_id === config.ids.deliveryId && (DeliveryAmnt > 0 || AdditnlDeliveryAmnt > 0) && freeDeliveryAmnt > 0) {
        var subTotal = parseFloat(cartDetails.cart_sub_total)
        var percentage = (subTotal * 100) / freeDeliveryAmnt
        percentage = Math.round(percentage)
        var remaingAmnt = parseFloat(freeDeliveryAmnt - subTotal).toFixed(2)

        if (remaingAmnt > 0) {
          this.setState({ remaingAmnt: remaingAmnt })
          this.setState({ delPercentage: percentage })
          // this.changeDeliveryAmount(DeliveryAmnt,subTotal);
        } else {
          this.setState({ remaingAmnt: 0 })
          this.setState({ delPercentage: 0 })
          this.changeDeliveryAmount(0, subTotal)
          this.setState({ additnl_deliveryAmnt_cal: 0 })
        }
      } else {
        //alert(DeliveryAmnt + "//"+freeDeliveryAmnt);
      }
    }
  }

  /* this function used to  set cart items count */
  cartCountSts(cartsts) {
    // var cartCount = (cartsts === "Yes")? this.state.cartDetails.cart_total_items : 0;
    //alert(cartCount);
    cookie.save("cartValue", cartsts)
    // cookie.save('cartCount',this.state.cartDetails.cart_total_items);
  }

  /* show avilablity details */

  /* hide menu */
  hideMenu() {
    return APPID === apps.workspace ? "none" : "block"
  }

  /* show avilablity details */

  loadAvliablityDetails(i) {
    if (cookie.load("defaultAvilablityId") !== config.ids.dineinId && i === 0) {
      if (cookie.load("defaultAvilablityId") === "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11") {
        return (
          <div className="outlet_wrap">
            {" "}
            <div className="left_t_outlet" style={{ width: "99%" }}>
              {" "}
              <span className="pull-right">
                <Link style={{ cursor: "pointer", textDecoration: "none" }} onClick={this.changeDeliveryAddr.bind()}>
                  Change Address
                </Link>
              </span>
              <h4>Your {cookie.load("defaultAvilablity")} Address</h4>{" "}
              <p>
                {cookie.load("orderDeliveryAddress")}, Singapore {cookie.load("orderPostalCode")}{" "}
              </p>
            </div>{" "}
          </div>
        )
      } else if (cookie.load("defaultAvilablityId") === "718B1A92-5EBB-4F25-B24D-3067606F67F0") {
        if (APPID === apps.workspace) {
          return (
            <div className="outlet_wrap">
              {" "}
              <div className="left_t_outlet" style={{ width: "99%" }}>
                <h4>Your {cookie.load("defaultAvilablity")} Outlet</h4>{" "}
                <p>
                  Workspace Espresso Bar <br /> 12 Marina Boulevard
                  <br /> Marina Bay Financial Centre Tower 3<br /> #01-08 Singapore 018982
                </p>
              </div>{" "}
            </div>
          )
        } else {
          if (cookie.load("outlet_option_single") === "1") {
            return (
              <div className="outlet_wrap">
                {" "}
                <div className="left_t_outlet" style={{ width: "99%" }}>
                  <h4>Your {cookie.load("defaultAvilablity")} Outlet</h4> <p>{cookie.load("orderHandledByText")} </p>
                </div>{" "}
              </div>
            )
          } else {
            return (
              <div className="outlet_wrap">
                {" "}
                <div className="left_t_outlet" style={{ width: "99%" }}>
                  {" "}
                  <span className="pull-right">
                    <Link style={{ cursor: "pointer", textDecoration: "none", display: this.hideMenu() }} onClick={this.changeDeliveryAddr.bind()}>
                      Change Outlet
                    </Link>
                  </span>
                  <h4>Your {cookie.load("defaultAvilablity")} Outlet</h4> <p>{cookie.load("orderHandledByText")} </p>
                </div>{" "}
              </div>
            )
          }
        }
      }
    }
  }

  findvocuherItems() {
    let incrementValues = "0"
    let otherproducts = 0

    if (this.state.checkItems != "") {
      const task = this.state.checkItems.map((item, index) => {
        if (item.cart_item_product_type == "5" && item.cart_voucher_order_item_id == "0" && item.cart_item_unit_price > 0) {
          incrementValues++
        } else {
          otherproducts++
        }
      })
    }
    if (incrementValues > 0 && otherproducts == "0") {
      cookie.save("vocuherItems", "1")
    } else {
      cookie.save("vocuherItems", "0")
    }

    if (this.state.checkDetails != "") {
      if (this.state.cart_delivery_charge) cookie.save("cart_delivery_charge", this.state.checkDetails.cart_delivery_charge)
    }
  }

  /* this function used to load cart items */
  loadCartItems() {
    return this.state.cartItems.map((item, index) => (
      <div className="row product-holder1 top-zero" key={index}>
        {this.loadAvliablityDetails(index)}
        {/*  <div className="right_t_outlet outlet_pos_rgt">
                                            <a >Change Outlet</a>
                                    </div>  */}

        {this.loadiItemsCount(index)}

        {/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 cart_com">
					<img src={item.cart_item_product_image} alt="pizza -thumbnail" width="100%"/> {}
				</div> */}

        <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 cart_com">
          <h1 className="head22">
            <Link /*to={"product/"+item.cart_item_product_id+"/update/"+item.cart_item_id} */>
              {strp.stripslashes(item.cart_item_product_name)} {this.loadModifierItems(item.cart_item_type, item.modifiers, item.set_menu_component)}
            </Link>
          </h1>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 nopadding cart_com cart_qty">
          <div className="counter-bg new_cart">
            <div className="subh count-div new_cart_bub">
              <Link className="btn btn-my btn-left btn-group-mini" onClick={this.incQty.bind(this, item.cart_item_id, item.cart_item_qty, item.cart_item_product_id, "remove", item.cart_voucher_order_item_id)}>
                <i className="material-icons">remove</i>
              </Link>
              &nbsp;<span className="head22">{item.cart_item_qty}</span>{" "}
              <Link className="btn btn-my btn-right btn-group-sm" onClick={this.incQty.bind(this, item.cart_item_id, item.cart_item_qty, item.cart_item_product_id, "add", item.cart_voucher_order_item_id)}>
                <i className="material-icons">add</i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 unit-price cart_com cart_price">
          <h1 className="head222 pull-right">{item.cart_item_total_price} </h1>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 del-icon cart_com cart_delet">
          <h1 className="head22 color-light-gray">
            <Link style={{ cursor: "pointer", textDecoration: "none" }} onClick={this.deleteCartItem.bind(this, item.cart_item_id)}>
              <i className="fa fa-trash" aria-hidden="true"></i>
            </Link>
          </h1>
        </div>
      </div>
    ))
  }

  showProAvblErrorMgs(cartItms) {
    // console.log(cartItms); return false;
    var errorMgs = ""
    var availability = cookie.load("defaultAvilablityId")
    var showtext = "delivery/pickup"
    if (availability === config.ids.deliveryId) {
      showtext = "delivery"
    } else if (availability === config.ids.pickupId) {
      showtext = "pickup"
    }
    if (cartItms.pro_current_datetime_avl === "no") {
      errorMgs = "This product is not available for order today. Please refer to the product description."
    } else if (cartItms.pro_fullfill_datetime_avl === "no") {
      errorMgs = "This product is not available for " + showtext + " on the selected date and time. Please refer to the product description."
    }

    if (errorMgs !== "") {
      return <div className="pro-avbl-errormsg">{errorMgs}</div>
    }
  }

  showProStockErrorMgs(cartItms) {
    var errorMgs = ""
    var availability = cookie.load("defaultAvilablityId")
    var showtext = "delivery/pickup"
    if (availability === config.ids.deliveryId) {
      showtext = "delivery"
    } else if (availability === config.ids.pickupId) {
      showtext = "pickup"
    }
    if (cartItms.pro_current_stock_avl === "no") {
      errorMgs = "The selected quantity is not available for order."
    } else if (cartItms.pro_fullfill_stock_avl === "no") {
      errorMgs = "The selected quantity is not available for " + showtext + " on the selected date and time."
    }

    if (errorMgs !== "") {
      return <div className="pro-avbl-errormsg">{errorMgs}</div>
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length
    var comboLen = combo.length
    var html = "<ul className='table_list cart_sub_items mod'>"

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"]
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"]
        html += "<li className='sub-item'>" + modName + " : " + modval + "</li> "
      }
      html += "</ul>"
      var reactElement = htmlToReactParserTo.parse(html)
      return reactElement
    } else if (itemType === "Component" && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"]
        //var comboVal =combo[i]['product_details'][0]['cart_menu_component_product_name'];
        var comboVal = this.showComboProducts(combo[i]["product_details"])
        html += " <li className='sub-item'><b>" + comboName + " :</b>" + comboVal + " " + this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) + "</li> "

        //html += "<li className='sub-item'><b>"+comboName+" :</b>"+comboVal+" "+ "</li> ";
      }
      html += "</ul>"
      var reactElement = htmlToReactParserTo.parse(html)
      return reactElement
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length
    var html = " "
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]["cart_menu_component_product_name"]
        var comboQty = combos[r]["cart_menu_component_product_qty"]
        var comboPrice = combos[r]["cart_menu_component_product_price"]
        var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : ""
        html += "<li className='sub-item'>" + comboQty + " X " + strp.stripslashes(helper.getAliasName(comboPro)) + newPrice + " </li> "
      }
      return html
    }
    return ""
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length
    var html = "<ul className='table_list cart_sub_items combo'>"
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"]
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"]
        var modValPrice = modifiers[i]["modifiers_values"][0]["cart_modifier_price"]
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : ""

        html += "<li className='sub-item'>" + modName + ": " + modval + newModValPrice + "</li> "
      }
      html += "</ul>"
      // var reactElement = htmlToReactParserTo.parse(html );
      return html
    }

    return ""
  }

  /* this function used to load cart itmes count */
  loadiItemsCount(key) {
    if (key === 0) {
      cookie.save("cartCount", this.state.cartDetails.cart_total_items)
      return (
        <p className="cart-text">
          You have {this.state.cartDetails.cart_total_items} item(s) in your cart{" "}
          <span className="pull-right">
            <Link style={{ cursor: "pointer", textDecoration: "none" }} onClick={this.destroyCart.bind(this, "Yes")}>
              Clear Cart
            </Link>
          </span>{" "}
        </p>
      )
    }
  }

  /* this function used to load delivery content */
  loadDeliveryContent(availblity) {
    var DelAmount = parseFloat(this.state.cartDetails.cart_delivery_charge)
    if (isNaN(DelAmount)) {
      var delivery_amount = parseFloat(cookie.load("cart_delivery_charge"))
    } else {
      delivery_amount = parseFloat(this.state.cartDetails.cart_delivery_charge)
    }

    var voucher_new = cookie.load("vocuherItems")

    if (availblity === config.ids.deliveryId && delivery_amount > 0 && voucher_new != "1") {
      return (
        <tr>
          <td className="sub-price text-left">Delivery</td>
          <td className="sub-num text-right ">{parseFloat(delivery_amount).toFixed(2)}</td>
        </tr>
      )
    }
  }

  /* load GST  amount */
  loadTaxAmount(subTotal) {
    var outlet_tax_option = cookie.load("outlet_tax_option")
    var outlet_tax_surcharge = cookie.load("outlet_tax_surcharge")
    var outlet_tax_surcharge_inclusive = cookie.load("outlet_tax_surcharge_inclusive")

    if (outlet_tax_option == 1 && outlet_tax_surcharge > "0.00" && this.state.cartDetails.cart_availability_id != config.ids.dineinId) {
      var gstAmount = outlet_tax_surcharge
    } else if (outlet_tax_option == 1 && outlet_tax_surcharge_inclusive > "0.00" && this.state.cartDetails.cart_availability_id != config.ids.dineinId) {
      var gstAmount = "0.00"
    } else {
      var gstAmount = parseFloat(this.state.settings.client_tax_surcharge)
    }

    var subTotal_delAdditnlAmount = subTotal

    if (this.state.cartDetails.cart_availability_id === config.ids.deliveryId) {
      if (isNaN(this.state.additnl_deliveryAmnt_cal)) {
        var subTotal_delAdditnlAmount = parseFloat(cookie.load("cart_delivery_charge")) + parseFloat(subTotal)
      } else {
        var subTotal_delAdditnlAmount = parseFloat(this.state.additnl_deliveryAmnt_cal) + parseFloat(this.state.cartDetails.cart_delivery_charge) + parseFloat(subTotal)
      }
    }

    var displayGst = helper.getGstValue(gstAmount, subTotal_delAdditnlAmount, "format")

    var voucher_new = cookie.load("vocuherItems")

    if (gstAmount > 0 && voucher_new != "1") {
      return (
        <tr>
          <td className="sub-price text-left">GST({gstAmount}%)</td>
          <td className="sub-num text-right ">{displayGst}</td>
        </tr>
      )
    }
  }

  /* load gstInclusive  amount */

  showGstInclusive(subTotal) {
    var outlet_tax_option = cookie.load("outlet_tax_option")
    var outlet_tax_surcharge = cookie.load("outlet_tax_surcharge")
    var outlet_tax_surcharge_inclusive = cookie.load("outlet_tax_surcharge_inclusive")

    if (outlet_tax_option == 1 && this.state.cartDetails.cart_availability_id != config.ids.dineinId) {
      if (outlet_tax_surcharge_inclusive > "0.00") {
        var gstAmount = parseFloat(outlet_tax_surcharge)
        var gstInclusive = parseFloat(outlet_tax_surcharge_inclusive)
      } else {
        var gstAmount = parseFloat(outlet_tax_surcharge)
        var gstInclusive = "0.00"
      }
    } else {
      var gstAmount = parseFloat(this.state.settings.client_tax_surcharge)
      var gstInclusive = parseFloat(this.state.settings.client_tax_surcharge_inclusive)
    }

    var subTotal_delAdditnlAmount = subTotal

    if (this.state.cartDetails.cart_availability_id === config.ids.deliveryId) {
      var subTotal_delAdditnlAmount = parseFloat(this.state.additnl_deliveryAmnt_cal) + parseFloat(this.state.cartDetails.cart_delivery_charge) + parseFloat(subTotal)
    }

    var displayInclusiveGst = helper.getInclusiveGst(gstInclusive, subTotal_delAdditnlAmount, "format")
    var voucher_new = cookie.load("vocuherItems")
    if (gstAmount === 0 && displayInclusiveGst != "") {
      return (
        <tr>
          <td className="text-right displayInclusiveGst" colSpan={2}>
            {displayInclusiveGst}
          </td>
        </tr>
      )
    }
  }

  /* this function used to load additional delivery content */
  loadadditionalDeliveryContent(availblity) {
    /*var delAmount = parseFloat(this.state.settings.additional_delivery_charge);*/
    var delAmount = parseFloat(this.state.additnl_deliveryAmnt_cal)

    var voucher_new = cookie.load("vocuherItems")

    if (availblity === config.ids.deliveryId && delAmount > 0 && voucher_new != "1") {
      return (
        <tr>
          <td className="sub-price text-left">Additional Surcharge</td>
          <td className="sub-num text-right ">{delAmount.toFixed(2)}</td>
        </tr>
      )
    }
  }

  /* show final tottal */
  showFinalTotal(grandTotal) {
    var subTotal = parseFloat(this.state.cartDetails.cart_sub_total)

    var outlet_tax_option = cookie.load("outlet_tax_option")
    var outlet_tax_surcharge = cookie.load("outlet_tax_surcharge")
    var outlet_tax_surcharge_inclusive = cookie.load("outlet_tax_surcharge_inclusive")

    if (outlet_tax_option == 1 && outlet_tax_surcharge > "0.00" && this.state.cartDetails.cart_availability_id != config.ids.dineinId) {
      var gstAmount = outlet_tax_surcharge
    } else if (outlet_tax_option == 1 && outlet_tax_surcharge_inclusive > "0.00" && this.state.cartDetails.cart_availability_id != config.ids.dineinId) {
      var gstAmount = "0.00"
    } else {
      var gstAmount = parseFloat(this.state.settings.client_tax_surcharge)
    }

    // var subTotal_delivery_amt = subTotal;
    // if(this.state.cartDetails.cart_availability_id == config.ids.deliveryId) {
    // var subTotal_delivery_amt = parseFloat(this.state.additnl_deliveryAmnt_cal) + parseFloat(this.state.cartDetails.cart_delivery_charge) + subTotal;
    // }

    var subTotal_delivery_amt = subTotal

    if (this.state.cartDetails.cart_availability_id === config.ids.deliveryId) {
      if (isNaN(parseFloat(this.state.additnl_deliveryAmnt_cal) && parseFloat(this.state.cartDetails.cart_delivery_charge))) {
        var subTotal_delivery_amt = parseFloat(cookie.load("cart_delivery_charge")) + parseFloat(subTotal)
      } else {
        subTotal_delivery_amt = parseFloat(this.state.additnl_deliveryAmnt_cal) + parseFloat(this.state.cartDetails.cart_delivery_charge) + parseFloat(subTotal)
      }
    } /*var displayGst = helper.getGstValue(gstAmount,subTotal,'format');*/

    var displayGst = helper.getGstValue(gstAmount, subTotal_delivery_amt, "format")

    /*var delAmount = parseFloat(this.state.settings.additional_delivery_charge) + parseFloat(grandTotal) + parseFloat(displayGst);*/

    var voucher_new = cookie.load("vocuherItems")

    if (voucher_new != "1") {
      var delAmount = parseFloat(this.state.additnl_deliveryAmnt_cal) + parseFloat(grandTotal) + parseFloat(displayGst)
    } else {
      var delAmount = subTotal
    }

    if (isNaN(delAmount)) {
      //return "";
      //var defaultAmount = parseFloat(this.state.cartDetails.cart_sub_total) + parseFloat(cookie.load("cart_delivery_charge")) + parseFloat(displayGst);
      //return defaultAmount.toFixed(2);
    } else {
      return delAmount.toFixed(2)
    }
  }

  /* this  function used to load min order percentage */
  loadMinOrderPercentage(availblity) {
    var minAmount = this.state.settings.callcnter_min_amount
    var remainAmnt = this.state.minOrderRemaingAmnt
    if (availblity === config.ids.deliveryId && minAmount > 0 && remainAmnt > 0) {
      return (
        <div>
          <div className="progress">
            <div className={"progress-bar progress-bar-danger"} style={{ width: this.state.minOrderPercentage + "%" }}></div>
          </div>
          <p className="text-center">
            <b>{currency + this.state.minOrderRemaingAmnt} more to min order amount.</b>
          </p>
        </div>
      )
    }
  }

  /* this  function used to load delivery percentage */
  loadDeliveryPercentage(availblity) {
    var freeDeliveryAmnt = this.state.settings.client_free_delivery_amount
    var remainAmnt = this.state.remaingAmnt
    if (availblity === config.ids.deliveryId && (this.state.cartDetails.cart_delivery_charge > 0 || this.state.additnl_deliveryAmnt_cal > 0) && freeDeliveryAmnt > 0 && remainAmnt > 0) {
      return (
        <div>
          <div className="progress">
            <div className={"progress-bar progress-bar-danger"} style={{ width: this.state.delPercentage + "%" }}></div>
          </div>
          <p className="text-center">
            <b>Spend {currency + this.state.remaingAmnt} more to get free delivery!</b>
          </p>
        </div>
      )
    }
  }

  /* this function used to change delivery amount */
  changeDeliveryAmount(amnt, grandTotal) {
    this.setState({ cartDetails: Object.assign({}, this.state.cartDetails, { cart_delivery_charge: amnt, cart_grand_total: grandTotal }) })
    //  this.setState({cartDetails: Object.assign({}, this.state.cartDetails, {cart_delivery_charge: amnt})});
  }

  /* this metod used to validate cart delvery amount and next redirect URL */
  validateCart() {
    if (this.state.cartDetails.cart_availability_id === config.ids.deliveryId) {
      var subtotal = parseFloat(this.state.cartDetails.cart_sub_total)
      var minCart = parseFloat(this.state.settings.callcnter_min_amount)
      if (subtotal < minCart) {
        helper.showInfo("A minimum order value of " + currency + minCart + " is required in order to checkout the cart. The current cart value is " + currency + subtotal + ".")
        return false
      }
      //hashHistory.push("/add-on");
    } else {
      //hashHistory.push("/add-on");
    }
    this.nextpage()
  }

  /* Focus class name */
  isClassName() {
    if (typeof cookie.load("product_remarks") !== "undefined" && cookie.load("product_remarks") !== "") {
      return "form-group label-floating card-no is-focused special_instructions"
    } else {
      return "form-group label-floating card-no is-empty special_instructions"
    }
  }

  /* show next page link */
  nextpage() {
    if (typeof cookie.load("UserId") === "undefined" || (cookie.load("UserId") && cookie.load("UserId") === "")) {
      hashHistory.push(this.getSignInlink())
    } else {
      if (cookie.load("defaultAvilablityId") === config.ids.dineinId) {
        /* only for sarvesh app */
        if (APPID === "287E5552-328E-4DBE-A3CA-E998ACCAC9BD") {
          helper.sarveshTableSetup()
        } else {
          if (typeof cookie.load("orderOutletId") !== "undefined" && cookie.load("orderOutletId") !== "" && typeof cookie.load("orderTableNo") !== "undefined" && cookie.load("orderTableNo") !== "") {
            hashHistory.push("/checkout")
          } else {
            hashHistory.push("/book-a-table")
          }
        }
      } else {
        if (this.state.settings.client_advanced_timeslot_enable === "1") {
          hashHistory.push("/delivery-date-advanced")
        } else if (this.state.settings.client_delivery_time_setting_enable === "1") {
          hashHistory.push("/delivery-date-new")
        } else {
          hashHistory.push("/delivery-date")
        }
      }
    }
  }

  idReMarks() {
    if (typeof cookie.load("product_remarks") !== "undefined" && cookie.load("product_remarks") !== "") {
      return cookie.load("product_remarks")
    } else {
      return ""
    }
  }

  componentDidMount() {
    loadAnalytics()
  }
  render() {
    var reMarkClass = cookie.load("product_remarks") !== "" ? "is-focused" : ""
    var reMarkVal = cookie.load("product_remarks") !== "" ? cookie.load("product_remarks") : ""
    if (this.state.status === "Loading") {
      return (
        <div className="container-fluid desktop-container position-rel" style={{ minHeight: this.state.windowHeightNormal }}>
          <div className="row">
            <Loading />
          </div>
        </div>
      )
    } else if (this.state.cartDetails.cart_total_items > 0)
      return (
        <div>
          <DocumentMeta {...meta} />
          <div className="container-fluid desktop-container" style={{ minHeight: this.state.windowHeight }}>
            <div className="row">
              <div className="col-xs-12 top-nav inner-top-nav head-main">
                <div className="col-xs-3 nav-head pd-l-20 head-child">
                  <Link to={"/"}>
                    <img src="/img/icons/arrow-right.png" className="icon-img-small-3" alt="left-arrow" />
                  </Link>
                </div>

                <div className="col-xs-6 nav-head  head-child">
                  <h1 className="main-title">CART</h1>
                </div>
                <div className="col-xs-3 nopadding  head-child"></div>
              </div>
            </div>

            {this.loadCartItems()}

            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-7"></div>

              <div className="col-sm-5">
                <table className="sub-price-table" style={{ border: 0 }}>
                  <tbody>
                    <tr>
                      <td className="sub-price text-left">Sub Total</td>
                      <td className="sub-num text-right ">{parseFloat(this.state.cartDetails.cart_sub_total).toFixed(2)}</td>
                    </tr>

                    {this.loadDeliveryContent(this.state.cartDetails.cart_availability_id)}

                    {this.loadadditionalDeliveryContent(this.state.cartDetails.cart_availability_id)}

                    {this.loadTaxAmount(this.state.cartDetails.cart_sub_total)}

                    <tr>
                      <td colSpan="2">
                        <hr></hr>
                      </td>
                    </tr>
                    <tr>
                      <td className="total">Total</td>
                      <td className=" text-right total-num">{this.showFinalTotal(this.state.cartDetails.cart_grand_total)}</td>
                    </tr>
                    {this.showGstInclusive(this.state.cartDetails.cart_sub_total)}
                  </tbody>
                </table>

                {this.loadMinOrderPercentage(this.state.cartDetails.cart_availability_id)}
                {this.loadDeliveryPercentage(this.state.cartDetails.cart_availability_id)}
              </div>

              <div className="clearfix"></div>
              <div className="space-5"></div>

              <div className="col-xs-12">
                <div className={this.isClassName()}>
                  <div className="input-group">
                    <label className="control-label" htmlFor="remarks">
                      {APPID === "CD8D5FE3-0586-44DD-9360-EE9DEF351223" ? "Please enter your Berth number , Quay Crane and Yard location here" : "Special Instructions..."}
                    </label>
                    <textarea type="text" id="remarks" defaultValue={this.idReMarks()} className={APPID === "CD8D5FE3-0586-44DD-9360-EE9DEF351223" ? "form-control port_makan cart_text_area" : "form-control cart_text_area"} cols="5" rows="2" onChange={this.handleChange}></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-5"></div>
            <div className="space-5"></div>
            <div className="space-5"></div>
            <div className="space-5"></div>

            <div className="row">
              <Link onClick={this.validateCart.bind(this)}>
                <button className="col-lg-12 continue-but desktop-container main-title container-fluid">CONTINUE</button>
              </Link>{" "}
            </div>
          </div>

          {/* For Pickup Success */}

          <div className="modal" id="Pickup-Sucess">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/DeliverySucess.png" className="img-responsive" alt="" />
                    Awesome, you can pickup !
                  </h4>
                </div>
                <div className="modal-body text-center">
                  {this.showDeliveryTiming}
                  <br />
                  <button type="button" onClick={this.pickupSuccess.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* For shop closed error */}

          <div className="modal" id="ShopClosedError">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/Cant_Deliver.png" className="img-responsive" alt="" />
                    Sorry !
                  </h4>
                </div>
                <div className="modal-body">
                  <p>
                    <b>Outlet Closed Temporarily. Please Try again later</b>{" "}
                  </p>
                  <br />
                  <div className="col-sm-12">
                    <button type="button" onClick={this.closeShopClosedError.bind(this)} className="col-lg-6 btn btn-raised btn-info fullwidth ">
                      Check Later
                    </button>
                  </div>

                  <br />
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* For Delivery error */}

          <div className="modal" id="DeliveryError">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/Cant_Deliver.png" className="img-responsive" alt="" />
                    Sorry !
                  </h4>
                </div>
                <div className="modal-body">
                  <p>
                    Sorry we cant deliver to your address <br />
                    Do you want to change your address or check again later ?
                  </p>
                  <br />
                  <div className="col-sm-6">
                    <button type="button" onClick={this.closeDeliveryErr.bind(this)} className="col-lg-6 btn btn-raised btn-info fullwidth ">
                      Check Later
                    </button>
                  </div>
                  <div className="col-sm-6">
                    <button type="button" onClick={this.changeDelivery.bind(this)} className="col-lg-6 btn btn-raised btn-info fullwidth ">
                      Change Address
                    </button>
                  </div>
                  <br />
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* For Delivery Success */}
          <div className="modal" id="DeliverySucess">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/DeliverySucess.png" className="img-responsive" alt="thumb-up" />
                    Awesome, we can deliver!
                  </h4>
                </div>
                <div className="modal-body text-center">
                  <p>
                    <b>Your Delivery Address: </b>
                    <br />
                    {this.state.orderDeliveryAddress}
                  </p>
                  {this.showdeliveryTiming}
                  <br />
                  <button type="button" onClick={this.deliverySuccess.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* For delivery popup */}

          <div className="modal" id="DeliveryAddress">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                  </button>
                  <h4 className="modal-title">Your Delivery Address</h4>
                </div>
                <Overlay load={this.state.load} />
                <div className="modal-body">
                  <div className="form-group label-floating is-empty card-no">
                    <div className="input-group">
                                                          <label className="control-label">Enter Your Postal Code</label>
                                                          
                      <input value={this.state.addressField} id="postalcode1" maxLength="6" className="form-control" onKeyPress={(e) => this.validatezip(e)} onChange={this.handleChangeValue} />
                                                      
                    </div>
                                               
                  </div>
                  <br />
                  <button type="button" onClick={this.selectOutlet.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* after login user popup */}
          <div className="modal" id="AddressList">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/DeliverySucess.png" className="img-responsive" alt />
                    Your Delivery Address
                  </h4>
                </div>
                <Overlay load={this.state.load} />
                <div className="modal-body" style={{ textAlign: "left" }}>
                  {/* {this.loadDefaultAddress()} */}
                  {this.loadAddressList()}
                  <p>
                             <input value={this.state.addressField} onChange={this.handleChangeValue} id="postalcode" maxLength="6" placeholder="Enter Your Postal Code" className="add_list" onKeyPress={(e) => this.validatezip(e)} />
                            {" "}
                  </p>
                  <br />
                     {" "}
                  <button type="button" onClick={this.selectOutlet.bind(this)} className="btn btn-raised btn-info ">
                    Continue
                  </button>
                          
                  <br />
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          {/* For takeaway popup */}

          <div className="modal" id="Pickup-OutletSelection">
            <div className="modal-dialog locWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="close" />
                  </button>
                  <h4 className="modal-title">Select Your Pickup Location</h4>
                </div>
                <Overlay load={this.state.load} />
                <div className="modal-body">
                  <div className="map-loc">
                    {/* <Map markers={this.state.markers} /> */}

                    <div className="map-box scrollbar" id="style-3">
                      {this.__loadOutlets()}
                    </div>

                    <br />
                    <br />
                  </div>
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>

          <div className="modal fade swithErrorCls" id="SwitchingError">
            <div className="modal-dialog PopupWidth">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    <img src="/img/icons/modal-close.png" className="img-responsive" alt="" />
                  </button>
                  <h4 className="modal-title">
                    <img src="/img/icons/SwitchingError.png" className="img-responsive" alt="" />
                    Wait a second !
                  </h4>
                </div>
                <div className="modal-body">
                  <p>
                    By switching you are about to clear your cart. <br />
                    Do you wish to proceed ?
                  </p>
                  <br />
                  <div className="col-sm-6">
                    <button type="button" onClick={this.closeSwitcher.bind(this)} className="btn btn-raised btn-info fullwidth">
                      Cancel
                    </button>
                  </div>
                  <div className="col-sm-6">
                    <button type="button" className="col-lg-6 btn btn-raised btn-info fullwidth " onClick={this.switchAvailability.bind(this)}>
                      Yes Proceed
                    </button>
                  </div>
                  <br />
                  <br />
                  <br />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
        </div>
      )
    else {
      return (
        <RouteTransition pathname={this.props.location.pathname} atEnter={{ translateX: -10 }} atLeave={{ translateX: -10 }} atActive={{ translateX: 0 }} mapStyles={(styles) => ({ transform: `translateX(${styles.translateX}%)` })}>
          {" "}
          <div className="container-fluid desktop-container" style={{ minHeight: this.state.windowHeightNormal }}>
            <DocumentMeta {...meta} />
            <div className="row">
              <div className="col-xs-12 top-nav inner-top-nav head-main">
                <div className="col-xs-3 nav-head pd-l-20 head-child">
                  <Link to={"/"}>
                    <img src="/img/icons/arrow-right.png" className="icon-img-small-3" alt="left-arrow" />
                  </Link>
                </div>

                <div className="col-xs-6 nav-head head-child">
                  <h1 className="main-title">CART</h1>
                </div>
                <div className="col-xs-3 nopadding head-child"></div>
              </div>
            </div>

            <div className="row product-holder1">
              <div className="empty-cart-wrap">
                <div className="rgt-top-img">
                  <img src="img/cart-rgt-top.jpg" alt="" />
                </div>
                <div className="empty-cart-order">
                  <img className="img-responsive" src="img/empty-cart.png" alt="" />
                  <h3>Cart is empty</h3>
                  <p>You have to add something first</p>
                  <Link to={"/"} className="btn btn-cart-order">
                    Order Now
                  </Link>
                </div>
                <div className="lft-btm-img">
                  <img src="img/cart-left-btm.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>{" "}
        </RouteTransition>
      )
    }
  }
}
export default Cart

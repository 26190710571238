/* eslint-disable */
import React from "react"
import { loadAnalytics } from "./withTracker"
import { hashHistory } from "react-router"
import axios from "axios"
import cookie from "react-cookie"
import DocumentMeta from "react-document-meta"
import Loading from "./loading"

import config from "./config"
import lang from "./lang"
const APIURL = config.path.apiUrl
const APPID = config.ids.appId
var helper = require("./Helper.js")

/* Load meta tags */
const meta = {
  title: lang.activation_meta_title,
  description: lang.activation_meta_title,
  meta: {
    name: {
      keywords: lang.activation_meta_title,
    },
  },
}

class Quickbook extends React.Component {
  componentWillMount() {
    /* add class */
    helper.addBodyClass()
  }
  /* Account activation */
  componentDidMount() {
    loadAnalytics()
    var qs = require("qs")
    let tableNo = this.props.params.tableNo

    /* for account activation - start */

    if (typeof tableNo !== "undefined" && tableNo !== "") {
      // return false;
      var postObject = {
        app_id: APPID,
        tm_table_number: tableNo,
      }
      axios.post(APIURL + "tablemanagement/find_table", qs.stringify(postObject)).then((response) => {
        /* Success response */
        if (response.data.status === "ok") {
          let errorMsg = response.data.message

          cookie.save("orderOutletId", response.data.result_set[0].outlet_id)
          cookie.save("orderOutletName", response.data.result_set[0].outlet_name)
          cookie.save("orderTableNo", tableNo)

          cookie.save("outlet_tax_option", response.data.result_set[0].outlet_tax_option)
          cookie.save("outlet_tax_surcharge", response.data.result_set[0].outlet_tax_surcharge)
          cookie.save("outlet_tax_surcharge_inclusive", response.data.result_set[0].outlet_tax_surcharge_inclusive)

          var tatVal = response.data.result_set[0].outlet_dine_tat !== "" ? response.data.result_set[0].outlet_dine_tat : response.data.result_set[0].outlet_delivery_timing

          cookie.save("orderTAT", tatVal)

          //cookie.save('siteURL','');
          cookie.save("dineinFrom", "homepage")
          cookie.save("defaultAvilablityImage", lang.dineInImageWhite)
          cookie.save("defaultAvilablityId", config.ids.dineinId)
          cookie.save("defaultAvilablity", "Dine In")
          cookie.save("onetimeClick", "Yes")

          // helper.showInfo('success! you are ready for ordering');
          hashHistory.push("/")
        }

        /* Success response */
        if (response.data.status === "error") {
          helper.showInfo("sorry this table not available now please try again later")
          hashHistory.push("/")
        }
        /* error reponse */
      })
    } else {
      hashHistory.push("/")
    }
    /* for account activation - end  */
  }

  render() {
    return (
      <div>
        <DocumentMeta {...meta} />
        {/*<Loading/> */}
        <div className="chk_popup act_loder" id="PayProcess" style={{ position: "unset" }}>
          <div className="white-outer center-block process_order">
            <div className="row ">
              <div className="col-xs-12 padd2">
                <h4>Please wait while we check the availability of the table</h4>
                <div className="clock_part">
                  <img src="/img/spinner.gif" alt="" />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Quickbook
